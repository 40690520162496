import React from "react";
import { ITag } from "~/interfaces/common";
import { FAV_TAG } from "~/hooks/useTalentFilterSettings";
import cn from "classnames";
import { InputSwitch } from "primereact/inputswitch";
import { Checkbox, CheckboxChangeEvent } from "primereact/checkbox";
import ApTag from "~/components/common/ApTag/ApTag";

interface IProps {
  tagsList: ITag[];
  tagsOperator: string;
  tagsFilter: number[];
  onSwitchChange: (e: CheckboxChangeEvent) => void;
  onTagChange: (e: CheckboxChangeEvent) => void;
}

function TagsFilter(props: IProps) {
  const { tagsList, onSwitchChange, tagsOperator, onTagChange, tagsFilter } = props;

  return (
    <div className="tags-filter">
      <div className="tags-filter-switch flex align-items-center mt-1 mb-3">
        <InputSwitch
          onChange={onSwitchChange}
          checked={tagsOperator}
          trueValue={"AND"}
          falseValue={"OR"}
          className="mr-2"
        />
        <span className="tags-filter-switch-label">
          {tagsOperator === "OR" ? "Include at least 1 selected item" : "Include all selected item(s)"}
        </span>
      </div>
      {tagsList.map((tag: ITag) => {
        const isFavouriteTag = tag.name === FAV_TAG.name;
        return (
          <div key={tag.id} className="tags-filter-checkbox flex">
            <Checkbox
              inputId={String(tag.id)}
              name="tags"
              value={tag.id}
              onChange={onTagChange}
              checked={tagsFilter?.some((id) => id === tag.id)}
              className="mr-2"
            />
            <ApTag
              fill={tag?.color}
              title={tag.name}
              icon={isFavouriteTag ? "pi pi-star-fill" : undefined}
              className={cn("small-tag", isFavouriteTag && "no-padding")}
            />
          </div>
        );
      })}
    </div>
  );
}

export default TagsFilter;
