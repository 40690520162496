import React, { CSSProperties } from "react";
import { Tooltip } from "primereact/tooltip";

export interface ITooltipIcon {
  id: string | number;
  content?: string;
  position?: "top" | "bottom" | "left" | "right";
  style?: CSSProperties;
}

export default function TooltipIcon({ id, content, position = "top", style }: ITooltipIcon) {
  return (
    <>
      <Tooltip target={`.tooltip-icon-${id}`} position={position} />
      <span
        className={`pi pi-question-circle tooltip-icon-${id}`}
        data-pr-tooltip={content}
        style={{ verticalAlign: "text-bottom", ...style }}
      />
    </>
  );
}
