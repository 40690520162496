import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import ApDropdown from "~/components/common/ApDropdown/ApDropdown";
import { maxSymbolsMessage, minSymbolsMessage } from "~/utils";

export default function FilterNameForm({ values, setValues, setIsValid, filters }: any) {
  const [isErrors, setIsErrors] = useState<any>(true);
  useEffect(() => {
    setIsValid(false);
  }, []);

  useEffect(() => {
    setIsValid(!isErrors?.filterName && values?.filterName?.length > 0);
  }, [isErrors]);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        filterName: "",
      }}
      validationSchema={Yup.object().shape({
        filterName: Yup.string()
          .required("Filter name is required")
          .min(3, minSymbolsMessage)
          .max(50, maxSymbolsMessage),
      })}
      onSubmit={() => {}}
      innerRef={(formikActions) => (formikActions ? setIsErrors(formikActions.errors) : setIsErrors(true))}
    >
      {(formik: any) => (
        <Form className="w-full mb-6">
          <ApDropdown
            editable
            id="filterName"
            className="w-full"
            formik={formik}
            noField
            options={filters || []}
            optionLabel="name"
            optionValue="id"
            value={formik.values.filterName}
            placeholder="Enter a new name or update the existing filter"
            onChange={(e) => {
              const filterName =
                typeof e.value === "number" ? filters.find(({ id }: { id: number }) => id === e.value)?.name : e.value;
              formik.setFieldValue("filterName", filterName);
              setValues({ filterName });
              setIsErrors(true);
            }}
            appendTo={"self"}
          />
        </Form>
      )}
    </Formik>
  );
}
