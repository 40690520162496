import { Calendar, CalendarDateTemplateEvent } from "primereact/calendar";
import { Tooltip } from "primereact/tooltip";
import React, { useEffect, useMemo, useState } from "react";
import cn from "classnames";
import "./ApAvailabilityCalendar.scss";
import moment from "moment";
import useTalent from "~/hooks/useTalent";
import Loader from "~/components/Loader/Loader";
import { ITalentAvailability, TalentType } from "~/store/constants/talent";
import { pluralize } from "~/utils";

export enum SelectionMode {
  range = "range",
  multiple = "multiple",
}

export enum CalendarType {
  AVAILABILITY = "AVAILABILITY",
  CAPACITY = "CAPACITY",
  BRIEF_DATES = "BRIEF_DATES",
}

export type DateItem = { date: Date; capacity?: number };

type Props = {
  talentId?: number;
  dates?: DateItem[] | Date[];
  talentType?: TalentType;
  selectionMode: SelectionMode;
  disabledDays?: number[];
  onChange?: any;
  value?: Date | Date[];
  selectable?: boolean;
  capacity?: number;
  calendarType?: CalendarType;
};

const LegendItems = {
  green: "available",
  yellow: "partial availability",
  red: "unavailable",
  grey: "NWD",
};

const getRoundedTime = (time: number) => {
  const roundedTime = Math.round(time * 4) / 4; //rounding to nearest 0.25
  return `${roundedTime} ${pluralize(roundedTime, "hour")}`;
};

export default function ApAvailabilityCalendar({
  talentId,
  dates,
  talentType,
  selectionMode = SelectionMode.range,
  disabledDays,
  onChange,
  value,
  selectable = false,
  capacity,
  calendarType,
}: Props) {
  const { getAvailabilityCalendar, isFetchingAvailabilityCalendar } = useTalent();
  const { isMultipleSelection, defaultDate } = useMemo(() => {
    const isMultiple = selectionMode === SelectionMode.multiple;
    const defaultDateValue = isMultiple ? dates?.[0]?.date ?? new Date() : dates?.[0];
    return { isMultipleSelection: isMultiple, defaultDate: defaultDateValue };
  }, [selectionMode, dates]);
  const [availability, setAvailability] = useState<ITalentAvailability[]>([]);
  const [selectedDate, setSelectedDate] = useState(defaultDate);
  const dateString = moment(selectedDate).format("YYYY-MM-DD");
  const viewDate = new Date(selectedDate);
  const isFreelancer = useMemo(() => {
    if (!talentId) return false;
    return talentType === TalentType.FREELANCER;
  }, [talentId, talentType]);
  const isBriefCalendar = calendarType === CalendarType.BRIEF_DATES;

  useEffect(() => {
    if (!talentId) return;
    !isBriefCalendar && getAvailabilityCalendar(talentId, dateString).then((data) => setAvailability(data));
  }, [talentId, dateString]);

  useEffect(() => {
    !selectable && setSelectedDate(defaultDate);
  }, [dates]);

  function renderDateTemplate(date: CalendarDateTemplateEvent) {
    const formattedMonth = `${(date.month + 1).toString().padStart(2, "0")}`;
    const formattedDay = `${date.day.toString().padStart(2, "0")}`;
    const formattedDate = `${date.year}-${formattedMonth}-${formattedDay}`;

    const availabilityInfo = availability?.find((item) => item.date === formattedDate);
    const availabilityNumber = availabilityInfo ? availabilityInfo?.availability : 0;
    let availabilityHours = 0;
    if (availabilityInfo) {
      availabilityHours = Math.max(0, availabilityInfo.available_hours - availabilityInfo.busy_hours);
    }

    const formattedMomentDate = moment(formattedDate, "YYYY-MM-DD");

    let currentActiveDate = null;
    let isDateInRange = false;

    if (isMultipleSelection) {
      isDateInRange =
        dates?.some((d) => {
          const isSame = formattedMomentDate.isSame(moment(d.date), "date");
          if (isSame) currentActiveDate = d;
          return isSame;
        }) || false;
    } else {
      isDateInRange = dates?.length
        ? formattedMomentDate.isBetween(moment(dates[0]), moment(dates[1] || dates[0]), "day", "[]")
        : false;
    }

    let className;

    if (isBriefCalendar) {
      className = "green";
    } else if (isFetchingAvailabilityCalendar || !availabilityInfo || availabilityInfo.available_hours === 0) {
      className = "grey";
    } else if (availabilityNumber === 100) {
      className = "green";
    } else if (availabilityNumber > 0 && availabilityNumber < 100) {
      className = "yellow";
    } else {
      className = "red";
    }

    const tooltipContent = !isFreelancer
      ? `${getRoundedTime(availabilityHours)} available${
          selectionMode === "multiple" && selectable && currentActiveDate?.capacity
            ? `\n Capacity: ${currentActiveDate.capacity}%`
            : ""
        }`
      : "";

    return (
      <>
        {availabilityInfo && !isFreelancer && <Tooltip target={`._${formattedDate}`} />}
        <span
          className={cn("calendar_day", `_${formattedDate}`, className, isDateInRange && "is-selected")}
          data-pr-position="top"
          data-pr-at="center top-8"
          data-pr-tooltip={tooltipContent}
        >
          {date.day}
          {isDateInRange && (
            <span className="capacity-bar">
              <i style={{ width: `${selectionMode === "multiple" ? currentActiveDate.capacity : capacity}%` }} />
            </span>
          )}
        </span>
      </>
    );
  }

  function renderLegend(items: (keyof typeof LegendItems)[]) {
    return (
      <div className="availability-legend">
        {items.map((item, index) => (
          <span key={index} className="legend-item">
            <span className={`legend-icon ${item}`} />
            <span>{LegendItems[item]}</span>
          </span>
        ))}
      </div>
    );
  }

  return (
    <div className="AvailabilityCalendarContainer">
      {isFetchingAvailabilityCalendar && <Loader size={3} />}
      <Calendar
        className={cn("AvailabilityCalendar", selectable && "selectable")}
        dateTemplate={renderDateTemplate}
        dateFormat="YYYY-MM-DD"
        inline
        disabled={isFetchingAvailabilityCalendar}
        viewDate={viewDate}
        selectOtherMonths
        onViewDateChange={(date) => setSelectedDate(moment(date.value).format("YYYY-MM-DD"))}
        minDate={selectable ? new Date() : undefined}
        selectionMode={selectionMode}
        disabledDays={disabledDays || []}
        onChange={onChange && onChange}
        value={value ? value : null}
      />
      {!isBriefCalendar && renderLegend(isFreelancer ? ["green", "red"] : ["green", "yellow", "red", "grey"])}
    </div>
  );
}
