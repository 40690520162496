@import "../../mixins.scss";

.TalentPrimaryDetailsForm {
  .ApSelectButton .p-button {
    font-weight: 700;
    border-radius: 10px !important;
    height: 40px;
    margin: 0 5px;
    background-color: $lightRedColor;
    color: $brandColor;
    &:hover {
      background: #c8c8c8;
      border-color: #c8c8c8;
      color: #333;
    }
    &.p-highlight {
      background-color: $brandColor;
      color: #fff;
    }
  }
  .switch {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1.2rem;
    & label {
      margin-bottom: 0;
    }
  }
}

.step-content {
  max-width: 700px;
  margin: 1.5em auto;
  .p-card-body {
    padding: 2em;
  }
}
