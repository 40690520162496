import { AnyAction } from "redux";
import * as Skills from "../constants/skills";

// ------------------------------------
// Interface
// ------------------------------------
export interface ISkillsState {
  isFetching: boolean;
}

// ------------------------------------
// Initial state
// ------------------------------------
const initialState: ISkillsState = {
  isFetching: false,
};

// ------------------------------------
// Reducer
// ------------------------------------
export default (state = initialState, action: AnyAction): ISkillsState => {
  switch (action.type) {
    case Skills.SKILL_SAVE_FAILURE:
    case Skills.SKILL_SAVE_SUCCESS:
      return {
        ...state,
        isFetching: false,
      };
    case Skills.SKILL_SAVE_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    default:
      return state;
  }
};
