import asyncComponent from "../components/common/AsyncComponent";
import Auth from "./Auth/AuthView";
import * as permissionType from "~/constants/permissions";
import { IRoute } from "~/interfaces/routes";

const AsyncBrief = asyncComponent(() => import("./Brief/BriefView"));
const AsyncBriefs = asyncComponent(() => import("./Briefs/BriefsView"));
const AsyncBriefNew = asyncComponent(() => import("./CreateBrief/CreateBrief"));
const AsyncBriefDraft = asyncComponent(() => import("./CreateDraftBrief/CreateDraftBrief"));
const AsyncBriefRebook = asyncComponent(() => import("./CreateRebook/CreateRebook"));
const AsyncDirectBrief = asyncComponent(() => import("./DirectBrief/DirectBrief"));
const AsyncOrganisation = asyncComponent(() => import("./Organisation/OrganisationView"));
const AsyncOrganisations = asyncComponent(() => import("./Organisations/OrganisationsView"));
const AsyncOrganisationNew = asyncComponent(() => import("./OrganisationNew/OrganisationNewView"));
const AsyncContact = asyncComponent(() => import("./Contact/ContactView"));
const AsyncConversations = asyncComponent(() => import("./Conversations/ConversationsView"));
const AsyncTalents = asyncComponent(() => import("./Talents/TalentsView"));
const AsyncTalent = asyncComponent(() => import("./Talent/TalentView"));
const AsyncTalentQuick = asyncComponent(() => import("./TalentQuick/TalentQuick"));
const AsyncTalentTeamQuick = asyncComponent(() => import("./TalentQuick/TalentTeamQuick"));
const AsyncNotifications = asyncComponent(() => import("./Notifications/NotificationsView"));
const AsyncNotificationPreferences = asyncComponent(() => import("./Notifications/NotificationPreferences"));
const AsyncSkills = asyncComponent(() => import("./Skills/SkillsView"));
const AsyncPermissions = asyncComponent(() => import("./UserManagement/UserManagementView"));
const AsyncSettings = asyncComponent(() => import("./Settings/SettingsView"));
const AsyncProfile = asyncComponent(() => import("./Profile/ProfileView"));
const AsyncPopupManagement = asyncComponent(() => import("./PopupManagement/PopupManagementView"));
const AsyncRequests = asyncComponent(() => import("./Requests/RequestsView"));
const AsyncBookings = asyncComponent(() => import("./Bookings/BookingsView"));
const AsyncCreateProject = asyncComponent(() => import("./CreateProject/CreateProject"));
const AsyncProject = asyncComponent(() => import("./Project/ProjectView"));
const AsyncResources = asyncComponent(() => import("./Resources/ResourcesView"));

export default (): IRoute[] => {
  return [
    {
      protected: true,
      component: AsyncOrganisations,
      exact: true,
      path: "/organisations",
      homePageFor: [permissionType.accessAdmin],
    },
    {
      protected: true,
      component: AsyncResources,
      exact: true,
      path: "/resourcing",
      homePageFor: [permissionType.getOwnOrganisation, permissionType.getAnyOrganisation],
    },
    {
      protected: true,
      component: AsyncBriefNew,
      exact: true,
      path: "/briefs/new",
    },
    {
      protected: true,
      component: AsyncBriefDraft,
      exact: true,
      path: "/briefs/draft/:briefId",
    },
    {
      protected: true,
      component: AsyncBriefRebook,
      exact: true,
      path: "/briefs/direct_book",
    },
    {
      protected: true,
      component: AsyncDirectBrief,
      exact: true,
      path: "/briefs/direct_brief",
    },
    {
      protected: true,
      component: AsyncBrief,
      path: "/briefs/:briefId",
    },
    {
      protected: true,
      accessPermissions: [permissionType.accessAdmin],
      component: AsyncOrganisationNew,
      exact: true,
      path: "/organisations/new",
    },
    {
      protected: true,
      accessPermissions: [permissionType.getOwnOrganisation, permissionType.getAnyOrganisation],
      component: AsyncOrganisation,
      path: "/organisations/:organisationId",
    },
    {
      protected: true,
      component: AsyncTalents,
      exact: true,
      path: "/talents",
    },
    {
      protected: true,
      component: AsyncTalentQuick,
      exact: true,
      path: "/talents/new",
    },
    {
      protected: true,
      component: AsyncTalentTeamQuick,
      exact: true,
      path: "/talents/new_team",
    },
    {
      protected: true,
      component: AsyncTalent,
      path: "/talents/:talentId",
    },
    {
      protected: true,
      component: AsyncRequests,
      path: "/requests",
    },
    {
      protected: true,
      component: AsyncBookings,
      path: "/bookings",
    },
    {
      protected: true,
      component: AsyncNotifications,
      path: "/notifications",
    },
    {
      protected: true,
      component: AsyncConversations,
      exact: true,
      path: "/conversations",
    },
    {
      protected: true,
      accessPermissions: [permissionType.accessAdmin],
      component: AsyncContact,
      exact: true,
      path: "/contact",
    },
    {
      protected: true,
      accessPermissions: [permissionType.accessAdmin],
      component: AsyncSkills,
      path: "/skills",
    },
    {
      protected: true,
      accessPermissions: [permissionType.accessAdmin],
      component: AsyncPermissions,
      path: "/permissions",
    },
    {
      protected: true,
      accessPermissions: [permissionType.accessAdmin],
      component: AsyncSettings,
      exact: true,
      path: "/settings",
    },
    {
      protected: true,
      accessPermissions: [permissionType.accessOrganisationAdmin],
      component: AsyncNotificationPreferences,
      exact: true,
      path: "/notification-preferences",
    },
    {
      protected: true,
      component: AsyncProfile,
      exact: true,
      path: "/profile",
    },
    {
      protected: true,
      component: AsyncPopupManagement,
      accessPermissions: [permissionType.accessAdmin],
      exact: true,
      path: "/popups",
    },
    {
      protected: false,
      component: Auth,
      exact: true,
      path: "/login",
    },
    {
      protected: false,
      exact: true,
      component: Auth,
      path: "/sso",
    },
    {
      protected: false,
      component: Auth,
      exact: true,
      path: "/reset_password",
    },
    {
      protected: false,
      component: Auth,
      path: "/reset_password/:token",
    },
    {
      protected: true,
      component: Auth,
      exact: true,
      path: "/change-password",
    },
    {
      protected: true,
      component: AsyncCreateProject,
      exact: true,
      path: "/projects/new",
    },
    {
      protected: true,
      component: AsyncCreateProject,
      exact: true,
      path: "/projects/draft/:id",
    },
    {
      protected: true,
      component: AsyncProject,
      exact: true,
      path: "/projects/:id",
    },
  ];
};
