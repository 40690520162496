import * as Yup from "yup";
import { checkCapital, checkLength, checkLowercase, checkNumber, checkSpecial, requiredFieldMessage } from "../utils";
import { ROLES } from "~/contexts/PermissionContext";

const commonUserManagementFields = {
  first_name: Yup.string()
    .min(2, "Please, add at least 2 symbols")
    .max(50, "Please, make it shorter than 50 symbols")
    .required(requiredFieldMessage),
  last_name: Yup.string()
    .min(2, "Please, add at least 2 symbols")
    .max(50, "Please, make it shorter than 50 symbols")
    .required(requiredFieldMessage),
  email: Yup.string().email("Please, enter the correct email").required(requiredFieldMessage),
  role: Yup.string(),
};

export const UserManagementSchema = Yup.object().shape({
  ...commonUserManagementFields,
  organisations: Yup.array().test("role-test", requiredFieldMessage, function (value) {
    const { role } = this.parent;
    const validation = Yup.array().min(2, "Please, add at least 2 organisations").required(requiredFieldMessage);

    if (ROLES.REGIONAL_ADMIN === role) {
      try {
        validation.validateSync(value);
      } catch (error) {
        return this.createError({ message: error.message });
      }
      return true;
    }

    return Yup.array().nullable().isValidSync(value);
  }),
  organisation: Yup.number().test("role-test", requiredFieldMessage, function (value) {
    const { role } = this.parent;

    if (ROLES.LOCAL_ADMIN === role) {
      try {
        Yup.number().required(requiredFieldMessage).validateSync(value);
      } catch (error) {
        return this.createError({ message: error.message });
      }
      return true;
    }

    return Yup.array().nullable().isValidSync(value);
  }),
});

export const UserManagementNewOrganisationSchema = Yup.object().shape({
  ...commonUserManagementFields,
  organisations: Yup.array().when("role", {
    is: (role: string) => role === ROLES.REGIONAL_ADMIN,
    then: Yup.array().min(1, "Please, add at least 1 organisation").required(requiredFieldMessage),
    otherwise: Yup.array().nullable(),
  }),
});

export const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Hmmm, something not quite right with your email address.")
    .required("Whoops, we're going to need your email to log you in 🤷"),
  password: Yup.string().required("Hey, your password is kinda 'need to know' stuff...."),
});

export const ResetPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email("Hmmm, something not quite right with your email address.")
    .required("Whoops, we're going to need your email to help you out 🤷"),
});

export const NewPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required("New password is required")
    .test(
      "password",
      "☝🏽oh, that password won’t work, (Fort Knox remember....)",
      (value) =>
        value &&
        checkLength(value) &&
        checkLowercase(value) &&
        checkCapital(value) &&
        checkNumber(value) &&
        checkSpecial(value)
    ),
  confirm_password: Yup.string()
    .required("Password confirmation is required")
    .oneOf([Yup.ref("password")], "Probably a typo but those two new passwords don't quite match."),
});
