import * as Yup from "yup";
import { UserManagementNewOrganisationSchema } from "./user";
import { requiredFieldMessage } from "~/utils";

const clientOrganisationDetails = {
  name: Yup.string()
    .min(2, "Please, add at least 2 symbols")
    .max(50, "Please, make it shorter than 50 symbols")
    .required(requiredFieldMessage),
  country: Yup.string().required("Please, choose the country from dropdown list"),
  city: Yup.string().min(2, "Please, add at least 2 symbols").max(50, "Please, make it shorter than 50 symbols"),
  postcode: Yup.string().min(3, "Please, add at least 3 symbols").max(20, "Please, make it shorter than 20 symbols"),
};

const organisationUsers = {
  users: Yup.array()
    .of(UserManagementNewOrganisationSchema)
    .min(1, "At least one user should be assigned")
    .required(requiredFieldMessage),
};

export const OrganisationSchema = Yup.object().shape({
  ...clientOrganisationDetails,
  ...organisationUsers,
});

export const organisationDetailsSchema = Yup.object().shape(clientOrganisationDetails);
export const timetableSchema = Yup.object().shape({
  timetable: Yup.array().of(
    Yup.object().shape({
      enabled: Yup.boolean().required(),
      hours: Yup.number()
        .label("hours")
        .when("enabled", {
          is: true,
          then: Yup.number().min(0.01).max(24).required(),
          otherwise: Yup.number().min(0).max(24).nullable(),
        }),
    })
  ),
});
export const organisationUsersSchema = Yup.object().shape(organisationUsers);
