import Axios from "axios";
import { push } from "react-router-redux";
import { Dispatch } from "redux";
import { API_URL } from "~/config";
import Organisation, { IOrganisation, IOrganisationUser, ITimetable } from "../constants/organisation";
import { IState } from "../reducers/index";
import Toasts, { TOAST_ERROR_MESSAGE, TOAST_IMAGE_SUCCESS, TOAST_SUCCESS_MESSAGE } from "../constants/toasts";
import { ROLES } from "~/contexts/PermissionContext";
import { OrganisationUsersService } from "~/API/OrganisationUsersService";

// ------------------------------------
// Actions
// ------------------------------------
export default {
  createOrganisation: (organisation: IOrganisation) => (dispatch: Dispatch, getState: () => IState) => {
    return new Promise(async (resolve, reject) => {
      const token = getState().user?.authenticatedUser?.token;
      if (token) {
        try {
          dispatch(Organisation.createOrganisationRequest());
          const newRegionalAdmins = organisation.users.filter((user) => user.roles.includes(ROLES.REGIONAL_ADMIN));
          const res = await Axios.post(`${API_URL}/organisation`, organisation);

          for (const user of newRegionalAdmins) {
            const newUserId = res.data.users.find((newUser: IOrganisationUser) => newUser.email === user.email).id;

            if (newUserId) {
              await OrganisationUsersService.updateUserById(newUserId, {
                organisations: [res.data.id, ...user.organisations],
              });
            }
          }

          dispatch(Organisation.createOrganisationSuccess(res));
          dispatch(Toasts.setToasts([{ severity: "success", summary: "", detail: TOAST_SUCCESS_MESSAGE }]));
          dispatch(push(`/organisations/${res.data.id}`));
          resolve(res);
        } catch (err) {
          dispatch(Organisation.createOrganisationFailure(err));
          dispatch(Toasts.setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
          reject(err);
        }
      }
    });
  },
  getOrganisation: (organisationId: number) => (dispatch: Dispatch, getState: () => IState) => {
    return new Promise(async (resolve, reject) => {
      const token = getState().user?.authenticatedUser?.token;
      if (token) {
        try {
          dispatch(Organisation.getOrganisationRequest());
          const res = await Axios.get(`${API_URL}/organisation/${organisationId}`);
          dispatch(Organisation.getOrganisationSuccess(res));
          resolve(res);
        } catch (err) {
          dispatch(Organisation.getOrganisationFailure(err));
          reject(err);
        }
      }
    });
  },
  getOrganisationUsers:
    (organisationId: number, params?: { [key: string]: any }) => (dispatch: Dispatch, getState: () => IState) => {
      return new Promise(async (resolve, reject) => {
        const token = getState().user?.authenticatedUser?.token;
        if (token) {
          try {
            dispatch(Organisation.getOrganisationUsersRequest());
            const res = await Axios.get(`${API_URL}/organisation/${organisationId}/users`, { params });
            dispatch(Organisation.getOrganisationUsersSuccess(res));
            resolve(res);
          } catch (err) {
            dispatch(Organisation.getOrganisationUsersFailure(err));
            reject(err);
          }
        }
      });
    },
  listOrganisations: (params?: { [key: string]: any }) => (dispatch: Dispatch, getState: () => IState) => {
    return new Promise(async (resolve, reject) => {
      const token = getState().user?.authenticatedUser?.token;
      if (token) {
        try {
          dispatch(Organisation.listOrganisationsRequest());
          const res = await Axios.get(`${API_URL}/organisations`, { params });
          dispatch(Organisation.listOrganisationsSuccess(res));
          resolve(res);
        } catch (err) {
          dispatch(Organisation.listOrganisationsFailure(err));
          reject(err);
        }
      }
    });
  },
  listOrganisationsRelated: (params?: { [key: string]: any }) => (dispatch: Dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch(Organisation.listOrganisationsRelatedRequest());
        const res = await Axios.get(`${API_URL}/organisations`, { params: { related: true, ...params } });
        dispatch(Organisation.listOrganisationsRelatedSuccess(res));
        resolve(res);
      } catch (err) {
        dispatch(Organisation.listOrganisationsRelatedFailure(err));
        reject(err);
      }
    });
  },
  getOrganisationPersonalProfile: (userId: number) => (dispatch: Dispatch, getState: () => any) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await Axios.get(`${API_URL}/user/${userId}/organisation_personal_profile`);
        resolve(res.data);
      } catch (err) {
        reject(err);
      }
    });
  },
  updateOrganisationPersonalProfile: (userId: number, data: object) => (dispatch: Dispatch, getState: () => any) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await Axios.put(`${API_URL}/user/${userId}/organisation_personal_profile`, data);
        resolve(res.data);
      } catch (err) {
        reject(err);
      }
    });
  },
  updateOrganisation:
    (organisation: IOrganisation | { id: number; published: boolean }, errorCallback?: (error) => void) =>
    (dispatch: Dispatch, getState: () => IState) => {
      return new Promise(async (resolve, reject) => {
        const token = getState().user?.authenticatedUser?.token;
        if (token) {
          try {
            dispatch(Organisation.updateOrganisationRequest());
            const res = await Axios.put(`${API_URL}/organisation/${organisation.id}`, organisation);
            dispatch(Organisation.updateOrganisationSuccess(res));
            dispatch(Toasts.setToasts([{ severity: "success", summary: "", detail: TOAST_SUCCESS_MESSAGE }]));
            resolve(res);
          } catch (err) {
            dispatch(Organisation.updateOrganisationFailure(err));
            !!errorCallback
              ? errorCallback(err.response?.data?.message || TOAST_ERROR_MESSAGE)
              : dispatch(
                  Toasts.setToasts([
                    { severity: "error", summary: "", detail: err.response?.data?.message || TOAST_ERROR_MESSAGE },
                  ])
                );
            reject(err);
          }
        }
      });
    },
  getOrganisationTimetable: (organisationId: number) => (dispatch: Dispatch, getState: () => any) => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch(Organisation.getOrganisationTimetableRequest());
        const res = await Axios.get(`${API_URL}/organisation/${organisationId}/timetable`);
        dispatch(Organisation.getOrganisationTimetableSuccess(res));

        resolve(res.data);
      } catch (err) {
        reject(err);
        dispatch(Organisation.getOrganisationTimetableFailure(err));
      }
    });
  },
  putOrganisationTimetable:
    (organisationId: number, data: ITimetable[]) => (dispatch: Dispatch, getState: () => any) => {
      return new Promise(async (resolve, reject) => {
        const token = getState().user?.authenticatedUser?.token;

        if (token) {
          try {
            dispatch(Organisation.updateOrganisationTimetableRequest());

            const res = await Axios.put(`${API_URL}/organisation/${organisationId}/timetable`, data);
            dispatch(Organisation.updateOrganisationTimetableSuccess(res));

            dispatch(Toasts.setToasts([{ severity: "success", summary: "", detail: TOAST_SUCCESS_MESSAGE }]));
            resolve(res.data);
          } catch (err) {
            dispatch(
              Toasts.setToasts([
                { severity: "error", summary: "", detail: err.response?.data?.message || TOAST_ERROR_MESSAGE },
              ])
            );
            dispatch(Organisation.updateOrganisationTimetableFailure(err));
            reject(err);
          }
        }
      });
    },
  saveProfileImage:
    (orgId: number | undefined, image: Blob | File, fieldCode: string) =>
    (dispatch: Dispatch, getState: () => IState) => {
      return new Promise(async (resolve, reject) => {
        const token = getState().user?.authenticatedUser?.token;
        if (token && orgId) {
          dispatch(Organisation.saveOrganisationFileRequest());
          const formData: FormData = new FormData();
          formData.append(fieldCode, image);
          Axios.post(`${API_URL}/organisation/${orgId}`, formData)
            .then((res) => {
              dispatch(Organisation.saveOrganisationFileSuccess(res));
              dispatch(Toasts.setToasts([{ severity: "success", summary: "", detail: TOAST_IMAGE_SUCCESS }]));
              resolve(res.data.image_url);
            })
            .catch((err) => {
              dispatch(Organisation.saveOrganisationFileFailure(err));
              dispatch(Toasts.setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
              reject(err);
            });
        } else {
          reject();
        }
      });
    },
};
