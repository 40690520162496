import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable, DataTableSortMeta } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Paginator } from "primereact/paginator";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as permissionType from "~/constants/permissions";
import { useBriefContext } from "~/contexts/BriefContext";
import { usePermissionContext } from "~/contexts/PermissionContext";
import { ITalent } from "~/store/constants/talent";
import useDebounce from "../../components/common/debounce";
import actions from "../../store/actions";
import { IState } from "../../store/reducers/index";
import "./AddMatchModal.scss";
import { getTalentTypeText } from "~/utils";

const defaultSort: DataTableSortMeta = {
  field: "name",
  order: 1,
};

export default function AddMatchModal() {
  const dispatch = useDispatch();
  const { modalProps } = useSelector((state: IState) => state.modal);
  const { talents_to_add, onSubmit, briefId, excluded_talents } = modalProps || {};
  const {
    addableTalents: talents,
    listAddableTalents,
    isFetchingTalents: loading,
    setAddableTalents,
  } = useBriefContext();
  const { userAccess } = usePermissionContext();

  const sortFunc = sortBackend();
  const [sort, setSort] = useState(defaultSort);

  const [talent, setTalent] = useState<{ id: number | null; name: string | null }>({ id: null, name: null });
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 1000);

  const excludedTalentsIds = excluded_talents.map((talent: ITalent) => talent.id);

  useEffect(() => {
    getTalents({ page: 0 });
  }, [sort, debouncedSearch]);

  function getTalents({ page = 0 }: { page: number }) {
    listAddableTalents({ briefId, page, search, sort });
  }

  function handleCloseModal() {
    dispatch(actions.modal.closeModal());
    setAddableTalents(undefined);
  }

  function confirmAddMatching() {
    onSubmit(talent.id);
    handleCloseModal();
  }

  function sortBackend() {
    let sorting = false;
    function askSort(e: { sortField: any; sortOrder: any }) {
      if (!sorting) {
        sorting = true;
        setSort({
          field: e.sortField,
          order: e.sortOrder,
        });
      }
      return 0;
    }
    return askSort;
  }

  function renderColumnName(rowData: ITalent) {
    return (
      <div>
        {!!rowData.blocked_clients && <span className="pi pi-ban mr-2" />}
        {rowData.name}
      </div>
    );
  }

  function renderColumnType(rowData: { type: string }) {
    return rowData.type ? getTalentTypeText(rowData.type) : "";
  }

  function renderColumnOrganisation(rowData: { organisation_name: string }) {
    return rowData.organisation_name ? (
      <span className={`matching-status accepted`} title="accepted">
        {rowData.organisation_name}
      </span>
    ) : null;
  }

  function renderButton({ id, name, blocked_clients }: { id: number; name: string; blocked_clients?: number }) {
    const isExcluded = excludedTalentsIds.includes(id);

    if (!!blocked_clients) {
      return <Button label="Unblock" onClick={() => window.open(`/talents/${id}`, "_blank")} />;
    }
    if (!isExcluded || userAccess(permissionType.accessAdmin)) {
      return <Button label="ADD" onClick={() => setTalent({ id, name })} />;
    }
    return <Button label="ADD" disabled />;
  }

  const talents_to_show = talents_to_add
    ? talents_to_add.filter(
        (item: { name: string; specialism_name: string }) =>
          search === "" ||
          item.name.toLowerCase().includes(search) ||
          item?.specialism_name?.toLowerCase().includes(search)
      )
    : talents?.data ?? [];

  return (
    <Dialog header="Select Profile" className="AddMatchModal" onHide={handleCloseModal} modal visible>
      {talent.id ? (
        <>
          <p>
            Are you sure you want to add <strong>{talent?.name}</strong>?
          </p>
          <div className="flex">
            <Button
              className="p-button-secondary flex-1 mr-2"
              label="BACK"
              onClick={() => setTalent({ id: null, name: null })}
            />
            <Button label="ADD" className="flex-1" onClick={confirmAddMatching} />
          </div>
        </>
      ) : (
        <>
          <InputText
            placeholder="Search"
            onChange={({ target: { value } }) => setSearch(value.toLowerCase())}
            value={search}
          />
          <DataTable
            className="AddMatch__talents"
            emptyMessage={`No talent to add`}
            value={talents_to_show}
            sortOrder={sort.order}
            sortField={sort.field}
            loading={loading}
            onSort={sortFunc}
          >
            <Column body={renderColumnName} field="name" header="Name" sortable={true} style={{ minWidth: "230px" }} />
            <Column field="type" header="Type" sortable={true} body={renderColumnType} />
            <Column field="specialism_name" header="Specialism" sortable={true} />
            <Column body={renderColumnOrganisation} field="start_from" header="Organisation" sortable={true} />
            <Column header="" body={renderButton} />
          </DataTable>
          {talents && talents.meta.total > talents.meta.per_page && (
            <Paginator
              rows={talents.meta.per_page}
              totalRecords={talents.meta.total}
              first={(talents.meta.current_page - 1) * talents.meta.per_page}
              onPageChange={getTalents}
            />
          )}
        </>
      )}
    </Dialog>
  );
}
