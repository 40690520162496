import React, { Dispatch, useEffect, useMemo, useRef } from "react";
import { Calendar, CalendarDateTemplateParams } from "primereact/calendar";
import ApCheckbox from "~/components/common/ApCheckbox/ApCheckbox";
import { useGlobalContext } from "~/contexts/GlobalContext";
import moment from "moment";
import { calculateDates, getDiffBetweenDays } from "~/utils";
import { Slider } from "primereact/slider";
import { FormikContextType } from "formik";
import CalendarFooterTemplate from "~/forms/BriefViewDetailsForm/Calendar/CalendarFooterTemplate";
import { InputNumber } from "primereact/inputnumber";
import "./CalendarBlock.scss";
import { differenceWith, intersectionWith } from "lodash";

interface ICalendarBlock {
  formik: FormikContextType<any>;
  dates: { date: Date; capacity?: number }[] | Date[];
  minDate?: Date | undefined;
  setDates: Dispatch<any>;
  setDurationDays: Dispatch<any>;
  durationDays: number;
  maxDurationDays: number;
  setMaxDurationDays: Dispatch<any>;
  bookingStatus?: string | undefined;
}

export default function CalendarBlock(props: ICalendarBlock) {
  const {
    formik,
    dates,
    minDate,
    setDates,
    setDurationDays,
    durationDays,
    maxDurationDays,
    setMaxDurationDays,
    bookingStatus,
  } = props;
  const {
    global: { optionsNormalization },
  } = useGlobalContext();
  const DURATION_ID = optionsNormalization?.DURATION_ID;
  const isDurationTypeConsec = formik?.values.duration_id === DURATION_ID?.CONSEC;
  const isShowDurationSlider = formik?.values.duration_id === DURATION_ID?.NUMBER;
  const isExactWorkingDates = formik?.values.duration_id === DURATION_ID?.EXACT;
  const calendarType = isExactWorkingDates ? "multiple" : "range";

  const calendarRef = useRef();
  const calendarValue = useMemo(() => {
    if (dates?.length) {
      return isExactWorkingDates ? dates?.map(({ date }) => date) : dates;
    }

    return undefined;
  }, [dates]);

  const handleChangeDates = ({ value }: { value: Date | Date[] | undefined | null }) => {
    if (Array.isArray(value)) {
      isShowDurationSlider && changeDurationDays(value);
      if (isExactWorkingDates) {
        calculateDates.sort(value);
        let newDates: ICalendarBlock["dates"] = [];

        if (value.length > dates?.length) {
          let newDate = differenceWith(value, dates, (a, b) => moment(a).isSame(b.date)); // find new date
          newDates = [...dates, { date: newDate[0], capacity: 100 }];
        } else if (value.length < dates.length) {
          newDates = intersectionWith(dates, value, (a, b) => moment(a.date).isSame(b)); // exclude deleted date
        }

        calculateDates.sortObj(newDates);

        setDates(newDates);
        formik.setValues({
          ...formik.values,
          start_date: value.length ? moment(value[0]).format("YYYY-MM-DD") : "",
          end_date: value.length ? moment(value[value.length - 1]).format("YYYY-MM-DD") : "",
          dates: calculateDates.formatDates(newDates),
        });
      } else {
        const days = calculateDurationDays(value);
        formik.setValues({
          ...formik.values,
          start_date: moment(value[0]).format("YYYY-MM-DD"),
          end_date: moment(value[1] ?? value[0]).format("YYYY-MM-DD"),
          ...(isShowDurationSlider && { duration_days: days + 1 }),
          dates: [],
        });
        setDates(value);
      }
    }
  };

  const resetDates = () => {
    setDates([]);
    formik.setValues({
      ...formik.values,
      start_date: "",
      end_date: "",
      dates: [],
    });
  };

  const calculateDurationDays = (dates: Date[]) => {
    const endDate = dates[1] ? moment(dates[1]) : moment(dates[0]);
    const startDate = moment(dates[0]);
    return getDiffBetweenDays({ startDate, endDate });
  };

  const changeDurationDays = (dates: Date[], day: number = 0) => {
    const days = calculateDurationDays(dates);
    setMaxDurationDays(days + 1 || null);
    day === 0 ? setDurationDays(days + 1 || null) : setDurationDays(day);
  };

  const handleSetDurationDays = (day: number) => {
    setDurationDays(day);
    formik.setFieldValue("duration_days", day);
  };

  useEffect(() => {
    changeDurationDays([formik.values.start_date, formik.values.end_date], formik?.values?.duration_days);
  }, []);

  useEffect(() => {
    if (calendarRef?.current?.getInput() && !dates?.length) {
      calendarRef.current.getInput().value = "";
    }
  }, [dates, calendarRef?.current]);

  const renderDateTemplate = (dateEvent: CalendarDateTemplateParams) => {
    const currentDate = moment(`${dateEvent.year} ${dateEvent.month + 1} ${dateEvent.day}`, "YYYY-MM-DD").toDate();
    const inDatesIndex = dates?.findIndex(({ date }) => moment(date).isSame(currentDate));
    const isSelected = inDatesIndex !== -1;
    const capacity = dates[inDatesIndex]?.capacity || 100;
    return (
      <>
        <span title={isSelected ? `Capacity: ${parseInt(capacity)}%` : undefined}>
          {isSelected && <i style={{ height: `${100 - parseInt(capacity)}%` }} />}
          {dateEvent.day}
        </span>
      </>
    );
  };

  const renderFooterTemplate = () => {
    return (
      <>
        {isDurationTypeConsec && (
          <div className="include-weekends-wrapper">
            <ApCheckbox formik={formik} noField name="include_weekends" label="Include weekend days" />
          </div>
        )}
        {isExactWorkingDates && (
          <CalendarFooterTemplate dates={dates} setDates={setDates} resetDates={resetDates} formik={formik} />
        )}
      </>
    );
  };

  return (
    <div className="calendar-block">
      <div className="p-fluid p-formgrid">
        <div className="field field-required">
          <label htmlFor="start_date">Brief dates</label>
          <Calendar
            ref={calendarRef}
            dateFormat="dd/mm/yy"
            inline={isExactWorkingDates}
            onChange={handleChangeDates}
            showIcon={!isExactWorkingDates}
            selectionMode={calendarType}
            value={calendarValue}
            minDate={minDate}
            disabledDays={!formik?.values.include_weekends && isDurationTypeConsec ? [0, 6] : []}
            footerTemplate={renderFooterTemplate}
            dateTemplate={isExactWorkingDates ? renderDateTemplate : undefined}
          />
          {formik.touched?.start_date && formik.errors?.start_date && (
            <div className="ap-error">{formik.errors?.start_date}</div>
          )}
        </div>
      </div>
      {isShowDurationSlider && maxDurationDays && (
        <div className="card p-fluid field">
          <label htmlFor="start_date">Duration Days: {durationDays}</label>
          <div className="slider-block">
            <InputNumber
              inputId="duration_days"
              value={durationDays}
              onValueChange={(e) => handleSetDurationDays(e.value as number)}
              showButtons
              buttonLayout="horizontal"
              mode="decimal"
              min={0.5}
              max={maxDurationDays}
              decrementButtonClassName="p-button-danger"
              incrementButtonClassName="p-button-danger"
              incrementButtonIcon="pi pi-plus"
              decrementButtonIcon="pi pi-minus"
              step={0.5}
            />
            <Slider
              step={0.5}
              max={maxDurationDays}
              min={0.5}
              value={durationDays}
              onChange={(e) => handleSetDurationDays(e.value as number)}
            />
          </div>
        </div>
      )}
    </div>
  );
}
