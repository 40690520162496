import React from "react";
import cn from "classnames";
import { ProgressBar } from "primereact/progressbar";
import { Tooltip } from "primereact/tooltip";
import { TalentType } from "~/store/constants/talent";
import { getAvailabilityBarClass } from "~/utils";
import "./AvailabilityBar.scss";

interface IAvailabilityBar {
  id: number;
  type: string | null;
  availability?: number;
  noClick?: boolean;
}

const AvailabilityBar = ({ id, type, availability = 100, noClick }: IAvailabilityBar) => {
  return (
    <div className="availability-bar">
      <Tooltip target={`.talent-${id}`} position="top" />
      {type !== TalentType.FREELANCER && (
        <ProgressBar
          className={cn(`talent-${id}`, getAvailabilityBarClass(availability), noClick && "no-click")}
          data-pr-tooltip={`${availability}%`}
        />
      )}
    </div>
  );
};

export default AvailabilityBar;
