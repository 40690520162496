import classNames from "classnames";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../store/actions";
import { AxiosError } from "axios";
import Loader from "../../components/Loader/Loader";
import ThemedButton from "../../components/common/ThemedButton/ThemedButton";
import { IState } from "../../store/reducers";
import { useFormik } from "formik";
import ApInputText from "../../components/common/ApInputText/ApInputText";
import { ResetPasswordSchema } from "../../schemas/user";

function ResetPasswordForm() {
  const dispatch = useDispatch();

  const [result, setResult] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const { isAuthenticating } = useSelector((state: IState) => state.user);

  const handleSubmit = (form: { email: string }) => {
    dispatch(actions.user.resetPassword(form))
      .then(() => setResult("success"))
      .catch((error: AxiosError) => {
        setResult("error");
        setErrorMessage(error as unknown as string);
      });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
    },
    validationSchema: ResetPasswordSchema,
    onSubmit: handleSubmit,
  });

  return (
    <form className={classNames("form", { "form-hidden": result === "success" })} onSubmit={formik.handleSubmit}>
      <h1>Forgot Password</h1>
      <p className="info">
        {result === "success" ? (
          <span>
            An email with recovery link was successfully sent.
            <br />
            Please, check your mailbox and follow the instructions.
          </span>
        ) : (
          <span>
            No worries! Just type your email and
            <br />
            we will send you a password reset link.
          </span>
        )}
      </p>
      <div className="ThemedInput">
        <ApInputText
          formik={formik}
          id="email"
          noField
          placeholder="Email"
          isError={formik.touched.hasOwnProperty("email") && formik.errors.hasOwnProperty("email")}
        />
      </div>
      <ThemedButton
        className="submit btn-primary"
        disabled={isAuthenticating || (formik.touched && !formik.isValid)}
        type="submit"
      >
        {isAuthenticating ? <Loader /> : "Reset My Password"}
      </ThemedButton>
      {result === "error" && <span className="error-msg">{errorMessage}</span>}
      <Link to="/login" className="prt-login-form-pass">
        Back to Sign In
      </Link>
    </form>
  );
}

export default ResetPasswordForm;
