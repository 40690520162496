@import "../../mixins.scss";

.p-datatable-row {
  .p-dropdown {
    background: transparent !important;
    border: 0;
    width: 100%;
    .p-dropdown-label {
      background: transparent !important;
      padding: 0;
    }
    .p-dropdown-trigger {
      background: transparent !important;
    }
  }
  .p-calendar,
  .p-calendar > .p-inputtext {
    width: 100%;
  }
  .p-datepicker-calendar table {
    table-layout: initial !important;
  }
}

.BookingsView__table {
  .p-datatable-wrapper {
    overflow-x: visible;
  }

  .name-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 8px;
  }

  .disabled {
    td:not(.actions) {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}

.BookingsView__document {
  white-space: nowrap;
  text-overflow: ellipsis;
}

.p-datatable-table .p-datatable-tbody > tr > td {
  .action-buttons-wrapper {
    position: relative;
    > .p-button {
      margin: 0 auto;
    }
    > .p-badge {
      position: absolute;
      right: -3px;
      top: -5px;
      font-size: 1em;
      color: $brandColor;
      border: 1px solid $brandColor;
      background-color: #fff;
      min-width: 1rem;
      height: 1rem;
      line-height: 19px;
    }
  }
}

.action-buttons {
  margin-top: 4px;
  .p-button {
    color: #000;
    padding: 1em 2em;
    .p-button-icon {
      margin-right: 0.4em;
    }
  }
}
