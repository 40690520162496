import { Field, FormikProps, FormikValues, useFormikContext } from "formik";
import { Button } from "primereact/button";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import actions from "../../store/actions";
import { IOrganisation, IOrganisationUser } from "~/store/constants/organisation";
import { ITalent, TalentType } from "~/store/constants/talent";
import { IUserForm } from "~/store/constants/user";
import "./UsersListForm.scss";
import { ROLES, usePermissionContext } from "~/contexts/PermissionContext";
import * as permissionType from "~/constants/permissions";
import { TabPanel, TabView } from "primereact/tabview";
import UsersForm from "../UsersForm";
import isUndefined from "lodash/isUndefined";
import useTalent from "~/hooks/useTalent";
import useOrganisations from "~/hooks/useOrganisations";
import toasts from "~/store/actions/toasts";
import { DisciplineCode } from "~/interfaces/common";
import ArchivedUsersForm from "../UsersForm/ArchivedUsersForm";
import { useParams } from "react-router-dom";

interface Props {
  data?: IOrganisation | ITalent;
  type?: "organisation" | "talent";
  validate: any;
  updateUserList?: () => void;
  isNewUser?: boolean;
  isTeam?: boolean;
  disciplineToFetch?: DisciplineCode;
  handlePrompt: (isDirty: boolean) => void;
  isNewOrganisation?: boolean;
}

const isDisabledActiveTab = (users: IOrganisationUser[] | IUserForm[]) =>
  !users?.filter((user) => user.published).length;

const isDisabledInActiveTab = (users: IOrganisationUser[] | IUserForm[]) =>
  !users?.filter((user) => !user.published).length;

export default function UsersListForm(props: Props) {
  const { validate: formikProps, disciplineToFetch } = props;
  const dispatch = useDispatch();
  const { userAccess, isTalent, isAdmin } = usePermissionContext();
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  const { editTalent } = useTalent();
  const { organisations } = useOrganisations();
  const formik: FormikProps<any> = useFormikContext();
  const organisationId = useParams<{ organisationId?: string }>().organisationId;

  useEffect(() => {
    props.handlePrompt && props.handlePrompt(formik.dirty);
  }, [formik.dirty]);

  function openUserDialog(user?: IOrganisationUser | IUserForm, userIndex?: number) {
    dispatch(
      actions.modal.openModal("ADD_USER_MODAL", {
        onClose: closeUserModal,
        onSubmit: submitUser.bind(null, formikProps, userIndex),
        onRemove: removeUser.bind(null, formikProps, userIndex),
        user,
        type: props?.type,
        isNewOrganisation: props.isNewOrganisation,
        organisations,
      })
    );
  }

  function openExistingUserDialog() {
    dispatch(
      actions.modal.openModal("SELECT_USERS", {
        organisationId: formikProps.values.organisation_id,
        isNewUser: props.isNewUser,
        preselected: props.isNewUser
          ? formikProps.values.related || []
          : formikProps.values.users
              .filter(({ published }) => published)
              .map((user) => ({
                ...user,
                id: user.talent_id,
                name: user.full_name || `${user.first_name} ${user.last_name}`,
                isPreselected: true,
              })) || [],
        onSubmit: (response: { selectedItems: IUserForm }) =>
          submitUser(formikProps, undefined, response.selectedItems),
        disciplineToFetch,
      })
    );
  }

  function handleTalentTeamWarning(callback: Function) {
    if (props.isNewUser || formikProps.values?.users?.length !== 1) {
      callback();
      return;
    }

    dispatch(
      toasts.setPopup({
        content: "The talent specialism will be automatically updated to Creative Team if you proceed.",
        buttons: [{ text: "Got it", callback }],
      })
    );
  }

  async function submitUser(
    formik: FormikProps<FormikValues>,
    userIndex: number | undefined,
    user: IUserForm | IUserForm[]
  ) {
    props.handlePrompt && props.handlePrompt(false);
    if (Array.isArray(user)) {
      if (props.isNewUser) {
        formik.setFieldValue("related", user);
        closeUserModal();
        return;
      }
      await editTalent(+props?.data?.id, { related: user.map(({ id }) => id) });
      props.updateUserList && props.updateUserList();
      return;
    }

    let newUsers;
    if (!isUndefined(userIndex)) {
      if (props.data) {
        const res = await dispatch(actions.talent.updateTalentUser({ ...user }));
        if (res?.status !== 200 || res?.statusText !== "OK") return;
      }
      newUsers = [...formik.values.users];
      newUsers[userIndex] = { ...user, full_name: `${user.first_name} ${user.last_name}` };
    } else {
      if (props.data) {
        const userData: IUserForm = { ...user };

        if (props?.type === "organisation") {
          userData.roles = [user.role, ...user.additional_roles];
          delete userData.role;
          delete userData.additional_roles;

          if (![ROLES.REGIONAL_ADMIN, ROLES.LOCAL_ADMIN].includes(user.role as ROLES)) {
            if (isAdmin) {
              delete userData.organisation;
              userData.organisations = [organisationId];
            } else {
              delete userData.organisations;
              userData.organisation = organisationId as string;
            }
          } else if ([ROLES.REGIONAL_ADMIN, ROLES.LOCAL_ADMIN].includes(user.role as ROLES)) {
            if (isAdmin) {
              delete userData.organisation;
              if (user.role === ROLES.LOCAL_ADMIN) {
                userData.organisations = [user.organisation];
              }
            } else {
              delete userData.organisations;
            }
          }
        } else {
          delete userData.roles;
        }

        await dispatch(
          actions.user.createUser({
            user: { ...userData },
            type: props?.type,
            id: props?.data?.id,
          })
        );
        props.updateUserList && props.updateUserList();
      }

      newUsers = [...formik.values.users, { ...user }];
    }
    formik.setFieldValue("users", newUsers);
    closeUserModal();
  }

  function removeUser(formik: any, field: "users" | "related", userIndex: number) {
    const newUsers = [...formik.values[field]];
    !isUndefined(userIndex) && newUsers.splice(userIndex, 1);
    formik.setFieldValue(field, newUsers);
    closeUserModal();
  }

  function closeUserModal() {
    dispatch(actions.modal.closeModal());
  }

  function renderTalentUserButtons() {
    if (
      props.type === "talent" &&
      !isTalent &&
      userAccess(
        permissionType.postOwnOrganisationTalentUser,
        permissionType.postAnyUser,
        permissionType.postOwnTalentUser
      ) &&
      (formikProps.values.type === TalentType.TEAM || props.isTeam)
    ) {
      return (
        <div className="UsersListForm__addUser">
          <Button
            type="button"
            label={props.isNewUser ? "ADD MEMBER(S)" : "ADD / REMOVE MEMBER(S)"}
            onClick={() => handleTalentTeamWarning(openExistingUserDialog)}
          />
        </div>
      );
    }
    return null;
  }

  return (
    <div className="UsersListForm">
      <Field name="users">
        {({
          form: {
            touched,
            errors,
            values: { users, related },
          },
        }: {
          form: { touched: { [key: string]: any }; errors: { [key: string]: any }; values: { [key: string]: any } };
        }) =>
          props?.isNewUser ? (
            <>
              <UsersForm {...props} users={users} onRemove={(i: number) => removeUser(formikProps, "users", i)} />
              <UsersForm {...props} users={related} onRemove={(i: number) => removeUser(formikProps, "related", i)} />
              {touched.users && errors.users && <div className="col-12 ap-error">{errors.users}</div>}
            </>
          ) : (
            <>
              <TabView activeIndex={activeTabIndex} onTabChange={(e) => setActiveTabIndex(e.index)}>
                <TabPanel header="Active Users" disabled={isDisabledActiveTab(users)}>
                  <UsersForm users={users} isPublished {...props} changeTab={setActiveTabIndex} />
                  {touched.users && errors.users && <div className="col-12 ap-error">{errors.users}</div>}
                </TabPanel>
                <TabPanel header="Inactive Users" disabled={isDisabledInActiveTab(users)}>
                  <UsersForm users={users} isPublished={false} {...props} changeTab={setActiveTabIndex} />
                  {touched.users && errors.users && <div className="col-12 ap-error">{errors.users}</div>}
                </TabPanel>
                {isAdmin && props.type === "organisation" && (
                  <TabPanel header="Archived Profiles">
                    <ArchivedUsersForm />
                  </TabPanel>
                )}
              </TabView>
            </>
          )
        }
      </Field>
      {props.type === "organisation" &&
        activeTabIndex !== 2 &&
        userAccess(permissionType.postOwnOrganisationUser, permissionType.postAnyUser) && (
          <div className="UsersListForm__addUser">
            <Button label="Add User" onClick={() => openUserDialog()} type="button" />
          </div>
        )}
      {renderTalentUserButtons()}
    </div>
  );
}
