import * as Sentry from "@sentry/react";
import { ENV } from "./config";

const initSentry = () =>
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: ENV,
    release: ENV,
    integrations: [
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    replaysOnErrorSampleRate: 1.0,
    ignoreErrors: ["Cannot find module"],
  });

export default initSentry;
