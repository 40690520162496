import classNames from "classnames";
import { Button } from "primereact/button";
import React, { ChangeEvent, KeyboardEvent, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import DropZone from "../common/DropZone/DropZone";
import "./ItemSelector.scss";
import toasts from "~/store/actions/toasts";

export type SelectedItem = {
  id: number;
  name: string;
  order_id?: number;
};

type Props = {
  items: SelectedItem[];
  itemName: string;
  minPrioritizeItems?: number;
  maxItems?: number;
  selected?: SelectedItem[];
  addCustom?: boolean;
  prioritize?: boolean;
  selectorStep?: number;
  stepOneText?: React.ReactNode;
  stepTwoText?: React.ReactNode;
  onStepChange?: (step: number) => void;
  onSave: (items: SelectedItem[]) => void;
};

export default function ItemSelector({
  items,
  itemName,
  selected = [],
  minPrioritizeItems = 2,
  maxItems = 0,
  addCustom = false,
  prioritize = false,
  selectorStep = 1,
  stepOneText,
  stepTwoText,
  onSave,
  onStepChange,
}: Props) {
  const dispatch = useDispatch();

  const [step, setStep] = useState<number>(selectorStep);
  const [allItems, setAllItems] = useState<SelectedItem[]>([]);
  const [customItem, setCustomItem] = useState<string>("");
  const [selectedItems, setSelectedItems] = useState<SelectedItem[]>([]);

  useEffect(() => {
    selected.length && setSelectedItems(selected);
  }, []);

  useEffect(() => {
    items.length && setAllItems(items);
  }, [items]);

  useEffect(() => {
    setStep(selectorStep);
  }, [selectorStep]);

  useEffect(() => {
    onSave(selectedItems.map((item, index) => ({ ...item, order_id: index + 1 })));
  }, [selectedItems]);

  const findItemIndex = (item: SelectedItem, items: SelectedItem[]) =>
    items.findIndex((i) => i.name.toLowerCase() === item.name.toLowerCase());

  function handleAddNewSkill(event: KeyboardEvent<HTMLInputElement>) {
    if (event.key !== "Enter") return;
    const item = { id: 0 - allItems.length, name: customItem.trim() };
    if (findItemIndex(item, allItems) > -1) {
      dispatch(
        toasts.setToasts([
          {
            severity: "warn",
            detail: `This ${itemName.toLowerCase()} is already in the list`,
          },
        ])
      );
    } else {
      onItemClick(item);
      setCustomItem("");
    }
  }

  function onItemClick(item: SelectedItem) {
    const itemIndex = findItemIndex(item, selectedItems);
    const itemNotSelected = itemIndex === -1;

    if (itemNotSelected && maxItems && selectedItems.length >= maxItems) {
      dispatch(
        toasts.setToasts([
          {
            severity: "warn",
            detail: `Sorry, only ${maxItems} items can be selected`,
          },
        ])
      );
      return;
    }

    itemNotSelected && setSelectedItems([item, ...selectedItems]);

    // Select Custom item: add to all items
    item.id < 1 && itemNotSelected && findItemIndex(item, allItems) === -1 && setAllItems([item, ...allItems]);

    // Unselect
    if (!itemNotSelected) {
      const newSelectedItems = [...selectedItems];
      newSelectedItems.splice(itemIndex, 1);
      setSelectedItems(newSelectedItems);
    }
  }

  return (
    <div className="ItemSelectorContainer">
      {step === 1 && allItems.length > 0 && stepOneText}
      {step === 2 && allItems.length > 0 && stepTwoText}
      <div className="mainModalContent">
        {step === 1 && (
          <>
            <ul>
              {addCustom && (
                <li className="addCustom">
                  <input
                    onKeyDown={handleAddNewSkill}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => setCustomItem(event.target.value)}
                    placeholder={`+ Add your own ${itemName}`}
                    type="text"
                    value={customItem}
                  />
                </li>
              )}
              {allItems.map((item) => (
                <li
                  key={item.name}
                  className={classNames(itemName, {
                    selected: selectedItems.filter((i) => i.id === item.id).length,
                  })}
                  onClick={() => onItemClick(item)}
                >
                  {item.name}
                </li>
              ))}
            </ul>
            {prioritize && selectedItems.length >= minPrioritizeItems && (
              <Button
                icon="pi pi-sort-amount-up"
                label="Prioritise"
                className="button"
                onClick={() => {
                  setStep(2);
                  onStepChange && onStepChange(2);
                }}
              />
            )}
          </>
        )}
        {step === 2 && (
          <>
            <DropZone items={selectedItems} itemClass="prioritise" setItems={setSelectedItems} />
            <Button
              icon="pi pi-arrow-left"
              label={`Select ${itemName.toLowerCase()}s`}
              className="button"
              onClick={() => {
                setStep(1);
                onStepChange && onStepChange(1);
              }}
            />
          </>
        )}
      </div>
    </div>
  );
}
