export interface IColumn {
  name?: string;
  field: string;
  header?: string;
  showStatement?: boolean;
  sortable?: boolean;
  cellCallback?: (rowData: any, columnProps?: any) => JSX.Element | null;
  className?: string;
  props?: {
    [key: string]: any;
  };
  style?: {
    [key: string]: any;
  };
}

const getColumns = () => {
  const columns: IColumn[] = [
    {
      field: "client_pod",
      header: "Client Group",
    },
    {
      field: "program_lead",
      header: "Program Lead",
    },
    {
      field: "client_lead",
      header: "Client Lead ",
    },
  ];

  return columns;
};

export default getColumns;
