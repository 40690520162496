import React, { useMemo } from "react";
import { BreadCrumb } from "primereact/breadcrumb";
import { MenuItem } from "primereact/menuitem";
import { NavLink } from "react-router-dom";

export default function ApBreadCrumb({
  model,
  home = { icon: "pi pi-home", url: "/" },
  ...rest
}: {
  model: MenuItem[];
  home?: MenuItem;
  [key: string]: any;
}) {
  const items = useMemo(() => {
    return model.map((item) => {
      return !!item.url && !!item.label
        ? {
            template: () => (
              <NavLink to={item.url as string} className={"p-menuitem-link"}>
                {item.label}
              </NavLink>
            ),
          }
        : item;
    });
  }, [model]);

  return <BreadCrumb model={items} home={home} {...rest} />;
}
