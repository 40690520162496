import { Tag } from "primereact/tag";
import React, { MouseEventHandler } from "react";
import { generateHEXContrastColor } from "~/utils";
import "./ApTag.scss";
import cn from "classnames";

interface IApTag {
  fill: string;
  title?: string;
  onClick?: MouseEventHandler<HTMLSpanElement>;
  className?: string;
  icon?: string;
}

const ApTag = (props: IApTag) => (
  <Tag
    title={props?.title || ""}
    value={props?.title || ""}
    style={{
      background: props?.fill ? props.fill.padStart(7, "#") : "transparent",
      color: props?.fill ? generateHEXContrastColor(props.fill) : "black",
    }}
    icon={props?.icon}
    onClick={props?.onClick}
    className={cn("ApTag", props?.className)}
  />
);

export default ApTag;
