import React, { useRef } from "react";
import { ISettings } from "~/hooks/useSettings";
import "./LogoSettingsView.scss";
import { useDispatch } from "react-redux";
import actions from "~/store/actions";
import { Card } from "primereact/card";
import { getSettingImageRatio, makeUniqueUrl } from "~/utils";
import { FileUpload, FileUploadSelectEvent } from "primereact/fileupload";

export default function LogoSettingsView(props: {
  images: ISettings[];
  saveImageCallback: (code: string, image: Blob | File) => any;
}) {
  const dispatch = useDispatch();

  const fileRef = useRef<any>(null);

  const selectImage = (event: FileUploadSelectEvent, currentSetting: ISettings): void => {
    const { files } = event;

    if (files && files.length) {
      dispatch(
        actions.modal.openModal("UPDATE_IMAGE_MODAL", {
          file: files[0],
          aspect: currentSetting?.ratio || 1,
          freeCrop: !currentSetting?.ratio,
          isGlobalSettings: true,
          saveImageCallback: () => dispatch(actions.modal.closeModal()),
          handleSaveImage: async (image: Blob | File) => {
            const updatedImg = await props.saveImageCallback(currentSetting?.code as string, image);
            const imgElementToUpdate = document.querySelectorAll(
              `[src^="${currentSetting?.value}"]`
            ) as NodeListOf<HTMLImageElement>;
            imgElementToUpdate.forEach((img) => {
              img.src = makeUniqueUrl(updatedImg as string);
            });

            dispatch(actions.modal.closeModal());
          },
        })
      );
      fileRef.current.clear();
    }
  };

  const renderLogoSettings = (setting: ISettings, key: number) => {
    const ratio = getSettingImageRatio(setting.code);
    return (
      <div className="col-12 md:col-4 logo-item" key={key}>
        <Card>
          <label>
            <p>{setting.name}</p>
          </label>
          <div className="img-wrapper">
            <img src={makeUniqueUrl(setting.value as string)} />
          </div>
          {ratio && <p className="ratio">Ratio: 1:{parseFloat(ratio.toFixed(1))}</p>}
          <FileUpload
            auto
            ref={fileRef}
            customUpload
            mode="basic"
            name={setting.code}
            accept="image/*"
            chooseLabel="Update image"
            onSelect={(e: FileUploadSelectEvent) => selectImage(e, { ...setting, ratio })}
          />
        </Card>
      </div>
    );
  };

  return <div className="grid">{props.images.map(renderLogoSettings)}</div>;
}
