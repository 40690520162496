import React, { RefObject, SyntheticEvent, useEffect, useRef, useState } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/lib/ReactCrop.scss";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import actions from "../../store/actions";
import toasts from "~/store/constants/toasts";

import "./ImageCropper.scss";
import * as permissionType from "~/constants/permissions";
import { usePermissionContext } from "~/contexts/PermissionContext";
import { Button } from "primereact/button";
import { isUndefined, isNull } from "lodash";
import cn from "classnames";
import { FileUpload, FileUploadSelectEvent } from "primereact/fileupload";

interface IOriginal {
  file: File;
  baseUrl: string;
}

export default function ImageCropper(props: {
  isAvatarImage?: boolean;
  talentId?: number;
  clientId?: string;
  isOnboarding?: boolean;
  saveImageCallback?: () => void;
  file?: File;
  isGlobalSettings?: boolean;
  aspect: number;
  freeCrop?: boolean;
  handleSaveImage?: (img: Blob | void) => void;
}) {
  const { userAccess } = usePermissionContext();
  const defaultCrop: ReactCrop.Crop = props.freeCrop ? { aspect: undefined } : { aspect: props.aspect || 780 / 410 };
  const inputEl = useRef() as RefObject<FileUpload>;

  const [crop, setCrop] = useState(defaultCrop);
  const [original, setOriginal] = useState<IOriginal | null>(null);

  const dispatch = useDispatch();
  const { talentId, clientId } = Object.keys(props).length ? props : useParams();

  useEffect(() => {
    if (props.file) {
      convertOriginal(props.file);
      renderToast();
    }
  }, []);

  const saveCroppedImage = async (e: SyntheticEvent) => {
    e.preventDefault();
    if (!original?.baseUrl) return;

    const img: Blob | void = await getCroppedImg(original.baseUrl, crop);
    if (talentId)
      dispatch(
        actions.talent.saveProfileImage(talentId, img, props.saveImageCallback, props.isOnboarding, props.isAvatarImage)
      );
    if (clientId) dispatch(actions.client.saveProfileImage(clientId, img, userAccess(permissionType.accessAdmin)));
    if (props.handleSaveImage) props.handleSaveImage(img);
  };

  const saveOriginalImage = (e: SyntheticEvent) => {
    e.preventDefault();
    if (!original?.file) return;
    if (props.handleSaveImage) props.handleSaveImage(original.file);
  };

  const renderToast = () => {
    dispatch(
      toasts.setAlerts([
        {
          closable: false,
          content: renderAskModal(closeAskModal),
          severity: "warn",
          sticky: true,
        },
      ])
    );
  };

  const convertOriginal = (file: File) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async () => {
      setOriginal({
        file,
        baseUrl: reader.result as string,
      });
    };
  };

  const selectImage = (event: FileUploadSelectEvent): void => {
    inputEl.current?.clear();
    const { files } = event;
    if (files && files.length) {
      convertOriginal(files[0]);
    }
  };

  const closeAskModal = () => dispatch(toasts.setAlerts([]));

  const renderAskModal = (onClose: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) => {
    return (
      <div className="flex flex-column" style={{ flex: "1" }}>
        <div className="text-center">
          <i className="pi pi-exclamation-triangle" style={{ fontSize: "3rem" }} />
          <p>
            Nice 🙌
            <br />
            Next, pls use your cursor to click and drag an area in the image, so we can crop the image to fit the
            correct profile dimensions 👍🏿
          </p>
        </div>
        <footer>
          <Button className="p-button-success" label="Got It" onClick={onClose} type="button" />
        </footer>
      </div>
    );
  };

  const onLoad = (img: HTMLImageElement) => {
    const aspect = defaultCrop.aspect;

    const width = img.width / aspect < img.height * aspect ? 100 : ((img.height * aspect) / img.width) * 100;
    const height = img.width / aspect > img.height * aspect ? 100 : (img.width / aspect / img.height) * 100;
    const y = (100 - height) / 2;
    const x = (100 - width) / 2;

    const freeCropSettings = {
      unit: "px",
      width: img.width,
      height: img.height,
      x: 0,
      y: 0,
      aspect,
    };

    const cropSettings = {
      unit: "px",
      width: (img.width * width) / 100,
      height: (img.height * height) / 100,
      x: (img.width * x) / 100,
      y: (img.height * y) / 100,
      aspect,
    };

    setCrop(props.freeCrop ? freeCropSettings : cropSettings);

    return false; // Return false if you set crop state in here.
  };

  const getCroppedImg = (image: string, crop: ReactCrop.Crop): Promise<Blob> => {
    const canvas = document.createElement("canvas");
    return new Promise((resolve, reject) => {
      if (crop.width && crop.height) {
        const { x: cx, y: cy, width: cw, height: ch } = crop;
        const ctx = canvas.getContext("2d");
        if (!isNull(ctx)) {
          const img = document.getElementsByClassName("ReactCrop__image")[0] as HTMLImageElement;
          img.onload = async () => {
            const imgType = img.src.substring("data:image/".length, img.src.indexOf(";base64"));
            const scaleX = img.naturalWidth / img.width;
            const scaleY = img.naturalHeight / img.height;
            canvas.width = Math.ceil((crop.width as number) * scaleX);
            canvas.height = Math.ceil((crop.height as number) * scaleY);
            await ctx.drawImage(
              img,
              (cx as number) * scaleX,
              (cy as number) * scaleY,
              cw * scaleX,
              ch * scaleY,
              0,
              0,
              cw * scaleX,
              ch * scaleY
            );
            canvas.toBlob(
              (blob: Blob | null) => {
                if (!isNull(blob)) {
                  resolve(blob);
                } else {
                  reject();
                }
              },
              imgType,
              1
            );
          };
          img.src = image;
        } else {
          reject();
        }
      } else {
        reject();
      }
    });
  };

  const cropSetted: boolean =
    !isUndefined(crop.width) && !isUndefined(crop.height) ? crop.width * crop.height > 0 : false;

  if (!talentId && !clientId && !props?.isGlobalSettings) {
    return null;
  }

  return (
    <div className={cn("ImageCropContainer", !original && props.isOnboarding && "max-w-full")}>
      {!props.isOnboarding && <label htmlFor="profile_image">Image</label>}
      {!!original?.baseUrl && (
        <div className="wide">
          <ReactCrop
            src={original.baseUrl}
            crop={crop}
            onChange={(n) => setCrop(n)}
            onImageLoaded={onLoad}
            circularCrop={props.isAvatarImage}
          />
        </div>
      )}
      <div className={cn("p-formgrid grid", !!original && "img-selected")}>
        <div className={cn(!cropSetted && props.isOnboarding ? "col-12" : "col")}>
          <button
            className="p-button p-button-text-icon-left"
            onClick={() => inputEl.current?.getInput()?.click()}
            type="button"
          >
            {!original ? "Select image" : "Select other Image"}
          </button>
        </div>
        {cropSetted && (
          <div className="col">
            <button onClick={saveCroppedImage} className="p-button p-button-text-icon-left">
              Save Image
            </button>
          </div>
        )}
        {props.isGlobalSettings && (
          <div className="col-12 pt-0">
            <button onClick={saveOriginalImage} className="p-button p-button-text-icon-left">
              Save Original Image
            </button>
          </div>
        )}
      </div>
      <FileUpload
        auto
        customUpload
        mode="basic"
        className="hidden"
        ref={inputEl}
        accept="image/*"
        onSelect={selectImage}
      />
    </div>
  );
}
