import React, { useEffect, useMemo, useState } from "react";
import { Field, FieldInputProps, FieldMetaProps, FormikProps, FormikValues } from "formik";
import { MultiSelect, MultiSelectProps } from "primereact/multiselect";
import { Button } from "primereact/button";
import "./ApMultiselectEditable.scss";

interface IMultiselect extends MultiSelectProps {
  id: string;
  options: any[];
  formik?: FormikProps<FormikValues>;
  noField?: boolean;
  keyName: string;
  onAdd: (e: any) => void;
}

export default function ApMultiselectEditable(props: IMultiselect) {
  let meta, field;
  const { noField, options, onAdd, keyName, ...rest } = props;

  if (props.noField && props.formik) {
    meta = props.formik.getFieldMeta(props.id);
    field = props.formik.getFieldProps(props.id);
  }

  const [customOptions, setCustomOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState<any[]>(props?.value || field?.value || []);
  const [filterValue, setFilterValue] = useState("");

  const allOptions = useMemo(() => {
    return [...(options || []), ...customOptions];
  }, [options, customOptions]);

  const isSameOption = allOptions.filter((item: any) => item[props.keyName] === filterValue).length > 0;
  const isEmptyField = !filterValue.trim().length;

  const headerTemplate = (options: any) => {
    return (
      <div className="p-multiselect-header">
        {options.checkboxElement}
        {options.filterElement}
        <Button
          style={{ width: "80px", padding: "10px", justifyContent: "center" }}
          type="button"
          disabled={filterValue === "" || isSameOption || isEmptyField}
          onClick={(e) => {
            e.preventDefault();
            setCustomOptions((prev) => [...prev, { [props.keyName]: filterValue }]);
            onAdd({ [props.keyName]: filterValue });
          }}
        >
          Add
        </Button>
        {options.closeElement}
      </div>
    );
  };

  const renderMultiSelect = (field: FieldInputProps<any>, meta: FieldMetaProps<any>) => {
    useEffect(() => {
      !!field.value && setSelectedOptions(field.value);
    }, [field]);

    return (
      <>
        <MultiSelect
          {...field}
          {...rest}
          inputId={props.id}
          value={selectedOptions}
          options={allOptions}
          filterPlaceholder={props.filterPlaceholder || "Filter..."}
          panelHeaderTemplate={headerTemplate}
          onFilter={(e) => {
            setFilterValue(e.filter);
          }}
        />
        {meta.touched && meta.error && <div className="ap-error">{meta.error}</div>}
      </>
    );
  };

  return noField && meta ? (
    renderMultiSelect(field as FieldInputProps<any>, meta)
  ) : (
    <Field name={props.id}>{({ field, meta }: any) => renderMultiSelect(field, meta)}</Field>
  );
}
