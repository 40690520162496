@import "../../../mixins.scss";

.ApCard {
  cursor: pointer;

  .update-img-wrp {
    width: 100%;
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    position: absolute;
    bottom: 0;
    z-index: 2;
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
    font: bold 15px "Metropolis", Helvetica, Arial, sans-serif;
    color: $lightGray;
  }

  .wrp-profile-image {
    width: 40%;
    position: relative;
    z-index: 3;
    top: 53px;
    left: 15px;
    transform: scale(1);
    transition: all 0.2s ease-in-out;
    transform-origin: bottom left;
    border: 3px solid #fff;
    border-radius: 50%;
    overflow: hidden;

    &.freelance {
      border-color: var(--yellow-color);
    }

    img {
      display: block;
      width: 100%;
    }

    .clip-overlay {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 1.1em;
      background-color: var(--yellow-color);

      &::after {
        display: block;
        font-size: 0.5em;
        padding-top: 0.2em;
        text-align: center;
      }

      &.freelance {
        &::after {
          content: "Freelancer";
        }
      }
    }

    &:hover .update-img-wrp {
      opacity: 1;
      transition: opacity 0.2s linear;
    }
  }

  .card-description {
    height: 110px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .card-shrink .card-description {
    height: 0;
    opacity: 0;
    animation: descriptionShrink 0.3s ease-in;
  }
  .img-fade {
    opacity: 0;
    animation: imageFade 0.3s ease-in;
  }

  &.p-component {
    font-family: $baseFont;
  }

  &.p-card {
    position: relative;
    background: #fff;
    border-radius: 10px;
    overflow: hidden;
    max-width: 365px;
    box-shadow: 0px 1px 10px -3px rgba(0, 0, 0, 0.2);
    height: 510px;

    svg {
      color: $brandColor;
      vertical-align: middle;
    }

    .wrp-img {
      position: absolute;
      z-index: 1;
      display: block;
      width: 100%;
      height: 180px;
      transition: height 300ms ease-out;

      .update-img-wrp {
        top: 0;
        background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
      }
      &:hover .update-img-wrp {
        opacity: 1;
        transition: opacity 0.2s linear;
      }
    }

    .p-card-header {
      position: relative;
    }

    .p-card-body {
      position: absolute;
      bottom: 0;
      width: 100%;
      background: #ffffff;
      font-family: $baseFont;
      padding: 0;

      .p-card-content {
        padding-bottom: 0;

        .p-card-title {
          font-size: 16px;
          color: $brandColor;
          text-decoration: underline;
          position: relative;
          margin: 0;

          &.with-actions {
            padding-right: 95px;
          }

          .brief-actions {
            position: absolute;
            top: 0;
            right: 0;
          }
        }

        .p-card-text {
          padding: 0 20px;

          &.main-text {
            position: relative;
            z-index: 2;
            background-color: #ffffff;
            padding-top: 20px;
            :first-child {
              z-index: 3;
            }
            .icon-company {
              margin-right: 4px;
            }
          }

          .col-12 {
            padding-top: 0.2rem !important;
          }

          .col-12.talent-rate_hidden {
            opacity: 0;
          }
        }

        .brief-actions {
          text-align: right;
        }

        .p-accordion {
          background-color: #fff;
          position: relative;
          z-index: 2;
          &.accordion-custom {
            margin-bottom: 3rem;
            &.accordion-expanded {
              margin-bottom: 1rem;
              animation: accordionExpand 0.4s ease-in;
            }
          }
          .p-accordion-tab {
            a {
              color: $primaryColor;
              text-transform: uppercase;

              &:focus,
              &:active {
                outline: none;
                box-shadow: none;
              }
            }

            .normalCase {
              a {
                text-transform: inherit;
              }
            }
            .availability-hidden.p-accordion-header .availability .p-progressbar {
              display: none;
            }
            .p-accordion-header .availability {
              display: flex;
              align-items: center;
              justify-content: space-between;
              .availability-bar .p-progressbar {
                width: 100px;
                margin-right: 20px;
              }
            }

            .p-accordion-header-link {
              display: flex;
              justify-content: space-between;
              font-size: 12px;
              text-transform: uppercase;
              border: none;
              border-bottom: 1px solid #f3f3f3;
              color: #d4272b;
              background: #fff;
              border-radius: 0;
              padding: 1rem 20px;
            }

            .p-accordion-toggle-icon {
              order: 1;
              margin-right: 0;
              font-size: 1rem;
            }

            .wrp-content {
              .textWrapper {
                height: 320px;
                overflow: auto;
                font-size: 0.8rem;
                > p {
                  margin-top: 0;
                  padding: 0 20px;
                }
                .skill-list {
                  padding: 0 20px;
                  margin-top: 12px;
                  ul {
                    display: inline;
                    padding: 0;
                    li {
                      display: inline-block;
                      margin: 2px;
                      padding: 2px 5px 0;
                      border-radius: 10px;
                      border: 1px solid var(--grey-color);
                    }
                  }
                }
              }
            }
          }

          .p-accordion-content {
            padding: 0;
            border: none;
          }
        }

        .matching-status {
          padding: 6px 0;
          max-width: inherit;
          display: block;
        }

        label {
          font-weight: 700;
        }

        .card-actions {
          min-height: 51px;
          &__link {
            background-color: #fff;
          }
          a,
          button {
            border-radius: 5px;
            border: 1px solid #f1f5f5;
            padding: 0.429rem;
            margin-right: 7px;

            &:hover {
              border: 1px solid $brandColor;
            }
          }

          > div:last-child {
            text-align: right;

            button {
              margin-right: 0;
              margin-left: 7px;
            }
          }

          .visible-hidden {
            visibility: hidden;
          }
        }
      }
    }

    &.freelance {
      box-shadow: 0px 1px 10px -3px var(--yellow-color);
    }

    .talent-type {
      margin: 5px 0;

      svg {
        color: $brandColor;
        vertical-align: middle;
      }

      a {
        display: inline-block;
        color: $brandColor;
        font-weight: 700;
        padding-left: 10px;
      }
    }
  }

  @keyframes accordionExpand {
    0% {
      margin-bottom: 3rem;
    }
    100% {
      margin-bottom: 1rem;
    }
  }
  @keyframes descriptionShrink {
    from {
      height: 110px;
      opacity: 1;
    }
    to {
      height: 0;
      opacity: 0;
    }
  }
  @keyframes imageFade {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}
