.BriefDetailsModal {
  label {
    font-weight: 600;
    display: block;
  }
  .columns-2 {
    p {
      break-inside: avoid;
    }
  }
}
