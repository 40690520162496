import { AxiosError, AxiosResponse } from "axios";

// ------------------------------------
// Constants
// ------------------------------------
export const BROADCAST_SEND_FAILURE = "BROADCAST_SEND_FAILURE";
export const BROADCAST_SEND_REQUEST = "BROADCAST_SEND_REQUEST";
export const BROADCAST_SEND_SUCCESS = "BROADCAST_SEND_SUCCESS";

// ------------------------------------
// Action Creators
// ------------------------------------
export default {
  sendMessageFailure: (err: AxiosError) => ({
    type: BROADCAST_SEND_FAILURE,
    payload: err?.response?.data,
  }),
  sendMessageRequest: () => ({
    type: BROADCAST_SEND_REQUEST,
  }),
  sendMessageSuccess: (res: AxiosResponse) => ({
    type: BROADCAST_SEND_SUCCESS,
    payload: res.data,
  }),
};
