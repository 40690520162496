export const errorMsgEntries = ({ path }: { path: string }) => {
  let label;
  if (path === "skills") label = "Skills";
  else {
    label = path[0].toUpperCase() + path.slice(1);
  }

  return `I need one of my own ${label} added please`;
};

export const dropdownMessage = "Please choose the ${label} from dropdown list";
export const selectAtLeastOne = "Please, select at least ${min} ${label}";
