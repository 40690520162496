export const DEFAULT_PAGINATED_STATE = {
  data: [],
  current_page: 1,
  per_page: 15,
  total: 0,
};

export const GLOBAL_ADMIN_ROLE_ID = 1;
export const REGIONAL_ADMIN_ROLE_ID = 2;
export const LOCAL_ADMIN_ROLE_ID = 5;
export const TRO_ROLE_ID = 6;
export const BU_ROLE_ID = 8;

export const WORK_DAY_HOURS = 8;
export const HALF_WORK_DAY_HOURS = WORK_DAY_HOURS / 2;
