import * as Yup from "yup";
import "yup-phone-lite";
import { UserManagementSchema } from "./user";
import { genieDetailsMaxInputLength } from "./common";
import { requiredFieldMessage, invalidNumberMessage, yupString, isDuplicatesInArray, urlMessage } from "~/utils";
import { TalentPersonalYouSchema } from "~/schemas/PersonalYouSchema";
import { URL_REGEXP } from "~/config";
import { TalentType } from "~/store/constants/talent";

export enum MaxMostInfluential {
  DISCIPLINE_CREATIVE = 3,
  DISCIPLINE_DESIGN = 3,
  DISCIPLINE_SOCIAL = 4,
  DISCIPLINE_STRATEGY = 4,
  DISCIPLINE_BUSINESS = 4,
  DISCIPLINE_PRODUCTION = 4,
}

export const yearMinValueTest: any = {
  name: "year-min-value",
  message: "${path} must not be earlier than 1980",
  test: (value: string) => !value || +value >= 1980,
};

export const yearMaxValueTest: any = {
  name: "year-max-value",
  message: "${path} must not be later than current year",
  test: (value: string) => !value || +value <= new Date().getFullYear(),
};

const minUsersTest = {
  name: "min-users-test",
  test: function (values) {
    if (values.users?.length + values.related?.length > 1) {
      return true;
    }
    return this.createError({
      message: "Minimum of two users required on a team",
      path: "users",
    });
  },
};

const uniqueEmailsTest = {
  name: "unique-emails-test",
  test: function (values) {
    if (values?.users?.length < 2) {
      return true;
    }
    const newEmails = values.users.map((value) => value.email);
    return isDuplicatesInArray(newEmails)
      ? this.createError({
          message: "New users should have unique e-mails",
          path: "users",
        })
      : true;
  },
};

const talentQuick = {
  name: Yup.string()
    .min(2, "Please, add at least 2 symbols")
    .max(50, "Please, make it shorter than 50 symbols")
    .required(requiredFieldMessage),
  discipline_code: Yup.string().required(requiredFieldMessage),
  organisation_id: Yup.number().required(requiredFieldMessage),
  tro: Yup.array().of(Yup.number()).required(requiredFieldMessage).nullable(),
  onboarded_by: Yup.number()
    .nullable()
    .when("type", {
      is: TalentType.FREELANCER,
      then: Yup.number().required(requiredFieldMessage).typeError(requiredFieldMessage),
    }),
  last_working_date: Yup.date().nullable(),
};

const SkillsManagementSchema = Yup.object().shape({
  id: Yup.number(),
  name: Yup.string().required(requiredFieldMessage),
  order_id: Yup.number(),
});

const primaryDetails = (isSuperAdmin: boolean = true) => ({
  name: Yup.string()
    .min(2, "Please, add at least 2 symbols")
    .max(50, "Please, make it shorter than 50 symbols")
    .required(requiredFieldMessage),
  discipline_code: Yup.string().required(requiredFieldMessage),
  specialism_code: Yup.string().nullable().required(requiredFieldMessage),
  level_code: Yup.string().required(requiredFieldMessage),
  team_code: Yup.string()
    .nullable()
    .when(["specialism_code"], {
      is: (specialism_code) => specialism_code === "SPECIALISM_CREATIVE_TEAM",
      then: Yup.string().required(requiredFieldMessage),
    }),
  start_from: Yup.date().nullable().required(requiredFieldMessage),
  location_code: Yup.string().required(requiredFieldMessage),
  notes: Yup.string().nullable().max(640, "Please, make it shorter than 640 symbols"),
  skills: isSuperAdmin
    ? Yup.array().of(SkillsManagementSchema).min(1, "At least one Skill should be assigned")
    : Yup.array().nullable(),
});

const users = {
  users: Yup.array()
    .of(UserManagementSchema)
    .min(1, "At least one user should be assigned")
    .required(requiredFieldMessage),
};

const genieDetails = {
  headline: Yup.string()
    .min(2, "Please, add at least 2 symbols")
    .max(
      genieDetailsMaxInputLength.headline,
      `Please, make it shorter than ${genieDetailsMaxInputLength.headline} symbols`
    )
    .nullable()
    .required(requiredFieldMessage),
  subhead: Yup.string()
    .min(2, "Please, add at least 2 symbols")
    .max(
      genieDetailsMaxInputLength.subhead,
      `Please, make it shorter than ${genieDetailsMaxInputLength.subhead} symbols`
    )
    .nullable()
    .required(requiredFieldMessage),
  description: Yup.string()
    .min(2, "Please, add at least 2 symbols")
    .max(
      genieDetailsMaxInputLength.description,
      `Please, make it shorter than ${genieDetailsMaxInputLength.description} symbols`
    )
    .nullable()
    .required(requiredFieldMessage),
  rate: Yup.number().min(0).nullable().typeError(invalidNumberMessage),
  annual_rate: Yup.number().min(0).nullable().typeError(invalidNumberMessage),
  portfolio_url: Yup.string()
    .nullable()
    .matches(
      /^((https?):\/\/)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,10}\b([-a-zA-Z0-9()@:%_+.~#?&\/=]*)?$/,
      "Please, enter URL in correct format"
    )
    .required(requiredFieldMessage),
  cv_url: Yup.string().nullable(),
};

const blockedBoosted = {
  freeze: Yup.string(),
};

export const TalentPrimaryDetailsSchema = (isSuperAdmin: boolean) => Yup.object().shape(primaryDetails(isSuperAdmin));

export const TalentUsersSchema = Yup.object().shape(users);

const validPhoneTest = {
  name: "validate-phone",
  test: function (this: any) {
    const inputValue = this.parent.phone_meta?.inputValue;
    if (!inputValue) return true;

    return Yup.string()
      .phone(this.parent?.phone_meta?.iso2)
      .isValid(this.parent.telephone)
      .then((isValid: boolean) => {
        return isValid
          ? true
          : this.createError({
              message:
                inputValue.replace(/[^0-9.]/g, "")[0] === "0"
                  ? "Invalid contact number, try removing the '0' at the beginning"
                  : "Invalid contact number",
            });
      });
  },
};

export const TalentOnboardingSchema = Yup.object().shape({
  portfolio_url: Yup.string().matches(URL_REGEXP, urlMessage).nullable(),
  telephone: Yup.string().test(validPhoneTest).nullable(),
});

export const TalentProfileAdminSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Please, add at least 2 symbols")
    .max(50, "Please, make it shorter than 50 symbols")
    .required(requiredFieldMessage),
  telephone: Yup.string().test(validPhoneTest).nullable(),
});

export const TalentProfileSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Please, add at least 2 symbols")
    .max(50, "Please, make it shorter than 50 symbols")
    .required(requiredFieldMessage),
  telephone: Yup.string().test(validPhoneTest).nullable(),
  organisation_id: Yup.number().required(),
  tro: Yup.array().of(Yup.number()).min(1, requiredFieldMessage),
  country: Yup.string().required(requiredFieldMessage),
  timezone: Yup.string().required(requiredFieldMessage),
  discipline_code: Yup.string().required(requiredFieldMessage),
  specialism_code: Yup.string().required(requiredFieldMessage),
  level_code: Yup.string().required(requiredFieldMessage),
  skills: Yup.array().of(SkillsManagementSchema).min(1, "At least ${min} Skill should be assigned"),
  sectors: Yup.array().of(SkillsManagementSchema).nullable(),
  tools: Yup.array().of(SkillsManagementSchema).nullable(),
  image_url: Yup.string()
    .nullable()
    .matches(
      /^((https?):\/\/)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,10}\b([-a-zA-Z0-9()@:%_+.~#?&\/=]*)?$/,
      "Please, enter URL in correct format"
    )
    .required(requiredFieldMessage),
  cv_url: Yup.string().nullable(),
  description: yupString(true, 2, 1000),
  portfolio_url: Yup.string().matches(URL_REGEXP, urlMessage).nullable(),
  last_working_date: Yup.date().nullable(),
});

export const TalentGenieDetailsSchema = Yup.object().shape({
  work_profile: Yup.object().shape(genieDetails),
  platforms: Yup.array().when("discipline_code", {
    is: "DISCIPLINE_SOCIAL",
    then: Yup.array().of(Yup.object()).max(3, "Max 3 Platforms should be assigned").nullable(),
  }),
  country: Yup.string().nullable(),
});

export const TalentBlockedBoostedSchema = Yup.object().shape(blockedBoosted);

export const TalentQuickSchema = ({ isTeam }: { isTeam: boolean } = { isTeam: false }) => {
  const validationSchema = Yup.object()
    .shape({
      ...talentQuick,
      users: Yup.array().of(TalentPersonalYouSchema),
    })
    .test(uniqueEmailsTest);

  return isTeam ? validationSchema.test(minUsersTest) : validationSchema;
};

export const TalentSchema = Yup.object().shape({
  ...primaryDetails(),
  ...users,
  ...genieDetails,
  ...blockedBoosted,
});

export const MostInfluentialManagementSchema = Yup.object().shape({
  client_name: Yup.string().required().label("Company Name").max(100),
  start_year: Yup.string()
    .required()
    .label("Start Year")
    .length(4, "Start Year must be exactly 4 characters")
    .test(yearMinValueTest),
  end_year: Yup.string()
    .required()
    .label("Finish Year")
    .length(4, "Finish Year must be exactly 4 characters")
    .test(
      "finish-year-later-or-equal-to-start-year",
      "Finish Year must be later than or equal to Start Year",
      function (endYear: string) {
        const startYear = this.parent.start_year;
        if (!endYear || !startYear) return true;
        return +startYear <= +endYear;
      }
    )
    .test(yearMaxValueTest),
});

export const ReferenceSchema = Yup.object().shape({
  full_name: Yup.string().required().label("Name").max(100),
  email: Yup.string().label("Email").email("Please, enter the correct email").max(100).required(),
});

// ------------------------------------
// Initial values
// ------------------------------------

export const initialValues = {
  name: "",
  description: "",
  headline: "",
  subhead: "",
  portfolio_url: "",
  cv_url: "",
  rate: "",
  annual_rate: "",
  freeze: false,
  freeze_reason: "",
  start_from: "",
  organisation_id: "",
  discipline_code: "",
  specialism_code: "",
  level_code: "",
  team_code: "",
  location_code: "",
  notes: "",
  starred: false,
  users: [],
  skills: [],
  sectors: [],
  blocked_clients: [],
  boosted_clients: [],
  work_profile: {
    image_url: "",
  },
  platforms: [],
  country: "",
};
