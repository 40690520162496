import React, { useMemo } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ITag } from "~/interfaces/common";
import ApTag from "~/components/common/ApTag/ApTag";
import "./TagsList.scss";
import { useSelector } from "react-redux";

interface ITagsListProps {
  selectedItems: ITag[];
  tags: ITag[];
  handleAttachTag: (data: any) => void;
  handleDettachTag: (data: any) => void;
  handleEditMode: (data?: any) => void;
}

export default function TagsList({
  selectedItems,
  tags,
  handleAttachTag,
  handleDettachTag,
  handleEditMode,
}: ITagsListProps) {
  const isFetchingTags = useSelector((state: any) => state.user.isFetchingTags);
  const isFetchingTalentTags = useSelector((state: any) => state.talent.isFetchingTags);
  const isFetching = useMemo(() => isFetchingTags || isFetchingTalentTags, [isFetchingTags, isFetchingTalentTags]);

  return (
    <DataTable
      emptyMessage="No tags found"
      value={tags as ITag[]}
      className="tags-table"
      selection={selectedItems}
      onRowSelect={handleAttachTag}
      onRowUnselect={handleDettachTag}
      footer={() => <Button label="Create a new tag" className="p-button-rounded" onClick={() => handleEditMode()} />}
      loading={isFetchingTags}
      selectionMode="checkbox"
      disabled={isFetching}
    >
      <Column selectionMode="multiple" style={{ width: "45px" }} />
      <Column body={(rowData) => <ApTag title={rowData.name} fill={rowData.color} />} />
      <Column
        className="tag-edit"
        body={(rowData) => (
          <Button
            className="p-button-icon p-button-text"
            icon="pi pi-pencil"
            tooltip="Edit"
            tooltipOptions={{ position: "top" }}
            onClick={() => handleEditMode(rowData)}
          />
        )}
      />
    </DataTable>
  );
}
