import { BriefStatuses, IBrief } from "~/interfaces/brief";
import { IClearance } from "~/interfaces/clearance";
import { TalentType } from "~/store/constants/talent";

export const getStatusOptions = (rowData: any) => {
  let colorClass: string | undefined = undefined;
  let options: any[] = [];
  let placeholder: string | undefined = undefined;

  const isFreelancer =
    rowData?.talent_type === TalentType.FREELANCER || rowData?.talent_type === TalentType.FREELANCE_TEAM;

  const canRequest = rowData?.available_statuses?.includes("CLEARANCE_REQUESTED");
  const canApprove = rowData?.available_statuses?.includes("CLEARANCE_APPROVED");
  const canBook = rowData?.available_statuses?.includes("BOOKED");
  const canAmendBooking = rowData?.available_statuses?.includes("BOOKING_AMENDED");

  switch (rowData.status) {
    case "MATCHED":
      options = [
        ...(!isFreelancer && canRequest ? [{ name: "Request clearance", code: "CLEARANCE_REQUESTED" }] : []),
        ...(!isFreelancer && canBook ? [{ name: "Allocate", code: "BOOKED" }] : []),
        ...(isFreelancer ? [{ name: "Enquire", code: "BOOKED_FREELANCER" }] : []),
      ];
      colorClass = "pending";
      placeholder = "Matched";
      break;
    case "CLEARANCE_REQUESTED":
      options = [
        ...(canApprove ? [{ name: "Approve clearance", code: "CLEARANCE_APPROVED" }] : []),
        ...(canApprove ? [{ name: "Decline clearance", code: "CLEARANCE_DECLINED" }] : []),
        { name: "Amend clearance", code: "CLEARANCE_AMENDED" },
        { name: "Cancel clearance", code: "CLEARANCE_CANCELLED" },
      ];
      colorClass = "requested";
      placeholder = "Clearance requested";
      break;
    case "CLEARANCE_APPROVED":
      options = [
        ...(canBook ? [{ name: "Allocate", code: "BOOKED" }] : []),
        { name: "Amend clearance", code: "CLEARANCE_AMENDED" },
        { name: "Cancel clearance", code: "CLEARANCE_CANCELLED" },
      ];
      colorClass = "clearance-approved";
      placeholder = "Clearance approved";
      break;
    case "CLEARANCE_DECLINED":
      options = [{ name: "Request clearance", code: "CLEARANCE_REQUESTED" }];
      colorClass = "declined";
      placeholder = "Clearance declined";
      break;
    case "RESOURCED":
    case "BOOKED":
      options = [
        ...(!isFreelancer && canAmendBooking ? [{ name: "Amend allocation", code: "AMEND_REQUESTED" }] : []),
        { name: "Cancel allocation", code: "BOOKING_CANCELLED" },
      ];
      colorClass = "accepted";
      placeholder = "Allocated";
      break;
    case "BOOKING_AMENDED":
      options = [
        ...(canApprove ? [{ name: "Approve request", code: "CLEARANCE_APPROVED" }] : []),
        ...(canApprove ? [{ name: "Decline request", code: "CLEARANCE_DECLINED" }] : []),
      ];
      colorClass = "requested";
      placeholder = "Amendment requested";
      break;
    case "BOOKING_CANCELLED":
      options = [
        ...(!isFreelancer && canRequest ? [{ name: "Request clearance", code: "CLEARANCE_REQUESTED" }] : []),
        ...(!isFreelancer && canBook ? [{ name: "Allocate", code: "BOOKED" }] : []),
        ...(isFreelancer ? [{ name: "Enquire", code: "BOOKED_FREELANCER" }] : []),
      ];
      colorClass = "declined";
      placeholder = "Allocation cancelled";
      break;
    case "CANCELLED":
      colorClass = "declined";
      placeholder = "Cancelled";
      break;
    case "REMOVED":
      colorClass = "declined";
      placeholder = "Talent removed";
      break;
    case "BRIEF_CANCELLED":
      colorClass = "declined";
      placeholder = "Brief cancelled";
      break;

    default:
      options = [];
      break;
  }
  return {
    colorClass,
    options,
    placeholder,
  };
};
export const getSuccessMessageForBriefMatching = (requestedMatches: number, matchesFound: number) => {
  let msg;
  let severity = "success";

  if (matchesFound === 0) {
    severity = "error";
    msg = "No matches to add";
  } else if (matchesFound < requestedMatches) {
    msg = `${matchesFound} match${matchesFound > 1 ? "es" : ""} added. No more matches`;
  } else {
    msg = "Matches have been added successfully";
  }
  return {
    msg,
    severity,
  };
};

export const checkBriefForDraft = (brief: IBrief) => brief?.status === BriefStatuses?.DRAFT;

export const draftBriefValues = (brief: IBrief) => {
  const allEntries = (typeOfEntries: string) => {
    const customEntries = `custom_${typeOfEntries}`;
    const mergedEntries = [...brief[typeOfEntries], ...brief[customEntries].map((el) => ({ name: el.name }))];
    return mergedEntries
      .sort((a: any, b: any) => (a.id && !b.id ? -1 : !a.id && b.id ? 1 : 0))
      .map((entry: any, key: number) => ({
        ...entry,
        order_id: key + 1,
      }));
  };

  const briefFormated = {
    ...brief,
    ...brief.ir35,
    id: brief.id,
    skills: allEntries("skills"),
    sectors: allEntries("sectors"),
    excluded_talents: !!brief.excluded_talents.length ? brief.excluded_talents.map((talent) => talent.id) : [],
    duration_days: Number(brief.duration_days) + Number(brief.duration_weekends),
  };
  delete briefFormated.ir35;
  delete briefFormated.ir35_type;
  delete briefFormated.custom_skills;
  delete briefFormated.custom_sectors;
  delete briefFormated.custom_platforms;
  delete briefFormated.author;
  delete briefFormated.client;
  delete briefFormated.matches;
  delete briefFormated.umbrella_companies;
  delete briefFormated.region;
  delete briefFormated.first_response;
  if (briefFormated.contract_id !== 1) {
    briefFormated.ir35_id = null;
  }

  return briefFormated;
};

export const formatValuesBeforeBriefSaving = (form: any, formValues: any, params: any) => {
  const values = { ...formValues, specialism_flexible: !formValues.specialism_flexible };
  const { TALENT_GOTO_TYPES } = params;
  const isDraft = checkBriefForDraft(formValues);

  if (!values.include_tools) {
    values.tools = [];
  }
  delete values.include_tools;

  if (!values.include_sectors) {
    values.sectors = [];
  }
  delete values.include_sectors;

  if (values.goto_types?.length === TALENT_GOTO_TYPES.length) {
    values.goto_types = [];
  }

  if (isDraft) {
    ["freelancer_control", "freelancer_substitute", "freelancer_other_projects", "freelancer_amend"].forEach((el) =>
      !values[el] ? (values[el] = null) : (el = el)
    );
  }

  if (isDraft) {
    delete values.skill_list;
    delete values.platform_list;
    delete values.sector_list;
    if (!values.production_id) {
      delete values.production_id;
    }
    if (!values.start_date) {
      delete values.start_date;
      delete values.end_date;
      delete values.dates;
      delete values.duration_id;
    }
  }
  return values;
};

export const getClearanceId = (clearances: IClearance[], status: string) => {
  switch (status) {
    case "CLEARANCE_AMENDED":
      return clearances
        .reverse()
        .find((clearance: IClearance) => clearance.status === "REQUESTED" || clearance.status === "APPROVED")?.id;
    default:
      return null;
  }
};

export const getDatesModalContent = (status: string) => {
  switch (status) {
    case "CLEARANCE_REQUESTED":
      return {
        header: "Confirm clearance dates and capacity",
        subhead: "Please review your clearance dates and capacity below before sending your clearance request.",
        buttonLabel: "REQUEST CLEARANCE",
      };
    case "CLEARANCE_AMENDED":
      return {
        header: "Confirm clearance dates and capacity",
        subhead: "Please review the dates and capacity below before submitting your clearance amendment request.",
        buttonLabel: "REQUEST CLEARANCE",
      };
    case "BOOKED":
    default:
      return {
        header: "Confirm allocation dates and capacity",
        subhead: "Please review your dates and capacity below before confirming your allocation.",
        buttonLabel: "CONFIRM ALLOCATION",
      };
  }
};
