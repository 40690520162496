import React, { Dispatch, useEffect, useMemo } from "react";
import ApCheckbox from "~/components/common/ApCheckbox/ApCheckbox";
import { useGlobalContext } from "~/contexts/GlobalContext";
import moment from "moment";
import { calculateDates, getDiffBetweenDays } from "~/utils";
import { FormikContextType } from "formik";
import "./AvailabilityCalendarBlock.scss";
import { differenceWith, intersectionWith } from "lodash";
import ApAvailabilityCalendar, {
  CalendarType,
  DateItem,
  SelectionMode,
} from "~/components/common/ApAvailabilityCalendar/ApAvailabilityCalendar";
import { CalendarPropsRange } from "primereact/calendar";
import { TalentType } from "~/store/constants/talent";

interface ICalendarBlock {
  formik: FormikContextType<any>;
  dates: DateItem[] | Date[];
  minDate?: Date | undefined;
  setDates: Dispatch<any>;
  setDurationDays: Dispatch<any>;
  durationDays: number;
  maxDurationDays: number;
  setMaxDurationDays: Dispatch<any>;
  bookingStatus?: string | undefined;
  capacity?: number;
  talentId?: number;
  talentType?: TalentType | null;
  calendarType?: CalendarType;
}

export default function AvailabilityCalendarBlock(props: ICalendarBlock) {
  const {
    formik,
    dates,
    minDate,
    setDates,
    setDurationDays,
    setMaxDurationDays,
    bookingStatus,
    talentId,
    talentType,
    calendarType = CalendarType.CAPACITY,
  } = props;
  const {
    global: { optionsNormalization },
  } = useGlobalContext();
  const DURATION_ID = optionsNormalization?.DURATION_ID;
  const isDurationTypeConsec = formik?.values.duration_id === DURATION_ID?.CONSEC;
  const isExactWorkingDates = formik?.values.duration_id === DURATION_ID?.EXACT;
  const selectionMode: SelectionMode = isExactWorkingDates ? SelectionMode.multiple : SelectionMode.range;

  const calendarValue = useMemo(() => {
    if (dates?.length) {
      return isExactWorkingDates ? dates?.map(({ date }) => date) : dates;
    }

    return undefined;
  }, [dates]);

  const handleChangeDates: CalendarPropsRange["onChange"] = ({ value }) => {
    if (Array.isArray(value)) {
      if (isExactWorkingDates) {
        let newDates: ICalendarBlock["dates"] = [];

        if (value.length > dates?.length) {
          let newDate = differenceWith(value, dates, (a, b) => moment(a).isSame(b.date)); // find new date
          newDates = [...dates, { date: newDate[0], capacity: 100 }] as ICalendarBlock["dates"]; // add new date
        } else if (value.length < dates.length) {
          newDates = intersectionWith(dates, value, (a, b) => moment(a.date).isSame(b)); // exclude deleted date
        }

        calculateDates.sortObj(newDates as DateItem[]);

        setDates(newDates);
        formik.setValues({
          ...formik.values,
          start_date: value.length ? moment(value[0]).format("YYYY-MM-DD") : "",
          end_date: value.length ? moment(value[value.length - 1]).format("YYYY-MM-DD") : "",
          dates: calculateDates.formatDates(newDates as DateItem[]),
        });
      } else {
        formik.setValues({
          ...formik.values,
          start_date: moment(value[0]).format("YYYY-MM-DD"),
          end_date: moment(value[1] ?? value[0]).format("YYYY-MM-DD"),
          dates: value,
        });

        setDates(value);
      }

      formik.setTouched({ ...formik.touched, start_date: true, dates: true }, false);
    }
  };

  const resetDates = () => {
    setDates([]);
    formik.setValues({
      ...formik.values,
      start_date: "",
      end_date: "",
      dates: [],
    });
  };

  const calculateDurationDays = (dates: Date[]) => {
    const endDate = dates[1] ? moment(dates[1]) : moment(dates[0]);
    const startDate = moment(dates[0]);
    return getDiffBetweenDays({ startDate, endDate });
  };

  const changeDurationDays = (dates: Date[], day: number = 0) => {
    const days = calculateDurationDays(dates);
    setMaxDurationDays(days + 1 || null);
    day === 0 ? setDurationDays(days + 1 || null) : setDurationDays(day);
  };

  useEffect(() => {
    changeDurationDays([formik.values.start_date, formik.values.end_date], formik?.values?.duration_days);
  }, []);

  return (
    <div className="availability-calendar-block">
      <div className="p-fluid p-formgrid">
        <div className="field field-required field-date">
          <label htmlFor="start_date">Dates</label>
          <span className="calendar-label-clear" onClick={resetDates}>
            <i className="pi pi-times" />
            <strong>clear</strong>
          </span>
          <ApAvailabilityCalendar
            dates={dates}
            setDates={setDates}
            bookingStatus={bookingStatus}
            talentId={talentId}
            talentType={talentType}
            minDate={minDate}
            disabledDays={!formik?.values.include_weekends && isDurationTypeConsec ? [0, 6] : []}
            onChange={handleChangeDates}
            value={calendarValue}
            selectionMode={selectionMode}
            selectable
            capacity={formik?.values.capacity}
            calendarType={calendarType}
          />

          {isDurationTypeConsec && (
            <div className="include-weekends-wrapper">
              <ApCheckbox formik={formik} noField name="include_weekends" label="Include weekend days" />
            </div>
          )}
          {formik.touched?.start_date && formik.errors?.start_date && (
            <div className="ap-error">{formik.errors?.start_date}</div>
          )}
        </div>
      </div>
    </div>
  );
}
