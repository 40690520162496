import React from "react";
import { connect } from "react-redux";
import { IState } from "../store/reducers/index";
import AddMatchModal from "./AddMatchModal/AddMatchModal";
import AddUserModal from "./AddUserModal/AddUserModal";
import AmendBookingModal from "./AmendBookingModal/AmendBookingModal";
import ClearanceDatesModal from "./ClearanceDatesModal/ClearanceDatesModal";
import BookingExtendModal from "./BookingExtendModal/BookingExtendModal";
import BookingAmendModal from "./BookingAmendModal/BookingAmendModal";
import RemoveInstanceModal from "./RemoveInstanceModal/RemoveInstanceModal";
import SelectTalentModal from "./SelectTalentModal/SelectTalentModal";
import InfluentialEmployerModal from "~/modals/InfluentialEmployerModal/InfluentialEmployerModal";
import ReferenceModal from "~/modals/ReferenceModal/ReferenceModal";
import ChangeRoleModal from "~/modals/ChangeRoleModal/ChangeRoleModal";
import RemoveTalentModal from "~/modals/RemoveTalentModal/RemoveTalentModal";
import GenerateMatchesModal from "~/modals/GenerateMatchesModal/GenerateMatchesModal";
import ChangeBookingRateModal from "./ChangeBookingRateModal/ChangeBookingRateModal";
import MessageTalent from "./MessageTalent/MessageTalent";
import EditNotesModal from "~/modals/EditNotesModal/EditNotesModal";
import CreatePopupModal from "~/modals/CreatePopupModal/CreatePopupModal";
import UpdateImageModal from "~/modals/UpdateImageModal/UpdateImageModal";
import SelectUserModal from "~/modals/SelectUserModal/SelectUserModal";
import ArchiveTalentModal from "~/modals/ArchiveTalentModal/ArchiveTalentModal";
import DirectMatchModal from "./DirectMatchModal/DirectMatchModal";

const MODAL_COMPONENTS: any = {
  ADD_USER_MODAL: AddUserModal,
  AMEND_BOOKING_DATES: AmendBookingModal,
  CLEARANCE_DATES_MODAL: ClearanceDatesModal,
  BOOKING_EXTEND_MODAL: BookingExtendModal,
  BOOKING_AMEND_MODAL: BookingAmendModal,
  GENERATE_MATCHES_MODAL: GenerateMatchesModal,
  REMOVE_INSTANCE: RemoveInstanceModal,
  ADD_MATCHING: AddMatchModal,
  SELECT_TALENTS: SelectTalentModal,
  INFLUENTIAL_EMPLOYER_MODAL: InfluentialEmployerModal,
  REFERENCE_MODAL: ReferenceModal,
  CHANGE_ROLE_MODAL: ChangeRoleModal,
  REMOVE_TALENT_MODAL: RemoveTalentModal,
  BOOKING_RATE_MODAL: ChangeBookingRateModal,
  MESSAGE_TALENT: MessageTalent,
  EDIT_NOTES: EditNotesModal,
  CREATE_POPUP_MODAL: CreatePopupModal,
  UPDATE_IMAGE_MODAL: UpdateImageModal,
  SELECT_USERS: SelectUserModal,
  ARCHIVE_TALENT: ArchiveTalentModal,
  DIRECT_MATCH_MODAL: DirectMatchModal,
};

const ModalRoot = ({ modalType, modalProps, ...rest }: { modalType?: string; modalProps?: any }) => {
  if (!modalType || !MODAL_COMPONENTS[modalType]) return null;
  const SpecificModal = MODAL_COMPONENTS[modalType];
  return <SpecificModal side={modalProps.side} {...modalProps} {...rest} />;
};

export default connect((state: IState) => state.modal)(ModalRoot);
