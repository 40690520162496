import classnames from "classnames";
import { Field } from "formik";
import { FileUpload, FileUploadProps } from "primereact/fileupload";
import { Button } from "primereact/button";
import React, { ReactNode, useRef } from "react";
import PopupIcon from "~/components/Toaster/PopupIcon";
import { extractFileName } from "~/utils";
import "./ApFileUpload.scss";
import { IAttachment } from "~/interfaces/Interview";
import { isEmpty } from "lodash";

interface IUploadProps extends FileUploadProps {
  id: string;
  noField?: boolean;
  formik?: any;
  label?: string;
  buttonLabel?: string;
  className?: string;
  info?: string | ReactNode;
  onChange: (files: any) => void;
  onDelete?: (file: File | IAttachment, id: number) => void;
}

export default function ApFileUpload({
  noField,
  formik,
  id,
  label,
  className,
  info,
  onChange,
  onDelete,
  ...rest
}: IUploadProps) {
  const uploaderRef = useRef(null);

  let meta, field, value;
  if (noField && formik) {
    meta = formik.getFieldMeta(id);
    field = formik.getFieldProps(id);
    value = formik.getFieldProps(id).value;
  }

  const renderedLabel = label && (
    <label htmlFor={id}>
      {label} {info && <PopupIcon content={info} />}
    </label>
  );

  const renderedError = meta?.touched && meta?.error && <div className="ap-error">{meta?.error}</div>;
  const description = rest.accept ? <div className="description">Supported file types: {rest.accept}</div> : null;
  const buttonLabel = rest.buttonLabel ? (
    <span className="button-label" onClick={() => uploaderRef.current?.getInput()?.click()}>
      {rest.buttonLabel}
    </span>
  ) : null;

  delete rest.buttonLabel;

  const props = {
    ...rest,
    id,
    customUpload: !!onChange,
    uploadHandler: ({ files }: { files: File[] }) => {
      uploaderRef?.current?.clear();
      !!onChange && onChange(files);
    },
  };

  const deleteHandler = (file: File, index: number) => {
    uploaderRef?.current?.clear();
    onDelete && onDelete(file, index);
  };

  const renderFileName = (file: IAttachment | File | string, index = 0) => {
    if (file instanceof File) {
      return (
        <div className="preview" key={index}>
          <span>{file.name}</span>
          {!!onDelete && (
            <Button icon="pi pi-trash" disabled={props.disabled} onClick={() => deleteHandler(file, index)} />
          )}
        </div>
      );
    } else {
      const fileUrl = typeof file !== "string" && file?.url ? file?.url : file;
      return (
        <div className="preview" key={index}>
          <a href={fileUrl} target="_blank">
            {extractFileName(fileUrl)}
          </a>
          {!!onDelete && (
            <Button icon="pi pi-trash" disabled={props.disabled} onClick={() => deleteHandler(file, index)} />
          )}
        </div>
      );
    }
  };

  return !!noField && !!meta ? (
    <div
      className={classnames("ApFileUpload", className, {
        error: meta.touched && meta.error,
      })}
    >
      {renderedLabel}
      {(value instanceof File || !isEmpty(value)) &&
        (props.multiple ? (
          <div className="preview-list">
            {value.map((file: IAttachment | File, i: number) => renderFileName(file, i))}
          </div>
        ) : (
          renderFileName(value)
        ))}
      <div className="upload-wrapper">
        <FileUpload
          ref={uploaderRef}
          {...field}
          {...props}
          chooseOptions={{ icon: "pi pi-upload", iconOnly: true }}
          onBlur={() => {
            formik.handleBlur({ target: { name: id } });
          }}
        />
        <div>
          {buttonLabel}
          {description}
        </div>
      </div>
      {renderedError}
    </div>
  ) : (
    <Field name={id}>
      {({ field, meta }: any) => (
        <div className="ApFileUpload">
          {renderedLabel}
          {(field.value instanceof File || !isEmpty(field.value)) &&
            (props.multiple ? (
              <div className="preview-list">
                {field.value.map((file: IAttachment | File, i: number) => renderFileName(file, i))}
              </div>
            ) : (
              renderFileName(field.value)
            ))}
          <div className="upload-wrapper">
            <FileUpload
              ref={uploaderRef}
              {...field}
              {...props}
              chooseOptions={{ icon: "pi pi-upload", iconOnly: true }}
              onBlur={() => {
                field.handleBlur({ target: { name: id } });
              }}
            />
            <div>
              {buttonLabel}
              {description}
            </div>
          </div>
          {!!meta?.error && !!meta?.touched && <div className="ap-error">{meta.error}</div>}
        </div>
      )}
    </Field>
  );
}
