import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Slider } from "primereact/slider";
import { Dialog } from "primereact/dialog";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "primereact/button";
import actions from "~/store/actions/index";
import "./GenerateMatchesModal.scss";
import Loader from "~/components/Loader/Loader";
import { IState } from "~/store/reducers/index";
import { useBriefContext } from "~/contexts/BriefContext";

const maxCountGeneratedMatches = 20;

interface IProps {
  options: { briefId: number; activeTab: number };
}

function GenerateMatchesModal() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { isFetching: loading, getBriefMatchesByLimit } = useBriefContext();
  const { modalProps } = useSelector((state: IState) => state.modal);
  const { options }: IProps = modalProps || {};
  const { briefId, activeTab } = options;
  const [maxMatches, setMaxMatches] = useState(1 as number);

  const onSubmit = async () => {
    await getBriefMatchesByLimit(briefId, maxMatches);
    dispatch(actions.modal.closeModal());
    activeTab !== 0 && history.push(`/briefs/${briefId}?activeTab=0`);
  };

  return (
    <Dialog
      className="GenerateMatchesModal"
      header={activeTab === 0 ? "Generate more matches" : "Generate more matches with latest changes?"}
      footer={
        <footer>
          <Button
            className="p-button p-button-secondary"
            label="Cancel"
            onClick={() => dispatch(actions.modal.closeModal())}
            type="button"
            disabled={loading}
          />
          <Button className="p-button" label="Submit" type="button" onClick={onSubmit} disabled={loading} />
        </footer>
      }
      visible={true}
      modal={true}
      onHide={() => dispatch(actions.modal.closeModal())}
    >
      {loading ? (
        <div className="wrp-spinner">
          <Loader />
        </div>
      ) : (
        <div className="body-matches-modal">
          <p>
            Add talent: <b>{maxMatches}</b>
          </p>
          <Slider
            max={maxCountGeneratedMatches}
            min={1}
            value={maxMatches}
            onChange={(e) => setMaxMatches(e.value as number)}
          />
        </div>
      )}
    </Dialog>
  );
}

export default GenerateMatchesModal;
