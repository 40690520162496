import React from "react";
import "react-image-crop/lib/ReactCrop.scss";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../store/actions";
import "./UpdateImageModal.scss";
import cn from "classnames";
import { Dialog } from "primereact/dialog";
import ImageCropper from "~/forms/ImageCropper/ImageCropper";
import { IState } from "~/store/reducers/index";
import Loader from "~/components/Loader/Loader";

export default function UpdateImageModal(props: { talentId?: string; clientId?: string }) {
  const dispatch = useDispatch();
  const loading = useSelector((state: IState) => state.talent.isFetchingFile);

  const handleCloseModal = () => dispatch(actions.modal.closeModal());

  return (
    <Dialog
      header="Update Image"
      className={cn("UpdateImageModal", loading && "isLoading")}
      onHide={handleCloseModal}
      modal
      visible
    >
      {loading && <Loader size={3} />}
      <ImageCropper {...props} saveImageCallback={handleCloseModal} />
    </Dialog>
  );
}
