import { ICommonFilterSettings, IDefaultPaginatedState, IListItem, IQueryParams } from "./common";
import { IBrief } from "./brief";
import { DropdownChangeEvent } from "primereact/dropdown";
import { AxiosResponse } from "axios";

export interface IProject {
  id: number;
  brief_count: number;
  author_id: number;
  author: { id: number; full_name: string };
  project_id: string;
  status: ProjectStatuses.DRAFT | ProjectStatuses.OPEN | ProjectStatuses.RESOURCED | ProjectStatuses.CANCELLED;
  name: string;
  notifications: string;
  description: string;
  freeze_reason?: {
    code: string;
    name: string;
    description?: string;
    created_at: string;
  };
  frozen_by?: {
    id: number;
    name: string;
  };
  program_lead: string;
  client_lead: string;
  client_pod: string;
  start_date: string;
  end_date: string;
  created_at: string;
  updated_at: string;
  briefs: IBrief[];
  client_list: string;
  clients?: { id: number; name: string }[];
  organisation_list: string;
  organisation_id: number;
  organisations: { id: number; name: string }[];
  proposal_url?: string;
}
export interface IProjectPayload {
  id?: number;
  name: string;
  organisations: number[];
  author_id: number;
  status: IProject["status"];
  project_id?: string;
  description?: string;
  program_lead?: string | null;
  client_lead?: string | null;
  client_pod?: string | null;
  clients?: { name: string }[];
  start_date?: string;
  end_date?: string;
  proposal_url?: File | string | undefined;
}

export interface IProjectProposalFile {
  id: number;
  proposal_url: File | null;
}

export interface IProjectForm {
  id?: number;
  name: string;
  project_id?: string;
  description: string;
  organisation?: number;
  author_id?: number;
  program_lead?: string;
  client_lead?: string;
  client_pod?: string;
  proposal_url?: File | string;
  client?: string;
  dates?: Date[];
  status: IProject["status"];
}

export interface IProjectState {
  isFetching: boolean;
  isFetchingProjectBriefs: boolean;
  isProposalFetching: boolean;
  project: IProject;
  projectBriefs: IBrief[];
  projects: IDefaultPaginatedState<IProject>;
  organisationProjects: IListItem[] | undefined;
  setOrganisationProjects: (projects: IListItem[]) => void;
  additionalProjectValues: any;
  // actions
  deleteProject: (projectId: number) => Promise<AxiosResponse>;
  createProject: (data: IProjectPayload) => Promise<IProject>;
  getProject: (projectId: number) => Promise<void>;
  setProject: (project: IProject) => void;
  listProjects: (params: IQueryParams) => Promise<void>;
  listProjectBriefs: (projectId: number, talents: number[]) => Promise<void>;
  setProjectBriefs: (briefs: IBrief[] | []) => void;
  updateProject: (
    data: Partial<IProjectPayload>,
    shouldProjectBePublished?: boolean,
    isProjectDraft?: boolean
  ) => Promise<IProject | void>;
  updateProjectProposal: (data: IProjectProposalFile) => Promise<string>;
  listOrganisationProjects: (organisationId: number) => Promise<void>;
  updateDraftProject: (data: Partial<IProjectPayload>) => Promise<void>;
  getProjectFormValues: (values: any) => Promise<void>;
  saveOrConfirmCallback: (values: any) => Promise<void>;
  redirectAfterProjectCreated: (project: IProject) => void;
  cancelProject: (data: { projectId: number; reason_id: number; description?: string }) => Promise<AxiosResponse>;
  resetProjects: () => void;
}

export enum ProjectStatuses {
  OPEN = "OPEN",
  CANCELLED = "CANCELLED",
  RESOURCED = "RESOURCED",
  DRAFT = "DRAFT",
}

export type IProjectFilterSettings = {
  columns: { field: string; header: string }[];
  columnsOptions: { field: string; header: string }[];
  projectsCategories: { label: string; code: string }[];
  projectsCategory: { label: string; code: string };
  setProjectsCategory: (projectsCategoryIndex: null | { label: string; code: string }) => void;
  resetFilterProjectsSettings: (currentPath?: string) => void;
  selectedColumns: { field: string; header: string }[];
  setSelectedColumns: (columns: { field: string; header: string }[]) => void;
  defaultCategoryIndex: number;
  page: number;
  per_page: number;
  setPage: (page: number) => void;
  setPerPage: (per_page: number) => void;
  onPaginatorChange: (event: { first: number; rows: number }) => void;
  changeProjectsCategory: (event: DropdownChangeEvent) => void;
} & ICommonFilterSettings;
