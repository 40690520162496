@import "../../../mixins.scss";

.target-item {
  display: flex;

  .rank {
    background-color: $brandColor;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    height: 18px;
    min-width: 20px;
    margin-right: 10px;
    border-radius: 3px;
  }
}
