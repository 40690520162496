import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import isEmpty from "lodash/isEmpty";

function useQuery() {
  const { search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);

  const getMany = (keys: string[]) => {
    const result: Record<string, string> = {};

    keys.forEach((key) => {
      const value = query.get(key);
      value && (result[key] = value);
    });

    return isEmpty(result) ? null : result;
  };

  return {
    getMany,
    query,
  };
}

export default useQuery;
