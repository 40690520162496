import React, { createContext, useContext } from "react";
import useProject from "~/hooks/useProject";
import useProjectFilterSettings from "~/hooks/useProjectFilterSettings";
import { IProjectFilterSettings, IProjectState } from "~/interfaces/project";

export const ProjectContext = createContext(null as (IProjectState & IProjectFilterSettings) | null);

export const useProjectContext = () => {
  const context = useContext(ProjectContext);
  if (!context) {
    throw new Error("useProjectContext must be used within a ProjectContextProvider");
  }
  return context;
};

const ProjectContextProvider = ({ children }: any) => {
  return (
    <ProjectContext.Provider
      value={{
        ...useProject(),
        ...useProjectFilterSettings(),
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
};

export default ProjectContextProvider;
