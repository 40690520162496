import { MessagesMessage } from "primereact/messages";
import { ToastMessage } from "primereact/toast";
import { Popup } from "../reducers/toasts";
import { AxiosError } from "axios";

// ------------------------------------
// Constants
// ------------------------------------
export const SET_ALERTS = "SET_ALERTS";
export const SET_TOASTS = "SET_TOASTS";
export const SET_POPUP = "SET_POPUP";

export const TOAST_SUCCESS_MESSAGE = "That’s all updated for you now 👌🏾";
export const TOAST_ERROR_MESSAGE = "It's not you, it's me..... can you try that again pls?";

export const SET_GLOBAL_ERROR = "SET_GLOBAL_ERROR";

export const TOAST_IMAGE_SUCCESS = "Image updated for you now 👌🏾";

// ------------------------------------
// Action Creators
// ------------------------------------
export default {
  setAlerts: (alerts: MessagesMessage | MessagesMessage[]) => ({
    type: SET_ALERTS,
    payload: alerts,
  }),
  setToasts: (toasts: ToastMessage | ToastMessage[]) => ({
    type: SET_TOASTS,
    payload: toasts,
  }),
  setPopup: (popup?: Popup) => ({
    type: SET_POPUP,
    payload: popup,
  }),
  setGlobalError: (error: AxiosError | undefined) => ({
    type: SET_GLOBAL_ERROR,
    payload: error,
  }),
};
