import Axios from "axios";
import { API_URL } from "~/config";
import { ILeadType } from "~/interfaces/entity";

export class EntityService {
  static async getLeads(organisationId: number, type?: ILeadType) {
    return await Axios.get(`${API_URL}/organisation/${organisationId}/leads`, { data: { type } });
  }
  static async getClientPods(organisationId: number) {
    return await Axios.get(`${API_URL}/organisation/${organisationId}/client_pods`);
  }
  static async getPodClients(clientPodId: number) {
    return await Axios.get(`${API_URL}/client_pod/${clientPodId}/clients`);
  }
}
