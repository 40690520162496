import React, { ChangeEvent, useState } from "react";
import { Dialog } from "primereact/dialog";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "primereact/button";
import actions from "~/store/actions/index";
import "./ArchiveTalentModal.scss";
import { InputText } from "primereact/inputtext";
import { useHistory } from "react-router-dom";
import { IState } from "~/store/reducers/index";
import { TalentActivityStatus } from "~/store/constants/talent";

const CHECK_WORD = "ARCHIVE";

export default function ArchiveTalentModal({ talentId }: { talentId: number }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [checkInput, setCheckInput] = useState("");
  const isFetching = useSelector((state: IState) => state.talent.isFetching);

  const onSubmit = async () => {
    const res = await dispatch(actions.talent.updateTalent({ id: talentId, status: TalentActivityStatus.ARCHIVED }));
    if (res?.status === 200) {
      dispatch(actions.modal.closeModal());
      history.push("/talents");
    }
  };

  return (
    <Dialog
      className="ArchiveTalentModal"
      header="Archive talent profile"
      footer={
        <footer>
          <Button
            className="p-button p-button-secondary"
            label="Cancel"
            onClick={() => dispatch(actions.modal.closeModal())}
            type="button"
            disabled={isFetching}
          />
          <Button
            className="p-button"
            label="Confirm"
            type="button"
            onClick={onSubmit}
            disabled={checkInput !== CHECK_WORD || isFetching}
            loading={isFetching}
          />
        </footer>
      }
      visible={true}
      modal={true}
      onHide={() => dispatch(actions.modal.closeModal())}
    >
      <div className="archive-talent-modal">
        <p>
          Archiving this profile will remove them from the system completely, only a Global Admin can reinstate their
          profile.
        </p>
        <p>
          Please type <b>ARCHIVE</b> to confirm.
        </p>
        <div className="archive-input-wrp">
          <InputText
            disabled={isFetching}
            maxLength={15}
            value={checkInput}
            onChange={(e: ChangeEvent) => setCheckInput(e.target.value.toUpperCase())}
          />
        </div>
      </div>
    </Dialog>
  );
}
