@import "../../mixins.scss";
.logo-item {
  text-align: center;
  .img-wrapper {
    position: relative;
    width: 100%;
    height: 200px;
    margin: 15px 0 6px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    + .p-fileupload {
      margin-top: 34px;
    }
  }
  .ratio {
    margin: 0 0 10px;
    color: #a1a1a1;
    font-style: italic;
  }
  .p-fileupload-choose .p-icon {
    display: none;
  }
}
