import React from "react";
import BookingContext from "~/contexts/BookingContext";
import BriefContext from "~/contexts/BriefContext";
import ClearanceContext from "~/contexts/ClearanceContext";
import ConversationContext from "~/contexts/ConversationContext";
import DashboardContext from "~/contexts/DashboardContext";
import FilterContext from "~/contexts/FilterContext";
import GlobalContext from "~/contexts/GlobalContext";
import LoadingAnimationContext from "~/contexts/LoadingAnimationContext";
import OrganisationContext from "~/contexts/OrganisationContext";
import PermissionContext from "~/contexts/PermissionContext";
import TalentContext from "~/contexts/TalentContext";
import ProjectContext from "~/contexts/ProjectContext";
import EntityContext from "~/contexts/EntityContext";
import NotificationContext from "~/contexts/NotificationContext";

const AppContext = ({ children }: any) => {
  return (
    <PermissionContext>
      <GlobalContext>
        <LoadingAnimationContext>
          <NotificationContext>
            <ProjectContext>
              <BriefContext>
                <TalentContext>
                  <FilterContext>
                    <OrganisationContext>
                      <ClearanceContext>
                        <BookingContext>
                          <EntityContext>
                            <DashboardContext>
                              <ConversationContext>{children}</ConversationContext>
                            </DashboardContext>
                          </EntityContext>
                        </BookingContext>
                      </ClearanceContext>
                    </OrganisationContext>
                  </FilterContext>
                </TalentContext>
              </BriefContext>
            </ProjectContext>
          </NotificationContext>
        </LoadingAnimationContext>
      </GlobalContext>
    </PermissionContext>
  );
};

export default AppContext;
