import * as Yup from "yup";
import { IOptionsNormalization } from "~/hooks/useOptions";
import { requiredFieldMessage, yupString } from "~/utils";
import { BriefNewOverview, CommonBriefDetailsSchema } from "./BriefNewSchema";
import { dropdownMessage } from "./helpers";

export const BriefViewOverview = () => {
  const { ...BriefOverview } = BriefNewOverview();
  return Yup.object().shape(BriefOverview);
};

export const BriefViewDetails = (
  isSuperAdmin: boolean,
  isBriefRebook: boolean,
  optionsNormalization: IOptionsNormalization,
  skillsCount: number
) => {
  const DISCIPLINE_ID = optionsNormalization?.DISCIPLINE_ID;
  return Yup.object().shape({
    ...CommonBriefDetailsSchema(),
    include_weekends: Yup.boolean().nullable(),
    team_id: Yup.mixed().nullable(),
    discipline_id: isBriefRebook ? Yup.mixed().nullable() : Yup.mixed().label("discipline").required(dropdownMessage),
    specialism_id: isBriefRebook ? Yup.mixed().nullable() : Yup.mixed().label("specialism").required(dropdownMessage),
    level_id: isBriefRebook ? Yup.mixed().nullable() : Yup.mixed().label("level").required(dropdownMessage),
    production_id: Yup.number().nullable(),
    skills: Yup.array()
      .of(Yup.object())
      .required("Gotta’add those powers please!")
      .min(1)
      .max(skillsCount, `Sorry, only ${skillsCount} items can be selected`)
      .label("Skills"),
    sectors: isSuperAdmin
      ? Yup.array()
          .nullable()
          .when("discipline_id", {
            is: DISCIPLINE_ID?.SOCIAL,
            then: Yup.array().of(Yup.object()).required("Please, choose the sectors").min(1),
          })
      : Yup.array().nullable(),
  });
};

export const MessageTalentSchema = (CUSTOM_MESSAGE_ID: number) =>
  Yup.object().shape({
    message: Yup.string().when("messageType", {
      is: CUSTOM_MESSAGE_ID,
      then: yupString(true, 10, 500),
    }),
  });
