import { Button } from "primereact/button";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import useOrganisationUsers from "~/hooks/useOrganisationUsers";
import actions from "~/store/actions";
import "./UsersForm.scss";
import toasts from "~/store/actions/toasts";
import { useParams } from "react-router-dom";
import { UserPlus } from "phosphor-react";

import { ITalent, TalentActivityStatus } from "~/store/constants/talent";
import { useTalentContext } from "~/contexts/TalentContext";
import { Paginator, PaginatorPageChangeEvent } from "primereact/paginator";
import Loader from "~/components/Loader/Loader";

export default function ArchivedUsersForm() {
  const { isUsersFetching, organisationArchivedTalents, getOrganisationArchivedTalents } = useOrganisationUsers();

  const { editTalent } = useTalentContext();
  const [per_page, setPerPage] = useState(20);
  const [page, setPage] = useState(1);
  const [users, setUsers] = useState<ITalent[]>([]);

  useEffect(() => {
    setUsers(
      !!organisationArchivedTalents?.data.length
        ? [...organisationArchivedTalents?.data].map((item: ITalent) => {
            return { ...item, isFetching: false };
          })
        : []
    );
  }, [organisationArchivedTalents?.data]);

  const dispatch = useDispatch();

  const organisationId = useParams<{ organisationId?: string }>().organisationId;

  useEffect(() => {
    organisationId && getOrganisationArchivedTalents(+organisationId, { page, per_page });
  }, [per_page, page]);

  function onPaginatorChange(e: PaginatorPageChangeEvent) {
    setUsers([]);
    setPerPage(e.rows);
    setPage(e.page + 1);
  }

  const setIsFetching = (currentUser: ITalent) => {
    setUsers((users: ITalent[]) => {
      return users.map((user) => {
        return user.id === currentUser.id
          ? {
              ...user,
              isFetching: !user.isFetching,
            }
          : user;
      });
    });
  };

  const handleUserUnarchive = (e: React.MouseEvent, user: ITalent) => {
    e.stopPropagation();

    dispatch(
      toasts.setPopup({
        content: "Are you sure you want to reinstate this profile?",
        buttons: [
          {
            text: "Go back",
            callback: () => {
              dispatch(actions.modal.closeModal());
            },
          },
          {
            text: "Confirm",
            callback: () => updateUser(user),
          },
        ],
      })
    );
  };

  const updateUser = async (user: ITalent) => {
    setIsFetching(user);
    const status = await editTalent(user?.id, { status: TalentActivityStatus.PUBLISHED });

    if (status === 200) {
      const newUsersList = users.filter((prevUser: any) => prevUser.id !== user.id);
      newUsersList.length === 0 && setPage((prevPage) => prevPage - 1);
      setUsers(newUsersList);
    }

    setIsFetching(user);
  };

  const renderUsers = (user: ITalent, i: any) => {
    return (
      <div className={"UsersListForm__user talent-list"} key={i}>
        <div className="user-info">
          <h3>{user.name?.trim()}</h3>
          <div>
            <i className="pi pi-envelope" />
            {user?.personal_profile?.email ? (
              <a href={`mailto:${user?.personal_profile?.email}`}>{user?.personal_profile?.email}</a>
            ) : (
              "—"
            )}
          </div>
        </div>

        <div className="user-actions text-right">
          <label className="label">{user?.specialism_name || "—"}</label>
          <div className="flex justify-content-end user-actions-buttons">
            <Button
              onClick={(e) => handleUserUnarchive(e, user)}
              type="button"
              className="p-button-text ml-2"
              loading={user.isFetching}
              tooltip={"Unarchive"}
              tooltipOptions={{ position: "top" }}
              icon={<UserPlus size={20} />}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="archived-list">
      {isUsersFetching && (
        <div className="archived-list-loader">
          <Loader size={2} />
        </div>
      )}

      {organisationArchivedTalents?.meta?.total === 0 && <div>No archived profiles</div>}
      {users.map((user, i) => renderUsers(user, i))}

      {organisationArchivedTalents?.meta?.total > organisationArchivedTalents?.meta?.per_page && (
        <Paginator
          rows={per_page}
          totalRecords={organisationArchivedTalents?.meta?.total}
          first={(page - 1) * per_page}
          rowsPerPageOptions={[20, 50, 100, 200]}
          onPageChange={onPaginatorChange}
        />
      )}
    </div>
  );
}
