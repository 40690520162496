import React, { useEffect, useMemo } from "react";
import { FormikProps, useFormikContext } from "formik";
import { ToggleButton } from "primereact/togglebutton";
import ApInputNumber from "~/components/common/ApInputNumber/ApInputNumber";
import "./TimetableForm.scss";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import cn from "classnames";
import { ITimetable, ITimetableType } from "~/store/constants/organisation";
import { sumBy } from "lodash";

interface IProps {
  type?: ITimetableType;
  readOnly?: boolean;
  handlePrompt: (isDirty: boolean) => void;
  loading: boolean;
}

export default function TimetableForm(props: IProps) {
  const formik: FormikProps<any> = useFormikContext();
  const totalHours = useMemo(() => {
    const sum = sumBy(
      formik.values.timetable.filter(({ enabled }: ITimetable) => enabled),
      (obj: any) => Number(obj.hours)
    );
    if (Number.isInteger(sum)) return sum;
    return parseFloat(sum.toFixed(2));
  }, [formik.values.timetable]);

  useEffect(() => {
    props.handlePrompt && props.handlePrompt(formik.dirty);
  }, [formik.dirty]);

  return (
    <div className="TimetableForm">
      <div className="red-bordered lg:col-6 xs:col-12">
        <p className="mt-0">
          {props.type === ITimetableType.ORGANISATION && (
            <>
              Please select all of the days in your organisation’s standard working week, and then add the standard
              number of working hours for each day selected.
              <br />
              E.g. Monday (8), Tuesday (8), Wednesday (8), Thursday (8), Friday(8)
              <br />
              <br />
              Please Note: The working schedule you chose below will be applied to all profiles across your
              organisation, but each individual can configure their own working schedule on their profile
            </>
          )}
          {props.type === ITimetableType.TALENT && (
            <>
              Please select all of the days in your personal standard working week, and then add your standard number of
              working hours for each day selected.
              <br />
              E.g. Monday (8), Tuesday (8), Wednesday (8), Thursday (8), Friday(4)
            </>
          )}
        </p>
      </div>
      <div>
        <div className="mb-4">
          {formik.values.timetable.map((day: ITimetable, index: number) => (
            <ToggleButton
              className="mr-2 circle-toggle-button"
              key={day.id}
              checked={day.enabled}
              onChange={(e) => {
                if (e.value) {
                  formik.setFieldValue(`timetable[${index}]`, { ...day, enabled: true });
                } else {
                  formik.setFieldValue(`timetable[${index}]`, { ...day, enabled: false });
                }
              }}
              onLabel={day.weekday_name.charAt(0)}
              offLabel={day.weekday_name.charAt(0)}
              disabled={props.loading || props.readOnly}
            />
          ))}
        </div>

        {formik.values.timetable.map((day: ITimetable, index: number) => (
          <div
            key={day.id}
            className={cn("p-fluid field day-field", (!day.enabled || props.loading) && "day-field-disabled")}
          >
            <label htmlFor={day.weekday_name}>{day.weekday_name}</label>

            <ApInputNumber
              key={`${day.id}`}
              id={`timetable[${index}].hours`}
              name={`timetable[${index}]`}
              value={Number(day.hours)}
              formik={formik}
              noField
              min={0.01}
              max={24}
              maxFractionDigits={2}
              disabled={!day.enabled || props.loading || props.readOnly}
            />
          </div>
        ))}

        <Divider className="work-divider" />

        <div className="p-fluid field">
          <h4>
            Total weekly working hours: <strong>{totalHours}</strong>
          </h4>
        </div>
        {!props.readOnly && (
          <Button
            label="Save"
            type="button"
            onClick={(e) => {
              e.preventDefault();
              formik.handleSubmit();
            }}
            disabled={props.loading}
            loading={props.loading}
          />
        )}
      </div>
    </div>
  );
}
