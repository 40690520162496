import { MessagesMessage } from "primereact/messages";
import { ToastMessage } from "primereact/toast";
import { Dispatch } from "redux";
import Toasts from "../constants/toasts";
import { Popup } from "../reducers/toasts";
import { AxiosError } from "axios";

// ------------------------------------
// Actions
// ------------------------------------
export default {
  setAlerts: (alerts: MessagesMessage | MessagesMessage[]) => (dispatch: Dispatch) => {
    dispatch(Toasts.setAlerts(alerts));
  },
  setToasts: (toasts: ToastMessage | ToastMessage[]) => (dispatch: Dispatch) => {
    dispatch(Toasts.setToasts(toasts));
  },
  setPopup: (popup?: Popup) => (dispatch: Dispatch) => {
    dispatch(Toasts.setPopup(popup));
  },
  setGlobalError: (error: AxiosError | undefined) => (dispatch: Dispatch) => {
    dispatch(Toasts.setGlobalError(error));
  },
};
