@import "../../mixins.scss";
.UsersListForm {
  &__user {
    background: #fff;
    border: solid 2px transparent;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
    margin-bottom: 1em;
    padding: 1em;
    position: relative;
    &:hover {
      cursor: pointer;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.6), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
    }
    &.active {
      border-color: $brandColor;
    }
    h3 {
      margin: 0 0 1em;
    }
    .send-message {
      float: right;
    }
    .local-admin-switch {
      display: flex;
      float: right;
      align-items: flex-end;
      flex-direction: column;
      > .p-disabled {
        > .p-inputswitch-slider {
          background: $brandColor;
        }
      }
      .p-button {
        position: static;
      }
    }
    .label {
      margin: 0 0 0.5em;
    }
    div > .pi {
      color: #848484;
      font-size: 0.8em;
      margin-right: 1em;
    }
  }
  &__addUser {
    border: dashed 2px var(--grey-accent-color);
    border-radius: 10px;
    height: 100%;
    margin-bottom: 1em;
    min-height: 100px;
    position: relative;
    text-align: center;
    > .p-button {
      height: 50%;
    }
  }
}
