// Global Admin
export const accessAdmin = "accessAdminPanel";
export const getAnyRole = "getAnyRole";
export const postAnyRole = "postAnyRole";
export const editAnyRole = "editAnyRole";
export const getAnyConfig = "getAnyConfig";
export const postAnyConfig = "postAnyConfig";
export const editAnyConfig = "editAnyConfig";
export const postAnySetting = "postAnySetting";
export const editAnySetting = "editAnySetting";
export const getAnyUser = "getAnyUser";
export const postAnyUser = "postAnyUser";
export const editAnyUser = "editAnyUser";
export const getClientAdminList = "getClientAdminList";
export const getClientList = "getClientList";
export const getAnyOrganisation = "getAnyOrganisation";
export const postAnyClient = "postAnyClient";
export const editAnyClient = "editAnyClient";
export const getTalentList = "getTalentList";
export const getGlobalTalentList = "getGlobalTalentList";
export const getAnyTalent = "getAnyTalent";
export const postAnyTalent = "postAnyTalent";
export const editAnyTalent = "editAnyTalent";
export const getBriefList = "getBriefList";
export const getAnyBrief = "getAnyBrief";
export const postAnyBrief = "postAnyBrief";
export const postAnyProject = "postAnyProject";
export const editAnyBrief = "editAnyBrief";
export const getAnyMatch = "getAnyMatch";
export const postAnyMatch = "postAnyMatch";
export const editAnyMatch = "editAnyMatch";
export const getAnyBooking = "getAnyBooking";
export const postAnyBooking = "postAnyBooking";
export const editAnyBooking = "editAnyBooking";
export const getAnyGoto = "getAnyGoto";
export const getAnyDashboard = "getAnyDashboard";
export const postAnyMessage = "postAnyMessage";
export const getAnyNotification = "getAnyNotification";
export const postAnyNotification = "postAnyNotification";
export const editAnyNotification = "editAnyNotification";
export const postAnySkill = "postAnySkill";
export const editAnySkill = "editAnySkill";
export const getAnyReferralCode = "getAnyReferralCode";
export const postAnyReferralCode = "postAnyReferralCode";
export const editAnyReferralCode = "editAnyReferralCode";
export const getAnyClientPaymentProfile = "getAnyClientPaymentProfile";
export const editAnyClientPaymentProfile = "editAnyClientPaymentProfile";
export const editAnyProject = "editAnyProject";

// Organisation Admin - Local Admin, ARU, TRO
export const accessOrganisationAdmin = "accessOrganisationAdminPanel";
export const getCurrentUser = "getCurrentUser";
export const getOwnOrganisationUser = "getOwnOrganisationUser";
export const postOwnOrganisationUser = "postOwnOrganisationUser";
export const postOwnTalentUser = "postOwnTalentUser";
export const postOwnOrganisationTalentUser = "postOwnOrganisationTalentUser";
export const postOwnOrganisationProject = "postOwnOrganisationProject";
export const postOwnOrganisationBrief = "postOwnOrganisationBrief";
export const postOwnClientBooking = "postOwnClientBooking";
export const editCurrentUser = "editCurrentUser";
export const getOwnOrganisation = "getOwnOrganisation";
export const editOwnClient = "editOwnClient";
export const getOwnClientBrief = "getOwnClientBrief";
export const postOwnClientBrief = "postOwnClientBrief";
export const editOwnClientBrief = "editOwnClientBrief";
export const getOwnClientMatch = "getOwnClientMatch";
export const editOwnClientMatch = "editOwnClientMatch";
export const postOwnClientNotification = "postOwnClientNotification";
export const getOwnClientTalents = "getOwnClientTalents";
export const editOwnOrganisationGotoTalents = "editOwnOrganisationGotoTalents";
export const getClientAdminDashboard = "getClientAdminDashboard";
export const getOwnClientBooking = "getOwnClientBooking";
export const postDirectTalentMatch = "postDirectTalentMatch";
export const editOwnClientNotes = "editOwnClientNotes";
export const editOwnOrganisationUser = "editOwnOrganisationUser";
export const getOwnRoles = "getOwnRoles";
export const triggerPortfolioOpenedEvent = "triggerPortfolioOpenedEvent";
export const getOwnClientPaymentProfile = "getOwnClientPaymentProfile";
export const editOwnClientPaymentProfile = "editOwnClientPaymentProfile";
export const editOwnOrganisationTalent = "editOwnOrganisationTalent";
export const editOwnOrganisation = "editOwnOrganisation";

export const getOwnUserBooking = "getOwnUserBooking";
export const editOwnUserMatch = "editOwnUserMatch";
export const getOwnClientPaymentData = "getOwnClientPaymentData";
export const editOwnOrganisationProject = "editOwnOrganisationProject";

// Talent
export const accessTalent = "accessTalentPanel";

// Talent + TRO
export const editOwnTalent = "editOwnTalent";

// Local Admin + TRO
export const approveOwnTalentClearance = "approveOwnTalentClearance";

//Local Admin + Regional Admin
export const getOwnTalentList = "getOwnTalentList";

//TRO
export const postOwnTalent = "postOwnTalent";
export const getResourcedTalentList = "getResourcedTalentList";

//ARU
export const getPublishedTalentList = "getPublishedTalentList";
