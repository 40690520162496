import React, { MutableRefObject, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import { useSelector } from "react-redux";
import { IState } from "../../store/reducers/index";
import "./Toaster.scss";

export default function Toaster() {
  const toastRef = useRef() as MutableRefObject<Toast>;
  const toasts = useSelector((state: IState) => state.toasts.toasts);

  useEffect(() => {
    !!toasts.length && toastRef.current.show(toasts);
    !toasts.length && toastRef.current.clear();
  }, [toasts]);

  return <Toast className="Toaster" ref={toastRef}></Toast>;
}
