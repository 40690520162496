import Axios, { AxiosResponse } from "axios";
import { API_URL } from "~/config";
import { IBrief, IBriefClientAdminData, IBriefValidate, IListAddableTalentsData, IMatching } from "~/interfaces/brief";
import { DataTableSortMeta } from "primereact/datatable";
import { buildPaginatedQuery } from "~/utils";

export class BriefService {
  static async cancelBrief(data: { briefId: number; reason_id: number; description?: string }) {
    return await Axios.delete(`${API_URL}/brief/${data.briefId}/cancel`, { data });
  }
  static async deleteBrief(briefId: number) {
    return await Axios.delete(`${API_URL}/brief/${briefId}/delete`);
  }
  static async createBrief(projectId: number, data: IBrief) {
    return await Axios.post(`${API_URL}/project/${projectId}/brief`, data);
  }
  static async createClientAdminBrief(data: IBrief) {
    return await Axios.post(`${API_URL}/brief`, data);
  }
  static async getBrief(briefId: number) {
    return await Axios.get(`${API_URL}/brief/${briefId}`);
  }
  static async getClientBrief(briefId: number) {
    return await Axios.get(`${API_URL}/client_admin/brief/${briefId}`);
  }
  static async getBriefMatches(briefId: number) {
    return await Axios.get(`${API_URL}/brief/${briefId}/matches`);
  }
  static async getBriefMatch(matchId: number) {
    return await Axios.get(`${API_URL}/match/${matchId}`);
  }
  static async getBriefPreMatchedList(briefId: number) {
    return Axios.get(`${API_URL}/brief/${briefId}/matches/weight`);
  }
  static async sendMessageFromBrief(briefId: number, talents: { id: number }[], message: string) {
    return await Axios.post(`${API_URL}/send_message/brief/${briefId}`, { talents, message });
  }
  // TODO: uncomment once BE part done
  // static async getClientBriefMatches(briefId: number) {
  //   return await Axios.get(`${API_URL}/client_admin/brief/${briefId}/matches`);
  // }
  static async getBriefMatchesByLimit(briefId: number, limit: number): Promise<AxiosResponse> {
    return await Axios.post(`${API_URL}/brief/${briefId}/matches`, {}, { params: { limit } });
  }
  static async listBriefs(
    statuses: string,
    page = 0,
    per_page?: number,
    search?: string,
    sort?: DataTableSortMeta,
    other?: { [key: string]: any }
  ) {
    const query = buildPaginatedQuery(page, sort, {
      search_brief: search,
      per_page,
      ...(statuses !== "ALL" && { statuses: [statuses] }),
      ...other,
    });
    return await Axios.get(`${API_URL}/briefs${query}`);
  }
  static async createMatch(briefId: number, talents: number | number[], matchData?: Partial<IMatching>) {
    return await Axios.post(`${API_URL}/brief/${briefId}/talent/matches`, { talents: [talents].flat(), ...matchData });
  }
  static async createClientAdminMatch(briefId: number, talents: number | number[]) {
    return await Axios.post(`${API_URL}/client_admin/brief/${briefId}/talent/matches`, { talents: [talents].flat() });
  }
  static async updateBrief(data: IBrief) {
    return await Axios.put(`${API_URL}/brief/${data.id}`, data);
  }
  static async updateJobDescriptionForBrief(id: number, JobUrlFormData: IBrief) {
    return await Axios.post(`${API_URL}/brief/${id}`, JobUrlFormData);
  }
  static async updateJobDescriptionClientAdminBrief(id: number, JobUrlFormData: IBrief) {
    return await Axios.post(`${API_URL}/client_admin/brief/${id}`, JobUrlFormData);
  }
  static async updateClientAdminBrief(data: IBriefClientAdminData) {
    return await Axios.put(`${API_URL}/client_admin/brief/${data.id}`, data);
  }
  static async updateMatch(data: IMatching) {
    return await Axios.put(`${API_URL}/match/${data.id}`, data);
  }
  static async validateBrief(type: number, IR35_TYPES: any, data: IBrief) {
    return await Axios.post(`${MSG_URL}/validate_ir35/${type === IR35_TYPES.INSIDE ? "inside" : "outside"}`, data);
  }
  static async validateBriefDates(data: IBriefValidate) {
    return await Axios.post(`${API_URL}/brief/validate`, data);
  }
  static async validateTalentAvailability(briefId: number, data: IBriefValidate) {
    return await Axios.post(`${API_URL}/brief/${briefId}/talent/${data.talent_id}/availability`, data);
  }
  static async regenerateSDS(bookingId: number) {
    return await Axios.post(`${API_URL}/booking/${bookingId}/documents/regenerate`);
  }
  static async getIndicativeSalary(
    isAdmin: boolean,
    {
      discipline_code,
      specialism_code,
      level_code,
    }: {
      discipline_code: string;
      specialism_code: string;
      level_code: string;
    }
  ) {
    return await Axios.post(
      `${API_URL}/${isAdmin ? "" : "client_admin/"}brief/indicative_salary`,
      {},
      {
        params: { discipline_code, specialism_code, level_code },
      }
    );
  }
  static async getAddableTalents({ briefId, page = 0, search, sort }: IListAddableTalentsData) {
    const query = new URLSearchParams({
      ...(page && {
        page: `${page + 1}`,
        per_page: "15",
      }),
      ...(sort?.field && {
        order_by: sort.field,
        direction: sort.order === 1 ? "asc" : "desc",
      }),
      ...(search && {
        name: search,
      }),
    }).toString();

    return Axios.get(`${API_URL}/brief/${briefId}/available_talents?${query}`);
  }
  static trackPortfolioClick(talentId: number, match_id: number) {
    Axios.post(`${API_URL}/client_admin/talent/${talentId}/event/portfolio_opened`, { match_id });
  }
  static trackDownloadCvClick(talentId: number, match_id: number) {
    Axios.post(`${API_URL}/client_admin/talent/${talentId}/event/cv_viewed`, { match_id });
  }
  static async updateBooking(bookingId: number, data: any) {
    return await Axios.put(`${API_URL}/booking/${bookingId}`, data);
  }
  static async updateBriefDescriptionUrl(briefId: number, description_url: File | null) {
    const form = description_url
      ? (() => {
          const form = new FormData();
          form.append("description_url", description_url);
          return form;
        })()
      : { description_url };

    return await Axios.post(`${API_URL}/brief/${briefId}`, form);
  }
}
