import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../store/actions";
import { IState } from "../../store/reducers/index";
import { ITalent } from "~/store/constants/talent";
import "./RemoveTalentModal.scss";
import { IMatching } from "~/interfaces/brief";

interface IProps {
  onSubmit: () => void;
  rowData: IMatching | ITalent;
  talent_name?: string;
  type: "removeMatch" | undefined;
}

export default function RemoveTalentModal() {
  const dispatch = useDispatch();
  const { modalProps } = useSelector((state: IState) => state.modal);
  const { onSubmit, rowData, type }: IProps = modalProps || {};

  const isTypeRemoveMatch = type === "removeMatch" && "talent_name" in rowData;

  const name = isTypeRemoveMatch ? rowData?.talent_name : rowData?.name;

  return (
    <Dialog
      header="Remove talent"
      className="RemoveTalentModal"
      onHide={() => {
        dispatch(actions.modal.closeModal());
      }}
      footer={
        <Button
          label="Confirm"
          onClick={() => {
            isTypeRemoveMatch ? onSubmit() : onSubmit(talent.id);
            dispatch(actions.modal.closeModal());
          }}
        />
      }
      modal
      visible
    >
      <p>
        Are you sure you want to remove <b>{name}</b> talent?
      </p>
      {!isTypeRemoveMatch && (
        <p>
          <strong>Warning! This action can not be reverted</strong>
        </p>
      )}
    </Dialog>
  );
}
