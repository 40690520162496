import { useMemo, useState } from "react";
import { OptionsService } from "~/API/OptionsService";
import { ICountry, IGlobalOptions, ILanguage, ITimeZone } from "~/contexts/GlobalContext";
import { IPaymentCompany, IUmbrella } from "~/interfaces/brief";
import { IClient } from "~/interfaces/common";

export type IConfigType =
  | "application_settings"
  | "disciplines"
  | "duration_types"
  | "brief_freeze_reasons"
  | "match_freeze_reasons"
  | "talent_freeze_reasons";
export interface IOptionsNormalization {
  CONTRACT_TYPES: { [key: string]: number };
  IR35_TYPES: { [key: string]: number };
  DISCIPLINE_ID: { [key: string]: number };
  DURATION_ID: { [key: string]: number };
  LOCATION_ID: { [key: string]: { [key: string]: number } };
  SPECIALISMS: { id: number; label: string }[];
  LEVELS: { id: number; label: string }[];
  SKILLS: { id: number; label: string }[];
  SECTORS: { id: number; label: string }[];
}

const getFormatedOptions = (globalOptions: any) => {
  const formated = (types: any) =>
    types?.reduce((acc: { [key: string]: number }, item: any) => {
      const i = item.code.indexOf("_");
      acc[item.code.substring(i + 1)] = item.id;
      return acc;
    }, {});

  const formatedLocationId = (types: any) =>
    types?.reduce((acc: { [key: string]: { [key: string]: number } }, item: any) => {
      const i = item.code.indexOf("_");
      acc[item.ir35_code] = {
        ...acc[item.ir35_code],
        [item.code.substring(i + 1)]: item.id,
      };
      return acc;
    }, {});

  const SPECIALISMS: { [key: number]: string } = {};
  const LEVELS: { [key: number]: string } = {};
  const SKILLS: { [key: number]: string } = {};
  const SECTORS: { [key: number]: string } = {};

  const collect = (items: any, container: any) =>
    items.forEach(({ id, name }: { id: number; name: string }) => (container[id] = name));
  const formatAsOptions = (items: { [key: string | number]: string }, key: "id" | "code" = "id") =>
    Object.entries(items).map(([prop, label]) => ({ [key]: key === "id" ? +prop : prop, label }));

  globalOptions.disciplines.forEach(
    ({ sectors, skills, specialisms }: { sectors: any; skills: any; specialisms: any }) => {
      collect(sectors, SECTORS);
      collect(skills, SKILLS);

      specialisms.forEach(({ id, name, levels }: { id: number; name: string; levels: any }) => {
        SPECIALISMS[id] = name;
        collect(levels, LEVELS);
        delete LEVELS[1];
      });
    }
  );

  return {
    IR35_TYPES: formated(globalOptions?.ir35_types),
    CONTRACT_TYPES: formated(globalOptions?.contract_types),
    DISCIPLINE_ID: formated(globalOptions?.disciplines),
    DURATION_ID: formated(globalOptions?.duration_types),
    LOCATION_ID: formatedLocationId(globalOptions?.locations),

    SPECIALISMS: formatAsOptions(SPECIALISMS),
    LEVELS: formatAsOptions(LEVELS),
    SKILLS: formatAsOptions(SKILLS),
    SECTORS: formatAsOptions(SECTORS),
  };
};

const useOptions = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [globalOptions, setGlobalOptions] = useState(undefined as any);
  const [publicOptions, setPublicOptions] = useState(undefined as any);
  const [countries, setCountries] = useState([] as ICountry[]);
  const [timezones, setTimezones] = useState([] as ITimeZone[]);
  const [languages, setLanguages] = useState([] as ILanguage[]);
  const [countryResidence, setCountryResidence] = useState("" as string);
  const [umbrellaCompanies, setUmbrellaCompanies] = useState([] as IUmbrella[]);
  const [paymentCompanies, setPaymentCompanies] = useState([] as IPaymentCompany[]);
  const [clients, setClients] = useState([] as IClient[]);

  const setCountry = (value: string) => setCountryResidence(value);

  const optionsNormalization: IOptionsNormalization = useMemo(
    () => (globalOptions && getFormatedOptions(globalOptions)) || {},
    [globalOptions]
  );

  const TALENT_GOTO_TYPES = useMemo(() => {
    return globalOptions?.goto_types ?? [];
  }, [globalOptions]);

  const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const getTimezonesOptions = (data: { [key: string]: string }) => {
    const defaultOptions = Object.keys(data).map((key) => ({
      name: data[key],
      code: key,
    }));
    const localTimezoneOption = defaultOptions.find((item) => item.code === localTimezone);
    return localTimezoneOption
      ? [localTimezoneOption, ...defaultOptions.filter((option) => option.code !== localTimezoneOption.code)]
      : defaultOptions;
  };

  const maxSkillsForDiscipline: { [key: string | number]: number } = useMemo(
    () =>
      globalOptions &&
      globalOptions.disciplines.reduce(
        (acc: { [key: string]: string }, item: { id: number; code: string; skills: any[] }) => {
          const maxSkill = Math.round(item.skills.length / 5);
          return {
            ...acc,
            [item.id]: maxSkill > 3 ? maxSkill : 4,
            [item.code]: maxSkill > 3 ? maxSkill : 4,
          };
        },
        {}
      ),
    [globalOptions]
  );

  const token = localStorage.getItem("user");

  const getGlobalOptions = async () => {
    if (token) {
      try {
        setIsFetching(true);
        const res = await OptionsService.getGlobalOptions();
        res.status === 200 && setGlobalOptions(res.data);
      } catch (err) {
        console.error(err);
      } finally {
        setIsFetching(false);
      }
    }
  };

  const getGlobalOption = (configID: IConfigType, code: string) =>
    globalOptions?.[configID].find((item: any) => item.code === code);

  const getGlobalOptionValue = (configID: IConfigType, code: string, defaultValue: any) =>
    getGlobalOption(configID, code)?.value || defaultValue;

  const getPublicOptions = async () => {
    try {
      setIsFetching(true);
      const res = await OptionsService.getPublicOptions();
      res.status === 200 && setPublicOptions(res.data);
    } catch (err) {
      console.error(err);
    } finally {
      setIsFetching(false);
    }
  };

  const getCountries = async () => {
    if (token) {
      try {
        setIsFetching(true);
        const res = await OptionsService.getCountries();
        res.status === 200 && setCountries(res.data);
      } catch (err) {
        console.error(err);
      } finally {
        setIsFetching(false);
      }
    }
  };

  const getTimeZones = async () => {
    if (token) {
      try {
        setIsFetching(true);
        const res = await OptionsService.getTimeZones();
        if (res.status === 200) {
          const timezonesOptions = getTimezonesOptions(res.data);
          setTimezones(timezonesOptions);
        }
      } catch (err) {
        console.error(err);
      } finally {
        setIsFetching(false);
      }
    }
  };

  const getCities = (query: string) => OptionsService.getCities({ country: countryResidence, search: query });

  const getLanguages = async () => {
    if (token) {
      try {
        setIsFetching(true);
        const res = await OptionsService.getLanguages();
        res.status === 200 && setLanguages(res.data);
      } catch (err) {
        console.error(err);
      } finally {
        setIsFetching(false);
      }
    }
  };

  const getUmbrellaCompanies = async () => {
    if (token) {
      try {
        setIsFetching(true);
        const res = await OptionsService.getUmbrellaCompanies();
        const companies = res.data.sort((a: IUmbrella, b: IUmbrella) => a.company_name.localeCompare(b.company_name));

        res.status === 200 && setUmbrellaCompanies(companies);
      } catch (err) {
        console.error(err);
      } finally {
        setIsFetching(false);
      }
    }
  };
  const getPaymentCompanies = async () => {
    if (token) {
      try {
        setIsFetching(true);
        const res = await OptionsService.getPaymentCompanies();
        const companies = res.data.sort((a: IPaymentCompany, b: IPaymentCompany) => a.name.localeCompare(b.name));

        res.status === 200 && setPaymentCompanies(companies);
      } catch (err) {
        console.error(err);
      } finally {
        setIsFetching(false);
      }
    }
  };
  const getClients = async () => {
    if (token) {
      try {
        setIsFetching(true);
        const res = await OptionsService.getClients();

        res.status === 200 && setClients(res.data.sort((a: IClient, b: IClient) => a.name.localeCompare(b.name)));
      } catch (err) {
        console.error(err);
      } finally {
        setIsFetching(false);
      }
    }
  };

  return {
    isFetching,
    optionsNormalization,
    maxSkillsForDiscipline,
    globalOptions,
    publicOptions,
    countries,
    languages,
    umbrellaCompanies,
    paymentCompanies,
    clients,
    getGlobalOptions,
    getPublicOptions,
    getGlobalOption,
    getGlobalOptionValue,
    getCountries,
    getCities,
    getLanguages,
    getUmbrellaCompanies,
    getPaymentCompanies,
    getClients,
    setCountry,
    timezones,
    getTimeZones,
    TALENT_GOTO_TYPES,
  } as IGlobalOptions;
};
export default useOptions;
