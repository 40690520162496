import moment from "moment";
import { Badge } from "primereact/badge";
import classNames from "classnames";
import React, { useEffect } from "react";
import { useGlobalContext } from "~/contexts/GlobalContext";
import Loader from "../Loader/Loader";
import "./BriefDatesDaysCount.scss";
import { useBriefContext } from "~/contexts/BriefContext";
import { IBriefValidate } from "~/interfaces/brief";
import BriefDays from "~/forms/BriefViewDetailsForm/BriefDays";

interface Props {
  data: IBriefValidate;
  shouldValidateAvailability?: boolean;
  callback?: (dates: { duration_days: number; duration_weekends: number }) => void;
}

export default function BriefDatesDaysCount(props: Props) {
  const { validateBriefDates, validatedDates, isValidatingDates, validateTalentAvailability } = useBriefContext();
  const {
    global: { optionsNormalization },
  } = useGlobalContext();

  const {
    talent_id,
    brief_id,
    start_date,
    end_date,
    include_weekends,
    duration_id,
    duration_days,
    dates,
    organisation_id,
    status,
    capacity,
  } = props.data || {};
  const { shouldValidateAvailability } = props || {};

  const DURATION_ID = optionsNormalization?.DURATION_ID;
  const isTypeNumberDuration = duration_id === DURATION_ID?.NUMBER;
  const isExactWorkingDates = duration_id === DURATION_ID?.EXACT;
  const showTalentFullAvailabilityWarning =
    shouldValidateAvailability && !isValidatingDates && validatedDates?.talent_full_availability === false;

  let payload: IBriefValidate = {
    ...(shouldValidateAvailability && { talent_id }),
    capacity,
    organisation_id,
    start_date: moment(start_date).format("YYYY-MM-DD"),
    end_date: moment(end_date ?? start_date).format("YYYY-MM-DD"),
    include_weekends,
    duration_id,
  };
  if (isTypeNumberDuration) {
    payload.duration_days = duration_days;
  }
  if (isExactWorkingDates) {
    payload.dates = dates;
  } else {
    payload.dates = [];
  }

  useEffect(() => {
    shouldValidateAvailability && brief_id
      ? validateTalentAvailability(brief_id, payload)
      : validateBriefDates(payload);
  }, []);

  const renderTitle = (status?: string) => {
    switch (status) {
      case "CLEARANCE_REQUESTED":
        return <p>Please review the dates and capacity below and click confirm to send the request</p>;
      case "BOOKED":
        return <p>Please review the dates and capacity below and click confirm to allocate the talent</p>;
      case "CLEARANCE_AMENDED":
      case "AMEND_REQUESTED":
        return <p>Please review the dates and capacity below and click confirm when ready to submit your amendment</p>;
      default:
        return <p>Please review the dates and capacity below and click confirm when ready to create your brief</p>;
    }
  };

  return (
    <>
      {showTalentFullAvailabilityWarning && (
        <div className="warn-msg-wrp">
          <div className="warn-msg-icon">
            <i className="pi pi-exclamation-triangle" />
          </div>
          <div className="warn-msg-text">
            <span>
              Warning! The chosen profile does not have availability for some or all of the selected dates, continue?
            </span>
          </div>
        </div>
      )}
      {renderTitle(status)}
      <h2>
        <small>
          {moment(start_date).format("DD/MM/YYYY")} — {moment(end_date ?? start_date).format("DD/MM/YYYY")}
        </small>
      </h2>
      {!validatedDates || isValidatingDates ? (
        <Loader />
      ) : (
        <>
          {isExactWorkingDates && <BriefDays dates={dates} />}
          <div className="BriefDatesDaysCount__stats">
            <div
              className={classNames({
                ["calendar-block"]: include_weekends || isTypeNumberDuration || isExactWorkingDates,
              })}
            >
              <i className="pi pi-calendar p-mr-4 p-text-secondary p-overlay-badge">
                <Badge value={validatedDates?.duration_days} severity="danger" />
              </i>
              <span>
                Weekdays
                {!include_weekends && !isTypeNumberDuration && !isExactWorkingDates && (
                  <>
                    <br />
                    (weekends excluded)
                  </>
                )}
              </span>
            </div>
            {(include_weekends || isTypeNumberDuration || isExactWorkingDates) && (
              <>
                <div>
                  <i className="pi pi-plus" />
                </div>
                <div className="calendar-block">
                  <i className="pi pi-calendar p-mr-4 p-text-secondary p-overlay-badge">
                    <Badge value={validatedDates?.duration_weekends} severity="danger" />
                  </i>
                  <span>Weekend days</span>
                </div>
              </>
            )}
          </div>
          {!isExactWorkingDates && (
            <div>
              <h3>Capacity: {capacity}%</h3>
            </div>
          )}
        </>
      )}
    </>
  );
}
