import cn from "classnames";
import { Button } from "primereact/button";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as permissionType from "~/constants/permissions";
import { ROLES, usePermissionContext } from "~/contexts/PermissionContext";
import useOrganisationUsers from "~/hooks/useOrganisationUsers";
import { IState } from "~/store/reducers/index";
import actions from "~/store/actions";
import { IOrganisationUser } from "~/store/constants/organisation";
import { IUserForm } from "~/store/constants/user";
import "./UsersForm.scss";
import toasts from "~/store/actions/toasts";
import { Link, useParams } from "react-router-dom";
import { PencilSimpleLine, UserMinus, UserPlus } from "phosphor-react";
import { LOCAL_ADMIN_ROLE_ID, REGIONAL_ADMIN_ROLE_ID } from "~/constants";
import some from "lodash/some";

interface Props {
  users: IOrganisationUser[] | IUserForm[];
  onRemove?: (index: number) => void;
  type?: "organisation" | "talent";
  isPublished: boolean;
  updateUserList?: () => void;
  changeTab?: (activeTab: number) => void;
  isNewUser?: boolean;
}

interface IUser extends IOrganisationUser {
  isFetching: boolean;
}

export default function UsersForm(props: Props) {
  const { isPublished, updateUserList, isNewUser, changeTab } = props;
  const { updateUserById } = useOrganisationUsers();

  const [users, setUsers] = useState<IUser[]>([]);
  useEffect(() => {
    setUsers(
      !!props.users?.length
        ? [...props?.users].map((item: IOrganisationUser) => {
            return { ...item, isFetching: false };
          })
        : []
    );
  }, [props.users]);
  const dispatch = useDispatch();
  const { userAccess, roles: allRoles = [], isTalent, isLocalAdmin, isRegionalAdmin, isAdmin } = usePermissionContext();
  const { authenticatedUser } = useSelector((state: IState) => state.user);
  const isOrganisation = props.type === "organisation";
  const organisationId = useParams<{ organisationId?: string }>().organisationId;

  const openRoleDialog = async (user: IOrganisationUser | IUserForm) => {
    dispatch(
      actions.modal.openModal("ADD_USER_MODAL", {
        onClose: () => dispatch(actions.modal.closeModal()),
        onSubmit: async (data: any) => {
          const formData = { ...data };
          if (user?.email === data.email) {
            delete formData.email;
          }

          if (![ROLES.REGIONAL_ADMIN, ROLES.LOCAL_ADMIN].includes(data.role as ROLES)) {
            if (isAdmin) {
              delete formData.organisation;
              !!organisationId && (formData.organisations = [+organisationId]);
            } else {
              delete formData.organisations;
              !!organisationId && (formData.organisation = +organisationId);
            }
          } else if ([ROLES.REGIONAL_ADMIN, ROLES.LOCAL_ADMIN].includes(data.role as ROLES)) {
            if (isAdmin) {
              delete formData.organisation;
              if (data.role === ROLES.LOCAL_ADMIN) {
                formData.organisations = [data.organisation];
              }
            } else {
              delete formData.organisations;
            }
          }

          if (authenticatedUser?.id === user.id) {
            delete formData.role;
            delete formData.additional_roles;
            delete formData.organisations;
          }

          await updateUserById(user?.id, formData);
          updateUserList && (await updateUserList());
          if (user.id === authenticatedUser?.id) {
            await dispatch(actions.user.getCurrentUser());
          }

          dispatch(actions.modal.closeModal());
        },
        user,
        type: props.type,
        isOwner: authenticatedUser?.id === user.id,
      })
    );
  };
  const setIsFetching = (curentUser: IUser) => {
    setUsers((users: any) => {
      return users.map((user) => {
        return user.id === curentUser.id
          ? {
              ...user,
              isFetching: !user.isFetching,
            }
          : user;
      });
    });
  };
  const canChangeTab = (user: IUser) => {
    if (user.published) {
      const isLastActiveUser = users.filter((user) => user.published).length === 1;
      isLastActiveUser && changeTab && changeTab(1);
    } else {
      const isLastInActiveUser = users.filter((user) => !user.published).length === 1;
      isLastInActiveUser && changeTab && changeTab(0);
    }
  };

  const togglePublish = (e: React.MouseEvent, user: IUser) => {
    e.stopPropagation();

    if (!user.published) {
      setIsFetching(user);
      updateUser(user, false);
    } else {
      dispatch(
        toasts.setPopup({
          content: "Are you sure you want to deactivate this user?",
          buttons: [
            {
              text: "Go back",
              callback: () => {
                setIsFetching(user);
                dispatch(actions.modal.closeModal());
              },
            },
            {
              text: "Confirm",
              callback: () => updateUser(user, true),
            },
          ],
        })
      );
    }
  };

  const updateUser = async (user: IUser, showErrorPopup: boolean) => {
    const status = await updateUserById(
      user.id,
      { published: !user.published },
      showErrorPopup ? (error) => dispatch(toasts.setPopup({ content: getPopupContent(error) })) : undefined
    );

    !isNewUser && canChangeTab(user);
    if (status === 200) {
      updateUserList && (await updateUserList());
      return;
    } else {
      setIsFetching(user);
    }
  };

  const getPopupContent = ({ message, data }: { message: string; data: { [key: string]: string } }) => (
    <>
      <p>{message}</p>
      {Object.keys(data)?.map((key, index) => (
        <span key={index}>
          <Link to={`/talents/${key}`} target="_blank">
            {data[key]}
          </Link>
          {index < Object.keys(data)?.length - 1 && ", "}
        </span>
      ))}
    </>
  );

  const renderUsers = (user: IOrganisationUser | IUserForm, i: any) => {
    const userClassName = cn("UsersListForm__user", {
      "talent-list": props.type === "talent",
      "organisation-list": isOrganisation,
      "related-user": isNewUser && !!user.id,
    });

    const userRole =
      user?.roles?.[0]?.display_name || allRoles.find(({ name }) => name === user.role)?.display_name || "No role";

    const isOwner = authenticatedUser?.id === user.id;
    const isDisabledToEdit =
      !isOwner &&
      ((isLocalAdmin &&
        some(user.roles, ({ id }: { id: number }) => [LOCAL_ADMIN_ROLE_ID, REGIONAL_ADMIN_ROLE_ID].includes(id))) ||
        (isRegionalAdmin && some(user.roles, ({ id }: { id: number }) => [REGIONAL_ADMIN_ROLE_ID].includes(id))));

    return (
      <div className={userClassName} key={i}>
        {!!props.onRemove && (
          <Button
            type="button"
            icon="pi pi-times"
            className="p-button-rounded p-button-danger p-button-text remove-button"
            onClick={(e) => {
              e.stopPropagation();
              props.onRemove && props.onRemove(i);
            }}
          />
        )}

        <div className="user-info">
          <h3>{user.name?.trim() || `${user.first_name?.trim()} ${user.last_name?.trim()}`}</h3>
          <div>
            <i className="pi pi-envelope" />
            <a href={`mailto:${user.email}`}>{user.email}</a>
          </div>
        </div>

        <div className="user-actions">
          {isOrganisation && <label className={cn("label", !!props.onRemove && "mr-5")}>{userRole}</label>}
          <div className="flex justify-content-end user-actions-buttons">
            {!props?.isNewUser && userAccess(permissionType.editOwnOrganisationUser, permissionType.editAnyUser) && (
              <Button
                disabled={isDisabledToEdit}
                className="p-button-text"
                onClick={() => openRoleDialog(user)}
                tooltip="Update user"
                tooltipOptions={{ position: "top" }}
                icon={<PencilSimpleLine size={20} />}
                type="button"
              />
            )}
            {userAccess(
              permissionType.editAnyUser,
              permissionType.editOwnOrganisationUser,
              permissionType.editOwnTalent
            ) &&
              !isNewUser &&
              !isTalent &&
              !isOwner && (
                <>
                  {(() => {
                    const isUserUnpublished = !user.published;

                    const tooltip = isUserUnpublished ? "Reactivate user" : "Deactivate user";
                    const icon = isUserUnpublished ? <UserPlus size={20} /> : <UserMinus size={20} />;
                    const shouldRenderButton = isUserUnpublished || isOrganisation;

                    return (
                      shouldRenderButton && (
                        <Button
                          onClick={(e) => togglePublish(e, user)}
                          type="button"
                          className="p-button-text ml-2"
                          loading={user.isFetching}
                          tooltip={tooltip}
                          tooltipOptions={{ position: "top" }}
                          icon={icon}
                          disabled={isDisabledToEdit}
                        />
                      )
                    );
                  })()}
                </>
              )}
          </div>
        </div>
      </div>
    );
  };

  return !!users.length && isNewUser
    ? users.map(renderUsers)
    : users.filter((user: IOrganisationUser | IUserForm) => user.published === isPublished).map(renderUsers);
}
