import React, { memo, MutableRefObject } from "react";
import { Button } from "primereact/button";
import Loader from "~/components/Loader/Loader";

export const getSlides = ({
  activeStep,
  completedTime,
  callbackFunc,
  isErrorBriefCreation: isError,
}: {
  activeStep: number;
  completedTime: MutableRefObject<any>;
  callbackFunc: any;
  isErrorBriefCreation: boolean;
}) => {
  const time = activeStep === 3 && completedTime.current ? `${completedTime.current}` : "...";

  const EllipseCheckElement = memo(() => (
    <span className="ellipse-check">
      <span className="pi pi-fw pi-check"></span>
    </span>
  ));

  const StatusLoadingElement = memo(() => (
    <p className="loading-status">
      <EllipseCheckElement />
      <Loader className="carusel-loader" />
    </p>
  ));

  return [
    {
      key: 1,
      content: (
        <>
          <StatusLoadingElement />
          <p className="step">
            <span className="current"> Analysing </span>
          </p>
        </>
      ),
    },
    {
      key: 2,
      content: (
        <>
          <StatusLoadingElement />
          <p className="step">
            <span className="current"> Matching </span>
          </p>
        </>
      ),
    },
    {
      key: 3,
      content: (
        <>
          <StatusLoadingElement />
          <p className="step">
            <span className="current"> Preparing </span>
          </p>
        </>
      ),
    },
    // empty element necessary for the correct operation of the slider
    {
      key: 4,
      content: "",
    },
  ];
};
