import { useState } from "react";
import { EntityService } from "~/API/EntityService";
import { ILeadType, IEntity } from "~/interfaces/entity";

const useEntity = () => {
  const [isFetching, setIsFetching] = useState({
    leads: false,
    clientPods: false,
    podClients: false,
  });
  const [programLeads, setProgramLeads] = useState([]);
  const [clientLeads, setClientLeads] = useState([]);
  const [clientPods, setClientPods] = useState<IEntity[]>([]);
  const [podClients, setPodClients] = useState<IEntity[]>([]);

  let controller: any;
  const createController = () => {
    controller = new AbortController();
  };

  const getLeads = async (organisationId: number) => {
    try {
      setIsFetching((prev) => ({ ...prev, leads: true }));
      const res = await EntityService.getLeads(organisationId);
      setProgramLeads(res.data.data.filter(({ type }: { type: ILeadType }) => type === "PROGRAM"));
      setClientLeads(res.data.data.filter(({ type }: { type: ILeadType }) => type === "CLIENT"));
    } finally {
      setIsFetching((prev) => ({ ...prev, leads: false }));
    }
  };

  const getClientPods = async (organisationId: number) => {
    try {
      setIsFetching((prev) => ({ ...prev, clientPods: true }));
      const res = await EntityService.getClientPods(organisationId);
      setClientPods(res.data.data);
    } finally {
      setIsFetching((prev) => ({ ...prev, clientPods: false }));
    }
  };

  const getPodClients = async (clientPodId: number) => {
    try {
      setIsFetching((prev) => ({ ...prev, podClients: true }));
      const res = await EntityService.getPodClients(clientPodId);
      setPodClients(res.data);
    } finally {
      setIsFetching((prev) => ({ ...prev, podClients: false }));
    }
  };

  return {
    // project state
    isFetching,
    programLeads,
    clientLeads,
    clientPods,
    setClientPods,
    podClients,
    setPodClients,
    getLeads,
    getClientPods,
    getPodClients,
  };
};
export default useEntity;
