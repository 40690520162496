@import "../../mixins.scss";

.UsersListForm {
  &__user {
    background: #fff;
    border: solid 2px transparent;
    border-radius: 5px;
    box-shadow: var(--box-shadow);
    margin-bottom: 1em;
    padding: 1em;
    position: relative;
    transition: box-shadow 0.3s ease;
    display: flex;
    justify-content: space-between;
    &:hover {
      cursor: pointer;
      box-shadow: 0 1px 10px -3px rgba(0, 0, 0, 0.4);
    }

    &.active {
      border-color: $brandColor;
    }

    h3 {
      margin: 0 0 1em;
    }

    .p-button-icon-only {
      border: 1px solid var(--grey-accent-color);
      &:hover {
        border: 1px solid var(--brand-color) !important;
      }
    }
    .user-actions {
      .p-button-icon-only {
        padding: 0.429rem;
      }
      &-buttons {
        margin-top: 7px;
        &:only-child {
          margin-top: 1em;
        }
      }
    }

    .remove-button {
      position: absolute;
      top: 0px;
      right: 0px;
    }

    &.talent-list,
    &.organisation-list {
      .toggle-publish-button.p-button {
        top: auto;
        bottom: 7px;
        right: 20px;
        min-width: 162px;

        .centered {
          margin: auto;
        }
      }
    }

    &.related-user {
      background-color: $lightGray;
      &:hover {
        cursor: initial;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
      }
    }

    .send-message {
      float: right;
    }

    .label {
      margin: 0 0 0.5em;
    }

    div > .pi {
      color: #848484;
      font-size: 0.8em;
      margin-right: 1em;
    }
  }

  &__addUser {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border: dashed 2px var(--grey-accent-color);
    border-radius: 10px;
    height: 100%;
    margin-bottom: 1em;
    min-height: 100px;
    position: relative;
    text-align: center;
    margin-top: 10px;

    > .p-button {
      height: 50%;
    }
  }

  .archived-list {
    min-height: 80px;
    &-loader {
      padding-top: 20px;
      text-align: center;
    }
    .p-paginator {
      margin-top: 50px;
      margin-bottom: 20px;
    }
  }
}
