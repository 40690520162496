import React, { createContext, useContext } from "react";
import useTalentFilterSettings from "~/hooks/useTalentFilterSettings";
import { ITalentFilterSettings } from "~/store/reducers/talent";
import useTalent from "~/hooks/useTalent";

export const TalentContext = createContext(null as ITalentFilterSettings | null);

export const useTalentContext = () => {
  const context = useContext(TalentContext);
  if (!context) {
    throw new Error("useTalentContext must be used within a TalentContextProvider");
  }
  return context;
};

const TalentContextProvider = ({ children }: any) => {
  return (
    <TalentContext.Provider
      value={{
        ...useTalent(),
        ...useTalentFilterSettings(),
      }}
    >
      {children}
    </TalentContext.Provider>
  );
};

export default TalentContextProvider;
