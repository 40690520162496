import isString from "lodash/isString";
import "primeflex/primeflex.min.css";
import "primeicons/primeicons.css";
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/nova/theme.css";
import "./theme-override.scss";
import "./App.scss";
import allSettled from "promise.allsettled";
import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { PrimeReactProvider } from "primereact/api";
import { DndProvider } from "react-dnd";
import { TouchBackend } from "react-dnd-touch-backend";
import { connect, useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router";
import { Route, Switch } from "react-router-dom";
import * as permissionType from "~/constants/permissions";
import { useBriefContext } from "~/contexts/BriefContext";
import { useGlobalContext } from "~/contexts/GlobalContext";
import { useLoadingAnimationContext } from "~/contexts/LoadingAnimationContext";
import { useOrganisationContext } from "~/contexts/OrganisationContext";
import { usePermissionContext } from "~/contexts/PermissionContext";
import { useTalentContext } from "~/contexts/TalentContext";
import { IRoute } from "~/interfaces/routes";
import TalentOnboarding from "~/modals/TalentOnboarding/TalentOnboarding";
import AppContext from "~/routes/AppContext";
import { IState } from "~/store/reducers/index";
import { TalentOnboardingFlow } from "~/store/reducers/talent";
import { isLocalStorageAvailable, setAuthToken } from "~/utils";
import ModalRoot from "../../modals";
import routes from "../../routes";
import actions from "../../store/actions";
import Alerter from "../Alerter/Alerter";
import LayoutHeader from "../LayoutHeader/LayoutHeader";
import Popup from "../Toaster/Popup";
import Toaster from "../Toaster/Toaster";
import LoadingAnimation from "../common/LoadingAnimation";
import PageNotFound from "../common/PageNotFound/PageNotFound";
import PrivateRoute from "../common/PrivateRoute";
import { Props, StateProps } from "./App.d";
import { useClearanceContext } from "~/contexts/ClearanceContext";
import { IClearanceStatus } from "~/interfaces/clearance";
import Hotjar from "@hotjar/browser";
import { HOTJAR_ID, HOTJAR_VERSION } from "~/config";
import ErrorBoundaryWrapper from "../ErrorBoundaryWrapper/ErrorBoundaryWrapper";
import { useNotificationContext } from "~/contexts/NotificationContext";

const mapDispatchToProps = {
  setNotAuthenticated: actions.user.setNotAuthenticated,
  verifyToken: actions.user.verifyToken,
};

const mapStateToProps = (state: IState): StateProps => ({
  location: state.location,
});

const PrimeReactValues = {
  zIndex: {
    modal: 1100, // dialog, sidebar
    overlay: 1000, // dropdown, overlaypanel
    menu: 1000, // overlay menus
    tooltip: 1100, // tooltip
    toast: 1200, // toast
  },
  autoZIndex: true,
};

const App = (props: Props) => {
  const {
    global: { getGlobalOptions, getPublicOptions },
  } = useGlobalContext();
  const { resetFilterBriefsSettings } = useBriefContext();
  const { isShowStepperAnimation } = useLoadingAnimationContext();
  const { resetFilterTalentSettings } = useTalentContext();
  const { resetFilterOrganisationSettings } = useOrganisationContext();
  const { introFlow, getIntroFlow } = useTalentContext();
  const { getClearances } = useClearanceContext();
  const { getNotifications } = useNotificationContext();
  const dispatch = useDispatch();

  const [currentPath, setCurrentPath] = useState(null as null | string);
  const [previousPath, setPreviousPath] = useState(null as null | string);

  useEffect(() => {
    if (location.pathname !== currentPath) {
      setPreviousPath(currentPath);
      setCurrentPath(location.pathname);
      dispatch(actions.toasts.setGlobalError(undefined));
    }
  }, [location.pathname]);

  useEffect(() => {
    resetFilterBriefsSettings(currentPath);
    resetFilterTalentSettings(currentPath);
    resetFilterOrganisationSettings(currentPath);
  }, [currentPath, previousPath]);

  const queryParam = useMemo(() => Object.fromEntries(new URLSearchParams(props.location.search)), [location.search]);

  const {
    userAccess,
    permissions: statePermissions,
    savePermissions,
    isTalent,
    isRegionalAdmin,
  } = usePermissionContext();
  const { authenticatedUser: user, isAuthenticating, isAuthenticated } = useSelector((state: IState) => state.user);
  const globalError = useSelector((state: IState) => state.toasts.globalError);

  const isUserReady = user && isAuthenticated && !isAuthenticating;
  const isAppReady = (!user && !localStorage.getItem("user")) || isUserReady;

  const { pathname } = window.location;
  const isAuthRoute =
    pathname.includes("/login") ||
    pathname.includes("/reset_password") ||
    pathname.includes("/change-password") ||
    pathname.includes("/sso");

  useEffect(() => {
    const permissions = localStorage.getItem("permissions");
    permissions && !statePermissions && savePermissions(permissions.split(","));
  }, [isUserReady]);

  useLayoutEffect(() => {
    if (pathname !== "/" || !isAppReady) return;

    if (isTalent && user?.talent_id) {
      props.history.push(`/talents/${user?.talent_id}`);
    } else if (!isTalent) {
      props.history.push("/talents");
    }
  }, [isAppReady, pathname]);

  useEffect(() => {
    // Turn off any logging when not in debug mode
    if (queryParam.debug !== "true") {
      window.console.log = () => {};
      window.console.group = () => {};
      window.console.groupEnd = () => {};
      window.console.groupCollapsed = () => {};
      window.console.warn = () => {};
      window.console.error = () => {};
    }

    if (!!HOTJAR_ID) {
      Hotjar.init(HOTJAR_ID, HOTJAR_VERSION, {
        debug: queryParam?.debug === "true",
      });
    }

    // Take token from storage if exist
    if (isLocalStorageAvailable()) {
      const token = localStorage.getItem("user");
      if (token && localStorage.getItem("permissions")) {
        setAuthToken(token);
        // Verify existing token to retrieve the session
        props.verifyToken(token).catch((err) => {
          (err.response?.status === 401 || err.response.status === 403) && logoutUser();
        });
      } else {
        token && logoutUser();
      }
    }

    if (!Promise.allSettled) {
      Promise.allSettled = allSettled;
    }
  }, []);

  useEffect(() => {
    if (isUserReady) {
      getGlobalOptions();
      if (isTalent) getIntroFlow();

      if (userAccess(permissionType.approveOwnTalentClearance)) {
        getClearances({ count: 1, status: IClearanceStatus.REQUESTED });
      }
      !isTalent && getNotifications();
      isRegionalAdmin && dispatch(actions.organisation.listOrganisationsRelated());
    }
  }, [user?.id, isRegionalAdmin]);

  useEffect(() => {
    getPublicOptions();
    if (!localStorage.getItem("user") && pathname === "/") {
      props.history.push("/login");
    }
  }, []);

  function logoutUser() {
    localStorage.removeItem("user");
    localStorage.removeItem("permissions");
    setAuthToken("");
    props.setNotAuthenticated();
    props.history.push("/login");
  }

  const canAccess = (accessPermissions: IRoute["accessPermissions"]): boolean =>
    accessPermissions ? accessPermissions.some((perm) => userAccess(perm)) : true;

  return (
    <DndProvider
      backend={TouchBackend}
      options={{
        enableTouchEvents: true,
        enableMouseEvents: true,
      }}
    >
      <main className="App">
        {!isAuthRoute && <LayoutHeader />}
        {isAppReady && (
          <Switch location={props.location}>
            {routes().map((route, i: number) => {
              if (!canAccess(route.accessPermissions)) {
                return null;
              }
              if (!!route.homePageFor && canAccess(route.homePageFor)) {
                route.path = isString(route.path) ? ["/", route.path] : route.path.push("/");
              }
              if (!!globalError) {
                return <Route component={PageNotFound} />;
              }
              return route.protected ? (
                <PrivateRoute key={i} {...route} />
              ) : (
                <Route exact={route.exact} key={i} {...route} />
              );
            })}
            <Route component={PageNotFound} />
          </Switch>
        )}
        {isShowStepperAnimation && <LoadingAnimation />}
        {isUserReady &&
          ((location.pathname !== "/change-password" && introFlow === TalentOnboardingFlow.INTRO) ||
            queryParam.showonb === "true") && <TalentOnboarding />}
        <ModalRoot />
        <Toaster />
        <Alerter />
        <Popup />
      </main>
    </DndProvider>
  );
};
const APP = (props: any) => {
  return (
    <ErrorBoundaryWrapper>
      <AppContext>
        <PrimeReactProvider value={PrimeReactValues}>
          <App {...props} />
        </PrimeReactProvider>
      </AppContext>
    </ErrorBoundaryWrapper>
  );
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(APP));
