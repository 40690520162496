import { TalentActivityStatus } from "~/store/constants/talent";

export enum IClearanceStatus {
  "ALL" = "ALL",
  "REQUESTED" = "REQUESTED",
  "APPROVED" = "APPROVED",
  "DECLINED" = "DECLINED",
  "CANCELLED" = "CANCELLED",
  "AMENDED" = "AMENDED",
}

export enum IClearancesType {
  AVAILABILITY = "AVAILABILITY",
  BOOKING_AMENDMENT = "BOOKING_AMENDMENT",
}

export interface IClearance {
  id: number;
  availability: number;
  author: {
    id: number;
    full_name: string;
    email: string;
    published: boolean;
  };
  talent: {
    id: number;
    name: string;
    type: string;
    status: TalentActivityStatus;
  };
  organisation: {
    id: number;
    name: string;
  };
  clients: {
    id: number;
    name: string;
  }[];
  tro: {
    id: number;
    full_name: string;
    email: string;
  }[];
  brief_id: number;
  match_id: number;
  brief_name: string;
  brief_description: string;
  brief_description_url: string;
  start_date: string;
  end_date: string;
  status: IClearanceStatus;
  capacity: string;
  duration_id: number;
  duration_days: string;
  duration_weekends: string;
  dates: { date: string; capacity: string }[];
  decline_reason: {
    declined_at: string;
    description: string;
    name: string;
  };
  comment: string;
}
