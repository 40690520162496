@import "../../mixins.scss";

.PersonalYouForm {
  .p-inputswitch {
    & + label {
      line-height: 24px !important;
    }
  }

  #uk_work_verification,
  #residence_work_verification {
    width: 100%;
  }

  .p-selectbutton.p-buttonset.p-component {
    width: 100%;
    display: flex;
    padding: 0 0.5rem;
    > .p-button {
      width: 50%;
    }
  }

  .field.grid > label {
    font-weight: bold;
  }

  &.readonly-form {
    .field {
      margin: 1.5rem auto;
      > label::after {
        content: "";
      }
    }
    .readonly-text {
      margin: 0;
    }
  }

  .button-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    .p-button {
      width: 40%;
    }
  }
}
