import { AxiosError, AxiosResponse } from "axios";
import { IAttendee } from "~/components/common/Attendees/Attendees";
import { IBrief, IUmbrella } from "~/interfaces/brief";
import { IClient } from "~/interfaces/common";
import { IDiscipline } from "./global";

// ------------------------------------
// Constants
// ------------------------------------

export const ORGANISATIONS_CREATE_FAILURE = "ORGANISATIONS_CREATE_FAILURE";
export const ORGANISATIONS_CREATE_REQUEST = "ORGANISATIONS_CREATE_REQUEST";
export const ORGANISATIONS_CREATE_SUCCESS = "ORGANISATIONS_CREATE_SUCCESS";

export const ORGANISATIONS_GET_FAILURE = "ORGANISATIONS_GET_FAILURE";
export const ORGANISATIONS_GET_REQUEST = "ORGANISATIONS_GET_REQUEST";
export const ORGANISATIONS_GET_SUCCESS = "ORGANISATIONS_GET_SUCCESS";

export const ORGANISATION_USERS_GET_FAILURE = "ORGANISATION_USERS_GET_FAILURE";
export const ORGANISATION_USERS_GET_REQUEST = "ORGANISATION_USERS_GET_REQUEST";
export const ORGANISATION_USERS_GET_SUCCESS = "ORGANISATION_USERS_GET_SUCCESS";

export const ORGANISATIONS_LIST_FAILURE = "ORGANISATIONS_LIST_FAILURE";
export const ORGANISATIONS_LIST_REQUEST = "ORGANISATIONS_LIST_REQUEST";
export const ORGANISATIONS_LIST_SUCCESS = "ORGANISATIONS_LIST_SUCCESS";

export const ORGANISATIONS_RELATED_LIST_FAILURE = "ORGANISATIONS_RELATED_LIST_FAILURE";
export const ORGANISATIONS_RELATED_LIST_REQUEST = "ORGANISATIONS_RELATED_LIST_REQUEST";
export const ORGANISATIONS_RELATED_LIST_SUCCESS = "ORGANISATIONS_RELATED_LIST_SUCCESS";

export const ORGANISATIONS_UPDATE_FAILURE = "ORGANISATIONS_UPDATE_FAILURE";
export const ORGANISATIONS_UPDATE_REQUEST = "ORGANISATIONS_UPDATE_REQUEST";
export const ORGANISATIONS_UPDATE_SUCCESS = "ORGANISATIONS_UPDATE_SUCCESS";

export const ORGANISATIONS_FILE_SAVE_FAILURE = "ORGANISATIONS_FILE_SAVE_FAILURE";
export const ORGANISATIONS_FILE_SAVE_REQUEST = "ORGANISATIONS_FILE_SAVE_REQUEST";
export const ORGANISATIONS_FILE_SAVE_SUCCESS = "ORGANISATIONS_FILE_SAVE_SUCCESS";

export const ORGANISATION_TIMETABLE_GET_REQUEST = "ORGANISATION_TIMETABLE_GET_REQUEST";
export const ORGANISATION_TIMETABLE_GET_FAILURE = "ORGANISATION_TIMETABLE_GET_FAILURE";
export const ORGANISATION_TIMETABLE_GET_SUCCESS = "ORGANISATION_TIMETABLE_GET_SUCCESS";

export const ORGANISATION_TIMETABLE_UPDATE_REQUEST = "ORGANISATION_TIMETABLE_UPDATE_REQUEST";
export const ORGANISATION_TIMETABLE_UPDATE_FAILURE = "ORGANISATION_TIMETABLE_UPDATE_FAILURE";
export const ORGANISATION_TIMETABLE_UPDATE_SUCCESS = "ORGANISATION_TIMETABLE_UPDATE_SUCCESS";

// ------------------------------------
// Interfaces
// ------------------------------------

export interface IOrganisationUser {
  id: number;
  fb_psid: string | null;
  ge_psid: string;
  auth_token: string;
  name: string;
  first_name: string;
  last_name: string;
  full_name: string;
  email: string;
  organisation_id: number;
  organisation_name: string;
  organisations?: string[];
  talent_id: number | null;
  reference_id: null;
  telephone: string;
  onboarded: number;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
  must_change_password: number;
  reset_pass_token_exp: null;
  reset_pass_token: null;
  tos: number;
  nda: number;
  disciplines: { id: number; name: string }[];
  published: boolean;
  roles: { id: number; display_name: string }[];
}

export interface IOrganisation {
  address_1: string;
  address_2: string;
  created_at: string;
  description: string;
  headline: string;
  subhead: string;
  legal_name: string;
  name: string;
  postcode: string;
  published: boolean;
  city: string;
  profile_image: string;
  id: number;
  notes: string;
  country: { id: number; name: string; code: string };
  closed_briefs: IBrief[];
  opened_briefs: IBrief[];
  resourced_briefs: IBrief[];
  updated_at: string;
  users: IOrganisationUser[];
  umbrella_companies?: IUmbrella[];
  custom_attendees?: IAttendee[];
  work_profile?: {
    display_rate: boolean;
    display_fee: boolean;
    image_url: string;
    vision_statement: string;
    company_benefits: string;
    genie_take: string;
  };
  disciplines: IDiscipline[];
  clients: IClient[];
  timetable?: ITimetable[];
  image_url: string;
  talent_background_url: string;
  assignees: IAssignee[];
}

export interface ITimetable {
  id: number;
  weekday: number;
  weekday_name: string;
  hours: string;
  enabled: boolean;
  organisation_id: number;
}

export interface IAssignee {
  id?: number;
  full_name: string;
  published?: boolean;
}

export enum ITimetableType {
  ORGANISATION = "organisation",
  TALENT = "talent",
}
export interface ITalentTimetable {
  timetable: ITimetable[];
  type: ITimetableType;
  // talent_id: number;
}

// ------------------------------------
// Action Creators
// ------------------------------------
export default {
  createOrganisationFailure: (err: AxiosError) => ({
    type: ORGANISATIONS_CREATE_FAILURE,
    payload: err?.response?.data,
  }),
  createOrganisationRequest: () => ({
    type: ORGANISATIONS_CREATE_REQUEST,
  }),
  createOrganisationSuccess: (res: AxiosResponse) => ({
    type: ORGANISATIONS_CREATE_SUCCESS,
    payload: res.data,
  }),
  getOrganisationFailure: (err: AxiosError) => ({
    type: ORGANISATIONS_GET_FAILURE,
    payload: err?.response?.data,
  }),
  getOrganisationRequest: () => ({
    type: ORGANISATIONS_GET_REQUEST,
  }),
  getOrganisationSuccess: (res: AxiosResponse) => ({
    type: ORGANISATIONS_GET_SUCCESS,
    payload: res.data,
  }),
  getOrganisationTimetableFailure: (err: AxiosError) => ({
    type: ORGANISATION_TIMETABLE_GET_FAILURE,
    payload: err?.response?.data,
  }),
  getOrganisationTimetableRequest: () => ({
    type: ORGANISATION_TIMETABLE_GET_REQUEST,
  }),
  getOrganisationTimetableSuccess: (res: AxiosResponse) => ({
    type: ORGANISATION_TIMETABLE_GET_SUCCESS,
    payload: res.data,
  }),
  updateOrganisationTimetableFailure: (err: AxiosError) => ({
    type: ORGANISATION_TIMETABLE_UPDATE_FAILURE,
    payload: err?.response?.data,
  }),
  updateOrganisationTimetableRequest: () => ({
    type: ORGANISATION_TIMETABLE_UPDATE_REQUEST,
  }),
  updateOrganisationTimetableSuccess: (res: AxiosResponse) => ({
    type: ORGANISATION_TIMETABLE_UPDATE_SUCCESS,
    payload: res.data,
  }),
  listOrganisationsFailure: (err: AxiosError) => ({
    type: ORGANISATIONS_LIST_FAILURE,
    payload: err?.response?.data,
  }),
  getOrganisationUsersRequest: () => ({
    type: ORGANISATION_USERS_GET_REQUEST,
  }),
  getOrganisationUsersSuccess: (res: AxiosResponse) => ({
    type: ORGANISATION_USERS_GET_SUCCESS,
    payload: res.data,
  }),
  getOrganisationUsersFailure: (err: AxiosError) => ({
    type: ORGANISATION_USERS_GET_FAILURE,
    payload: err?.response?.data,
  }),
  listOrganisationsRequest: () => ({
    type: ORGANISATIONS_LIST_REQUEST,
  }),
  listOrganisationsSuccess: (res: AxiosResponse) => ({
    type: ORGANISATIONS_LIST_SUCCESS,
    payload: res.data,
  }),
  listOrganisationsRelatedRequest: () => ({
    type: ORGANISATIONS_RELATED_LIST_REQUEST,
  }),
  listOrganisationsRelatedSuccess: (res: AxiosResponse) => ({
    type: ORGANISATIONS_RELATED_LIST_SUCCESS,
    payload: res.data,
  }),
  listOrganisationsRelatedFailure: (err: AxiosError) => ({
    type: ORGANISATIONS_RELATED_LIST_FAILURE,
    payload: err?.response?.data,
  }),
  updateOrganisationFailure: (err: AxiosError) => ({
    type: ORGANISATIONS_UPDATE_FAILURE,
    payload: err?.response?.data,
  }),
  updateOrganisationRequest: () => ({
    type: ORGANISATIONS_UPDATE_REQUEST,
  }),
  updateOrganisationSuccess: (res: AxiosResponse) => ({
    type: ORGANISATIONS_UPDATE_SUCCESS,
    payload: res.data,
  }),
  saveOrganisationFileFailure: (err: AxiosError) => ({
    type: ORGANISATIONS_FILE_SAVE_FAILURE,
    payload: err?.response?.data,
  }),
  saveOrganisationFileRequest: () => ({
    type: ORGANISATIONS_FILE_SAVE_REQUEST,
  }),
  saveOrganisationFileSuccess: (res: AxiosResponse) => ({
    type: ORGANISATIONS_FILE_SAVE_SUCCESS,
    payload: res.data,
  }),
};
