import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router";
import ThemedButton from "../../components/common/ThemedButton/ThemedButton";
import Loader from "../../components/Loader/Loader";
import PasswordRules from "../../components/PasswordRules/PasswordRules";
import { RouteComponentProps } from "react-router-dom";
import { IState } from "../../store/reducers/index";
import actions from "../../store/actions";
import { useFormik } from "formik";
import ApInputText from "../../components/common/ApInputText/ApInputText";
import { AxiosError } from "axios";
import { NewPasswordSchema } from "../../schemas/user";

function NewPasswordForm(props: RouteComponentProps) {
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const { isAuthenticating } = useSelector((state: IState) => state.user);

  const handleSubmit = (form: { password: string; confirm_password: string }) => {
    dispatch(actions.user.setPassword({ password: form.password }))
      .then(() => {
        dispatch(actions.user.verifyToken());
        props.history.push("/");
      })
      .catch((error: AxiosError) => {
        setErrorMessage(error as unknown as string);
      });
  };

  const changeShowPassword = () => setShowPassword((prevState) => !prevState);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      password: "",
      confirm_password: "",
    },
    validationSchema: NewPasswordSchema,
    onSubmit: handleSubmit,
  });

  return (
    <form className="form" onSubmit={formik.handleSubmit}>
      <h1>Change Password</h1>
      <p className="info">Please type your new password</p>
      <PasswordRules password={formik.values.password} />
      <div className="ThemedInput">
        <ApInputText
          formik={formik}
          id="password"
          noField
          placeholder="Password"
          type={showPassword ? "text" : "password"}
          isError={formik.touched.hasOwnProperty("password") && formik.errors.hasOwnProperty("password")}
        />
      </div>
      <div className="ThemedInput">
        <ApInputText
          formik={formik}
          id="confirm_password"
          noField
          placeholder="Confirm Password"
          type={showPassword ? "text" : "password"}
          isError={
            formik.touched.hasOwnProperty("confirm_password") && formik.errors.hasOwnProperty("confirm_password")
          }
          rightElement={
            <button
              className="eye"
              type="button"
              onTouchStart={changeShowPassword}
              onTouchEnd={changeShowPassword}
              onMouseDown={changeShowPassword}
              onMouseUp={changeShowPassword}
              onBlur={() => setShowPassword(false)}
            >
              <i className="icon-eye" />
            </button>
          }
        />
      </div>
      {errorMessage && <p className="error prt-valid-text">{errorMessage}</p>}
      <ThemedButton className="submit" disabled={isAuthenticating || (formik.touched && !formik.isValid)} type="submit">
        {isAuthenticating ? <Loader /> : "Change And Sign In"}
      </ThemedButton>
    </form>
  );
}

export default withRouter(NewPasswordForm);
