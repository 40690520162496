import React from "react";
import moment from "moment";
import { Button } from "primereact/button";
import { useDispatch } from "react-redux";
import toasts from "~/store/actions/toasts";
import "./CalendarFooterTemplate.scss";
import DatesCapacityForm from "./DatesCapacityForm";

interface IFooterTemplate {
  dates: { date: Date; capacity?: number }[] | undefined;
  resetDates: () => void;
  setDates: (dates: { date: Date; capacity?: number }[]) => void;
  formik?: any;
}

export default function CalendarFooterTemplate({ dates, setDates, resetDates, formik }: IFooterTemplate) {
  const dispatch = useDispatch();

  const showPopup = () => {
    dispatch(
      toasts.setPopup({
        content: (
          <div className="selected-brief-dates">
            <p>Set capacity:</p>
          </div>
        ),
        buttons: [{ text: "Got It" }],
        form: (props: any) => <DatesCapacityForm {...props} setDates={setDates} dates={dates} formik={formik} />,
      })
    );
  };
  return (
    <div className="selected-dates">
      <Button disabled={!dates.length} label="Clear Dates" onClick={() => resetDates()} />
      {!!dates.length && (
        <div className="dates">
          <span>Set capacity:</span>
          <div>
            <span>
              {moment(dates[0].date).format("DD MMM, YYYY")}
              <p className="more" onClick={showPopup}>
                ...
              </p>
              {moment(dates[dates.length - 1].date).format("DD MMM, YYYY")}
            </span>
          </div>
        </div>
      )}
    </div>
  );
}
