import { useMemo } from "react";
import { useSelector } from "react-redux";
import { IUserState } from "~/store/reducers/user";
import { IState } from "~/store/reducers/index";
import { usePermissionContext } from "~/contexts/PermissionContext";

const useOrganisations = () => {
  const {
    organisations: allOrganisations,
    relatedOrganisations,
    isFetchingOrganisationsList,
    isFetchingRelatedList,
  } = useSelector((state: IState) => state.organisation);
  const { isAdmin, isRegionalAdmin } = usePermissionContext();
  const currentUser = useSelector(({ user: { authenticatedUser } }: { user: IUserState }) => authenticatedUser);

  const organisations = useMemo(getOrganisations, [allOrganisations, relatedOrganisations, currentUser]);

  function getOrganisations() {
    if (!currentUser) return undefined;

    switch (true) {
      case isAdmin:
        return allOrganisations;
      case isRegionalAdmin:
        return relatedOrganisations;
      default:
        return [
          {
            id: currentUser.organisation_id,
            name: currentUser.organisation_name,
          },
        ];
    }
  }

  const isOrganisationsLoading = useMemo(() => {
    if (isAdmin) return isFetchingOrganisationsList;
    if (isRegionalAdmin) return isFetchingRelatedList;
    return false;
  }, [isFetchingOrganisationsList, isFetchingRelatedList, isAdmin, isRegionalAdmin]);

  return { organisations, isOrganisationsLoading };
};

export default useOrganisations;
