import { PaginatorPageChangeEvent } from "primereact/paginator";
import React, { useEffect, useState } from "react";
import * as permissionType from "~/constants/permissions";
import { useBookingContext } from "~/contexts/BookingContext";
import { usePermissionContext } from "~/contexts/PermissionContext";
import BookingsTable from "./BookingsTable";
import "./BookingsView.scss";
import { useSelector } from "react-redux";

export default function BookingsView({ id }: { id: number }) {
  const { bookingsState, getOrganisationBookings, isLoading, resetState } = useBookingContext();
  const { userAccess } = usePermissionContext();

  const [perPage, setPerPage] = useState(15);
  const [page, setPage] = useState(1);

  const isOrganisation = userAccess(permissionType.accessOrganisationAdmin);

  const organisationId = isOrganisation
    ? useSelector((state: any) => state.user.authenticatedUser?.organisation_id)
    : id;

  useEffect(() => {
    organisationId && getOrganisationBookings(organisationId, { page, per_page: perPage });
  }, [page, perPage]);

  useEffect(() => {
    return resetState;
  }, []);

  function onPaginatorChange(e: PaginatorPageChangeEvent) {
    setPerPage(e.rows);
    setPage(e.page + 1);
  }

  return (
    <div>
      <header>
        <div className="left-block">
          <h1>Allocations</h1>
        </div>
      </header>
      <BookingsTable
        variant="CLIENT"
        bookings={bookingsState.data}
        isLoading={isLoading}
        page={page}
        perPage={perPage}
        total={bookingsState.total}
        onPaginatorChange={onPaginatorChange}
      />
    </div>
  );
}
