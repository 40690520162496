import Axios from "axios";
import { Dispatch } from "redux";
import User, { ICheckTokenForm, ILoginForm, IResetPasswordForm, ISetPasswordForm, IUserForm } from "../constants/user";
import Talent from "../constants/talent";
import { API_URL, BACKEND_URL } from "~/config";
import { networkErrorMessage, setAuthToken } from "../../utils";
import { IState } from "../reducers/index";
import { TOAST_SUCCESS_MESSAGE, TOAST_ERROR_MESSAGE } from "../constants/toasts";
import Toasts from "../constants/toasts";
import { ITag } from "~/interfaces/common";

// ------------------------------------
// Actions
// ------------------------------------
export default {
  createUser:
    (data: { user: IUserForm; type: "talent" | "client"; id: number }) =>
    (dispatch: Dispatch, getState: () => IState) => {
      return new Promise(async (resolve, reject) => {
        const token = getState().user?.authenticatedUser?.token;
        if (token) {
          const { user, type, id } = data;
          try {
            const res = await Axios.post(`${API_URL}/${type}/${id}/user`, user);
            dispatch(Toasts.setToasts([{ severity: "success", summary: "", detail: TOAST_SUCCESS_MESSAGE }]));
            resolve(res);
          } catch (err) {
            dispatch(Toasts.setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
            reject(err);
          }
        }
      });
    },

  getPaymentProfile: (userId: number) => async (dispatch: Dispatch, getState: () => IState) => {
    dispatch(User.getPaymentProfileRequest());
    return new Promise(async (resolve, reject) => {
      try {
        const { token } = getState().user.authenticatedUser;
        const res = await Axios.get(`${API_URL}/user/${userId}/talent_payment_profile`);
        dispatch(User.getPaymentProfileSuccess(res));
        resolve(res);
      } catch (err) {
        dispatch(User.getPaymentProfileFailure(err));
        reject(err);
      }
    });
  },

  getBankProfile: (userId: number) => async (dispatch: Dispatch) => {
    dispatch(User.getBankProfileRequest());
    return new Promise(async (resolve, reject) => {
      try {
        const res = await Axios.get(`${API_URL}/user/${userId}/talent_bank_profile`);
        dispatch(User.getBankProfileSuccess(res));
        resolve(res);
      } catch (err) {
        dispatch(User.getBankProfileFailure(err));
        reject(err);
      }
    });
  },

  loginUser: (loginParams: ILoginForm | string, loginType: "FORM" | "SSO") => (dispatch: Dispatch) => {
    return new Promise(async (resolve, reject) => {
      dispatch(User.loginUserRequest());
      const loginRequest =
        loginType === "FORM"
          ? Axios.post(`${API_URL}/login`, loginParams)
          : Axios.get(`${BACKEND_URL}/callback${loginParams}`);

      loginRequest
        .then((res) => {
          const { token } = res.data.response;
          setAuthToken(token);
          dispatch(User.loginUserSuccess(res));
          localStorage.setItem("user", token);
          resolve(res);
        })
        .catch((err) => {
          dispatch(User.loginUserFailure(err));
          reject(err);
        });
    });
  },

  logoutUser: () => (dispatch: Dispatch) => {
    dispatch(User.logoutUser());
    dispatch(Talent.rebookTalentReset());
    localStorage.removeItem("user");
    localStorage.removeItem("permissions");
    setAuthToken("");
  },

  setNotAuthenticated: () => (dispatch: Dispatch) => {
    dispatch(User.setNotAuthenticated());
  },

  verifyToken: () => (dispatch: Dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(User.verifyTokenRequest());
      Axios.get(`${API_URL}/user/current`)
        .then((res) => {
          const actualToken = res.config.headers["Authorization"].replace("Bearer ", "");
          setAuthToken(actualToken);
          dispatch(User.verifyTokenSuccess(res, actualToken));
          resolve(actualToken);
        })
        .catch((err) => {
          dispatch(User.verifyTokenFailure(err));
          reject(err);
        });
    });
  },

  getCurrentUser: () => (dispatch: Dispatch) => {
    return new Promise(async (resolve, reject) => {
      Axios.get(`${API_URL}/user/current`)
        .then((res) => {
          dispatch(User.getUserCurrentSuccess(res));
          resolve(res);
        })
        .catch((err) => {
          dispatch(User.getUserCurrentFailure(err));
          reject(err);
        });
    });
  },

  updatePaymentProfile: (form: object, userId: number) => async (dispatch: Dispatch, getState: () => IState) => {
    dispatch(User.updatePaymentProfileRequest());
    return new Promise(async (resolve, reject) => {
      try {
        const res = await Axios.put(`${API_URL}/user/${userId}/talent_payment_profile`, form);
        dispatch(User.updatePaymentProfileSuccess(res));
        dispatch(Toasts.setToasts([{ severity: "success", summary: "", detail: TOAST_SUCCESS_MESSAGE }]));
        resolve(res);
      } catch (err) {
        dispatch(User.updatePaymentProfileFailure(err));
        dispatch(Toasts.setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
        reject(err);
      }
    });
  },

  resetPassword: (form: IResetPasswordForm) => (dispatch: Dispatch) => {
    dispatch(User.resetUserRequest());
    return new Promise(async (resolve, reject) => {
      try {
        const res = await Axios.post(`${API_URL}/user/reset_password?type=TYPE_PASSWORD_ADMIN_RESET`, form);
        dispatch(User.resetUserSuccess(res));
        resolve(res);
      } catch (err) {
        dispatch(User.resetUserFailure(err));
        reject(err?.response?.data?.result || networkErrorMessage);
      }
    });
  },

  setPassword: (form: ISetPasswordForm) => (dispatch: Dispatch, getState: () => any) => {
    dispatch(User.setPasswordRequest());
    return new Promise(async (resolve, reject) => {
      try {
        const res = await Axios.put(`${API_URL}/user/current`, { ...form });
        const { token } = res.data;
        localStorage.setItem("user", token);
        setAuthToken(token);
        dispatch(User.setPasswordSuccess(res));
        dispatch(Toasts.setToasts([{ severity: "success", summary: "", detail: res.data.result }]));
        resolve(res);
      } catch (err) {
        dispatch(User.setPasswordFailure(err));
        reject(err);
      }
    });
  },

  checkResetPasswordToken: (form: ICheckTokenForm) => (dispatch: Dispatch) => {
    dispatch(User.checkResetTokenRequest());
    return new Promise(async (resolve, reject) => {
      try {
        const res = await Axios.get(`${API_URL}/user/change_password/${form.token}`);
        const { token } = res.data.response;
        localStorage.setItem("user", res.data.response.token);
        localStorage.setItem("permissions", res.data.response.user.permissions);
        setAuthToken(token);
        dispatch(User.checkResetTokenSuccess(res));
        resolve(res);
      } catch (err) {
        dispatch(User.checkResetTokenFailure(err));
        reject(err);
      }
    });
  },

  getTags: () => (dispatch: Dispatch, getState: () => any) => {
    dispatch(User.getTagsRequest());
    return new Promise(async (resolve, reject) => {
      const token = getState().user?.authenticatedUser?.token;
      if (token) {
        try {
          const res = await Axios.get(`${API_URL}/tags`);
          dispatch(User.getTagsSuccess(res));
          resolve(res);
        } catch (err) {
          dispatch(User.getTagsFailure(err));
          reject(err);
        }
      }
    });
  },

  updateTag: (tag: ITag) => (dispatch: Dispatch, getState: () => IState) => {
    dispatch(User.updateTagRequest());
    return new Promise(async (resolve, reject) => {
      const token = getState().user?.authenticatedUser?.token;
      if (token) {
        try {
          const res = await Axios.put(`${API_URL}/tag/${tag.id}`, { color: tag.color, name: tag.name });
          const newTag = { id: res.data.id, color: res.data.color, name: res.data.name } as ITag;

          dispatch(User.updateTagSuccess(newTag));
          dispatch(Talent.updateTalentsPaginatedTags(newTag));
          dispatch(Toasts.setToasts([{ severity: "success", summary: "", detail: TOAST_SUCCESS_MESSAGE }]));
          resolve(newTag);
        } catch (err) {
          dispatch(User.updateTagFailure(err));
          dispatch(Toasts.setToasts([{ severity: "error", summary: "", detail: err?.response?.data?.message }]));
          reject(err);
        }
      }
    });
  },

  deleteTag: (tag: ITag) => (dispatch: Dispatch, getState: () => IState) => {
    dispatch(User.deleteTagRequest());
    return new Promise(async (resolve, reject) => {
      const token = getState().user?.authenticatedUser?.token;
      if (token) {
        try {
          const res = await Axios.delete(`${API_URL}/tag/${tag.id}`);

          dispatch(User.deleteTagSuccess(tag.id));
          dispatch(Talent.deleteTalentsPaginatedTags(tag.id));
          dispatch(Toasts.setToasts([{ severity: "success", summary: "", detail: TOAST_SUCCESS_MESSAGE }]));
          resolve(res);
        } catch (err) {
          dispatch(User.deleteTagFailure(err));
          dispatch(Toasts.setToasts([{ severity: "error", summary: "", detail: err?.response?.data?.message }]));
          reject(err);
        }
      }
    });
  },
};
