import React from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useSelector } from "react-redux";
import { IState } from "../../store/reducers/index";
import "./ClearanceDatesModal.scss";
import ClearanceDatesForm from "~/forms/ClearanceDatesForm/ClearanceDatesForm";

export default function ClearanceDatesModal() {
  const { modalProps } = useSelector((state: IState) => state.modal);
  const { content } = modalProps || {};

  return (
    <Dialog
      className="ClearanceDatesModal"
      header={content.header}
      footer={
        <footer>
          <Button form="clearance-dates-form" label={content.buttonLabel} type="submit" icon="pi pi-check" />
        </footer>
      }
      visible={true}
      modal={true}
      onHide={modalProps.onClose}
      style={{ minWidth: "50em" }}
    >
      <ClearanceDatesForm formProps={modalProps} />
    </Dialog>
  );
}
