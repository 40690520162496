import React from "react";
import { Dropdown, DropdownProps } from "primereact/dropdown";
import { Field, FieldInputProps, FieldMetaProps, FormikProps, FormikValues } from "formik";
import "./ApDropdown.scss";

interface IDropdown extends DropdownProps {
  id: string;
  label?: string;
  formik?: FormikProps<FormikValues>;
  noField?: boolean;
  optionValue?: string;
  showClear?: boolean;
  filterPlaceholder?: string;
  filterBy?: string;
  scrollHeight?: string;
  loading?: boolean;
  placeholder?: string;
}

export default function ApDropdown(props: IDropdown) {
  let meta, field;
  if (props.noField && props.formik) {
    meta = props.formik.getFieldMeta(props.id);
    field = props.formik.getFieldProps(props.id);
  }

  const { noField, loading, ...rest } = props;

  const renderDropdown = (field: FieldInputProps<any>, meta: FieldMetaProps<any>) => (
    <>
      {props.label && <label htmlFor={props.id}>{props.label}</label>}
      <Dropdown
        {...field}
        {...rest}
        filterPlaceholder={props.filterPlaceholder || "Filter..."}
        inputId={props.id}
        disabled={props.disabled || loading}
        loading={loading ? "true" : undefined}
      />
      {meta.touched && meta.error && (
        <div className="ap-error">
          {Array.isArray(meta.error) && props.optionValue ? meta.error?.[0]?.[props.optionValue] : meta.error}
        </div>
      )}
    </>
  );

  return noField && meta ? (
    renderDropdown(field as FieldInputProps<any>, meta)
  ) : (
    <Field name={props.id}>{({ field, meta }: any) => renderDropdown(field, meta)}</Field>
  );
}
