import React, { createContext, useContext } from "react";
import usePermission from "~/hooks/usePermission";

export const CLIENT_ROLES_REG_EXP = /^client_/;
export const ADDITIONAL_ROLES: Array<string> = ["client_admin_invoices"];
export enum ROLES {
  ADMIN = "admin",
  LOCAL_ADMIN = "local_admin",
  TRO = "tro",
  ARU = "aru",
  REGIONAL_ADMIN = "regional_admin",
  BRIEFING_USER = "bu",
}

export const ORGANISATION_ROLES = [ROLES.LOCAL_ADMIN, ROLES.TRO, ROLES.ARU, ROLES.REGIONAL_ADMIN, ROLES.BRIEFING_USER];

export interface IPermissions {
  permissions: null | { [key: string]: string };
  roles: Array<IRole>;
  isFetchingRoles: boolean;
  isAdmin: boolean;
  isRegionalAdmin: boolean;
  isLocalAdmin: boolean;
  isOrganisationAdmin: boolean;
  isTro: boolean;
  isBriefingUser: boolean;
  isTalent: boolean;
  canEditProject: (author_id: number) => boolean;
  savePermissions: (res: any) => void;
  userAccess: (...items: string[]) => boolean;
  resetPermissions: () => void;
  canEditMatches: () => boolean;
  getRoles: () => void;
  isMatchButtonVisible: boolean;
}

export interface IRole {
  description: string;
  display_name: string;
  id: number;
  name: string;
  permissions: null | { [key: string]: string };
}

export const PermissionContext = createContext(null as IPermissions | null);

export const usePermissionContext = () => {
  const context = useContext(PermissionContext);
  if (!context) {
    throw new Error("usePermissionContext must be used within a PermissionContextProvider");
  }
  return context;
};

const PermissionContextProvider = ({ children }: any) => {
  return (
    <PermissionContext.Provider
      value={{
        ...usePermission(),
      }}
    >
      {children}
    </PermissionContext.Provider>
  );
};

export default PermissionContextProvider;
