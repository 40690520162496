import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Paginator } from "primereact/paginator";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useDebounce from "../../components/common/debounce";
import actions from "../../store/actions";
import { IState } from "../../store/reducers/index";
import "./SelectUserModal.scss";
import useTalent from "~/hooks/useTalent";
import { ITalent, TalentActivityStatus } from "~/store/constants/talent";
import xorBy from "lodash/xorBy";
import cn from "classnames";

export default function SelectUserModal() {
  const dispatch = useDispatch();
  const [selectedItems, setSelectedItems] = useState([]);
  const { modalProps } = useSelector((state: IState) => state.modal);

  const { getIndividualUsers, individualUsers, isFetching } = useTalent();

  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 1000);
  const { organisationId, onSubmit, preselected = [], isNewUser, disciplineToFetch } = modalProps || {};

  useEffect(() => {
    getUsers({ page: 0 });
  }, [debouncedSearch]);

  useEffect(() => {
    !!preselected.length && setSelectedItems(preselected);
  }, [preselected]);

  function getUsers({ page }: { page: number }) {
    getIndividualUsers({
      page: page + 1,
      name: search,
      organisation_id: organisationId || "",
      ...(!!disciplineToFetch && { discipline_code: disciplineToFetch }),
    });
  }

  const isSelectable = (rowData: ITalent) =>
    (!rowData.is_blocked && rowData?.matchable === "100%") || rowData.isPreselected;

  const renderColumnMatchable = (rowData: ITalent) => {
    if (rowData.isPreselected) {
      return <span className="published-status text-color">—</span>;
    }
    if (rowData.status !== TalentActivityStatus.PUBLISHED) {
      return <span className="published-status declined">Inactive</span>;
    }
    if (rowData.matchable === "100%") {
      return <span className="published-status matchable">Matchable</span>;
    }
    return <span className="published-status published">Profile Incomplete</span>;
  };

  const renderSubmitButton = ({ isValid }: { isValid: boolean } = { isValid: true }) => (
    <>
      {!isValid && <div className="ap-error">Minimum of two users required on a team</div>}
      <Button
        label="Save"
        onClick={() => {
          onSubmit({ selectedItems });
          dispatch(actions.modal.closeModal());
        }}
        disabled={!isValid}
      />
    </>
  );

  return (
    <Dialog
      header="Select Users"
      className="SelectUserModal"
      onHide={() => dispatch(actions.modal.closeModal())}
      footer={
        isNewUser
          ? renderSubmitButton()
          : xorBy(selectedItems, preselected, "id").length
          ? renderSubmitButton({ isValid: selectedItems.length >= 2 })
          : null
      }
      modal
      visible
    >
      <div className="field col-12">
        <InputText
          placeholder="Search for users"
          onChange={({ target: { value } }) => setSearch(value.toLowerCase())}
          value={search}
        />
      </div>
      <DataTable
        dataKey="id"
        showSelectAll={false}
        isDataSelectable={({ data }) => isSelectable(data)}
        showSelectionElement={(rowData) => isSelectable(rowData)}
        rowClassName={(data) =>
          cn({
            "p-disabled": !isSelectable(data),
            bordered: preselected.length > 0 && data.id === preselected[preselected.length - 1].id,
          })
        }
        emptyMessage="No users to add"
        value={
          [
            ...preselected,
            ...individualUsers.data?.filter(({ id }) => !preselected.map(({ id }) => id).includes(id)),
          ] || []
        }
        selection={selectedItems}
        selectionMode="multiple"
        onSelectionChange={(e) => setSelectedItems(e.value)}
        loading={isFetching}
      >
        <Column selectionMode="multiple" style={{ width: "3em" }} />
        <Column field="name" header="Name" />
        <Column body={renderColumnMatchable} field="published" header="Status" />
        <Column field="email" header="Email" />
      </DataTable>
      {individualUsers.meta?.total > individualUsers.meta?.per_page && (
        <Paginator
          rows={individualUsers.meta.per_page}
          totalRecords={individualUsers.meta.total}
          first={(individualUsers.meta.current_page - 1) * individualUsers.meta.per_page}
          onPageChange={getUsers}
        />
      )}
    </Dialog>
  );
}
