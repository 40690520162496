import { Button } from "primereact/button";
import React from "react";
import { useHistory } from "react-router-dom";
import { IContentProps } from "./DirectBookOptions";

const DirectBookSecondStep = (props: IContentProps) => {
  const { preSelectedTalents, callback, setIsSecondStep, newTalentStatus } = props;
  const history = useHistory();

  return (
    <div className="DirectBookOptions">
      {!!setIsSecondStep && (
        <Button
          type="button"
          icon="pi pi-arrow-left"
          className="p-button-rounded p-button-text icon-back"
          onClick={() => setIsSecondStep(false)}
        />
      )}
      <div className="DirectBookOptions-buttons--second-step">
        <Button
          onClick={() => {
            history.push({
              pathname: !preSelectedTalents ? "/briefs/new" : "/briefs/direct_book",
              state: { preSelectedTalents, newTalentStatus },
            });
            callback && callback();
          }}
        >
          Add brief to existing project
        </Button>

        <Button
          onClick={() => {
            const queryParams = !preSelectedTalents
              ? "redirectUrl=/briefs/new"
              : `redirectUrl=/briefs/direct_book&talentId=${preSelectedTalents.map(
                  ({ id }) => id
                )}&newTalentStatus=${newTalentStatus}`;
            history.push(`/projects/new?${queryParams}`);
            callback && callback();
          }}
        >
          Add brief to new project
        </Button>
      </div>
    </div>
  );
};

export default DirectBookSecondStep;
