@mixin for-size($range) {
  $phone-upper-boundary: 600px;
  $tablet-portrait-upper-boundary: 900px;
  $tablet-landscape-upper-boundary: 1200px;
  $desktop-upper-boundary: 1800px;

  @if $range == phone-only {
    @media (max-width: #{$phone-upper-boundary - 1}) {
      @content;
    }
  } @else if $range == tablet-portrait-up {
    @media (min-width: $phone-upper-boundary) {
      @content;
    }
  } @else if $range == tablet-landscape-up {
    @media (min-width: $tablet-portrait-upper-boundary) {
      @content;
    }
  } @else if $range == tablet-landscape-down {
    @media (max-width: $tablet-portrait-upper-boundary) {
      @content;
    }
  } @else if $range == desktop-up {
    @media (min-width: $tablet-landscape-upper-boundary) {
      @content;
    }
  } @else if $range == desktop-down {
    @media (max-width: $tablet-landscape-upper-boundary) {
      @content;
    }
  } @else if $range == big-desktop-up {
    @media (min-width: $desktop-upper-boundary) {
      @content;
    }
  }
}

$baseFont: "Metropolis", Helvetica, Arial, sans-serif;
$baseEmojiFont: "Metropolis", Helvetica, Arial, apple color emoji, segoe ui emoji, noto color emoji, android emoji,
  emojisymbols, emojione mozilla, twemoji mozilla, segoe ui symbol, sans-serif;
$iconFont: "icomoon";
$primaryColor: #000;
$brandColor: #d4272b;
$brandColorDarken: #b80015;
$lightRedColor: #f3ebec;
$warningRed: #f3a4a4;
$fieldErrorBorderColor: #e32222;
$toggleBg: #f1f5f5;
$enabledSwitchColor: #368232;
$disabledSwitchColor: $brandColor;
$lightGray: #f1f5f5;

$briefWidthSm: 310px;
$briefWidthXs: 660px;

@mixin button-styles($color) {
  background-color: var($color);
  border: 1px solid var($color);
  svg {
    color: #fff;
  }
  &:hover {
    background-color: #fff;
    color: var($color);
    svg {
      color: var($color);
    }
  }
}

@mixin button-styles-div($color) {
  > button {
    @include button-styles($color);
  }
}
