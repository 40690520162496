import React, { createContext, useContext } from "react";
import useClearance, { IClearances } from "~/hooks/useClearance";
import useClearanceFilterSettings from "~/hooks/useClearanceFilterSettings";
import { ICommonFilterSettings } from "~/interfaces/common";

export const ClearanceContext = createContext(
  null as (IClearances & ICommonFilterSettings & { resetFilterClearanceSettings: (path: string) => void }) | null
);

export const useClearanceContext = () => {
  const context = useContext(ClearanceContext);

  if (!context) {
    throw new Error("useClearanceContext must be used within a ClearanceContextProvider");
  }
  return context;
};

const ClearanceContextProvider = ({ children }: any) => {
  return (
    <ClearanceContext.Provider
      value={{
        ...useClearance(),
        ...useClearanceFilterSettings(),
      }}
    >
      {children}
    </ClearanceContext.Provider>
  );
};

export default ClearanceContextProvider;
