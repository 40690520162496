import Axios, { GenericAbortSignal } from "axios";
import { API_URL } from "~/config";
import { IQueryParams } from "~/interfaces/common";

export class BookingService {
  static async getOrganisationBookings(id: number, params: IQueryParams, controller: { signal: GenericAbortSignal }) {
    return await Axios.get(`${API_URL}/organisation/${id}/bookings`, { params, signal: controller?.signal });
  }
  static async getTalentBookings(id: number, params: IQueryParams, controller: { signal: GenericAbortSignal }) {
    return await Axios.get(`${API_URL}/talent/${id}/bookings`, { params, signal: controller?.signal });
  }
}
