import React, { useRef } from "react";
import { useDispatch } from "react-redux";
import { Button } from "primereact/button";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { IFilter } from "~/hooks/useFilters";
import actions from "~/store/actions";

interface IProps {
  filters: any;
  activeFilter: any;
  onChangeCustomFilter: (e: DropdownChangeEvent) => void;
  onDeleteFilter: (filterId: number) => void;
}

export default function AppliedFilters(props: IProps) {
  const dispatch = useDispatch();
  const customFiltersRef = useRef(null);

  const deleteCustomFilterModal = (filterId: number) => {
    dispatch(
      actions.toasts.setPopup({
        content: <>Are you sure you want to delete this filter?</>,
        buttons: [
          {
            text: "Go back",
          },
          {
            text: "Delete",
            callback: () => props.onDeleteFilter(filterId),
          },
        ],
      })
    );
  };

  const filterOptionTemplate = (filter: IFilter) => (
    <div className="custom-filters-option">
      <span>{filter.name}</span>
      <Button
        className="delete-button"
        icon="pi pi-times"
        onClick={(e) => {
          e.stopPropagation();
          customFiltersRef?.current?.hide();
          filter.id && deleteCustomFilterModal(filter.id);
        }}
      />
    </div>
  );

  return (
    <Dropdown
      ref={customFiltersRef}
      className="custom-filters filteringSelector"
      value={props.activeFilter}
      options={props.filters}
      optionLabel="name"
      optionValue="id"
      itemTemplate={filterOptionTemplate}
      onChange={props.onChangeCustomFilter}
      placeholder="Select from saved filters"
      scrollHeight="300"
    />
  );
}
