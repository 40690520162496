export const PERSONAL_DETAILS = "PERSONAL_DETAILS";
export const NOTIFICATION_PREFERENCES = "NOTIFICATION_PREFERENCES";
export const SUPERPOWERS = "SUPERPOWERS";
export const USER_MANAGEMENT = "USER_MANAGEMENT";
export const POPUP_MANAGEMENT = "POPUP_MANAGEMENT";
export const SETTINGS = "SETTINGS";
export const LOGOUT = "LOGOUT";
export const TALENT_MENU = "TALENT_MENU";
export const SEPARATOR = "SEPARATOR";

export const TALENT = "TALENT";
export const CLIENTS = "CLIENTS";
export const BRIEFS = "BRIEFS";
export const NOTIFICATIONS = "NOTIFICATIONS";

export const SHARED_TALENT = "SHARED_TALENT";
export const ORGANISATION_PROFILE = "ORGANISATION_PROFILE";
export const BOOKINGS = "BOOKINGS";

export const CLEARANCES = "CLEARANCES";

export const TALENT_PROFILE = "TALENT_PROFILE";
