import { useMemo, useState } from "react";
import * as permissionType from "~/constants/permissions";
import { IPermissions } from "~/contexts/PermissionContext";
import { camelCase } from "lodash";
import { OrganisationUsersService } from "~/API/OrganisationUsersService";
import Toasts, { TOAST_ERROR_MESSAGE } from "~/store/constants/toasts";
import { useDispatch, useSelector } from "react-redux";
import {
  GLOBAL_ADMIN_ROLE_ID,
  LOCAL_ADMIN_ROLE_ID,
  TRO_ROLE_ID,
  REGIONAL_ADMIN_ROLE_ID,
  BU_ROLE_ID,
} from "~/constants";

const usePermission = () => {
  const [permissions, setPermissions] = useState<null | { [key: string]: string }>(null);
  const [isFetchingRoles, setIsFetchingRoles] = useState(false);
  const [roles, setRoles] = useState([]);
  const dispatch = useDispatch();

  const { id: userId, roles: userRoles = [] } = useSelector((state: any) => state.user.authenticatedUser || {});

  const savePermissions = async (res: any) => {
    const permissions = Object.entries(permissionType).reduce(
      (acc: { [key: string]: string }, [key, val]: [string, string]) => {
        res.forEach((item: string) => {
          const itemFormated = camelCase(item);

          if (itemFormated === val) {
            acc[val] = key;
          }
        });
        return acc;
      },
      {}
    );

    setPermissions(permissions);
  };

  const userAccess = (...perms: string[]) => (permissions ? !!perms.some((perm) => permissions[perm]) : false);

  const resetPermissions = () => setPermissions(null);

  const canEditMatches = () =>
    userAccess(permissionType.editOwnClientMatch, permissionType.editAnyMatch, permissionType.editOwnUserMatch);

  const canEditProject = (author_id: number) =>
    userAccess(permissionType.editAnyProject, permissionType.editOwnOrganisationProject) || author_id === userId;

  const isAdmin = useMemo(
    () => !!userId && userRoles?.some(({ id }: { id: number }) => id === GLOBAL_ADMIN_ROLE_ID),
    [userId]
  );

  const isRegionalAdmin = useMemo(
    () => !!userId && userRoles?.some(({ id }: { id: number }) => id === REGIONAL_ADMIN_ROLE_ID),
    [userId]
  );

  const isLocalAdmin = useMemo(
    () => !!userId && userRoles?.some(({ id }: { id: number }) => id === LOCAL_ADMIN_ROLE_ID),
    [userId]
  );

  const isOrganisationAdmin = useMemo(() => userAccess(permissionType.accessOrganisationAdmin), [userId]);

  const isTro = useMemo(() => !!userId && userRoles?.some(({ id }: { id: number }) => id === TRO_ROLE_ID), [userId]);

  const isBriefingUser = useMemo(
    () => !!userId && userRoles?.some(({ id }: { id: number }) => id === BU_ROLE_ID),
    [userId]
  );

  const isTalent = userAccess(permissionType.accessTalent);

  const isMatchButtonVisible = userAccess(
    permissionType.postAnyBrief,
    permissionType.postAnyProject,
    permissionType.postOwnOrganisationProject,
    permissionType.postOwnOrganisationBrief
  );

  const getRoles = async () => {
    setIsFetchingRoles(true);
    try {
      const { data: roles }: { data: [] } = await OrganisationUsersService.getRoles();
      setRoles(roles);
      setIsFetchingRoles(false);
    } catch (e) {
      dispatch(Toasts.setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
    }
  };

  return {
    permissions,
    roles,
    isFetchingRoles,
    isAdmin,
    isRegionalAdmin,
    isLocalAdmin,
    isOrganisationAdmin,
    isTro,
    isBriefingUser,
    isTalent,
    getRoles,
    savePermissions,
    resetPermissions,
    userAccess,
    canEditMatches,
    canEditProject,
    isMatchButtonVisible,
  } as IPermissions;
};
export default usePermission;
