import { AxiosError, AxiosResponse } from "axios";

// ------------------------------------
// Constants
// ------------------------------------
export const SKILL_SAVE_REQUEST = "SKILL_SAVE_REQUEST";
export const SKILL_SAVE_SUCCESS = "SKILL_SAVE_SUCCESS";
export const SKILL_SAVE_FAILURE = "SKILL_SAVE_FAILURE";

// ------------------------------------
// Interfaces
// ------------------------------------
export type IOption = {
  name: string;
  skills: ISkill[];
  id?: number;
};

export type ISkill = {
  id: number;
  name: string;
  discipline?: number;
  order_id?: number;
};

// ------------------------------------
// Action Creators
// ------------------------------------
export default {
  skillSaveRequest: () => ({
    type: SKILL_SAVE_REQUEST,
  }),
  skillSaveSuccess: (res: AxiosResponse) => ({
    type: SKILL_SAVE_SUCCESS,
    payload: res.data,
  }),
  skillSaveFailure: (err: AxiosError) => ({
    type: SKILL_SAVE_FAILURE,
    payload: err?.response?.data,
  }),
};
