import { Field, FieldProps, FormikProps, FormikValues } from "formik";
import moment from "moment";
import { Calendar, CalendarProps } from "primereact/calendar";
import React from "react";

type ICalendar = {
  id: string;
  label?: string;
  formik?: FormikProps<FormikValues>;
  noField?: boolean;
  stepMinute?: number;
  useRawValue?: boolean;
  valueFormat?: string;
} & CalendarProps;

export default function ApCalendar(props: ICalendar) {
  let meta, field;
  if (props.noField && props.formik) {
    meta = props.formik.getFieldMeta(props.id);
    field = props.formik.getFieldProps(props.id);
  }

  return props.noField && meta && field ? (
    <>
      {props.label && <label htmlFor={props.id}>{props.label}</label>}
      <Calendar
        dateFormat="dd/mm/yy"
        {...field}
        {...props}
        value={moment(field.value, props.valueFormat || "YYYY-MM-DD").toDate()}
        onChange={(e) => {
          e.value &&
            props.formik?.setFieldValue(
              props.id,
              props.useRawValue
                ? moment(e.value).seconds(0).toDate()
                : moment(e.value).format(props.valueFormat || "YYYY-MM-DD")
            );
        }}
      />
      {meta.touched && meta.error && <div className="ap-error">{meta.error}</div>}
    </>
  ) : (
    <Field name={props.id}>
      {({ field, meta }: FieldProps) => (
        <>
          {props.label && <label htmlFor={props.id}>{props.label}</label>}
          <Calendar dateFormat="dd/mm/yy" {...field} {...props} />
          {meta.error && meta.touched && <div className="ap-error">{meta.error}</div>}
        </>
      )}
    </Field>
  );
}
