import broadcast from "./broadcast";
import client from "./client";
import organisation from "./organisation";
import location from "./location";
import modal from "./modal";
import talent from "./talent";
import toasts from "./toasts";
import user from "./user";
import notifications from "./notifications";
import skills from "./skills";
import permissions from "./permissions";
import bookings from "./bookings";

export default {
  broadcast,
  client,
  organisation,
  location,
  modal,
  talent,
  toasts,
  user,
  notifications,
  skills,
  permissions,
  bookings,
};
