import React, { useEffect, useMemo } from "react";
import { Dialog } from "primereact/dialog";
import UserManagementForm from "../../forms/UserManagementForm/UserManagementForm";
import { useSelector } from "react-redux";
import { IState } from "../../store/reducers/index";
import { Button } from "primereact/button";
import { Formik, Form } from "formik";
import { UserManagementNewOrganisationSchema, UserManagementSchema } from "../../schemas/user";
import "./AddUserModal.scss";
import { IUserForm } from "../../store/constants/user";
import { ROLES, usePermissionContext } from "~/contexts/PermissionContext";
import { processingUserRoles } from "~/utils";

export default function AddUserModal() {
  const { modalProps } = useSelector((state: IState) => state.modal);

  const { getRoles } = usePermissionContext();
  const userRoles = useMemo(() => processingUserRoles(modalProps.user?.roles ?? []), [modalProps.user?.roles]);

  useEffect(() => {
    if (modalProps.type === "organisation") getRoles();
  }, []);

  async function onSubmit(user: IUserForm) {
    if (modalProps?.onSubmit) {
      await modalProps.onSubmit(user);
    }
  }

  return (
    <Formik
      initialValues={
        modalProps.user
          ? {
              first_name: modalProps.user.first_name,
              last_name: modalProps.user.last_name,
              email: modalProps.user.email,
              ...(modalProps.type === "organisation" && {
                role: userRoles.main,
                additional_roles: userRoles.additional,
                organisations: modalProps?.user.regional_organisations?.map(
                  ({ organisation_id }: { organisation_id: number }) => organisation_id
                ) || [modalProps?.user?.organisation_id],
              }),
            }
          : {
              first_name: "",
              last_name: "",
              email: "",
              ...(modalProps.type === "organisation" && {
                role: ROLES.LOCAL_ADMIN,
                additional_roles: [],
                organisations: [],
              }),
            }
      }
      validationSchema={modalProps.isNewOrganisation ? UserManagementNewOrganisationSchema : UserManagementSchema}
      onSubmit={onSubmit}
      validateOnChange={false}
      enableReinitialize={true}
    >
      {(formik) => (
        <Form>
          <Dialog
            className="AddUserModal ChangeRoleModal"
            header={`${modalProps.user ? "Edit user data" : "Add a new user"} `}
            footer={
              <footer>
                {/*todo uncomment on BE ready*/}
                {/*{modalProps.user && (*/}
                {/*  <Button*/}
                {/*    className="p-button-secondary"*/}
                {/*    icon="pi pi-trash"*/}
                {/*    label="Remove"*/}
                {/*    onClick={modalProps.onRemove}*/}
                {/*  />*/}
                {/*)}*/}
                <Button label="Save" type="submit" onClick={() => formik.submitForm()} loading={formik.isSubmitting} />
              </footer>
            }
            visible={true}
            modal={true}
            onHide={modalProps.onClose}
          >
            <UserManagementForm
              edit={!!modalProps.user?.id}
              type={modalProps.type}
              isOwner={modalProps.isOwner}
              isNewOrganisation={modalProps.isNewOrganisation}
            />
          </Dialog>
        </Form>
      )}
    </Formik>
  );
}
