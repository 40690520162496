import React, { ErrorInfo } from "react";
import { ErrorBoundary } from "react-error-boundary";
import GlobalError from "../common/GlobalError/GlobalError";

export default function ErrorBoundaryWrapper({ children }: { children: React.ReactNode }) {
  const logError = (error: Error, info?: ErrorInfo) => {
    // TODO: send error details to external API, e.g. Sentry
    // console.log({ error, info }, window.location.href);
  };

  return (
    <ErrorBoundary FallbackComponent={GlobalError} onError={logError}>
      {children}
    </ErrorBoundary>
  );
}
