import React, { useEffect, useState, useMemo } from "react";
import { FormikProps, FormikValues } from "formik";
import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";
import ApDropdown from "~/components/common/ApDropdown/ApDropdown";
import ApRadioGroup from "~/components/common/ApRadioGroup/ApRadioGroup";
import ApInputText from "~/components/common/ApInputText/ApInputText";
import ApFileUpload from "~/components/common/ApFileUpload/ApFileUpload";
import ApMultiselect from "~/components/common/ApMultiselect/ApMultiselect";
import ApInputTextarea from "~/components/common/ApInputTextarea/ApInputTextarea";
import ApCalendar from "~/components/common/ApCalendar/ApCalendar";
import TalentInfoBlock from "~/components/common/TalentInfoBlock/TalentInfoBlock";
import Loader from "~/components/Loader/Loader";
import Description from "../BriefViewDetailsForm/BriefFields/Description";
import BriefDatesForm from "./BriefDatesForm/BriefDatesForm";
import { validateFile } from "~/utils";
import { IState } from "~/store/reducers/index";
import { ITalent } from "~/store/constants/talent";
import { IOrganisation } from "~/store/constants/organisation";
import actions from "../../store/actions";
import { DataTableSortMeta } from "primereact/datatable";
import { useGlobalContext } from "~/contexts/GlobalContext";
import { usePermissionContext } from "~/contexts/PermissionContext";
import { useProjectContext } from "~/contexts/ProjectContext";
import { useEntityContext } from "~/contexts/EntityContext";
import useOrganisations from "~/hooks/useOrganisations";

export const FLOW_STATUS = {
  EXISTING: "EXISTING",
  NEW: "NEW",
  NEW_PROJECT_NEW_BRIEF: "NEW_PROJECT_NEW_BRIEF",
};

const defaultSort: DataTableSortMeta = {
  field: "name",
  order: 1,
};

const radioBriefOptions = [
  { name: "Existing Brief", code: FLOW_STATUS.EXISTING },
  { name: "New Brief", code: FLOW_STATUS.NEW },
];

const radioProjectOptions = [
  { name: "Existing Project", code: FLOW_STATUS.EXISTING },
  { name: "New Project", code: FLOW_STATUS.NEW },
];

interface IProps {
  formik: FormikProps<any>;
  preSelectedTalents: ITalent[];
  newTalentStatus?: string;
}

export default function DirectMatchProjectBriefForm(props: IProps) {
  const dispatch = useDispatch();

  const { formik, preSelectedTalents, newTalentStatus } = props;

  const {
    global: { optionsNormalization },
  } = useGlobalContext();
  const { isAdmin, isRegionalAdmin, isOrganisationAdmin } = usePermissionContext();
  const {
    organisationProjects,
    setOrganisationProjects,
    isFetching: isProjectsFetching,
    listOrganisationProjects,
    listProjectBriefs,
    projectBriefs,
    setProjectBriefs,
    isFetchingProjectBriefs,
    isProposalFetching,
  } = useProjectContext();
  const {
    programLeads,
    clientLeads,
    clientPods,
    setClientPods,
    podClients,
    setPodClients,
    getLeads,
    getClientPods,
    getPodClients,
    isFetching,
  } = useEntityContext();

  const talents = useSelector((state: IState) => state.talent?.talents);
  const { organisations: storeOrganisations, isOrganisationsLoading } = useOrganisations();
  const { organisation, isFetching: isOrgFetching } = useSelector((state: IState) => state.organisation);
  const organisations = isAdmin || isRegionalAdmin ? storeOrganisations : organisation ? [organisation] : [];

  const isTalentsFetching = useSelector((state: IState) => state.talent.isFetching);
  const authenticatedUser = useSelector((state: IState) => state.user.authenticatedUser);

  const [selectedClientPod, setSelectedClientPod] = useState<string | undefined>(undefined);

  const isNewBrief = formik.values.brief_flow === FLOW_STATUS.NEW;
  const isNewProject = formik.values.project_flow === FLOW_STATUS.NEW;
  const isNewProjectNewBrief = formik.values.project_brief_flow === FLOW_STATUS.NEW_PROJECT_NEW_BRIEF;
  const organisationId = formik.values.organisation_id;

  const DURATION_ID = optionsNormalization?.DURATION_ID;

  useEffect(() => {
    preSelectedTalents.length > 1 && dispatch(actions.talent.listTalents(defaultSort, { matchable: true }));

    if (isAdmin) {
      dispatch(actions.organisation.listOrganisations({ published: 1 }));
    }
  }, []);

  useEffect(() => {
    if (!!organisationId) {
      listOrganisationProjects(organisationId);
      isNewBrief && dispatch(actions.organisation.getOrganisation(organisationId));
    }
  }, [organisationId]);

  useEffect(() => {
    formik.setFieldValue("brief_options", projectBriefs);
    if (!!formik.values?.brief_id) {
      const ifSelectedBriefInList = projectBriefs.find((brief) => brief.id === formik.values.brief_id);

      if (!ifSelectedBriefInList) {
        formik.setFieldValue("brief_id", null);
      }
    }
  }, [projectBriefs]);

  useEffect(() => {
    if (!!organisationId && !!formik.values.project_id) {
      listProjectBriefs(formik.values.project_id, formik.values.talent_ids);
    } else {
      formik.setFieldValue("brief_id", null);

      setProjectBriefs([]);
    }
  }, [organisationId, formik.values.project_id, formik.values.talent_ids]);

  useEffect(() => {
    if (authenticatedUser?.id && authenticatedUser?.organisation_id && isOrganisationAdmin) {
      formik.setFieldValue("organisation_id", authenticatedUser?.organisation_id);
      formik.setFieldValue("author_id", authenticatedUser?.id);
    }
  }, [authenticatedUser, formik.values?.brief_flow, formik.values?.project_flow]);

  useEffect(() => {
    const orgId = isNewProject && !isAdmin && !isRegionalAdmin && organisationId ? organisationId : null;

    if (!!orgId) {
      dispatch(actions.organisation.getOrganisation(orgId));
    }
    if (isNewProject && !!organisationId) {
      getLeads(organisationId);
      getClientPods(organisationId);
      setPodClients([]);
    }
  }, [organisationId, isNewProject]);

  useEffect(() => {
    if (selectedClientPod && clientPods.length) {
      const clientPodId = clientPods.find((pod) => pod.name === selectedClientPod)?.id;
      clientPodId && getPodClients(clientPodId);
    }
  }, [selectedClientPod]);

  useEffect(() => {
    return () => {
      setClientPods([]);
      setPodClients([]);
    };
  }, []);

  useEffect(() => {
    if (isAdmin) formik.setFieldValue("assignees", []);
    if (!isAdmin && !!organisation && organisation.assignees.some(({ id }) => id === authenticatedUser?.id)) {
      formik.setFieldValue("assignees", [authenticatedUser?.id]);
    }
  }, [organisation]);

  const organisationAssignees = useMemo(
    () => organisation?.assignees.sort((a, b) => (a.id === authenticatedUser?.id ? -1 : 1)),
    [organisation]
  );

  const { organisationUsers, clients } = useMemo(() => {
    const organisation =
      organisationId && organisations?.length
        ? (organisations as IOrganisation[])?.find(({ id }) => id === organisationId)
        : { users: [], clients: [] };

    return {
      organisationUsers: organisation?.users || [],
      clients: organisation?.clients?.filter((c) => !c.client_pod) || [],
    };
  }, [organisationId, organisations]);

  const clientsOptions = useMemo(
    () => (selectedClientPod ? podClients : clients),
    [selectedClientPod, podClients, clients]
  );

  function onUploadDescription([file]: [File], field: string) {
    const errors = validateFile(file, {
      size: 10000000,
      types: ["doc", "docx", "pdf", "xls", "xlsx"],
    });

    if (errors.length) {
      formik.setFieldError(field, errors.join(", "));
      return;
    }

    formik.setFieldValue(field, file);
  }

  function deleteDescription(field: string) {
    formik.setFieldValue(field, null);
  }

  const resetForm = async () => {
    await formik.setValues({
      ...formik.values,
      project_brief_flow: null,
      organisation_id: undefined,
      author_id: undefined,
      brief_id: undefined,
      brief_name: "",
      wbs_code: "",
      job_number: "",
      description: "",
      description_url: "",
      project_id: undefined,
      project_name: "",
      project_custom_id: "",
      project_description: "",
      proposal_url: "",
      program_lead: "",
      client_lead: "",
      client_pod: "",
      client: "",
      project_dates: [],
      ...(!newTalentStatus
        ? {
            capacity: 100,
            start_date: "",
            end_date: "",
            dates: [],
            duration_id: DURATION_ID.CONSEC,
            include_weekends: true,
          }
        : {}),
    });
    setOrganisationProjects([]);
  };

  return (
    <div className="modal-container">
      <div className="left-block">
        <label style={{ fontWeight: "bold", marginBottom: "10px" }}>Talent</label>
        {preSelectedTalents.length > 1 ? (
          <ApMultiselect
            id="talent_ids"
            options={talents}
            optionLabel="name"
            optionValue="id"
            placeholder="Select talent"
            selectedItemsLabel="{0} talent selected"
            optionDisabled={(talent) => talent.matchable !== "100%"}
            filter
            formik={formik}
            noField
            style={{ marginBottom: "30px" }}
            loading={isTalentsFetching}
          />
        ) : (
          <TalentInfoBlock preSelectedTalents={preSelectedTalents} />
        )}
        <div className="p-fluid field field-required">
          <label htmlFor="brief_flow">Add talent to</label>
          <ApRadioGroup
            name="brief_flow"
            options={radioBriefOptions}
            onChange={async (e) => {
              resetForm();
              await formik.setFieldValue("brief_flow", e.value, true);
              await formik.setFieldValue("project_flow", FLOW_STATUS.EXISTING);
              formik.setTouched({});
            }}
          />
        </div>
        {isNewBrief && (
          <div className="p-fluid field field-required">
            <label htmlFor="project_flow">Add new brief to</label>
            <ApRadioGroup
              name="project_flow"
              options={radioProjectOptions}
              onChange={async (e) => {
                resetForm();
                await formik.setFieldValue("project_flow", e.value, true);
                formik.setTouched({});
              }}
            />
          </div>
        )}
      </div>
      <div className="right-block">
        <div className="DirectBriefForm">
          {(isAdmin || isRegionalAdmin) && !isNewProject && (
            <>
              <div className="p-fluid field field-required">
                <label htmlFor="organisation_id">Organisation</label>
                <ApDropdown
                  id="organisation_id"
                  dataKey="id"
                  disabled={!organisations}
                  filter={true}
                  filterBy="name, legal_name"
                  formik={formik}
                  noField
                  options={
                    organisations &&
                    organisations.sort((a, b) => {
                      const nameA = a.name || "";
                      const nameB = b.name || "";
                      return nameA.localeCompare(nameB);
                    })
                  }
                  onChange={({ value }) => {
                    formik?.setValues({
                      ...formik?.values,
                      organisation_id: value,
                      author_id: undefined,
                      brief_id: undefined,
                      project_id: undefined,
                    });
                  }}
                  optionLabel="name"
                  optionValue="id"
                  placeholder="Select organisation"
                  loading={isOrganisationsLoading}
                />
              </div>
              {isNewBrief && (
                <div className="p-fluid field field-required">
                  <label htmlFor="author_id">Brief owner</label>
                  <ApDropdown
                    id="author_id"
                    dataKey="id"
                    formik={formik}
                    noField
                    options={organisationUsers}
                    optionLabel="full_name"
                    optionValue="id"
                    placeholder="Select user"
                    filter={false}
                    disabled={!organisationId}
                  />
                </div>
              )}
            </>
          )}
          {!isNewProject && (
            <div className="p-fluid field field-required">
              <label htmlFor="project_id">Project</label>
              <ApDropdown
                id="project_id"
                dataKey="id"
                disabled={!organisationProjects?.length || !organisationId}
                formik={formik}
                filter={true}
                noField
                options={organisationProjects || []}
                onChange={async ({ value }) => {
                  await formik.setFieldValue("project_id", value);
                  formik.setFieldValue("project_name", organisationProjects?.find((p) => p.id === value)?.name || "");
                }}
                optionLabel="name"
                optionValue="id"
                placeholder="Select Project"
                loading={isProjectsFetching}
              />
            </div>
          )}
          {isNewProjectNewBrief && isNewProject && (
            <div className="p-fluid field field-required">
              <label htmlFor="project_id">Project</label>
              <div className="readonly-text">{formik.values.project_name}</div>
            </div>
          )}
          {!isNewBrief && (
            <div className="p-fluid field field-required">
              <label htmlFor="brief_id">Brief</label>
              <ApDropdown
                id="brief_id"
                dataKey="id"
                disabled={!formik.values.project_id}
                formik={formik}
                filter={true}
                noField
                options={projectBriefs || []}
                onChange={async ({ value }) => {
                  await formik.setFieldValue("brief_id", value);
                  formik.setFieldValue("brief_name", projectBriefs?.find((p) => p.id === value)?.name || "");
                }}
                optionLabel="name"
                optionValue="id"
                emptyMessage="No briefs found for selected project and talent(s)"
                placeholder="Select brief"
                loading={isFetchingProjectBriefs}
              />
            </div>
          )}
          {((isNewBrief && !isNewProject && formik.values.project_id) || (isNewBrief && isNewProjectNewBrief)) && (
            <>
              <div className="p-fluid field field-required">
                <label htmlFor="brief_name">Brief name</label>
                <ApInputText formik={formik} id="brief_name" noField />
              </div>
              <div className="wbs-wrapper">
                <div className="p-fluid field">
                  <label htmlFor="wbs_code">WBS code</label>
                  <ApInputText formik={formik} id="wbs_code" noField maxLength={255} />
                </div>
                <div className="p-fluid field">
                  <label htmlFor="job_number">Job number</label>
                  <ApInputText formik={formik} id="job_number" noField maxLength={255} />
                </div>
              </div>
              <div className="p-fluid field field-required">
                <Description
                  formik={formik}
                  onChangeFile={(e) => onUploadDescription(e, "description_url")}
                  onDeleteFile={() => deleteDescription("description_url")}
                />
              </div>
              <div className="p-fluid field field-required">
                <label htmlFor="assignees">Brief Assignee</label>
                <ApMultiselect
                  id="assignees"
                  filter
                  options={organisationAssignees}
                  itemTemplate={(item) => (
                    <div>{item.full_name + (item?.published === false ? " (DEACTIVATED)" : "")}</div>
                  )}
                  optionLabel="full_name"
                  optionValue="id"
                  placeholder="Select brief assignee(s)"
                  noField
                  formik={formik}
                  loading={isOrgFetching}
                />
              </div>
              {!newTalentStatus && <BriefDatesForm formik={formik} />}
            </>
          )}
          {isNewProject && !isNewProjectNewBrief && (
            <>
              <div className="formgrid grid">
                <div className="col field field-required">
                  <ApInputText id="project_name" label="Project Name" />
                </div>
                <div className="col field">
                  <ApInputText id="project_custom_id" label="Project ID" />
                </div>
              </div>
              <div className={cn("p-fluid field mb-5", !formik.values.proposal_url && "field-required")}>
                <ApInputTextarea id="project_description" label="Project Proposal" maxChars={1000} />
              </div>
              <div className="p-fluid field">
                {isProposalFetching ? (
                  <Loader />
                ) : (
                  <ApFileUpload
                    id="proposal_url"
                    formik={formik}
                    noField
                    mode="basic"
                    auto
                    accept=".doc,.docx,.pdf,.xls,.xlsx"
                    onChange={(e) => onUploadDescription(e, "proposal_url")}
                    buttonLabel="Upload proposal"
                    onDelete={() => deleteDescription("proposal_url")}
                  />
                )}
              </div>
              {(isAdmin || isRegionalAdmin) && (
                <div className="formgrid grid">
                  <div className="col field field-required">
                    <ApDropdown
                      id="organisation_id"
                      label="Organisation"
                      options={organisations}
                      optionLabel="name"
                      optionValue="id"
                      loading={isOrganisationsLoading}
                      onChange={({ value }) => {
                        setSelectedClientPod(undefined);
                        formik?.setValues({
                          ...formik?.values,
                          organisation_id: value,
                          author_id: undefined,
                          program_lead: undefined,
                          client_lead: undefined,
                          client_pod: undefined,
                          client: undefined,
                        });
                      }}
                    />
                  </div>
                  <div className="col field field-required">
                    <ApDropdown
                      id="author_id"
                      label="Project Owner"
                      options={organisationUsers}
                      optionLabel="full_name"
                      optionValue="id"
                    />
                  </div>
                </div>
              )}
              <div className="formgrid grid">
                <div className="col field">
                  <ApDropdown
                    editable
                    id="program_lead"
                    label="Program Lead"
                    options={programLeads}
                    optionLabel="name"
                    optionValue="name"
                    loading={isFetching.leads}
                  />
                </div>
                <div className="col field">
                  <ApDropdown
                    editable
                    id="client_lead"
                    label="Client Lead"
                    options={clientLeads}
                    optionLabel="name"
                    optionValue="name"
                    loading={isFetching.leads}
                  />
                </div>
              </div>
              <div className="formgrid grid">
                <div className="col field">
                  <ApDropdown
                    editable
                    panelClassName="no-transform"
                    id="client_pod"
                    label="Client Group"
                    options={clientPods}
                    optionLabel="name"
                    optionValue="name"
                    onChange={({ value }) => {
                      setSelectedClientPod(value);
                      formik?.setFieldValue("client_pod", value);
                      formik?.setFieldValue("client", undefined);
                    }}
                    loading={isFetching.clientPods}
                  />
                </div>
                <div className="col field field-required">
                  <ApDropdown
                    panelClassName="no-transform"
                    editable
                    id="client"
                    label="Client Name"
                    options={clientsOptions}
                    optionLabel="name"
                    optionValue="name"
                    loading={isFetching.podClients}
                  />
                </div>
              </div>
              <div className="p-fluid field field-required">
                <ApCalendar id="project_dates" label="Project Dates" selectionMode="range" readOnlyInput />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
