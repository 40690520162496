import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../store/actions";
import "./LoginForm.scss";
import { Link, RouteComponentProps, withRouter, useLocation } from "react-router-dom";
import ThemedButton from "../../components/common/ThemedButton/ThemedButton";
import Loader from "../../components/Loader/Loader";
import { IState } from "../../store/reducers/index";
import { useFormik } from "formik";
import ApInputText from "../../components/common/ApInputText/ApInputText";
import { LoginSchema } from "../../schemas/user";
import { Button } from "primereact/button";
import GoogleLogo from "../../assets/google-logo.svg";
import Office365Logo from "../../assets/office-365-logo.svg";
import { SSOConnections, BACKEND_URL } from "~/config";
import { AxiosError, AxiosResponse } from "axios";

function LoginForm(props: RouteComponentProps) {
  const dispatch = useDispatch();
  const { search, pathname } = useLocation();

  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const { isAuthenticating, authenticatedUser } = useSelector((state: IState) => state.user);

  const errors: { [k: string]: JSX.Element } = {
    WRONG_CREDENTIALS: <>Computer says "no" - Is this the right email/pass combo?</>,
  };

  useEffect(() => {
    if (pathname === "/sso" && !!search.length) {
      handleLogin();
    }
  }, [pathname, search]);

  useEffect(() => {
    if (authenticatedUser?.token) {
      props.history.push("/");
    }
  }, [authenticatedUser?.token]);

  const savePermissions = (res: { data: { response: { user: { permissions: string[] } } } }) => {
    const { permissions } = res.data.response.user;
    localStorage.setItem("permissions", permissions.join());
  };

  const handleLogin = (form?: { email: string; password: string }) => {
    const loginParams = form ? form : search;
    const loginType = form ? "FORM" : "SSO";

    dispatch(actions.user.loginUser(loginParams, loginType))
      .then((res: AxiosResponse) => {
        if (res?.status === 200) {
          savePermissions(res);
          if (res.data?.response.user.must_change_password) {
            return props.history.push("/change-password");
          }
          props.history.push((props.location.state as { referrer?: string })?.referrer ?? "/");
          dispatch(actions.user.verifyToken());
        }
      })
      .catch((err: AxiosError) => {
        setError(err?.response?.data?.result || "Unknown error");
      });
  };

  const changeShowPassword = () => setShowPassword((prevState) => !prevState);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: LoginSchema,
    onSubmit: handleLogin,
  });

  const onSSOLoginClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, connection: SSOConnections) => {
    e.preventDefault();
    window.open(`${BACKEND_URL}/login?connection=${connection}`, "_self");
  };

  return (
    <form className="LoginForm form" onSubmit={formik.handleSubmit}>
      <div className="ThemedInput">
        <ApInputText
          formik={formik}
          id="email"
          noField
          placeholder="Email"
          isError={formik.touched.hasOwnProperty("email") && formik.errors.hasOwnProperty("email")}
        />
      </div>
      <div className="ThemedInput">
        <ApInputText
          formik={formik}
          id="password"
          noField
          placeholder="Password"
          type={showPassword ? "text" : "password"}
          isError={formik.touched.hasOwnProperty("password") && formik.errors.hasOwnProperty("password")}
          rightElement={
            <button
              className="eye"
              type="button"
              onTouchStart={changeShowPassword}
              onTouchEnd={changeShowPassword}
              onMouseDown={changeShowPassword}
              onMouseUp={changeShowPassword}
              onBlur={() => setShowPassword(false)}
            >
              <i className="icon-eye" />
            </button>
          }
        />
      </div>
      <ThemedButton className="submit" disabled={isAuthenticating || (formik.touched && !formik.isValid)} type="submit">
        {isAuthenticating ? <Loader /> : "Sign In"}
      </ThemedButton>
      {error && <span className="error-msg">{errors[error] || "Unknown error occurred."}</span>}
      <div className="or-divider">
        <span className="or-text">OR</span>
      </div>
      <Button
        className="submit social-button"
        disabled={isAuthenticating}
        onClick={(e) => onSSOLoginClick(e, SSOConnections.GOOGLE)}
      >
        <img src={GoogleLogo} alt="Google Logo" />
        Sign In with Google
      </Button>
      <Button
        className="submit social-button"
        disabled={isAuthenticating}
        onClick={(e) => onSSOLoginClick(e, SSOConnections.OFFICE365)}
      >
        <img src={Office365Logo} alt="Office 365 Logo" />
        Sign In with Office 365
      </Button>
      <Link to="/reset_password">Forgot Password?</Link>
    </form>
  );
}

export default withRouter(LoginForm);
