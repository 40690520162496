import React from "react";
import { FormikContextType } from "formik";
import ApInputTextarea from "~/components/common/ApInputTextarea/ApInputTextarea";
import "../../CreateBriefForm/CreateBriefForm.scss";
import ApFileUpload from "~/components/common/ApFileUpload/ApFileUpload";
import Loader from "~/components/Loader/Loader";
import cn from "classnames";

interface IDescription {
  formik: FormikContextType<any>;
  onChangeFile: (e: any) => void;
  onDeleteFile: () => void;
  loading?: boolean;
}

export default function Description(props: IDescription) {
  const { formik } = props;

  return (
    <>
      <div className={cn("p-fluid field role-description", !formik.values.description_url && "field-required")}>
        <label htmlFor="description">Brief summary</label>
        <ApInputTextarea formik={formik} id="description" noField maxChars={1000} />
      </div>
      <div className="p-fluid field mt-5 mb-5">
        {props.loading ? (
          <Loader />
        ) : (
          <ApFileUpload
            formik={formik}
            noField
            id="description_url"
            mode="basic"
            auto
            accept=".doc,.docx,.pdf,.xls,.xlsx"
            onChange={(e) => props.onChangeFile(e)}
            buttonLabel={"Upload brief"}
            onDelete={props.onDeleteFile}
          />
        )}
      </div>
    </>
  );
}
