import React, { useEffect } from "react";
import { InputTextarea } from "primereact/inputtextarea";

export default function DeclineReasonForm(form: any) {
  const declineReasonValue = form.props.values.decline_reason;

  useEffect(() => {
    const isValid = !declineReasonValue?.length || declineReasonValue?.length <= form.maxChars;
    form.props.setIsValid(isValid);
  }, [declineReasonValue]);

  return (
    <>
      <div className="p-fluid field" style={{ marginBottom: "60px" }}>
        <InputTextarea
          style={{ minHeight: "150px" }}
          onChange={(e: any) => form.props.setValues({ decline_reason: e.target.value })}
        />
        {form.maxChars && (
          <div className="charCount text-right">
            {(declineReasonValue || "").length < form.maxChars ? form.maxChars - (declineReasonValue || "").length : 0}{" "}
            characters left
          </div>
        )}
        {declineReasonValue && declineReasonValue.length > form.maxChars && (
          <div className="ap-error">Please, make it shorter than {form.maxChars} symbols</div>
        )}
      </div>
    </>
  );
}
