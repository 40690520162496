import isNull from "lodash/isNull";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { Menu } from "primereact/menu";
import { MultiSelect } from "primereact/multiselect";
import { Paginator, PaginatorPageChangeEvent } from "primereact/paginator";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import useDebounce from "~/components/common/debounce";
import { useBriefContext } from "~/contexts/BriefContext";
import { usePermissionContext } from "~/contexts/PermissionContext";
import { BriefStatuses, IBrief } from "~/interfaces/brief";
import { checkBriefForDraft } from "~/routes/Brief/helper";
import actions from "~/store/actions";
import toasts from "~/store/actions/toasts";
import { IState } from "~/store/reducers/index";
import DisciplinesDropdown, { ALL_DISCIPLINES } from "../DisciplinesDropdown/DisciplinesDropdown";
import "./BriefsTabs.scss";
import { IColumn } from "./columns";
import { Badge } from "primereact/badge";

interface Props {
  hideTools?: boolean;
  className: string;
  search?: string;
  organisationId?: string | number;
  projectId?: string | number;
}

export default function BriefsTabs(props: Props) {
  const {
    columns,
    columnsOptions,
    listBriefs,
    isFetching: loading,
    briefs,
    briefsCategories,
    briefsCategory,
    setBriefsCategory,
    discipline,
    setDiscipline,
    selectedColumns,
    setSelectedColumns,
    briefType,
    sort,
    setSort,
    resetFilterBriefsSettings,
    deleteBrief,
    defaultCategoryIndex,
  } = useBriefContext();

  const { isAdmin } = usePermissionContext();
  const { search } = props;
  const [perPage, setPerPage] = useState(20);
  const [page, setPage] = useState(0);

  const locationState = useSelector((state: IState) => state.location.state) as { briefsCategoryIndex: number };

  const sortFunc = sortBackend();
  const debouncedSearch = useDebounce(search, 1000);

  const menuRefs = useRef<(Menu | null)[]>([]);
  const dispatch = useDispatch();

  const setOrderedColumns = (selectedColumns: { field: string; header: string }[]) => {
    const orderedSelectedColumns = columnsOptions.filter((col) =>
      selectedColumns.some((sCol: { field: string; header: string }) => sCol.field === col.field)
    );
    setSelectedColumns(orderedSelectedColumns);
    const columnFieldsArray = Object.keys(orderedSelectedColumns).map((key) => orderedSelectedColumns[key].field);
    localStorage.setItem("BRIEF_COLUMNS", columnFieldsArray.join());
  };

  useEffect(() => {
    return () => {
      props.organisationId && resetFilterBriefsSettings("/organisations");
    };
  }, []);

  useEffect(() => {
    isNull(briefsCategory) &&
      setBriefsCategory(briefsCategories[locationState?.briefsCategoryIndex ?? defaultCategoryIndex]);
    window.history.replaceState({}, document.title);
  }, [locationState?.briefsCategoryIndex]);

  useEffect(() => {
    if (!isNull(briefsCategory)) {
      getBriefs({ page });
    }
  }, [briefsCategory, debouncedSearch, sort, discipline, briefType, page, perPage]);

  useEffect(() => {
    setOrderedColumns(selectedColumns);
  }, [briefsCategory]);

  const customColumns = selectedColumns.map((sCol: IColumn) => {
    const { field, header, props } = columns.find((col) => col.field === sCol.field) as IColumn;
    return <Column key={field} field={field} header={header} {...props} />;
  });

  function sortBackend() {
    let sorting = false;
    function askSort(e: { sortField: any; sortOrder: any }) {
      if (!sorting) {
        sorting = true;
        setSort({
          field: e.sortField,
          order: e.sortOrder,
        });
      }
      return 0;
    }
    return askSort;
  }
  function changeBriefsCategory(e: DropdownChangeEvent) {
    if (!isNull(e.value)) {
      setBriefsCategory(e.value);
      setPage(0);
    }
  }

  function renderColumnName(rowData: IBrief) {
    let rebooked = null;
    const link = checkBriefForDraft(rowData) ? `/briefs/draft/${rowData?.id}` : `/briefs/${rowData?.id}`;

    switch (rowData.type) {
      case "REBOOK":
        rebooked = <span className="pi pi-user rebook mr-2" title="Go Direct" />;
        break;
      case "AVAILABILITY":
        rebooked = <span className="pi pi-users mr-2" />;
        break;
      default:
    }
    return (
      <strong>
        {rebooked}
        <Link to={link}>{rowData?.name}</Link>
        {rowData?.notifications > 0 && <Badge value={rowData.notifications} severity="danger" />}
      </strong>
    );
  }

  function renderColumnOrganisation(rowData: IBrief) {
    return <Link to={`/organisations/${rowData?.organisation_id}`}>{rowData?.organisation_name}</Link>;
  }

  function renderColumnStatus(rowData: IBrief) {
    let colorClass;

    switch (rowData.status) {
      case BriefStatuses.OPEN:
        colorClass = "requested";
        break;
      case BriefStatuses.RESOURCED:
        colorClass = "accepted";
        break;
      case BriefStatuses.CANCELLED:
        colorClass = "declined";
        break;
      case BriefStatuses.DRAFT:
      default:
        colorClass = "pending";
    }

    return (
      <span className={`matching-status ${colorClass}`} title={rowData.status}>
        {rowData.status}
      </span>
    );
  }

  function onPaginatorChange(e: PaginatorPageChangeEvent) {
    setPerPage(e.rows);
    setPage(e.page);
  }

  function getBriefs({ page = 0 }: { page: number }) {
    !isNull(briefsCategory) &&
      listBriefs(briefsCategory.code, page, perPage, search, sort, {
        ...(props.organisationId && { organisation_id: props.organisationId }),
        ...(props.projectId && { project_id: props.projectId }),
        ...(discipline.code && { discipline: discipline.code }),
        ...(briefType.code && { contract_code: briefType.code }),
      });
  }

  function renderBriefActions(rowData: IBrief, { rowIndex }: { rowIndex: number }) {
    return rowData.status === BriefStatuses.DRAFT ? (
      <div className="brief-actions">
        <Button icon="pi pi-ellipsis-h" onClick={(event) => (menuRefs.current[rowIndex] as Menu).toggle(event)} />
        <Menu
          model={[
            {
              label: "Delete Draft",
              command: () => {
                dispatch(
                  toasts.setPopup({
                    content: (
                      <>
                        Are you sure you want to cancel this draft brief? Once cancelled it will be deleted permanently
                        from your drafts.
                      </>
                    ),
                    buttons: [
                      {
                        text: "Go back",
                        callback: () => dispatch(actions.modal.closeModal()),
                      },
                      {
                        text: "Delete Draft",
                        callback: () => deleteBrief(rowData.id),
                      },
                    ],
                  })
                );
              },
            },
          ]}
          popup
          ref={(el) => (menuRefs.current[rowIndex] = el)}
          className="brief-action-buttons"
        />
      </div>
    ) : null;
  }

  function renderMultiSelect() {
    return (
      <MultiSelect
        id="columns"
        className="columnsSelect"
        value={selectedColumns}
        optionLabel="header"
        options={columnsOptions}
        onChange={({ value: selectedColumns }) => setOrderedColumns(selectedColumns)}
        dropdownIcon="pi pi-plus"
      />
    );
  }

  return (
    <div className={props.className}>
      {!props.hideTools && (
        <div className="briefTabsTools">
          <div>
            <Dropdown
              className="categorySelector"
              value={briefsCategory}
              options={briefsCategories}
              onChange={changeBriefsCategory}
              optionLabel="label"
              scrollHeight="300"
            />
            <DisciplinesDropdown
              discipline={discipline}
              setDiscipline={setDiscipline}
              setPage={setPage}
              className="ml-3"
            />
          </div>
        </div>
      )}
      <DataTable
        emptyMessage="No briefs found"
        globalFilter={search}
        loading={loading}
        lazy={true}
        value={(briefs?.data ?? []) as any[]}
        sortOrder={sort.order}
        sortField={sort.field}
        onSort={sortFunc}
      >
        <Column body={renderColumnName} field="name" header="Resource Brief Name" sortable={true} />
        <Column body={renderColumnStatus} field="status" header="Status" sortable={true} />
        {isAdmin && !window.location.pathname.includes("/organisations/") && (
          <Column body={renderColumnOrganisation} field="organisation_name" header="Organisation" sortable={true} />
        )}
        {customColumns}
        <Column field="actions" body={renderBriefActions} header={renderMultiSelect} className="more-columns" />
      </DataTable>
      {briefs?.total > briefs?.per_page && (
        <Paginator
          rows={perPage}
          totalRecords={briefs?.total}
          first={(briefs?.current_page - 1) * briefs?.per_page}
          rowsPerPageOptions={[20, 50, 100, 200]}
          onPageChange={onPaginatorChange}
        />
      )}
    </div>
  );
}
