@import "../../../mixins.scss";

.clipboardText {
  cursor: pointer;
  position: relative;
  .in-clipboard {
    animation: fadeInClipboard 5s;
    color: $brandColor;
    margin-left: 15px;
    position: absolute;
    &.topHint {
      right: 0;
      top: -1.75em;
    }
  }
}

@keyframes fadeInClipboard {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
