import { Field, FieldInputProps, FieldMetaProps } from "formik";
import cn from "classnames";
import React, { useCallback, useMemo } from "react";
import { PhoneInput, defaultCountries } from "react-international-phone";
import "react-international-phone/build/index.css";
import "./ApPhoneInput.scss";
import some from "lodash/some";

interface ApPhoneInput {
  noField?: boolean;
  formik?: any;
  id: string;
  label?: string;
  inputClassName?: string;
  disabled?: boolean;
  validate?: Function;
  value?: string;
  isError?: boolean;
  onChange?: Function;
  className?: string;
  inputRef?: any;
  defaultCountry?: string;
}

export default function ApPhoneInput(props: ApPhoneInput) {
  let meta, field;
  if (props.noField && props.formik) {
    meta = props.formik.getFieldMeta(props.id);
    field = props.formik.getFieldProps(props.id);
  }

  const isDefaultCountryExists = useMemo(
    () =>
      (props?.defaultCountry && some(defaultCountries, (o) => o[1] === props?.defaultCountry?.toLowerCase())) || false,
    [props?.defaultCountry]
  );
  const defaultCountry = isDefaultCountryExists ? props.defaultCountry?.toLowerCase() : "gb";

  const renderInput = (field: FieldInputProps<any>, meta: FieldMetaProps<any>) => {
    return (
      <>
        {props.label && <label htmlFor={props.id}>{props.label}</label>}
        <div>
          <PhoneInput
            disableDialCodeAndPrefix
            showDisabledDialCodeAndPrefix
            inputClassName={cn(props.inputClassName)}
            defaultCountry={defaultCountry}
            inputProps={{ id: props.id }}
            ref={props.inputRef}
            {...field}
            disabled={props.disabled}
            value={props.value || field.value || ""}
            onChange={(value: string, meta) => {
              if (typeof props.onChange !== "undefined") {
                props.onChange(value, meta);
              }

              field.onChange({ target: { value, name: props.id } });
            }}
            className={cn("ApPhoneInput", props.isError && "error", props.className)}
          />
          {meta.touched && meta.error && <div className="ap-error">{meta.error}</div>}
        </div>
      </>
    );
  };

  return props.noField && meta ? (
    renderInput(field, meta)
  ) : (
    <Field name={props.id} validate={props.validate}>
      {({ field, meta }: { field: FieldInputProps<any>; meta: FieldMetaProps<any> }) => renderInput(field, meta)}
    </Field>
  );
}
