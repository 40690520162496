// ------------------------------------
// Constants
// ------------------------------------
export const MODAL_HIDE = "MODAL_HIDE";
export const MODAL_SHOW = "MODAL_SHOW";

// ------------------------------------
// Action Creators
// ------------------------------------
export default {
  modalHide: () => ({
    type: MODAL_HIDE,
  }),
  modalShow: (modalType: string, modalProps: object) => ({
    type: MODAL_SHOW,
    payload: { modalType, modalProps },
  }),
};
