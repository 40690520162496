import Axios, { GenericAbortSignal } from "axios";
import { API_URL } from "~/config";
import { IClearanceStatus } from "~/interfaces/clearance";
import { IQueryParams } from "~/interfaces/common";

export class ClearanceService {
  static async getClearances(params: IQueryParams, controller: { signal: GenericAbortSignal }) {
    return await Axios.get(`${API_URL}/clearances`, { params, signal: controller?.signal });
  }
  static async setClearanceStatus(id: number, status: IClearanceStatus, params: any) {
    return await Axios.put(`${API_URL}/clearances/${id}`, { ...params, status });
  }
  static async getMatchClearances(matchId: number, controller: { signal: GenericAbortSignal }) {
    return await Axios.get(`${API_URL}/match/${matchId}/clearances`, { signal: controller?.signal });
  }
}
