import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ProjectService } from "~/API/ProjectService";
import { checkProjectForDraft, draftProjectValues } from "~/components/common/ProjectsTab/helpers";
import { DEFAULT_PAGINATED_STATE } from "~/constants";
import { IDefaultPaginatedState, IQueryParams } from "~/interfaces/common";
import { IProject, IProjectPayload, IProjectProposalFile, IProjectState } from "~/interfaces/project";
import Toasts, { TOAST_ERROR_MESSAGE, TOAST_SUCCESS_MESSAGE } from "~/store/constants/toasts";
import { IState } from "~/store/reducers/index";
import { useLoadingAnimationContext } from "./../contexts/LoadingAnimationContext";
import { IBrief } from "~/interfaces/brief";
import { IListItem } from "~/interfaces/common";

const useProject = () => {
  const { withLoadingAnimation } = useLoadingAnimationContext();
  const dispatch = useDispatch();
  const history = useHistory();
  const [isFetching, setIsFetching] = useState(false);
  const [isProposalFetching, setIsProposalFetching] = useState(false);
  const [isFetchingProjectBriefs, setIsFetchingProjectBriefs] = useState(false);
  const [project, setProject] = useState(undefined as IProject | undefined);
  const [projects, setProjects] = useState<IDefaultPaginatedState<IProject>>(DEFAULT_PAGINATED_STATE);
  const [organisationProjects, setOrganisationProjects] = useState(undefined as IListItem[] | undefined);
  const [projectBriefs, setProjectBriefs] = useState<IBrief[] | []>([]);

  const token = useSelector((state: IState) => state.user.authenticatedUser?.token);

  let controller: any;
  const createController = () => {
    controller = new AbortController();
  };

  const initialValues = {};

  const additionalProjectValues = {};

  const getProjectFormValues = (additionalValues: any) => ({
    ...additionalValues,
    ...initialValues,
  });

  const createProject = async (data: IProjectPayload) => {
    try {
      setIsFetching(true);
      const res = await ProjectService.createProject(data);

      return res.data;
    } catch (err) {
      dispatch(Toasts.setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
      throw err;
    } finally {
      setIsFetching(false);
    }
  };

  const redirectAfterProjectCreated = (project: IProject) => {
    if (checkProjectForDraft(project)) {
      return history.push({
        pathname: "/projects",
      });
    }
    return history.push(`/projects/${project.id}`);
  };

  const saveOrConfirmCallback = (values: any) => {
    withLoadingAnimation(createProject, values);
  };

  const getProject = async (projectId: number) => {
    if (token) {
      try {
        setIsFetching(true);
        setProject(undefined);
        const res = await ProjectService.getProject(projectId);
        if (checkProjectForDraft(res.data)) {
          setProject(draftProjectValues(res.data));
        } else {
          setProject(res.data);
        }
      } finally {
        setIsFetching(false);
      }
    }
  };

  const listProjects = async (params: IQueryParams) => {
    if (token) {
      try {
        createController();
        setIsFetching(true);
        setProject(undefined);
        const res = await ProjectService.listProjects(params, controller);

        setProjects(res.data);
      } finally {
        setIsFetching(false);
      }
    }
  };

  const listOrganisationProjects = async (organisationId: number) => {
    if (token) {
      try {
        createController();
        setIsFetching(true);
        setOrganisationProjects(undefined);
        const res = await ProjectService.listOrganisationProjects(organisationId, controller);

        setOrganisationProjects(res.data);
      } finally {
        setIsFetching(false);
      }
    }
  };

  const listProjectBriefs = async (projectId: number, talents: number[] = []) => {
    if (token) {
      try {
        createController();
        setIsFetchingProjectBriefs(true);
        const res = await ProjectService.listProjectBriefs(projectId, talents, controller);

        setProjectBriefs(res.data?.data || res.data); // can be paginated or not depending if talents are passed
      } finally {
        setIsFetchingProjectBriefs(false);
      }
    }
  };

  const updateProject = async (
    data: Partial<IProjectPayload>,
    shouldProjectBePublished: boolean = false,
    isProjectDraft: boolean = false
  ) => {
    if (token) {
      try {
        setIsFetching(true);
        let res = await ProjectService.updateProject(data);

        if (shouldProjectBePublished && !isProjectDraft) {
          setProject(undefined);
          return res.data;
        } else if (shouldProjectBePublished && isProjectDraft) {
          history.push({
            pathname: "/resourcing",
            state: { projectsCategoryIndex: 3 },
          });
        } else {
          setProject(res.data);
        }
      } catch (err) {
        dispatch(Toasts.setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
        throw err;
      } finally {
        setIsFetching(false);
      }
    }
  };

  const updateProjectProposal = async (data: IProjectProposalFile) => {
    setIsProposalFetching(true);

    try {
      const res = await ProjectService.updateProjectProposal(data);
      return res.data.proposal_url;
    } catch (err) {
      dispatch(Toasts.setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
    } finally {
      setIsProposalFetching(false);
    }
  };

  const updateDraftProject = (data: Partial<IProjectPayload>) => {
    return updateProject(data, true, true);
  };

  const cancelProject = async (data: { projectId: number; reason_id: number; description?: string }) => {
    try {
      setIsFetching(true);
      const res = await ProjectService.cancelProject(data);
      res?.status === 200 &&
        dispatch(Toasts.setToasts([{ severity: "success", summary: "", detail: TOAST_SUCCESS_MESSAGE }]));
      return res;
    } catch (err) {
      dispatch(Toasts.setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
    } finally {
      setIsFetching(false);
    }
  };

  const deleteProject = async (projectId: number) => {
    try {
      const res = await ProjectService.deleteProject(projectId);

      if (res?.status === 200 || res?.status === 204) {
        dispatch(Toasts.setToasts([{ severity: "success", summary: "", detail: TOAST_SUCCESS_MESSAGE }]));
        projects &&
          setProjects({
            ...projects,
            data: projects?.data?.filter(({ id }) => id !== projectId),
          });
      }
      return res;
    } catch (err) {
      dispatch(Toasts.setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
    }
  };

  const resetProjects = () => {
    setProjects(DEFAULT_PAGINATED_STATE);
    controller && controller.abort();
  };

  return {
    isFetching,
    isProposalFetching,
    isFetchingProjectBriefs,
    project,
    projectBriefs,
    projects,
    organisationProjects,
    setOrganisationProjects,
    additionalProjectValues,
    deleteProject,
    createProject,
    getProject,
    setProject,
    listProjects,
    listProjectBriefs,
    updateProject,
    updateProjectProposal,
    listOrganisationProjects,
    updateDraftProject,
    getProjectFormValues,
    saveOrConfirmCallback,
    redirectAfterProjectCreated,
    cancelProject,
    resetProjects,
    setProjectBriefs,
  } as IProjectState;
};
export default useProject;
