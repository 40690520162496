import { AxiosError, AxiosResponse } from "axios";
import { ICountry } from "~/contexts/GlobalContext";
import { IMatching } from "~/interfaces/brief";
import { IClient } from "~/interfaces/common";
import { ITag } from "~/interfaces/common";
import { ITalentTimetable } from "./organisation";

// ------------------------------------
// Constants
// ------------------------------------

export const TALENTS_ADD_GOTO_FAILURE = "TALENTS_ADD_GOTO_FAILURE";
export const TALENTS_ADD_GOTO_REQUEST = "TALENTS_ADD_GOTO_REQUEST";
export const TALENTS_ADD_GOTO_SUCCESS = "TALENTS_ADD_GOTO_SUCCESS";

export const TALENTS_REMOVE_GOTO_FAILURE = "TALENTS_REMOVE_GOTO_FAILURE";
export const TALENTS_REMOVE_GOTO_REQUEST = "TALENTS_REMOVE_GOTO_REQUEST";
export const TALENTS_REMOVE_GOTO_SUCCESS = "TALENTS_REMOVE_GOTO_SUCCESS";

export const TALENTS_LIST_FAILURE = "TALENTS_LIST_FAILURE";
export const TALENTS_LIST_REQUEST = "TALENTS_LIST_REQUEST";
export const TALENTS_LIST_SUCCESS = "TALENTS_LIST_SUCCESS";

export const TALENTS_LIST_PAGINATED_FAILURE = "TALENTS_LIST_PAGINATED_FAILURE";
export const TALENTS_LIST_PAGINATED_REQUEST = "TALENTS_LIST_PAGINATED_REQUEST";
export const TALENTS_LIST_PAGINATED_SUCCESS = "TALENTS_LIST_PAGINATED_SUCCESS";

export const TALENTS_LIST_SUGGESTIBLE_FAILURE = "TALENTS_LIST_SUGGESTIBLE_FAILURE";
export const TALENTS_LIST_SUGGESTIBLE_REQUEST = "TALENTS_LIST_SUGGESTIBLE_REQUEST";
export const TALENTS_LIST_SUGGESTIBLE_SUCCESS = "TALENTS_LIST_SUGGESTIBLE_SUCCESS";

export const UPDATE_TALENT_PAGINATED_NOTES = "UPDATE_TALENT_PAGINATED_NOTES";

export const TALENT_CREATE_FAILURE = "TALENT_CREATE_FAILURE";
export const TALENT_CREATE_REQUEST = "TALENT_CREATE_REQUEST";
export const TALENT_CREATE_SUCCESS = "TALENT_CREATE_SUCCESS";

export const TALENT_GET_FAILURE = "TALENT_GET_FAILURE";
export const TALENT_GET_REQUEST = "TALENT_GET_REQUEST";
export const TALENT_GET_SUCCESS = "TALENT_GET_SUCCESS";

export const SET_TALENT = "SET_TALENT";

export const TALENT_GET_REBOOK_FAILURE = "TALENT_GET_REBOOK_FAILURE";
export const TALENT_GET_REBOOK_REQUEST = "TALENT_GET_REBOOK_REQUEST";
export const TALENT_GET_REBOOK_SUCCESS = "TALENT_GET_REBOOK_SUCCESS";
export const TALENT_REBOOK_RESET = "TALENT_REBOOK_RESET";

export const TALENT_TIMETABLE_GET_REQUEST = "TALENT_TIMETABLE_GET_REQUEST";
export const TALENT_TIMETABLE_GET_SUCCESS = "TALENT_TIMETABLE_GET_SUCCESS";
export const TALENT_TIMETABLE_GET_FAILURE = "TALENT_TIMETABLE_GET_FAILURE";

export const TALENT_TIMETABLE_UPDATE_REQUEST = "TALENT_TIMETABLE_UPDATE_REQUEST";
export const TALENT_TIMETABLE_UPDATE_SUCCESS = "TALENT_TIMETABLE_UPDATE_SUCCESS";
export const TALENT_TIMETABLE_UPDATE_FAILURE = "TALENT_TIMETABLE_UPDATE_FAILURE";

export const TALENT_SAVE_REBOOK_FAILURE = "TALENT_SAVE_REBOOK_FAILURE";
export const TALENT_SAVE_REBOOK_REQUEST = "TALENT_SAVE_REBOOK_REQUEST";
export const TALENT_SAVE_REBOOK_SUCCESS = "TALENT_SAVE_REBOOK_SUCCESS";

export const TALENT_UPDATE_FAILURE = "TALENT_UPDATE_FAILURE";
export const TALENT_UPDATE_REQUEST = "TALENT_UPDATE_REQUEST";
export const TALENT_UPDATE_SUCCESS = "TALENT_UPDATE_SUCCESS";

export const TALENT_FILE_SAVE_FAILURE = "TALENT_FILE_SAVE_FAILURE";
export const TALENT_FILE_SAVE_REQUEST = "TALENT_FILE_SAVE_REQUEST";
export const TALENT_FILE_SAVE_SUCCESS = "TALENT_FILE_SAVE_SUCCESS";

export const TALENTS_NOTES_FAILURE = "TALENTS_NOTES_FAILURE";
export const TALENTS_NOTES_UPDATE = "TALENTS_NOTES_UPDATE";
export const TALENTS_NOTES_SUCCESS = "TALENTS_NOTES_SUCCESS";

export const TALENTS_LOCK_STATUS_UPDATE = "TALENTS_LOCK_STATUS_UPDATE";
export const TALENTS_LOCK_STATUS_SUCCESS = "TALENTS_LOCK_STATUS_SUCCESS";
export const TALENTS_LOCK_STATUS_FAILURE = "TALENTS_LOCK_STATUS_FAILURE";

export const USERS_UPDATE_TALENT_CV = "USERS_UPDATE_TALENT_CV";

export const UPDATE_TALENT_USER_REQUEST = "UPDATE_TALENT_USER_REQUEST";
export const UPDATE_TALENT_USER_SUCCESS = "UPDATE_TALENT_USER_SUCCESS";
export const UPDATE_TALENT_USER_FAILURE = "UPDATE_TALENT_USER_FAILURE";

export const ADD_TALENT_TAG_REQUEST = "ADD_TALENT_TAG_REQUEST";
export const ADD_TALENT_TAG_SUCCESS = "ADD_TALENT_TAG_SUCCESS";
export const ADD_TALENT_TAG_FAILURE = "ADD_TALENT_TAG_FAILURE";

export const REMOVE_TALENT_TAG_REQUEST = "REMOVE_TALENT_TAG_REQUEST";
export const REMOVE_TALENT_TAG_SUCCESS = "REMOVE_TALENT_TAG_SUCCESS";
export const REMOVE_TALENT_TAG_FAILURE = "REMOVE_TALENT_TAG_FAILURE";

export const UPDATE_TALENTS_PAGINATED_TAGS = "UPDATE_TALENTS_PAGINATED_TAGS";
export const DELETE_TALENTS_PAGINATED_TAGS = "DELETE_TALENTS_PAGINATED_TAGS";

export const TALENT_TOGGLE_FAVOURITE = "TALENT_TOGGLE_FAVOURITE";

// ------------------------------------
// Interfaces
// ------------------------------------

export interface ITalentWorkProfile {
  annual_rate?: number;
  headline: string;
  subhead: string;
  rate: number;
  description: string;
  portfolio_url: string;
  matchable: string;
  cv_url: string;
  image_url: string;
  profile_image_url: string;
}

export interface ITalentPersonalProfile {
  email: string;
  telephone: string;
  timezone: ITalentTimezone;
}

export interface ITalentNotes {
  notes: string;
  rating: number;
  type?: string;
}

interface ICompanyType {
  id: number;
  name: string;
  code: string;
}

interface ITalentPlatforms {
  id: number;
  name: string;
  code: string;
  order_id: number;
}

export interface ITalentSkill {
  id: number;
  name: string;
  order_id?: number;
}

export interface ITalentTimezone {
  zone: string;
  short: string;
  long: string;
}

export enum TalentActivityStatus {
  PUBLISHED = "PUBLISHED",
  UNPUBLISHED = "UNPUBLISHED",
  ARCHIVED = "ARCHIVED",
}

export interface ITalent {
  availability?: number;
  bookings_count?: number;
  organisation_id: number;
  contact_email_1: string;
  discipline_code: string | null;
  id: number;
  favourite: boolean;
  active_talents: number;
  level_code: string | null;
  name: string;
  specialism_code: string | null;
  specialism_name: string | null;
  skills: ITalentSkill[];
  sectors: ITalentSkill[];
  tools: ITalentSkill[];
  client_id: string;
  placed_at: object | string;
  team_code: string;
  user_id: number;
  fb_psid: string | null;
  ge_psid: string | null;
  day_rate: number;
  status: TalentActivityStatus;
  starred: boolean;
  start_from: Date;
  completeness: string;
  matchable?: string;
  work_profile?: ITalentWorkProfile;
  personal_profile?: ITalentPersonalProfile;
  rate?: number;
  rate_string?: string;
  type: TalentType | null;
  note?: ITalentNotes;
  is_blocked?: boolean;
  users: ITalentUser[];
  country: ICountry;
  timezone: ITalentTimezone;
  timetable?: ITalentTimetable;
  company_types: ICompanyType[];
  matches?: IMatching[];
  is_available: boolean;
  platforms: ITalentPlatforms[];
  freeze: boolean;
  freeze_reason?: {
    code: string;
    description: string;
  };
  created_at: string;
  preferences: { [key: string]: 0 | 1 };
  working_status_id: number | null;
  employer: string | null;
  direct_book_ready: boolean;
  tro: { id: number; full_name: string; email: string }[];
  organisation_name?: string;
  clients?: IClient[];
  blocked_clients?: IClient[] | number;
  teams: ITalent[];
  related: ITalent[];
  tags?: ITag[];
  onboarded_by: { id: number; name: string; email: string };
  last_working_date: string;
}

export type ITalentUser = {
  id: number;
  admin_id?: number;
  email: string;
  first_name: string;
  last_name: string;
  full_name: string;
  profile_image_url: string;
  published: boolean;
  talent_id: number;
  talent_name: string;
  roles: {
    id: number;
    name: string;
    display_name: string;
  }[];
  teams: {
    id: number;
    name: string;
  }[];
};

export interface ITalentIndividualUser {
  id: number;
  published: boolean;
  name: string;
  email: string;
}

export interface ITalentAvailability {
  availability: number;
  available_hours: number;
  busy_hours: number;
  date: string;
}

export enum TalentGotoType {
  PERMANENT = "PERMANENT",
  FREELANCE = "FREELANCE",
}

export enum TalentType {
  /* Permanent talents */
  INDIVIDUAL = "INDIVIDUAL",
  TEAM = "TEAM",
  /* Freelance talents */
  FREELANCER = "FREELANCER",
  FREELANCE_TEAM = "FREELANCE_TEAM",
}

// ------------------------------------
// Action Creators
// ------------------------------------
export default {
  addTalentGotoFailure: (err: AxiosError) => ({
    type: TALENTS_ADD_GOTO_FAILURE,
    payload: err?.response?.data,
  }),
  addTalentGotoRequest: (payload: number[]) => ({
    type: TALENTS_ADD_GOTO_REQUEST,
    payload,
  }),
  addTalentGotoSuccess: (res: AxiosResponse) => ({
    type: TALENTS_ADD_GOTO_SUCCESS,
    payload: res.data,
  }),
  removeTalentGotoFailure: (err: AxiosError) => ({
    type: TALENTS_REMOVE_GOTO_FAILURE,
    payload: err?.response?.data,
  }),
  removeTalentGotoRequest: (payload: number[]) => ({
    type: TALENTS_REMOVE_GOTO_REQUEST,
    payload,
  }),
  removeTalentGotoSuccess: (res: AxiosResponse) => ({
    type: TALENTS_REMOVE_GOTO_SUCCESS,
    payload: res.data,
  }),
  listTalentsFailure: (err: AxiosError) => ({
    type: TALENTS_LIST_FAILURE,
    payload: err?.response?.data,
  }),
  listTalentsRequest: () => ({
    type: TALENTS_LIST_REQUEST,
  }),
  listTalentsSuccess: (res: AxiosResponse) => ({
    type: TALENTS_LIST_SUCCESS,
    payload: res.data,
  }),
  listTalentsSuggestibleFailure: (err: AxiosError) => ({
    type: TALENTS_LIST_SUGGESTIBLE_FAILURE,
    payload: err?.response?.data,
  }),
  listTalentsSuggestibleRequest: () => ({
    type: TALENTS_LIST_SUGGESTIBLE_REQUEST,
  }),
  listTalentsSuggestibleSuccess: (res: AxiosResponse) => ({
    type: TALENTS_LIST_SUGGESTIBLE_SUCCESS,
    payload: res.data,
  }),
  listTalentsPaginatedFailure: (err: AxiosError) => ({
    type: TALENTS_LIST_PAGINATED_FAILURE,
    payload: err?.response?.data,
  }),
  listTalentsPaginatedRequest: () => ({
    type: TALENTS_LIST_PAGINATED_REQUEST,
  }),
  listTalentsPaginatedSuccess: (res: AxiosResponse) => ({
    type: TALENTS_LIST_PAGINATED_SUCCESS,
    payload: res.data,
  }),
  updateTalentPaginatedNotes: (data: { id?: number; note: ITalentNotes }) => ({
    type: UPDATE_TALENT_PAGINATED_NOTES,
    payload: data,
  }),
  createTalentFailure: (err: AxiosError) => ({
    type: TALENT_CREATE_FAILURE,
    payload: err?.response?.data,
  }),
  createTalentRequest: () => ({
    type: TALENT_CREATE_REQUEST,
  }),
  createTalentSuccess: () => ({
    type: TALENT_CREATE_SUCCESS,
  }),
  getTalentFailure: (err: AxiosError) => ({
    type: TALENT_GET_FAILURE,
    payload: err?.response?.data,
  }),
  getTalentRequest: () => ({
    type: TALENT_GET_REQUEST,
  }),
  getTalentSuccess: (res: AxiosResponse) => ({
    type: TALENT_GET_SUCCESS,
    payload: res.data,
  }),
  setTalent: (data?: ITalent) => ({
    type: SET_TALENT,
    payload: data,
  }),
  getTalentRebookFailure: (err: AxiosError) => ({
    type: TALENT_GET_REBOOK_FAILURE,
    payload: err?.response?.data,
  }),
  getTalentRebookRequest: () => ({
    type: TALENT_GET_REBOOK_REQUEST,
  }),
  getTalentRebookSuccess: (res: AxiosResponse) => ({
    type: TALENT_GET_REBOOK_SUCCESS,
    payload: res.data,
  }),
  getTalentTimetableRequest: () => ({
    type: TALENT_TIMETABLE_GET_REQUEST,
  }),
  getTalentTimetableSuccess: (res: AxiosResponse) => ({
    type: TALENT_TIMETABLE_GET_SUCCESS,
    payload: res.data,
  }),
  getTalentTimetableFailure: (err: AxiosError) => ({
    type: TALENT_TIMETABLE_GET_FAILURE,
    payload: err?.response?.data,
  }),
  saveTalentRebookFailure: (err: AxiosError) => ({
    type: TALENT_SAVE_REBOOK_FAILURE,
    payload: err?.response?.data,
  }),
  rebookTalentReset: () => ({
    type: TALENT_REBOOK_RESET,
  }),
  saveTalentRebookRequest: () => ({
    type: TALENT_SAVE_REBOOK_REQUEST,
  }),
  saveTalentRebookSuccess: (res: AxiosResponse) => ({
    type: TALENT_SAVE_REBOOK_SUCCESS,
    payload: res.data,
  }),
  updateTalentFailure: (err: AxiosError) => ({
    type: TALENT_UPDATE_FAILURE,
    payload: err?.response?.data,
  }),
  updateTalentRequest: () => ({
    type: TALENT_UPDATE_REQUEST,
  }),
  updateTalentSuccess: (res: AxiosResponse) => ({
    type: TALENT_UPDATE_SUCCESS,
    payload: res.data,
  }),
  updateTalentTimetableRequest: () => ({
    type: TALENT_TIMETABLE_UPDATE_REQUEST,
  }),
  updateTalentTimetableSuccess: (res: AxiosResponse) => ({
    type: TALENT_TIMETABLE_UPDATE_SUCCESS,
    payload: res.data,
  }),
  updateTalentTimetableFailure: (err: AxiosError) => ({
    type: TALENT_TIMETABLE_UPDATE_FAILURE,
    payload: err?.response?.data,
  }),
  saveTalentFileFailure: (err: AxiosError) => ({
    type: TALENT_FILE_SAVE_FAILURE,
    payload: err?.response?.data,
  }),
  saveTalentFileRequest: () => ({
    type: TALENT_FILE_SAVE_REQUEST,
  }),
  saveTalentFileSuccess: (res: AxiosResponse) => ({
    type: TALENT_FILE_SAVE_SUCCESS,
    payload: res.data,
  }),
  updateTalentNotesRequest: () => ({
    type: TALENTS_NOTES_UPDATE,
  }),
  updateTalentNotesSuccess: (res: AxiosResponse) => ({
    type: TALENTS_NOTES_SUCCESS,
    payload: res.data,
  }),
  updateTalentNotesFailure: () => ({
    type: TALENTS_NOTES_FAILURE,
  }),
  updateTalentLockStatusRequest: () => ({
    type: TALENTS_LOCK_STATUS_UPDATE,
  }),
  updateTalentLockStatusSuccess: (res: AxiosResponse) => ({
    type: TALENTS_LOCK_STATUS_SUCCESS,
    payload: res.data,
  }),
  updateTalentLockStatusFailure: () => ({
    type: TALENTS_LOCK_STATUS_FAILURE,
  }),
  updateTalentCVsuccess: (res: any) => ({
    type: USERS_UPDATE_TALENT_CV,
    payload: res,
  }),
  updateTalentUserRequest: () => ({
    type: UPDATE_TALENT_USER_REQUEST,
  }),
  updateTalentUserSuccess: (res: any) => ({
    type: UPDATE_TALENT_USER_SUCCESS,
    payload: res.data,
  }),
  updateTalentUserFailure: () => ({
    type: UPDATE_TALENT_USER_FAILURE,
  }),
  addTalentTagRequest: () => ({
    type: ADD_TALENT_TAG_REQUEST,
  }),
  addTalentTagSuccess: (res: any) => ({
    type: ADD_TALENT_TAG_SUCCESS,
    payload: res,
  }),
  addTalentTagFailure: () => ({
    type: ADD_TALENT_TAG_FAILURE,
  }),
  removeTalentTagRequest: () => ({
    type: REMOVE_TALENT_TAG_REQUEST,
  }),
  removeTalentTagSuccess: (res: any) => ({
    type: REMOVE_TALENT_TAG_SUCCESS,
    payload: res,
  }),
  removeTalentTagFailure: () => ({
    type: REMOVE_TALENT_TAG_FAILURE,
  }),
  toggleFavouriteTalent: (talentId: number) => ({
    type: TALENT_TOGGLE_FAVOURITE,
    payload: talentId,
  }),
  updateTalentsPaginatedTags: (payload: any) => ({
    type: UPDATE_TALENTS_PAGINATED_TAGS,
    payload,
  }),
  deleteTalentsPaginatedTags: (payload: any) => ({
    type: DELETE_TALENTS_PAGINATED_TAGS,
    payload,
  }),
};
