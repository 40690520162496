import Axios from "axios";
import { API_URL } from "~/config";
import { IQueryParams } from "~/interfaces/common";
import { IOrganisationUser } from "~/store/constants/organisation";

export class OrganisationUsersService {
  static getOrganisationUsers = async (organisationId: number) =>
    await Axios.get(`${API_URL}/organisation/${organisationId}/users`);
  static getOrganisationArchivedTalents = async (organisationId: number, params: IQueryParams) =>
    await Axios.get(`${API_URL}/organisation/${organisationId}/archived_talents`, { params });
  static attachUserRole = async (userId: number, roleId: string) => {
    return await Axios.put(`${API_URL}/user/${userId}/roles/${roleId}/attach`);
  };
  static detachUserRole = async (userId: number, roleId: string) => {
    return await Axios.delete(`${API_URL}/user/${userId}/roles/${roleId}/detach`);
  };
  static getRoles = async () => await Axios.get(`${API_URL}/roles_available`);
  static resetCacheRoles = async () => await Axios.get(`${API_URL}/roles/flush`);
  static getUserById = async (userId: number) => await Axios.get(`${API_URL}/user/${userId}`);
  static updateUserById = async (userId: number, data: IOrganisationUser) =>
    await Axios.put(`${API_URL}/user/${userId}`, data);
  static validateEmail = async (email: string) => await Axios.post(`${API_URL}/user/validate_email`, { email });
}
