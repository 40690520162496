import { Dialog } from "primereact/dialog";
import React, { useMemo } from "react";
import { IClearance, IClearanceStatus } from "~/interfaces/clearance";
import "./BriefDetails.scss";
import { useGlobalContext } from "~/contexts/GlobalContext";
import { IBooking } from "~/interfaces/booking";
import { renderBriefDates } from "~/forms/BriefViewDetailsForm/helper";
import { Link } from "react-router-dom";
import { extractFileName } from "~/utils";

type IBriefDetailsProps = {
  data: IClearance | IBooking;
  onHide: any;
  isBookingDetails?: boolean;
};

export default function BriefDetails({
  data: {
    brief_name,
    brief_description,
    brief_description_url,
    capacity,
    clients,
    start_date,
    end_date,
    duration_id,
    duration_days,
    duration_weekends,
    dates,
    comment,
    author: { full_name: authorName },
    talent: { name: talentName },
    tro = [],
    organisation: { name: organisationName },
    decline_reason,
    status,
  },
  onHide,
  isBookingDetails,
}: IBriefDetailsProps) {
  const {
    global: { globalOptions, optionsNormalization },
  } = useGlobalContext();

  const DURATION_ID = optionsNormalization?.DURATION_ID;

  const durationType = useMemo(
    () => globalOptions?.duration_types.find(({ id }: { id: number }) => id === duration_id),
    [duration_id]
  );

  const sum = Number(duration_days) + Number(duration_weekends);
  const sumDisplay = Number.isInteger(sum) ? sum : sum.toFixed(1);

  return (
    <Dialog
      header={`Brief name: ${brief_name}`}
      className="BriefDetailsModal"
      visible={true}
      onHide={onHide}
      style={{ minWidth: "50vw", maxWidth: "50vw" }}
    >
      {brief_description && !isBookingDetails && (
        <p>
          <label>Brief summary:</label> {brief_description}
        </p>
      )}
      {brief_description_url && !isBookingDetails && (
        <p>
          <label>Brief summary file:</label>{" "}
          <a href={brief_description_url} target="_blank">
            {extractFileName(brief_description_url)}
          </a>
        </p>
      )}
      <div className={"columns-2"}>
        {isBookingDetails ? (
          <>
            <p>
              <label>Allocation Author:</label> {authorName}
            </p>
            <p>
              <label>Status:</label> {status === "RESOURCED" ? "ALLOCATED" : status}
            </p>
          </>
        ) : (
          <>
            <p>
              <label>Organisation:</label> {organisationName}
            </p>
            <p>
              {clients.length > 0 && (
                <>
                  <label>Client:</label> {clients.map(({ name }) => name).join(", ")}
                </>
              )}
            </p>
            <p>
              <label>Clearance:</label> {tro.map(({ full_name }) => full_name).join(", ")}
            </p>
            {!!comment && (
              <p>
                <label>Comment:</label> {comment}
              </p>
            )}
            <p>
              <label>Name:</label> {talentName}
            </p>
            <p>
              <label>Requested by:</label> {authorName}
            </p>
          </>
        )}

        {!!capacity && duration_id !== DURATION_ID.EXACT && (
          <p>
            <label>Capacity:</label> {capacity}
          </p>
        )}

        <p>
          <label>Start date:</label> {start_date}
        </p>

        <p>
          <label>End date:</label> {end_date}
        </p>

        {!!durationType && (
          <p>
            <label>Duration type:</label> {durationType.name}
          </p>
        )}
        <p>
          <label>No. of allocated days:</label> {sumDisplay}
        </p>
        {[1, 2].includes(duration_id) && (
          <>
            <p>
              <label>Weekdays:</label> {duration_days}
            </p>
            <p>
              <label>Weekend days:</label> {duration_weekends}
            </p>
          </>
        )}
        {duration_id === DURATION_ID.EXACT && (
          <p>
            <label>Dates:</label>
            {dates.map(({ date, capacity }) => `${date} (${capacity})`).join(", ")}
          </p>
        )}
      </div>
      {status === IClearanceStatus.DECLINED && (
        <p>
          <label>Decline Reason:</label> {decline_reason?.description || "N/A"}
        </p>
      )}
    </Dialog>
  );
}
