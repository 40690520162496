import { FormikProps, FormikValues, useFormikContext } from "formik";
import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import ApMultiselect from "~/components/common/ApMultiselect/ApMultiselect";
import * as permissionType from "~/constants/permissions";
import { usePermissionContext } from "~/contexts/PermissionContext";
import { useProjectContext } from "~/contexts/ProjectContext";
import { useGlobalContext } from "~/contexts/GlobalContext";
import useOrganisations from "~/hooks/useOrganisations";
import ApDropdown from "../../components/common/ApDropdown/ApDropdown";
import ApInputText from "../../components/common/ApInputText/ApInputText";
import ApMultiCheckbox from "../../components/common/ApMultiCheckbox/ApMultiCheckbox";
import actions from "../../store/actions";
import { IState } from "../../store/reducers/index";
import { IUserState } from "../../store/reducers/user";

interface Props {
  editMode?: boolean;
  validate?: FormikProps<FormikValues>;
  formik?: FormikProps<FormikValues>;
  preSelectedOrganisation?: { organisation: number; organisationUser: number };
  handlerLoadComponent?: (formik: FormikProps<FormikValues>) => void;
  handlePrompt?: (isDirty: boolean) => void;
}

export default function BriefNewOverviewForm(props: Props) {
  const {
    global: { TALENT_GOTO_TYPES },
  } = useGlobalContext();
  const { organisationProjects, isFetching: isProjectsFetching, listOrganisationProjects } = useProjectContext();
  const { isAdmin, isRegionalAdmin, isOrganisationAdmin } = usePermissionContext();
  const dispatch = useDispatch();
  const formik = props.formik || useFormikContext();
  const currentUser = useSelector(({ user: { authenticatedUser } }: { user: IUserState }) => authenticatedUser);
  const {
    organisation,
    organisations: allOrganisations,
    isFetchingOrganisationsList: isAllOrganisationsLoading,
  } = useSelector((state: IState) => state.organisation);
  const { organisations, isOrganisationsLoading } = useOrganisations();

  const isOrganisationUsersFetching = useSelector((state: IState) => state.organisation.isFetching);
  const currentOrganisation = useSelector((state: IState) => state.organisation?.organisation);
  const { users: allOrganisationUsers, clients } = currentOrganisation || {};
  const organisationUsers = useMemo(
    () =>
      (allOrganisationUsers || [])
        .filter(({ published }) => published)
        .map((user) => ({
          ...user,
          full_name: `${user.first_name} ${user.last_name}`,
        })),
    [allOrganisationUsers]
  );

  const handlePrefillValues = () => {
    formik.setFieldValue("organisation_id", props?.preSelectedOrganisation?.organisation);
    formik.setFieldValue("author_id", props?.preSelectedOrganisation?.organisationUser);
  };

  useEffect(() => {
    if (
      props?.preSelectedOrganisation &&
      organisations &&
      organisationUsers &&
      (!formik.values.organisation_id || !formik.values.author_id)
    )
      handlePrefillValues();
  }, [organisations, organisationUsers]);

  useEffect(() => {
    props?.handlerLoadComponent && props.handlerLoadComponent(formik);
    dispatch(actions.organisation.listOrganisations({ published: 1 }));
  }, []);

  useEffect(() => {
    !!formik.values?.organisation_id && listOrganisationProjects(formik.values?.organisation_id);
  }, [formik.values?.organisation_id]);

  useEffect(() => {
    // logic not applied to draft briefs (not possible to change organisation)
    if (formik.initialValues.assignees.length) return;
    // reset assignees with organisation change
    if (isAdmin) formik.setFieldValue("assignees", []);
    // set current admin user as brief assignee by default
    if (!isAdmin && !!organisation && organisation.assignees.some(({ id }) => id === currentUser?.id)) {
      formik.setFieldValue("assignees", [currentUser?.id]);
    }
  }, [organisation]);

  useEffect(() => {
    if (organisations && organisations.length === 1 && formik.values.organisation_id === 0) {
      formik.setFieldValue("organisation_id", organisations[0]?.id);
    }
  }, [organisations]);

  useEffect(() => {
    if (currentUser?.id && currentUser?.organisation_id && isOrganisationAdmin) {
      formik.setFieldValue("organisation_id", currentUser?.organisation_id);
      formik.setFieldValue("author_id", currentUser?.id);
    }
  }, [currentUser]);

  useEffect(() => {
    props.handlePrompt && props.handlePrompt(formik.dirty);
  }, [formik.dirty]);

  useEffect(() => {
    if (formik.values?.goto_types?.length === 0) {
      formik.setFieldValue(
        "goto_types",
        TALENT_GOTO_TYPES.map(({ id }: { id: number }) => id)
      );
    }
  }, [TALENT_GOTO_TYPES]);

  useEffect(() => {
    formik.validateForm();
  }, [formik.values?.goto_types]);

  return (
    <div className="BriefOverviewForm">
      <div className="p-fluid">
        <div className="field field-required">
          <label htmlFor="name">Brief name</label>
          <ApInputText formik={formik} id="name" noField />
        </div>
      </div>
      {(isAdmin || isRegionalAdmin) && (
        <div className="p-fluid field field-required">
          <label htmlFor="organisation_id">Organisation name</label>
          <ApDropdown
            id="organisation_id"
            dataKey="id"
            disabled={!organisations || props.editMode || !(isAdmin || isRegionalAdmin)}
            formik={formik}
            filter={true}
            filterBy="name, legal_name"
            noField
            options={organisations}
            optionLabel="name"
            optionValue="id"
            placeholder="Select organisation"
            loading={isOrganisationsLoading}
          />
        </div>
      )}
      <div className="p-fluid field field-required">
        <label htmlFor="author_id">Brief owner</label>
        <ApDropdown
          id="author_id"
          dataKey="id"
          disabled={!organisationUsers || props.editMode || !(isAdmin || isRegionalAdmin)}
          formik={formik}
          noField
          options={organisationUsers}
          optionLabel="full_name"
          optionValue="id"
          placeholder="Select User"
          filter={false}
          loading={isOrganisationUsersFetching}
        />
      </div>
      <div className="p-fluid field field-required">
        <label htmlFor="organisation_id">Project</label>
        <ApDropdown
          id="project_id"
          dataKey="id"
          disabled={!organisationProjects || props.editMode}
          formik={formik}
          filter={true}
          noField
          options={organisationProjects || []}
          optionLabel="name"
          optionValue="id"
          placeholder="Select Project"
          loading={isProjectsFetching}
        />
      </div>
      <div className="p-fluid field">
        <label htmlFor="name">WBS code</label>
        <ApInputText formik={formik} id="wbs_code" noField maxLength={101} />
      </div>
      <div className="p-fluid field">
        <label htmlFor="name">Job number</label>
        <ApInputText formik={formik} id="job_number" noField maxLength={256} />
      </div>
      <div className="p-fluid field field-required">
        <label htmlFor="goto_types">Profile type</label>
        <ApMultiCheckbox formik={formik} id="goto_types" noField allItemsLabel="All" options={TALENT_GOTO_TYPES} />
      </div>
      <div className="p-fluid field field-required">
        <label htmlFor="organisations">Organisations to match from</label>
        <ApMultiselect
          id="organisations"
          filter
          options={allOrganisations}
          optionLabel="name"
          optionValue="id"
          placeholder="Select organisations"
          noField
          formik={formik}
          panelClassName="organisations-multiselect-panel"
          loading={isAllOrganisationsLoading}
        />
      </div>
    </div>
  );
}
