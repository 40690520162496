import { ICommonFilterSettings } from "~/interfaces/common";
import { AnyAction } from "redux";
import * as Organisation from "../constants/organisation";

// ------------------------------------
// Interface
// ------------------------------------
export interface IOrganisationState {
  isFetching: boolean;
  isFetchingTimetable: boolean;
  isFetchingOrganisationsList: boolean;
  isFetchingRelatedList: boolean;
  organisation?: Organisation.IOrganisation;
  organisations?: Organisation.IOrganisation[];
  relatedOrganisations?: Organisation.IOrganisation[];
}

// ------------------------------------
// Initial state
// ------------------------------------
const initialState: IOrganisationState = {
  isFetching: false,
  isFetchingTimetable: false,
  isFetchingOrganisationsList: false,
  isFetchingRelatedList: false,
  organisation: undefined,
  organisations: undefined,
  relatedOrganisations: undefined,
};

export type IOrganisationFilterSettings = {
  resetFilterOrganisationSettings: (currentPath: string | null) => void;
} & ICommonFilterSettings;

// ------------------------------------
// Reducer
// ------------------------------------
export default (state = initialState, action: AnyAction): IOrganisationState => {
  switch (action.type) {
    case Organisation.ORGANISATIONS_CREATE_FAILURE:
    case Organisation.ORGANISATIONS_GET_FAILURE:
    case Organisation.ORGANISATION_USERS_GET_FAILURE:
    case Organisation.ORGANISATIONS_UPDATE_FAILURE:
    case Organisation.ORGANISATIONS_FILE_SAVE_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case Organisation.ORGANISATIONS_LIST_FAILURE:
      return {
        ...state,
        isFetchingOrganisationsList: false,
      };
    case Organisation.ORGANISATIONS_RELATED_LIST_FAILURE:
      return {
        ...state,
        isFetchingRelatedList: false,
      };
    case Organisation.ORGANISATIONS_LIST_REQUEST:
      return {
        ...state,
        isFetchingOrganisationsList: true,
      };
    case Organisation.ORGANISATIONS_RELATED_LIST_REQUEST:
      return {
        ...state,
        isFetchingRelatedList: true,
      };
    case Organisation.ORGANISATIONS_CREATE_REQUEST:
    case Organisation.ORGANISATIONS_GET_REQUEST:
    case Organisation.ORGANISATION_USERS_GET_REQUEST:
    case Organisation.ORGANISATIONS_UPDATE_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case Organisation.ORGANISATIONS_FILE_SAVE_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case Organisation.ORGANISATIONS_CREATE_SUCCESS:
      return {
        ...state,
        isFetching: false,
      };
    case Organisation.ORGANISATIONS_UPDATE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        organisation: action.payload,
        organisations: state.organisations?.map((organisation) =>
          organisation.id === action.payload.id ? action.payload : organisation
        ),
      };
    case Organisation.ORGANISATIONS_GET_SUCCESS:
      return {
        ...state,
        isFetching: false,
        organisation: {
          ...action.payload,
          timetable:
            state.organisation?.timetable?.length &&
            state.organisation?.timetable[0].organisation_id === action.payload.id
              ? state.organisation.timetable
              : [],
        },
      };
    case Organisation.ORGANISATION_USERS_GET_SUCCESS:
      return {
        ...state,
        isFetching: false,
        organisation: {
          ...state.organisation,
          users: action.payload,
        },
      };
    case Organisation.ORGANISATIONS_LIST_SUCCESS:
      return {
        ...state,
        isFetchingOrganisationsList: false,
        organisations: action.payload,
      };
    case Organisation.ORGANISATIONS_RELATED_LIST_SUCCESS:
      return {
        ...state,
        isFetchingRelatedList: false,
        relatedOrganisations: action.payload,
      };
    case Organisation.ORGANISATIONS_FILE_SAVE_SUCCESS:
      return {
        ...state,
        organisation: action.payload,
        isFetching: false,
      };
    case Organisation.ORGANISATION_TIMETABLE_GET_REQUEST:
    case Organisation.ORGANISATION_TIMETABLE_UPDATE_REQUEST:
      return {
        ...state,
        isFetchingTimetable: true,
      };
    case Organisation.ORGANISATION_TIMETABLE_GET_FAILURE:
    case Organisation.ORGANISATION_TIMETABLE_UPDATE_FAILURE:
      return {
        ...state,
        isFetchingTimetable: false,
      };
    case Organisation.ORGANISATION_TIMETABLE_GET_SUCCESS:
    case Organisation.ORGANISATION_TIMETABLE_UPDATE_SUCCESS:
      return {
        ...state,
        isFetchingTimetable: false,
        organisation: {
          ...state.organisation,
          timetable: action.payload,
        },
      };
    default:
      return state;
  }
};
