import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IState } from "~/store/reducers/index";
import Toasts, { TOAST_ERROR_MESSAGE, TOAST_SUCCESS_MESSAGE } from "~/store/constants/toasts";
import { NotificationsService } from "~/API/NotificationsService";

export interface INotificationPreference {
  description: string;
  id: number;
  title: string;
  type: string;
  value: boolean;
}

const useNotifications = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [preferences, setPreferences] = useState<null | INotificationPreference[]>(null);
  const [notificationsCount, setNotificationsCount] = useState<number | null>(null);
  const token = useSelector((state: IState) => state.user?.authenticatedUser?.token);
  const dispatch = useDispatch();

  const getNotificationsPreferences = async () => {
    if (token) {
      try {
        setIsFetching(true);
        const res = await NotificationsService.getPreferences();
        res.status === 200 && setPreferences(res.data);
      } catch (err) {
        dispatch(Toasts.setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
      } finally {
        setIsFetching(false);
      }
    }
  };

  const getNotifications = async () => {
    try {
      setIsFetching(true);
      const res = await NotificationsService.getNotifications();
      res.status === 200 && setNotificationsCount(res.data);
    } catch (err) {
      dispatch(Toasts.setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
    } finally {
      setIsFetching(false);
    }
  };

  const updateNotificationsPreference = async (preference: number, value: boolean) => {
    if (token) {
      try {
        const res = await NotificationsService.updatePreference(preference, value);
        res.status === 200 &&
          dispatch(Toasts.setToasts([{ severity: "success", summary: "", detail: TOAST_SUCCESS_MESSAGE }]));
      } catch (err) {
        dispatch(Toasts.setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
      }
    }
  };

  return {
    isFetching,
    preferences,
    notificationsCount,
    setPreferences,
    getNotifications,
    getNotificationsPreferences,
    updateNotificationsPreference,
  };
};
export default useNotifications;
