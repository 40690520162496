import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import LoginForm from "../../forms/LoginForm/LoginForm";
import "./AuthView.scss";
import { Link } from "react-router-dom";
import { AxiosError } from "axios";
import classNames from "classnames";
import ThemedButton from "../../components/common/ThemedButton/ThemedButton";
import GenieTextLogo from "../../assets/genie-text.svg";
import NewPasswordForm from "../../forms/NewPasswordForm/NewPasswordForm";
import ResetPasswordForm from "../../forms/ResetPasswordForm/ResetPasswordForm";
import actions from "../../store/actions";
import { makeUniqueUrl } from "~/utils";
import { useGlobalContext } from "~/contexts/GlobalContext";

function AuthView(props: RouteComponentProps) {
  const dispatch = useDispatch();
  const {
    global: { publicOptions },
  } = useGlobalContext();

  const [result, setResult] = useState("");

  const { match } = props;
  const token = (props?.match?.params as { token: string })?.token;

  useEffect(() => {
    if (token) {
      dispatch(actions.user.checkResetPasswordToken({ token }))
        .then(() => {
          props.history.push("/change-password");
        })
        .catch((err: AxiosError) => {
          if (err && err.response && err.response.status === 404) {
            setResult("invalid_token");
          }
        });
    }
  }, [token]);

  return (
    <div
      className={classNames("AuthView", {
        change: match.path !== "/login",
      })}
    >
      <div className="panel">
        <div>
          {!!publicOptions?.images?.LOGIN_SCREEN_LOGO && (
            <img src={makeUniqueUrl(publicOptions.images.LOGIN_SCREEN_LOGO)} id="AuthView__Logo" alt="Login Logo" />
          )}
          <div className="powered-by-logo">
            <span>Powered by</span>
            <img src={GenieTextLogo} alt="Genie Logo" />
          </div>
          {result === "invalid_token" ? (
            <form className="form">
              <h1>Change Password</h1>
              <p className="error">
                <i className="icon-error">
                  <span className="path1"></span>
                  <span className="path2"></span>
                </i>
                Oh no, you’re too late! Your link expired 😫
              </p>
              <Link to="/reset_password">
                <ThemedButton className="submit" type="submit">
                  Send me a new link pls
                </ThemedButton>
              </Link>
            </form>
          ) : (
            <>
              {(match.path === "/login" || match.path === "/sso") && <LoginForm />}
              {match.path === "/change-password" && <NewPasswordForm />}
              {match.path === "/reset_password" && <ResetPasswordForm />}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default withRouter(AuthView);
