import Axios from "axios";
import { Store } from "redux";
import { API_URL } from "~/config";
import actions from "./actions";
import { setAuthToken } from "~/utils";

export default {
  setup: (store: Store) => {
    Axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        if (error?.response?.status === 401) {
          try {
            const originalReq = error.config;
            const oldToken = originalReq.headers.Authorization.replace("Bearer ", "");
            if (oldToken && originalReq.url.indexOf("api/refresh") === -1) {
              const res = await Axios.post(`${API_URL}/refresh`, { token: oldToken });
              const { token, user } = res.data.response;
              setAuthToken(token);
              localStorage.setItem("user", token);
              store.dispatch({
                type: "UPDATE_USER_TOKEN",
                payload: { token, user },
              });
              originalReq.headers["Authorization"] = `Bearer ${token}`;

              return Axios(originalReq);
            }
          } catch (err) {
            localStorage.clear();
            store.dispatch(actions.modal.closeModal());
            store.dispatch(actions.user.logoutUser());
          }
        }
        if (error?.response?.status === 404) {
          store.dispatch(actions.toasts.setGlobalError(error));
        }
        return Promise.reject(error);
      }
    );
  },
};
