import cn from "classnames";
import { FormikValues, useFormik } from "formik";
import React, { MouseEvent, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SelectedItem } from "~/components/ItemSelector/ItemSelector";
import { useGlobalContext } from "~/contexts/GlobalContext";
import { IState } from "~/store/reducers/index";
import actions from "../../store/actions";
import "./TalentOnboarding.scss";
import {
  SlideBio,
  SlideClients,
  SlideFinal,
  SlideInitial,
  SlideLevel,
  SlidePhoto,
  SlidePortfolio,
  SlideSectors,
  SlideSkills,
  SlideTools,
  SlideSpecialism,
  SlideCountry,
  SlideTimeZone,
  SlidePhoneNumber,
} from "./TalentOnboardingSlides";
import { TalentOnboardingFlow } from "~/store/reducers/talent";
import { useTalentContext } from "~/contexts/TalentContext";
import { ISpecialism } from "~/store/constants/global";
import { TalentOnboardingSchema } from "~/schemas/talent";
import useTalent from "~/hooks/useTalent";
import useQuery from "~/hooks/useQuery";
import { generateBio } from "~/routes/Talent/helpers";

const SLIDES_COUNT: number = 14;

export default function TalentOnboarding() {
  const dispatch = useDispatch();
  const [currentSlide, setCurrentSlide] = useState<number>(1);
  const [skipOnboarding, setSkipOnboarding] = useState(false);
  const [selectorStep, setSelectorStep] = useState<number>(1);
  const { query } = useQuery();

  const { getAIBio, isFetching: isTalentFetching } = useTalent();
  const {
    global: { globalOptions, clients, getClients, countries, timezones },
  } = useGlobalContext();
  const { onboardingStarted, setIntroFlow } = useTalentContext();

  const { talentId, userId }: { talentId: number; userId: number } = useSelector(
    ({
      user: {
        authenticatedUser: { id, talent_id },
      },
    }) => ({ talentId: talent_id, userId: id })
  );
  const { isFetching, talent } = useSelector((state: IState) => state.talent);
  const isMultiUsers = useMemo(() => (talent?.users?.length || 1) > 1, [talent]);
  const talentDiscipline = useMemo(
    () =>
      globalOptions &&
      globalOptions.disciplines.find((item: { code: string }) => item.code === talent?.discipline_code),
    [globalOptions, talent]
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      specialism_code: "",
      level_code: "",
      skills: [],
      sectors: [],
      tools: [],
      clients: [],
      country: "",
      timezone: "",
      telephone: "",
      phone_meta: { inputValue: "", iso2: "" },
      description: "",
      portfolio_url: "",
    },
    validationSchema: TalentOnboardingSchema,
    onSubmit: closeOnboarding,
  });

  useEffect(() => {
    const teamSpecialismCode = talentDiscipline?.specialisms?.find(({ is_team }: ISpecialism) => is_team)?.code;
    isMultiUsers &&
      currentSlide === 1 &&
      teamSpecialismCode &&
      formik.setFieldValue("specialism_code", teamSpecialismCode);
  }, [isMultiUsers, talentDiscipline]);

  useEffect(() => {
    // disabling background scroll
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  async function closeOnboarding(form: FormikValues) {
    if (!form.phone_meta.inputValue.length) {
      form.telephone = "";
    }
    delete form.phone_meta;

    await dispatch(
      actions.talent.updateTalent({
        id: talentId,
        onboarded: "ONBOARDED",
        ...form,
      })
    );
    setIntroFlow(TalentOnboardingFlow.NONE);
    await getClients();
  }

  function renderSwitchSlide() {
    const { values, setFieldValue } = formik;
    switch (currentSlide) {
      case 1:
        return <SlideInitial name={talent?.name || ""} />;
      case 2:
        return (
          <SlideSpecialism
            formik={formik}
            specialisms={talentDiscipline?.specialisms?.filter(({ is_team }: ISpecialism) => !is_team)}
          />
        );
      case 3:
        return (
          <SlideLevel
            formik={formik}
            levels={talentDiscipline?.specialisms
              ?.find((item: { code: string }) => item.code === formik.values.specialism_code)
              ?.levels?.filter(({ code }: { code: string }) => code !== "LEVEL_FLEXIBLE")}
          />
        );
      case 4:
        return (
          <SlideSkills
            items={talentDiscipline.skills}
            selected={values.skills}
            onSave={(value: SelectedItem[]) => setFieldValue("skills", value)}
            onStepChange={setSelectorStep}
            selectorStep={selectorStep}
          />
        );
      case 5:
        return (
          <SlideSectors
            items={talentDiscipline.sectors}
            selected={values.sectors}
            onSave={(value: SelectedItem[]) => setFieldValue("sectors", value)}
            onStepChange={setSelectorStep}
            selectorStep={selectorStep}
          />
        );
      case 6:
        return (
          <SlideTools
            items={talentDiscipline.tools}
            selected={values.tools}
            onSave={(value: SelectedItem[]) => setFieldValue("tools", value)}
            onStepChange={setSelectorStep}
            selectorStep={selectorStep}
          />
        );
      case 7:
        return <SlideClients formik={formik} clients={clients} />;
      case 8:
        return <SlideCountry formik={formik} countries={countries} />;
      case 9:
        return <SlideTimeZone formik={formik} timezones={timezones} />;
      case 10:
        return <SlidePhoneNumber formik={formik} />;
      case 11:
        return (
          <SlideBio
            formik={formik}
            getAIBio={() => generateBio(talentDiscipline, formik, getAIBio)}
            isFetching={isTalentFetching}
          />
        );
      case 12:
        return <SlidePortfolio formik={formik} />;
      case 13:
        return (
          <SlidePhoto
            isFetching={isFetching}
            talentId={talentId}
            saveImageCallback={() => setCurrentSlide(currentSlide + getNextSlideIncrement())}
          />
        );
      default:
        return <SlideFinal skipOnboarding={skipOnboarding} />;
    }
  }

  const getNextSlideIncrement = () => {
    if ((currentSlide === 1 && isMultiUsers) || (currentSlide === 9 && isMultiUsers)) {
      return 2;
    }
    return 1;
  };

  async function onClickNext(e: MouseEvent) {
    e.preventDefault();

    if (currentSlide === 1 && !query.get("showonb")) {
      const response: boolean = await onboardingStarted();
      if (!response) {
        setSkipOnboarding(true);
        setCurrentSlide(SLIDES_COUNT);
        return;
      }
    }

    if (
      ((currentSlide === 4 && formik.values.skills.length > 1) ||
        (currentSlide === 5 && formik.values.sectors.length > 1) ||
        (currentSlide === 6 && formik.values.tools.length > 1)) &&
      isNextSlideAvailable &&
      selectorStep === 1
    ) {
      setSelectorStep(2);
    } else if (isNextSlideAvailable) {
      setSelectorStep(1);
      setCurrentSlide(currentSlide + getNextSlideIncrement());
    }
  }

  const onClickPrev = (e: MouseEvent) => {
    e.preventDefault();
    if (
      ((currentSlide === 4 && formik.values.skills.length > 1) ||
        (currentSlide === 5 && formik.values.sectors.length > 1) ||
        (currentSlide === 6 && formik.values.tools.length > 1)) &&
      isNextSlideAvailable &&
      selectorStep === 2
    ) {
      setSelectorStep(1);
    } else if (currentSlide === 11 && isMultiUsers) {
      setCurrentSlide(currentSlide - 2);
    } else {
      setCurrentSlide(currentSlide - 1);
    }
  };

  const isNextSlideAvailable: boolean =
    !isFetching &&
    (currentSlide === 1 ||
      (currentSlide === 2 && !!formik.values.specialism_code) ||
      (currentSlide === 3 && !!formik.values.level_code) ||
      (currentSlide === 4 && formik.values.skills.length > 0) ||
      currentSlide === 5 ||
      currentSlide === 6 ||
      currentSlide === 7 ||
      (currentSlide === 8 && !!formik.values.country) ||
      (currentSlide === 9 && !!formik.values.timezone) ||
      (currentSlide === 10 && !formik.errors?.telephone?.length) ||
      (currentSlide === 11 && !!formik.values.description) ||
      (currentSlide === 12 && !formik.errors?.portfolio_url?.length) ||
      currentSlide === 13 ||
      currentSlide === SLIDES_COUNT);

  const isPaginationHidden: boolean =
    currentSlide === 1 ||
    currentSlide === SLIDES_COUNT ||
    (currentSlide === 4 && formik.values.skills.length > 1) ||
    (currentSlide === 5 && formik.values.sectors.length > 1) ||
    (currentSlide === 6 && formik.values.tools.length > 1);

  const getPaginationDots = (SLIDES_COUNT: number) => {
    const slides = [];
    //skip first and last by default
    for (let i = 2; i < SLIDES_COUNT; i++) {
      if ((i === 2 && isMultiUsers) || (i === 10 && isMultiUsers)) {
        continue;
      }
      slides.push(i);
    }
    return slides;
  };

  const paginationDots = useMemo(() => getPaginationDots(SLIDES_COUNT), [isMultiUsers]);

  return (
    <div className="ProfileOnboardingModalSlider">
      <div className="container">
        {talent && renderSwitchSlide()}
        <div className="controls">
          {currentSlide > 1 && (
            <>
              <div className="col col-1" />
              {!isPaginationHidden && (
                <div className="col col-2 dots">
                  <ul>
                    {paginationDots.map((key: number) => (
                      <li key={key} className={cn({ active: key === currentSlide })} />
                    ))}
                  </ul>
                </div>
              )}
            </>
          )}
          <div className="navigation col col-3">
            {currentSlide > 2 && currentSlide !== SLIDES_COUNT && (
              <a href="#" onClick={onClickPrev}>
                Prev
              </a>
            )}
            {currentSlide !== SLIDES_COUNT && (
              <>
                <a href="#" className={cn("nextStep", { disabled: !isNextSlideAvailable })} onClick={onClickNext}>
                  {currentSlide === 13 ||
                  (currentSlide === 5 && formik.values.sectors.length === 0) ||
                  (currentSlide === 6 && formik.values.tools.length === 0) ||
                  (currentSlide === 7 && formik.values.clients.length === 0) ||
                  (currentSlide === 10 && formik.values.phone_meta.inputValue.length === 0) ||
                  (currentSlide === 12 && formik.values.portfolio_url.length === 0)
                    ? "Skip"
                    : "Next"}
                </a>
              </>
            )}
            {currentSlide === SLIDES_COUNT && !skipOnboarding && (
              <a href="#" onClick={formik.handleSubmit}>
                View profile
              </a>
            )}
            {currentSlide === SLIDES_COUNT && skipOnboarding && (
              <a href="#" onClick={() => setIntroFlow(TalentOnboardingFlow.NONE)}>
                Got It
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
