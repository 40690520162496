import React, { useEffect, useMemo, useState, useRef } from "react";
import cn from "classnames";
import { FormikProps, FormikValues, useFormikContext } from "formik";
import { useGlobalContext } from "~/contexts/GlobalContext";
import ApRadioGroup from "../../components/common/ApRadioGroup/ApRadioGroup";
import { splitVocabulary, calculateDates, validateFile } from "~/utils";
import { usePermissionContext } from "~/contexts/PermissionContext";
import { useSelector } from "react-redux";
import Discipline from "../BriefViewDetailsForm/BriefFields/Discipline";
import Specialisms from "../BriefViewDetailsForm/BriefFields/Specialisms";
import Levels from "../BriefViewDetailsForm/BriefFields/Levels";
import Description from "../BriefViewDetailsForm/BriefFields/Description";
import ApPickList from "~/components/common/ApPickList/ApPickList";
import { InputSwitch } from "primereact/inputswitch";
import {
  DEFAULT_DURATION_DAYS,
  maxSectorsCount,
  maxToolsCount,
  SECTORS,
  SKILLS,
  TOOLS,
} from "../BriefViewDetailsForm/constants";
import CalendarBlock from "../BriefViewDetailsForm/Calendar/CalendarBlock";
import "../BriefViewDetailsForm/BriefViewDetailsForm.scss";
import { IState } from "~/store/reducers/index";
import ApInputNumber from "~/components/common/ApInputNumber/ApInputNumber";
import ApCheckbox from "~/components/common/ApCheckbox/ApCheckbox";
import { shortCapacityValues } from "~/config";
import ApMultiselect from "~/components/common/ApMultiselect/ApMultiselect";
import { IUserState } from "~/store/reducers/user";

interface Props {
  formik?: FormikProps<FormikValues>;
  handlePrompt?: (isDirty: boolean) => void;
}
export interface IMaxCount {
  [key: string]: IEntry;
}

type IEntry = {
  limit: number | null;
  selected: any;
};

export default function BriefNewDetailsForm(props: Props) {
  const formik = props.formik || useFormikContext();
  const {
    global: { globalOptions, optionsNormalization, maxSkillsForDiscipline },
  } = useGlobalContext();
  const { organisation, isFetching: isOrgFetching } = useSelector((state: IState) => state?.organisation) || {};
  const currentUser = useSelector(({ user: { authenticatedUser } }: { user: IUserState }) => authenticatedUser);

  const pickListRefs = useRef({ skills: null, sectors: null, tools: null });
  const { isAdmin } = usePermissionContext();

  const DURATION_ID = optionsNormalization?.DURATION_ID;

  const [durationDays, setDurationDays] = useState(formik?.values?.duration_days as number);
  const [maxDurationDays, setMaxDurationDays] = useState(null as any);
  const isExactWorkingDates = formik?.values.duration_id === DURATION_ID?.EXACT;

  const initialStateEntries = {
    skills: false,
    sectors: false,
    tools: false,
  };
  const [disabledEntries, setIsDisabledEntries] = useState(initialStateEntries);

  const getInitialDates = () => calculateDates.newBrief(formik, isExactWorkingDates);

  const [dates, setDates] = useState(getInitialDates());

  const [skills, setSkills] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState([]);

  const [sectors, setSectors] = useState([]);
  const [selectedSectors, setSelectedSectors] = useState([]);

  const [tools, setTools] = useState([]);
  const [selectedTools, setSelectedTools] = useState([]);

  const disciplines = globalOptions?.disciplines;
  const durationTypes = globalOptions?.duration_types;
  const durationTypeOptions = useMemo(
    () =>
      durationTypes
        ?.filter(({ is_enabled }: { is_enabled: boolean }) => is_enabled)
        ?.map(({ id: code, name, description }: { id: string; name: string; description: string }) => ({
          code,
          name,
          description,
        })),
    [durationTypes]
  );

  const selectedDiscipline = disciplines?.find((el: any) => el.id === formik.values.discipline_id);
  const specialisms = selectedDiscipline?.specialisms;
  const selectedSpecialism = specialisms?.find((el: any) => el.id === formik.values.specialism_id);
  const levels = useMemo(() => selectedSpecialism && [...selectedSpecialism.levels]?.reverse(), [selectedSpecialism]);

  useEffect(() => {
    if (levels?.length === 1 && formik.values.level_id !== levels[0].code) {
      formik.setFieldValue("level_id", levels[0].id);
    }
  }, [levels]);

  useEffect(() => {
    if (selectedDiscipline) {
      splitVocabulary(selectedDiscipline.skills, formik.values.skills ?? selectedSkills, setSkills, setSelectedSkills);
      splitVocabulary(
        selectedDiscipline.sectors,
        formik.values.sectors ?? selectedSectors,
        setSectors,
        setSelectedSectors
      );
      splitVocabulary(selectedDiscipline.tools, formik.values.tools ?? selectedTools, setTools, setSelectedTools);
    }
  }, [formik.values.discipline_id]);

  useEffect(() => {
    if (specialisms?.length === 1) {
      formik.setFieldValue("specialism_id", specialisms[0].id);
    }
  }, [specialisms]);

  useEffect(() => {
    props.handlePrompt && props.handlePrompt(formik.dirty);
  }, [formik.dirty]);

  const organisationAssignees = useMemo(
    () => organisation?.assignees.sort((a, b) => (a.id === currentUser?.id ? -1 : 1)),
    [organisation]
  );

  const maxCount: IMaxCount = useMemo(
    () => ({
      skills: {
        limit: (formik.values.discipline_id && maxSkillsForDiscipline[formik.values.discipline_id]) || null,
        selected: selectedSkills,
      },
      sectors: {
        limit: maxSectorsCount,
        selected: selectedSectors,
      },
      tools: {
        limit: maxToolsCount,
        selected: selectedTools,
      },
    }),
    [formik.values.discipline_id, selectedSkills, selectedSectors]
  );

  const changeDurationTypes = (e: any) => {
    resetDates();
    formik.setFieldValue("duration_id", e.value);
    formik.setFieldValue("duration_days", DEFAULT_DURATION_DAYS);
    setDurationDays(DEFAULT_DURATION_DAYS);
    setMaxDurationDays(null);
  };

  const resetDates = () => {
    setDates([]);
    formik.setValues({
      ...formik.values,
      start_date: "",
      end_date: "",
      dates: [],
    });
  };

  function onUploadDescription([file]: [File]) {
    const errors = validateFile(file, {
      size: 10000000,
      types: ["doc", "docx", "pdf", "xls", "xlsx"],
    });

    if (errors.length) {
      formik.setFieldError("description_url", errors.join(", "));
      return;
    }

    formik.setFieldValue("description_url", file);
  }

  function deleteDescription() {
    formik.setFieldValue("description_url", null);
  }

  return (
    <div className="BriefDetailsForm">
      <div className="block-specific-width block-fields">
        <Discipline
          disciplines={organisation?.disciplines}
          formik={formik}
          setSelectedSkills={setSelectedSkills}
          setSelectedSectors={setSelectedSectors}
          initialStateEntries={initialStateEntries}
          setIsDisabledEntries={setIsDisabledEntries}
        />
        <Specialisms formik={formik} specialisms={specialisms} canShowInfoPopup />
        <Levels formik={formik} levels={levels} className="indent" canShowInfoPopup />
      </div>

      <div className="p-fluid field">
        <ApCheckbox
          formik={formik}
          noField
          name="specialism_flexible"
          label="Only include profiles that match the selected specialism"
        />
      </div>

      <Description formik={formik} onChangeFile={(e) => onUploadDescription(e)} onDeleteFile={deleteDescription} />

      {selectedDiscipline && (
        <>
          <ApPickList
            formik={formik}
            pickListRefs={pickListRefs}
            typeOfEntries={SKILLS}
            entries={skills}
            label={<>What skills would be useful? Select & rank</>}
            selectedEntries={selectedSkills}
            disabledEntries={disabledEntries}
            setIsDisabledEntries={setIsDisabledEntries}
            onChange={(e) => {
              setSkills(e.source);
              setSelectedSkills(e.target);
              formik.setFieldValue("skills", e.target);
            }}
            sourceHeader="Select Skill"
            maxCount={maxCount}
            className={cn("p-fluid field skills", !isAdmin && "field-required")}
          />
        </>
      )}
      {selectedDiscipline && (
        <div className="p-fluid field tools-switch">
          <InputSwitch
            id="include_sectors"
            checked={formik.values?.include_sectors}
            onChange={(e) => formik.setFieldValue("include_sectors", e.value)}
          />
          <span>Add Sectors</span>
        </div>
      )}
      {formik.values?.include_sectors && (
        <ApPickList
          formik={formik}
          selectedDiscipline={selectedDiscipline}
          pickListRefs={pickListRefs}
          typeOfEntries={SECTORS}
          entries={sectors}
          label="Sector experience. Select & rank"
          selectedEntries={selectedSectors}
          disabledEntries={disabledEntries}
          setIsDisabledEntries={setIsDisabledEntries}
          onChange={(e) => {
            setSectors(e.source);
            setSelectedSectors(e.target);
            formik.setFieldValue("sectors", e.target);
          }}
          sourceHeader="Sectors"
          maxCount={maxCount}
          className="p-fluid field sectors field-required"
        />
      )}
      {selectedDiscipline && (
        <div className="p-fluid field tools-switch">
          <InputSwitch
            id="include_tools"
            checked={formik.values?.include_tools}
            onChange={(e) => formik.setFieldValue("include_tools", e.value)}
          />
          <span>{"Add Tools / Applications"}</span>
        </div>
      )}
      {formik.values?.include_tools && (
        <ApPickList
          pickListRefs={pickListRefs}
          typeOfEntries={TOOLS}
          entries={tools}
          label="Tools / Applications. Select & rank"
          selectedEntries={selectedTools}
          disabledEntries={disabledEntries}
          setIsDisabledEntries={setIsDisabledEntries}
          onChange={(e) => {
            setTools(e.source);
            setSelectedTools(e.target);
            formik.setFieldValue("tools", e.target);
          }}
          sourceHeader="Select Tools"
          maxCount={maxCount}
          className="p-fluid field tools field-required"
        />
      )}

      <div className="block-fields">
        {!!durationTypeOptions?.length && (
          <div className="p-fluid field field-required duration-types">
            <label htmlFor="duration_id">Duration types</label>
            <ApRadioGroup
              formik={formik}
              name="duration_id"
              noField
              options={durationTypeOptions}
              onChange={changeDurationTypes}
            />
          </div>
        )}
      </div>

      <CalendarBlock
        formik={formik}
        dates={dates}
        setDates={setDates}
        durationDays={durationDays}
        setDurationDays={setDurationDays}
        maxDurationDays={maxDurationDays}
        setMaxDurationDays={setMaxDurationDays}
      />
      {formik.values?.duration_id !== DURATION_ID?.EXACT && (
        <div className="p-fluid field field-required capacity">
          <label htmlFor="capacity">Capacity</label>
          <ApInputNumber
            formik={formik}
            id="capacity"
            noField
            max={100}
            min={1}
            maxLength={4}
            shorthandValues={shortCapacityValues}
            suffix="%"
          />
        </div>
      )}
      <div className="p-fluid field">
        <ApCheckbox
          formik={formik}
          noField
          disabled={!dates.length}
          name="full_availability"
          label="Only include matches with full availability"
        />
      </div>
      <div className="p-fluid field field-required">
        <label htmlFor="assignees">Brief Assignee</label>
        <ApMultiselect
          id="assignees"
          filter
          options={organisationAssignees}
          itemTemplate={(item) => <div>{item.full_name + (item?.published === false ? " (DEACTIVATED)" : "")}</div>}
          optionLabel="full_name"
          optionValue="id"
          placeholder="Select brief assignee(s)"
          noField
          formik={formik}
          loading={isOrgFetching}
        />
      </div>
    </div>
  );
}
