import React, { createContext, useContext } from "react";
import useBooking from "~/hooks/useBooking";
import { IBooking } from "~/interfaces/booking";
import { IDefaultPaginatedState, IQueryParams } from "~/interfaces/common";

export interface IBookingContext {
  isLoading: boolean;
  bookingsState: IDefaultPaginatedState<IBooking>;
  getOrganisationBookings: (id: number, params: IQueryParams) => void;
  getTalentBookings: (id: number, params: IQueryParams) => void;
  resetState: () => void;
}

export const BookingContext = createContext(null as IBookingContext | null);

export const useBookingContext = () => {
  const context = useContext(BookingContext);
  if (!context) {
    throw new Error("useBookingContext must be used within a BookingContextProvider");
  }
  return context;
};

const BookingContextProvider = ({ children }: any) => {
  return (
    <BookingContext.Provider
      value={{
        ...useBooking(),
      }}
    >
      {children}
    </BookingContext.Provider>
  );
};

export default BookingContextProvider;
