import React, { Dispatch, MutableRefObject, useCallback, useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import cn from "classnames";
import { FormikContextType, useFormikContext } from "formik";
import { ListBox } from "primereact/listbox";
import { PickList, PickListChangeParams } from "primereact/picklist";
import { IMaxCount } from "~/forms/BriefNewDetailsForm/BriefNewDetailsForm";
import { ISkill } from "~/store/constants/skills";
import { SECTORS, SKILLS, TOOLS } from "~/forms/BriefViewDetailsForm/constants";
import { getEntriesCounter } from "~/forms/BriefViewDetailsForm/helper";
import Toasts from "~/store/constants/toasts";
import "./ApPickList.scss";

interface IApPickList {
  formik?: FormikContextType<any>;
  isViewOnly?: boolean;
  selectedDiscipline?: any;
  setIsDisabledEntries?: Dispatch<any>;
  selectedEntries: any;
  onChange?: (e: any) => void;
  typeOfEntries: EntriesType;
  label?: JSX.Element | string;
  children?: JSX.Element | string;
  pickListRefs?: MutableRefObject<any>;
  entries: any;
  sourceHeader: string;
  targetHeader?: string;
  maxCount?: IMaxCount;
  className?: string;
  disabledEntries?: any;
}

type EntriesType = typeof SKILLS | typeof SECTORS | typeof TOOLS;

export default function ApPickList(props: IApPickList) {
  const {
    isViewOnly,
    selectedDiscipline,
    selectedEntries,
    typeOfEntries,
    label,
    pickListRefs = useRef({}),
    entries,
    sourceHeader,
    targetHeader,
    maxCount,
    className,
    disabledEntries,
    setIsDisabledEntries,
    children,
    onChange,
  } = props;
  const formik = props.formik || useFormikContext();
  const dispatch = useDispatch();
  const changeEntriesStatus = ({ type, isBlocked }: { type: string; isBlocked: boolean }) => {
    setIsDisabledEntries &&
      setIsDisabledEntries((entries) => ({
        ...entries,
        ...{ [type]: isBlocked },
      }));
  };
  const [selectedTargets, setSelectedTargets] = useState<any>([]);

  useEffect(() => {
    if (!!selectedEntries.length && disabledEntries && maxCount) {
      Object.entries(maxCount).forEach(([type, { limit, selected }]) => {
        changeEntriesStatus({ type, isBlocked: selected.length >= limit });
      });
    }
  }, [selectedEntries]);

  const canBeAddedEntry = (type: string, selectedItemsLength: number) => {
    if (!maxCount) {
      return true;
    }
    const maxItems = maxCount[type].limit;
    if (selectedItemsLength > maxItems) {
      dispatch(
        Toasts.setToasts([
          { severity: "error", summary: "", detail: `Sorry, only ${maxItems} ${type} can be selected` },
        ])
      );
      return false;
    }
    return true;
  };

  const handleDoubleClickEntries = useCallback(
    (item: ISkill, type: EntriesType) => {
      const oldSource = entries;
      const oldSelected = selectedEntries;
      const isRemoveItem = oldSelected.some((el: ISkill) => el.name === item.name);
      const event = isRemoveItem
        ? {
            source: [...oldSource, item],
            target: oldSelected.filter((el: ISkill) => el.name !== item.name),
          }
        : {
            source: oldSource.filter((el: ISkill) => el.name !== item.name),
            target: [...oldSelected, { ...item, order_id: oldSelected.length + 1 }],
          };

      if (!canBeAddedEntry(type, event.target.length)) return;

      onChange && onChange(event);
    },
    [entries]
  );

  const onPickListChange = (e: PickListChangeParams) => {
    const event = {
      ...e,
      target: e.target.map((item: ISkill, index: number) => ({ ...item, order_id: index + 1 })),
    };
    if (!canBeAddedEntry(typeOfEntries, event.target.length)) return;

    onChange && onChange(event);

    const updatedTargets = selectedTargets.map(
      (item: ISkill) => event.target.find((el: ISkill) => el.id === item.id) || item
    );
    const newTargets = event.target.filter((item: ISkill) => !selectedEntries.find((el: ISkill) => el.id === item.id));
    setSelectedTargets([...updatedTargets, ...newTargets]);
  };

  const skillTemplate = (item: ISkill, type: EntriesType) => (
    <div className="skill-item" onDoubleClick={() => handleDoubleClickEntries(item, type)}>
      {item.name}
    </div>
  );

  const targetTemplate = (item: ISkill, type: EntriesType) => {
    return (
      <div className="target-item" onDoubleClick={() => handleDoubleClickEntries(item, type)}>
        <div className="rank">{item.order_id}</div>
        {item.name}
      </div>
    );
  };

  return (
    <div className={cn(className, { ["disabled-source"]: disabledEntries && disabledEntries[typeOfEntries] })}>
      {label && <label htmlFor={typeOfEntries}>{label}</label>}
      {maxCount && getEntriesCounter(typeOfEntries, maxCount, selectedEntries, selectedDiscipline)}
      {isViewOnly ? (
        <ListBox
          optionLabel="name"
          options={selectedEntries}
          disabled
          emptyMessage="—"
          style={{ opacity: 1, backgroundColor: "#f8fafa" }}
        />
      ) : (
        <PickList
          ref={(el) => (pickListRefs.current[typeOfEntries] = el)}
          source={entries}
          target={selectedEntries}
          itemTemplate={(item) => skillTemplate(item, typeOfEntries)}
          onChange={onPickListChange}
          sourceHeader={sourceHeader}
          onSourceSelectionChange={(e: any) => setSelectedTargets([])}
          showSourceControls={false}
          onMoveToTarget={(e: any) => setSelectedTargets(e.value)}
          targetHeader={targetHeader || "Rank them"}
          targetItemTemplate={(item) => targetTemplate(item, typeOfEntries)}
          targetSelection={selectedTargets}
          onTargetSelectionChange={(e: any) => setSelectedTargets(e.value)}
          showTargetControls={selectedEntries.length > 0}
          className={cn("noselect", { "right-space": selectedEntries.length === 0 })}
          // dataKey="id"
        />
      )}
      {formik?.touched?.[typeOfEntries] && formik?.errors?.[typeOfEntries] && (
        <div className="ap-error">{formik.errors[typeOfEntries]}</div>
      )}
      {children}
    </div>
  );
}
