import { Dialog } from "primereact/dialog";
import React, { useMemo } from "react";
import { useGlobalContext } from "~/contexts/GlobalContext";
import { renderBriefDates } from "~/forms/BriefViewDetailsForm/helper";
import { IBooking } from "~/interfaces/booking";

type IBookingDetailsProps = {
  booking: IBooking;
  onHide: any;
};

export default function BookingDetails({
  booking: { brief_name, duration_id, duration_days, duration_weekends, dates },
  onHide,
}: IBookingDetailsProps) {
  const {
    global: { globalOptions, optionsNormalization },
  } = useGlobalContext();
  const DURATION_ID = optionsNormalization?.DURATION_ID;

  const durationType = useMemo(
    () => globalOptions?.duration_types.find(({ id }: { id: number }) => id === duration_id),
    [duration_id]
  );

  return (
    <Dialog
      header={brief_name}
      className="BookingDetailsModal"
      visible={true}
      onHide={onHide}
      style={{ minWidth: "50vw", maxWidth: "50vw" }}
    >
      {!!durationType && (
        <p>
          <label>Duration type:</label> {durationType.name}
        </p>
      )}
      {[1, 2].includes(duration_id) && (
        <>
          <p>
            <label>Weekdays:</label> {duration_days}
          </p>
          <p>
            <label>Weekend days:</label> {duration_weekends}
          </p>
        </>
      )}
      {duration_id === DURATION_ID.EXACT && (
        <p>
          <label>Dates:</label>
          {dates.map(({ date, capacity }) => `${date} (${capacity})`).join(", ")}
        </p>
      )}
    </Dialog>
  );
}
