import { Accordion, AccordionTab } from "primereact/accordion";
import { Card } from "primereact/card";
import React, { useRef, useState, ReactElement, RefObject } from "react";
import "./ApCard.scss";
import cn from "classnames";
import actions from "~/store/actions";
import { useDispatch } from "react-redux";
import TagsView from "../Tags/TagsView";
import { ITag } from "~/interfaces/common";
import { TalentType } from "~/store/constants/talent";
import { FileUpload, FileUploadSelectEvent } from "primereact/fileupload";

export interface ICardAccordionTab {
  id: number;
  title: string | ReactElement;
  content: React.ReactNode | Iterable<React.ReactNode>;
  isHidden?: boolean;
}

interface IApCard {
  talentId?: number;
  talentType?: TalentType;
  image_url: string;
  profile_image_url: string;
  canEditProfilePhoto?: boolean;
  renderCardText: ReactElement;
  talentName?: string;
  accordionTabs: ICardAccordionTab[];
  tags?: ITag[];
  hideTags?: boolean;
  readOnlyTags?: boolean;
  isTalent?: boolean;
  renderCardActions: () => ReactElement;
  onCardClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onCardHover?: ((e: React.MouseEvent<HTMLDivElement>) => void) | undefined;
}

export default function ApCard(props: IApCard) {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [imageType, setImageType] = useState<"general" | "profile" | null>(null);
  const dispatch = useDispatch();
  const inputEl = useRef() as RefObject<FileUpload>;

  const isFreelancer = props.talentType === TalentType.FREELANCER;

  const selectImage = (event: FileUploadSelectEvent): void => {
    const { files } = event;
    if (files && files.length) {
      const isAvatarImage = imageType === "profile";
      dispatch(
        actions.modal.openModal("UPDATE_IMAGE_MODAL", {
          file: files[0],
          talentId: props.talentId,
          aspect: isAvatarImage ? 1 : 780 / 410,
          isAvatarImage,
          saveImageCallback: () => dispatch(actions.modal.closeModal()),
        })
      );
    }
    inputEl.current?.clear();
  };

  const renderEditImageSection = props.canEditProfilePhoto ? (
    <div className="update-img-wrp">
      <span>edit</span>
    </div>
  ) : null;

  return (
    <Card
      className={cn("ApCard", isFreelancer && "freelance")}
      title={
        <div
          className={cn("wrp-profile-image", Number.isInteger(activeIndex) && "img-fade", isFreelancer && "freelance")}
          onClick={() => {
            setImageType("profile");
            inputEl.current?.getInput()?.click();
          }}
        >
          <img alt="Profile image" src={props.profile_image_url} />
          {renderEditImageSection}
          {isFreelancer && <div className="clip-overlay freelance"></div>}
        </div>
      }
      header={
        <div
          className="wrp-img"
          onClick={() => {
            setImageType("general");
            inputEl.current?.getInput()?.click();
          }}
        >
          {renderEditImageSection}
          <img alt="Talent background image" src={props.image_url} />
          {!props.isTalent && !!props.talentId && !props.hideTags && (
            <TagsView
              talentName={props.talentName || ""}
              talentId={props.talentId}
              tags={props.tags || []}
              readOnlyTags={props.readOnlyTags}
            />
          )}
        </div>
      }
      onClick={props.onCardClick}
      onMouseEnter={props.onCardHover}
    >
      <div className={cn("p-card-text main-text", Number.isInteger(activeIndex) && "card-shrink")}>
        {props.renderCardText}
      </div>
      <Accordion
        activeIndex={activeIndex}
        className={cn("accordion-custom", Number.isInteger(activeIndex) && "accordion-expanded")}
        onTabChange={(e: any) => setActiveIndex(e.index)}
        expandIcon="pi pi-chevron-down"
        collapseIcon="pi pi-chevron-up"
      >
        {props.accordionTabs.map((tab: any) => (
          <AccordionTab
            tabIndex={activeIndex}
            key={tab.id}
            header={tab.title}
            headerClassName={cn({ "availability-hidden": Number.isInteger(activeIndex) })}
            className={cn({ ["visible-hidden"]: tab.isHidden })}
          >
            <div className="wrp-content">{tab.content}</div>
          </AccordionTab>
        ))}
      </Accordion>
      <div className="p-card-text grid card-actions">{props.renderCardActions()}</div>
      {props.canEditProfilePhoto && (
        <FileUpload
          auto
          customUpload
          mode="basic"
          className="hidden"
          ref={inputEl}
          accept="image/*"
          onSelect={selectImage}
        />
      )}
    </Card>
  );
}
