import * as Yup from "yup";
import { requiredFieldMessage } from "~/utils";

export const CreateProjectSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Please, add at least 2 symbols")
    .max(255, "255 characters max")
    .required(requiredFieldMessage),
  project_id: Yup.string().nullable().max(100),
  description: Yup.string()
    .min(2, "Please, add at least 2 symbols")
    .max(1000, "Please, make it shorter than 1000 symbols")
    .test(function (this: any, value: string | undefined) {
      const { proposal_url } = this.parent;
      if (!!proposal_url || !!value) {
        return true;
      }
      return this.createError({ message: "Please type a project proposal description or upload a proposal file" });
    }),
  proposal_url: Yup.mixed().test(function (this: any, value: File | string | undefined) {
    const { description } = this.parent;
    if ((!!description && description.length > 2) || !!value) {
      return true;
    }
    return this.createError({ message: "Please type a project proposal description or upload a proposal file" });
  }),
  program_lead: Yup.string().nullable(),
  client_lead: Yup.string().nullable(),
  client_pod: Yup.string().nullable(),
  client: Yup.string().required(requiredFieldMessage),
  organisation: Yup.number().required(requiredFieldMessage),
  author_id: Yup.number().required(requiredFieldMessage),
  dates: Yup.array().of(Yup.mixed()).min(2, requiredFieldMessage).required(requiredFieldMessage),
});

export const CreateDraftProjectSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Please, add at least 2 symbols")
    .max(255, "255 characters max")
    .required(requiredFieldMessage),
  organisation: Yup.number().required(requiredFieldMessage),
  author_id: Yup.number().required(requiredFieldMessage),
});
