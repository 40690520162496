import axios from "axios";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { ClearanceService } from "~/API/ClearanceService";
import { useNotificationContext } from "~/contexts/NotificationContext";
import { usePermissionContext } from "~/contexts/PermissionContext";
import { IClearance, IClearanceStatus } from "~/interfaces/clearance";
import { IQueryParams } from "~/interfaces/common";
import Toasts, { TOAST_ERROR_MESSAGE, TOAST_SUCCESS_MESSAGE } from "~/store/constants/toasts";

export type IClearances = {
  isFetching: boolean;
  clearances: IClearance[];
  allRequestsCount: number;
  getClearances: (params: IQueryParams) => Promise<number>;
  resetClearances: () => void;
  setClearanceStatus: (id: number, status: IClearanceStatus, params?: any) => number;
  total: number;
  per_page: number;
  setPerPage: (page: number) => void;
  page: number;
  setPage: (page: number) => void;
  pendingRequestsCount: number;
  setAllRequestsCount: (value: number) => void;
  setPendingRequestsCount: (value: number) => void;
};

const useClearance = () => {
  const dispatch = useDispatch();
  const { isAdmin } = usePermissionContext();
  const { getNotifications } = useNotificationContext();
  const [isFetching, setIsFetching] = useState(false);
  const [clearances, setClearances] = useState<IClearance[]>([]);
  const [allRequestsCount, setAllRequestsCount] = useState<number | null>(null);
  const [pendingRequestsCount, setPendingRequestsCount] = useState<number | null>(null);
  const [total, setTotal] = useState<number>(0);
  const [per_page, setPerPage] = useState<number>(20);
  const [page, setPage] = useState<number>(1);

  let controller: any;
  const createController = () => {
    controller = new AbortController();
  };

  const getClearances = async (params: IQueryParams) => {
    try {
      createController();
      !params.count && setIsFetching(true);
      const res = await ClearanceService.getClearances(params, controller);

      if (res.status === 200 && params.count) {
        setAllRequestsCount(res.data);
        return;
      }

      if (res.status === 200) {
        const { data, total } = res.data;

        setClearances(data);
        setTotal(total);
        params.status === IClearanceStatus.REQUESTED && setPendingRequestsCount(total);
      }
      return res.status;
    } catch (err) {
      if (axios.isCancel(err)) {
        console.error(err?.message);
      } else {
        dispatch(Toasts.setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
      }
    } finally {
      !params.count && setIsFetching(false);
    }
  };

  const setClearanceStatus = async (id: number, newStatus: IClearanceStatus, params?: any) => {
    try {
      setIsFetching(true);
      const { status } = await ClearanceService.setClearanceStatus(id, newStatus, params);
      if (status === 200) {
        dispatch(Toasts.setToasts([{ severity: "success", summary: "", detail: TOAST_SUCCESS_MESSAGE }]));
        isAdmin && getNotifications();
      }
      return status;
    } catch (err) {
      dispatch(Toasts.setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
    } finally {
      setIsFetching(false);
    }
  };

  const getMatchClearances = async (matchId: number) => {
    try {
      createController();
      setIsFetching(true);
      const res = await ClearanceService.getMatchClearances(matchId, controller);

      if (res.status === 200) {
        setClearances(res.data);
      }
    } catch (err) {
      if (axios.isCancel(err)) {
        console.error(err?.message);
      } else {
        dispatch(Toasts.setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
      }
    } finally {
      setIsFetching(false);
    }
  };

  const resetClearances = () => {
    setClearances([]);
    controller && controller.abort();
  };

  return {
    isFetching,
    clearances,
    allRequestsCount,
    getClearances,
    getMatchClearances,
    resetClearances,
    setClearanceStatus,
    total,
    per_page,
    setPerPage,
    page,
    setPage,
    pendingRequestsCount,
    setAllRequestsCount,
    setPendingRequestsCount,
  };
};

export default useClearance;
