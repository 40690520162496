import { Form, Formik, FormikProps, FormikValues } from "formik";
import moment from "moment";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BriefDatesDaysCount from "~/components/BriefDatesDaysCount/BriefDatesDaysCount";
import ApRadioGroup from "~/components/common/ApRadioGroup/ApRadioGroup";
import * as permissionType from "~/constants/permissions";
import { useBriefContext } from "~/contexts/BriefContext";
import { useGlobalContext } from "~/contexts/GlobalContext";
import { usePermissionContext } from "~/contexts/PermissionContext";
import CalendarBlock from "~/forms/BriefViewDetailsForm/Calendar/CalendarBlock";
import { BookingDateConfirmSchema } from "~/schemas/BookingDateConfirmSchema";
import toasts from "~/store/actions/toasts";
import { calculateDates, getDiffBetweenDays } from "~/utils";
import { IState } from "../../store/reducers/index";
import "./AmendBookingModal.scss";
import ApInputNumber from "~/components/common/ApInputNumber/ApInputNumber";
import { shortCapacityValues } from "~/config";

const DEFAULT_DURATION_DAYS = 1;

export default function AmendBookingModal() {
  const dispatch = useDispatch();
  const { brief } = useBriefContext();
  const { userAccess } = usePermissionContext();
  const { modalProps } = useSelector((state: IState) => state.modal);

  const {
    content,
    opt: {
      id,
      talent_id,
      start_date,
      end_date,
      status,
      include_weekends,
      duration_days,
      duration_weekends,
      duration_id,
      talent_rate,
      match_id,
      capacity,
      shouldValidateAvailability,
    },
  } = modalProps || {};

  const {
    global: { optionsNormalization, globalOptions },
  } = useGlobalContext();
  const DURATION_ID = optionsNormalization?.DURATION_ID;
  const [durationDays, setDurationDays] = useState(Number(duration_days) + Number(duration_weekends) || 1);
  const [maxDurationDays, setMaxDurationDays] = useState(null as any);
  const isExactWorkingDates = duration_id === DURATION_ID?.EXACT;

  const [dates, setDates] = useState(calculateDates.currentBrief(brief, isExactWorkingDates));
  const durationTypes = globalOptions?.duration_types;
  const durationTypeOptions = useMemo(
    () =>
      durationTypes
        ?.filter(({ is_enabled }: { is_enabled: boolean }) => is_enabled)
        ?.map(({ id: code, name, description }: { id: string; name: string; description: string }) => ({
          code,
          name,
          description,
        })),
    [durationTypes]
  );

  const changeDurationDays = (dates: Date[], day: number = DEFAULT_DURATION_DAYS) => {
    const endDate = dates[1] ? moment(dates[1]) : moment(dates[0]);
    const startDate = moment(dates[0]);
    const days = getDiffBetweenDays({ startDate, endDate });
    setMaxDurationDays(days + 1 || null);
    setDurationDays(day);
  };
  useEffect(() => {
    changeDurationDays([start_date, end_date], duration_days + duration_weekends);
  }, []);

  function onSubmit(data: any) {
    const isExactWorkingDates = data.duration_id === DURATION_ID?.EXACT;

    dispatch(
      toasts.setPopup({
        content: (
          <BriefDatesDaysCount
            data={data}
            callback={(dates) => {
              data.duration_days = dates.duration_days;
              data.duration_weekends = dates.duration_weekends;
            }}
            shouldValidateAvailability={shouldValidateAvailability}
          />
        ),
        buttons: [
          { text: "Go back" },
          {
            callback: () =>
              modalProps.onSubmit &&
              modalProps.onSubmit({
                id,
                status,
                match_id,
                start_date: moment(data.start_date).format("YYYY-MM-DD"),
                end_date: moment(data.end_date ?? data.start_date).format("YYYY-MM-DD"),
                include_weekends: data.include_weekends,
                duration_id: data.duration_id,
                duration_days: data.duration_days,
                duration_weekends: data.duration_weekends,
                dates: isExactWorkingDates ? data.dates : [],
                capacity: data.capacity,
              }),
            text: "Confirm",
          },
        ],
      })
    );
  }

  const changeDurationTypes = (value: number, formik: FormikProps<FormikValues>) => {
    setDates([]);
    formik.setValues({
      ...formik.values,
      start_date: "",
      end_date: "",
      dates: [],
      duration_id: value,
      duration_days: DEFAULT_DURATION_DAYS,
    });
    setDurationDays(DEFAULT_DURATION_DAYS);
    setMaxDurationDays(null);
  };

  return (
    <Formik
      initialValues={{
        ...(userAccess(permissionType.accessAdmin) && { client_id: brief?.organisation_id }),
        start_date: moment(start_date, "DD/MM/YYYY").toDate(),
        end_date: moment(end_date, "DD/MM/YYYY").toDate(),
        include_weekends: include_weekends || false,
        duration_id: duration_id,
        duration_days: durationDays,
        dates: brief?.dates?.length ? calculateDates.formatSlashDash(brief?.dates) : [],
        talent_id,
        talent_rate,
        status,
        capacity,
        brief_id: brief?.id,
      }}
      validationSchema={BookingDateConfirmSchema}
      onSubmit={onSubmit}
    >
      {(props) => (
        <Form>
          <Dialog
            className="AmendBookingModal"
            header="Confirm amendment details"
            footer={
              <footer>
                <Button label="AMEND" type="submit" icon="pi pi-check" onClick={() => props.submitForm()} />
              </footer>
            }
            visible={true}
            modal={true}
            onHide={modalProps.onClose}
            style={{ minWidth: "50em" }}
          >
            {content} <br />
            <div className="field">
              <label htmlFor="duration_id">Duration types</label>
              <ApRadioGroup
                name="duration_id"
                options={durationTypeOptions}
                onChange={({ value }) => changeDurationTypes(value, props)}
              />
            </div>
            <CalendarBlock
              formik={props}
              dates={dates}
              setDates={setDates}
              durationDays={durationDays}
              setDurationDays={setDurationDays}
              maxDurationDays={maxDurationDays}
              setMaxDurationDays={setMaxDurationDays}
              hideFlexibleDatesCheckbox
            />
            {props.values.duration_id !== DURATION_ID?.EXACT && (
              <div className="p-fluid field field-required capacity">
                <label htmlFor="capacity">Capacity</label>
                <ApInputNumber
                  id="capacity"
                  noField
                  max={100}
                  min={1}
                  maxLength={4}
                  suffix="%"
                  placeholder="100"
                  shorthandValues={shortCapacityValues}
                />
              </div>
            )}
          </Dialog>
        </Form>
      )}
    </Formik>
  );
}
