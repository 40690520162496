import { Paginator, PaginatorPageChangeEvent } from "primereact/paginator";
import { Menu } from "primereact/menu";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import classNames from "classnames";
import React, { useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import BookingDetails from "~/components/BookingDetails/BookingDetails";
import { IBooking } from "~/interfaces/booking";
import { usePermissionContext } from "~/contexts/PermissionContext";
import { TalentActivityStatus } from "~/store/constants/talent";
import { joinArray } from "~/utils";

type IBookingsTable = {
  variant: "CLIENT" | "TALENT";
  bookings: IBooking[];
  isLoading: boolean;
  page: number;
  perPage: number;
  total: number;
  onPaginatorChange: (e: PaginatorPageChangeEvent) => void;
};

export default function BookingsTable({
  variant,
  bookings,
  isLoading,
  onPaginatorChange,
  page,
  perPage,
  total,
}: IBookingsTable) {
  const menuRefs = useRef<Menu[]>([]);
  const [bookingDetail, setBookingDetails] = useState<IBooking | undefined>();

  const { isTro } = usePermissionContext();

  function renderColumnTalentName(rowData: IBooking) {
    const isArchived = rowData?.talent?.status === TalentActivityStatus.ARCHIVED;
    return (
      <div className="name-wrapper">
        <NavLink to={`/talents/${rowData?.talent?.id}`}>{rowData?.talent?.name}</NavLink>
        {isArchived && <span className="published-status declined">ARCHIVED</span>}
      </div>
    );
  }

  function renderColumnBriefName(rowData: IBooking) {
    return isTro ? rowData?.brief_name : <NavLink to={`/briefs/${rowData?.brief_id}`}>{rowData?.brief_name}</NavLink>;
  }

  function renderColumnOrganisation(rowData: IBooking) {
    return rowData?.organisation?.name;
  }

  function renderColumnBriefClient(rowData: IBooking) {
    return rowData?.clients?.map(({ name }) => name).join(", ");
  }

  function renderColumnClearance(rowData: IBooking) {
    return joinArray(rowData.tro);
  }

  function renderColumnStatus({ status }: IBooking) {
    return (
      <span
        className={classNames("published-status", {
          accepted: status === "APPROVED" || status === "RESOURCED",
          draft: status === "DECLINED",
          pending: status === "REQUESTED",
          declined: status === "CANCELLED" || status === "AMENDED",
        })}
      >
        {status === "RESOURCED" ? "ALLOCATED" : status}
      </span>
    );
  }

  function renderActionButtons(rowData: IBooking, { rowIndex }: { rowIndex: number }) {
    const actionButtons = [
      {
        label: "More details",
        command: () => setBookingDetails(rowData),
      },
    ];

    return (
      <div className="action-buttons-wrapper">
        <Button icon="pi pi-ellipsis-h" onClick={(event) => toggleContextMenu(event, rowIndex)}></Button>
        <Menu
          ref={(el) => el && (menuRefs.current[rowIndex] = el)}
          popup
          model={actionButtons}
          className="action-buttons"
        />
      </div>
    );
  }

  function toggleContextMenu(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, rowIndex: number) {
    (menuRefs.current[rowIndex] as Menu).toggle(event);
  }

  const getRowClassName = (rowData: IBooking) =>
    rowData.talent.status === TalentActivityStatus.ARCHIVED ? "disabled" : "";

  return (
    <div className="BookingsTableView">
      <DataTable
        className="BookingsView__table"
        emptyMessage="No bookings found"
        loading={isLoading}
        value={bookings}
        rowClassName={getRowClassName}
      >
        <Column body={renderColumnTalentName} header="Name" className="name" />
        {variant === "TALENT" && <Column body={renderColumnOrganisation} header="Organisation" />}
        <Column body={renderColumnBriefName} header="Brief name" />
        <Column body={renderColumnBriefClient} header="Client" />
        <Column field="start_date" header="Start date" />
        <Column field="end_date" header="End date" />
        <Column body={renderColumnClearance} header="Clearance" />
        <Column field="capacity" header="Capacity" />
        <Column body={renderColumnStatus} field="status" header="Status" sortable={true} />
        <Column body={renderActionButtons} className="actions" />
      </DataTable>

      <Paginator
        rows={perPage}
        totalRecords={total}
        first={(page - 1) * perPage}
        rowsPerPageOptions={[15, 20, 50, 100, 200]}
        onPageChange={onPaginatorChange}
      ></Paginator>
      {!!bookingDetail && <BookingDetails onHide={() => setBookingDetails(undefined)} booking={bookingDetail} />}
    </div>
  );
}
