import { Field, FieldInputProps, FieldMetaProps } from "formik";
import { InputText } from "primereact/inputtext";
import cn from "classnames";
import React, { ChangeEvent, ReactNode, FocusEventHandler } from "react";
import { KeyFilterType } from "primereact/keyfilter";

interface IApInputText {
  required?: boolean;
  noField?: boolean;
  formik?: any;
  id: string;
  label?: string;
  maxChars?: number;
  keyfilter?: KeyFilterType;
  maxLength?: number;
  placeholder?: string;
  disabled?: boolean;
  validate?: Function;
  price?: boolean;
  value?: string;
  type?: string;
  isError?: boolean;
  rightElement?: ReactNode;
  onChange?: Function;
  autoFocus?: boolean;
  className?: string;
  onBlur?: FocusEventHandler<HTMLInputElement>;
}

export default function ApInputText(props: IApInputText) {
  let meta, field;
  if (props.noField && props.formik) {
    meta = props.formik.getFieldMeta(props.id);
    field = props.formik.getFieldProps(props.id);
  }

  function onChangePrice(e: ChangeEvent<HTMLInputElement>) {
    e.stopPropagation();
    const { value } = e.target;
    if (props.formik) {
      const val = props.price && value.charAt(0) === "£" ? value.substring(1) : value;
      props.formik.setFieldValue(props.id, val);
    }
  }

  return props.noField && meta ? (
    <>
      {props.label && <label htmlFor={props.id}>{props.label}</label>}
      <div>
        <InputText
          id={props.id}
          type={props.type || "text"}
          placeholder={props.placeholder}
          {...field}
          disabled={props.disabled}
          maxLength={props.maxChars || props.maxLength}
          value={((!!props.value || !!field.value) && !!props.price ? "£" : "") + (props.value || field.value || "")}
          keyfilter={props.keyfilter}
          onChange={
            props.price
              ? onChangePrice
              : (e) => {
                  if (typeof props.onChange !== "undefined") {
                    const valueToSet = e.target.value;
                    props.onChange(valueToSet);
                  }
                  field.onChange(e);
                }
          }
          className={cn(props.isError && "error", props.className)}
          onBlur={props.onBlur || field.onBlur}
          autoFocus={props.autoFocus || false}
        />
        {props.maxChars && (
          <div className="charCount text-right">{props.maxChars - (field.value || "").length} characters left</div>
        )}
        {props.rightElement}
      </div>
      {meta.touched && meta.error && <div className="ap-error">{meta.error}</div>}
    </>
  ) : (
    <Field name={props.id} validate={props.validate}>
      {({ field, meta }: { field: FieldInputProps<any>; meta: FieldMetaProps<any> }) => (
        <>
          {props.label && <label htmlFor={props.id}>{props.label}</label>}
          <div>
            <InputText
              id={props.id}
              className={cn(props.isError && "error", props.className)}
              type="text"
              placeholder={props.placeholder}
              {...field}
              disabled={props.disabled}
              maxLength={props.maxChars || props.maxLength}
              value={props.value || field.value || ""}
              keyfilter={props.keyfilter}
              onChange={
                props.price
                  ? onChangePrice
                  : (e) => {
                      if (typeof props.onChange !== "undefined") {
                        const valueToSet = e.target.value;
                        props.onChange(valueToSet);
                      }
                      field.onChange(e);
                    }
              }
              onBlur={props.onBlur || field.onBlur}
              autoFocus={props.autoFocus || false}
            />
            {props.maxChars && (
              <div className="charCount text-right">{props.maxChars - (field.value || "").length} characters left</div>
            )}
            {meta.touched && meta.error && <div className="ap-error">{meta.error}</div>}
          </div>
        </>
      )}
    </Field>
  );
}
