import { FormikProps, FormikValues, useFormik } from "formik";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Steps } from "primereact/steps";
import React, { MouseEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import BriefDatesDaysCount from "~/components/BriefDatesDaysCount/BriefDatesDaysCount";
import { useBriefContext } from "~/contexts/BriefContext";
import { useGlobalContext } from "~/contexts/GlobalContext";
import toasts from "../../store/actions/toasts";
import { BriefStatuses, IBrief } from "../../interfaces/brief";
import { IState } from "../../store/reducers/index";
import { isBriefFormTouched, processBriefForm, processDraftBriefForm } from "../../utils";
import { usePermissionContext } from "~/contexts/PermissionContext";
import { checkBriefForDraft } from "~/routes/Brief/helper";
import Loader from "~/components/Loader/Loader";
import "./CreateBriefForm.scss";
import { useLoadingAnimationContext } from "~/contexts/LoadingAnimationContext";
import actions from "~/store/actions";
import ApBreadCrumb from "~/components/common/ApBreadCrumb/ApBreadCrumb";
import useShowPrompt from "~/hooks/useShowPrompt";

const MIN_STEP = 0;

interface ICreateBriefFormProps {
  saveOrConfirmCallback: (values: any) => void;
  validationSchema: any;
  additionalValues: any;
  renderStepContent: (step: number, formik: FormikProps<FormikValues>, callback?: () => void) => void;
  title: string;
  handleOrganisationChange: (formik: FormikProps<FormikValues>) => void;
  handlerLoadComponent?: (formik: FormikProps<FormikValues>) => void;
  handleChangeDurationType?: (formik: FormikProps<FormikValues>) => void;
  isSubmitButtonDisabled: (formik: FormikProps<FormikValues>) => boolean;
  updateDraftBrief?: (formik: FormikProps<FormikValues>) => Promise<void>;
  cancelDraftBrief?: (briefId: number) => void;
  draftValues?: any;
  isAvailableSaveDraft?: boolean;
  loading: boolean;
}

function CreateBriefForm(props: ICreateBriefFormProps): JSX.Element {
  const {
    title,
    validationSchema,
    additionalValues,
    renderStepContent,
    saveOrConfirmCallback,
    handleOrganisationChange,
    handlerLoadComponent = () => null,
    handleChangeDurationType = () => null,
    isSubmitButtonDisabled,
    draftValues,
    updateDraftBrief,
    isAvailableSaveDraft,
    loading,
    cancelDraftBrief = () => null,
  } = props;
  const { withLoadingAnimation } = useLoadingAnimationContext();
  const dispatch = useDispatch();
  const history = useHistory<{ projectId?: number; organisationId?: number }>();
  const {
    global: { optionsNormalization, TALENT_GOTO_TYPES },
  } = useGlobalContext();

  const { resetBrief, getBriefFormValues, createBrief } = useBriefContext();
  const { isOrganisationAdmin } = usePermissionContext();

  const { DURATION_ID } = optionsNormalization;

  const authenticatedUser = useSelector((state: IState) => state.user.authenticatedUser);
  const [step, setStep] = useState(0);

  useEffect(() => {
    formik.validateForm();
  }, [step]);

  const redirectToFirstStep = () => {
    setStep(0);
    window.scrollTo(0, 0);
    formik.setSubmitting(false);
    setTimeout(() => {
      formik.setFieldError("ir35_id", "Please review IR35 type for this brief");
      formik.setFieldValue("ir35_id", null);
      formik.setTouched({ ...formik.touched, ir35_id: true });
    });
  };

  const handleOnSubmit = (form: any, actions) => {
    if (isLastStep) {
      processBriefForm(form, saveOrConfirm, { status: BriefStatuses.OPEN }, TALENT_GOTO_TYPES);
    } else {
      window.scrollTo(0, 0);
      setStep(step + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };
  const saveDraft = (e: React.MouseEvent<HTMLButtonElement>, formik: any) => {
    e.preventDefault();
    setShowPrompt(false);
    const isExistingDraftBrief = !!formik.values.id;
    processDraftBriefForm(
      formik.values,
      isExistingDraftBrief ? updateDraftBrief : createBrief,
      history,
      TALENT_GOTO_TYPES
    );
  };

  const cancelDraft = ({ values: { id } }: any) => {
    dispatch(
      toasts.setPopup({
        content: (
          <>
            Are you sure you want to cancel this draft brief? Once cancelled it will be deleted permanently from your
            drafts.
          </>
        ),
        buttons: [
          {
            text: "Go back",
            callback: () => dispatch(actions.modal.closeModal()),
          },
          {
            text: "Delete Draft",
            callback: async () => {
              setShowPrompt(false);
              await cancelDraftBrief(id);
              history.push({
                pathname: `/projects/${formik.values.project_id}`,
                state: { briefsCategoryIndex: 3 },
              });
            },
          },
        ],
      })
    );
  };

  const currentValidationSchema = validationSchema(step);

  const formik = useFormik({
    initialValues: draftValues || getBriefFormValues(additionalValues),
    onSubmit: handleOnSubmit,
    validationSchema: currentValidationSchema,
  });

  const isBriefDraft = checkBriefForDraft(formik.values);

  const steps = [
    { label: "Step 1", formTitle: "Details" },
    { label: "Step 2", formTitle: "Details" },
  ];
  const isLastStep = step === steps.length - 1;

  useEffect(() => {
    if (!isBriefDraft) {
      handlerLoadComponent(formik);
      resetBrief();
    }
  }, []);

  const { setShowPrompt } = useShowPrompt();
  useEffect(() => {
    !isBriefDraft && DURATION_ID && handleChangeDurationType(formik);
  }, [DURATION_ID]);

  // Compare form state with initial values to detect changes
  useEffect(() => {
    const isTouched = isBriefFormTouched({ ...formik.values }, { ...formik.initialValues }, isOrganisationAdmin);
    setShowPrompt(isTouched);
  }, [formik.values]);

  // Load client info on client change
  useEffect(() => {
    if (formik.values.organisation_id) {
      handleOrganisationChange(formik);
    }
  }, [formik.values.organisation_id]);

  useEffect(() => {
    if (authenticatedUser?.id && authenticatedUser?.organisation_id && isOrganisationAdmin) {
      formik.setFieldValue("organisation_id", authenticatedUser?.organisation_id);
      formik.setFieldValue("author_id", authenticatedUser?.id);
    }
  }, [authenticatedUser]);

  useEffect(() => {
    history.location.state?.projectId && formik.setFieldValue("project_id", history.location.state.projectId);
    history.location.state?.organisationId &&
      formik.setFieldValue("organisation_id", history.location.state.organisationId);
  }, [history.location.state?.projectId]);

  function prevStep(e: MouseEvent) {
    e.stopPropagation();
    window.scrollTo(0, 0);
    if (step > MIN_STEP) {
      setStep(step - 1);
    }
  }

  function saveOrConfirm(values: IBrief) {
    const isBriefDraft = checkBriefForDraft(formik.values);
    const updateDraftBriefFunction = (values) => withLoadingAnimation(updateDraftBrief, values);
    const callback =
      isBriefDraft && updateDraftBrief ? () => updateDraftBriefFunction(values) : () => saveOrConfirmCallback(values);

    dispatch(
      toasts.setPopup({
        content: <BriefDatesDaysCount data={formik.values} />,
        buttons: [
          { text: "Go back" },
          {
            text: "Confirm",
            callback,
          },
        ],
      })
    );
  }

  const submitLabel = () => {
    return isLastStep ? "Create Brief" : "Next";
  };

  const isDisallowSaveDraft = (formik: FormikProps<FormikValues>) =>
    !formik.values.name || !formik.values.organisation_id || !formik.values.author_id;

  const breadcrumModel = [{ label: "Resourcing", url: "/resourcing" }, { label: "New Brief" }];

  return (
    <div>
      <header>
        <div>
          <ApBreadCrumb model={breadcrumModel} />
          <h1>{title}</h1>
        </div>
        <Steps className="steps" activeIndex={step} model={steps} readOnly={true}></Steps>
      </header>
      <div>
        <form>
          <Card className="step-content" title={steps[step].formTitle}>
            {renderStepContent(step, formik, redirectToFirstStep)}
            <footer className="p-field">
              {step > 0 && step !== steps.length && (
                <Button
                  className="p-button p-button-secondary align-left mr-2"
                  label="Back"
                  onClick={prevStep}
                  type="button"
                />
              )}
              {draftValues?.status === BriefStatuses.DRAFT && (
                <Button
                  className="p-button align-left"
                  disabled={isDisallowSaveDraft(formik)}
                  label="Delete Draft"
                  type="button"
                  onClick={() => cancelDraft(formik)}
                />
              )}
              <div>
                {isAvailableSaveDraft && (
                  <Button
                    className="p-button mr-2"
                    disabled={isDisallowSaveDraft(formik)}
                    label="Save Draft"
                    type="button"
                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => saveDraft(e, formik)}
                  />
                )}
                <Button
                  className="p-button"
                  disabled={isSubmitButtonDisabled(formik)}
                  type="button"
                  onClick={formik.handleSubmit}
                >
                  {loading ? <Loader /> : submitLabel()}
                </Button>
              </div>
            </footer>
          </Card>
        </form>
      </div>
    </div>
  );
}

export default CreateBriefForm;
