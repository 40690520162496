import Axios from "axios";
import { API_URL } from "~/config";

export class TalentBookingsService {
  static async getTalentBookings(isAdmin: boolean, talentId: number) {
    const url = API_URL + (isAdmin ? `/talent/${talentId}/bookings` : `/client_admin/bookings?talent_id=${talentId}`);
    return await Axios.get(url);
  }
  static async UpdateClearanceById(clearanceId: number, data: any) {
    return await Axios.put(`${API_URL}/clearances/${clearanceId}`, data);
  }
  static async CreateBooking(briefId: number, data: any, isAdmin: boolean = false) {
    const prefix = isAdmin ? "" : "/client_admin";
    return await Axios.post(`${API_URL}${prefix}/brief/${briefId}/booking`, data);
  }
}
