import { FormikProps, FormikValues } from "formik";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import ApRadioGroup from "~/components/common/ApRadioGroup/ApRadioGroup";
import { useGlobalContext } from "~/contexts/GlobalContext";
import AvailabilityCalendarBlock from "~/forms/BriefViewDetailsForm/Calendar/AvailabilityCalendarBlock";
import TalentInfoBlock from "~/components/common/TalentInfoBlock/TalentInfoBlock";
import ApInputNumber from "~/components/common/ApInputNumber/ApInputNumber";
import { shortCapacityValues } from "~/config";
import ApInputTextarea from "~/components/common/ApInputTextarea/ApInputTextarea";
import "./DirectMatchClearanceForm.scss";
import DatesCapacityForm from "../BriefViewDetailsForm/Calendar/CalendarFooterTemplate/DatesCapacityForm";
import { ITalent } from "~/store/constants/talent";
const DEFAULT_DURATION_DAYS = 1;

interface IClearanceDatesForm {
  preSelectedTalents: ITalent[];
  formik: FormikProps<FormikValues>;
}

export default function DirectMatchClearanceForm(props: IClearanceDatesForm) {
  const { formik, preSelectedTalents } = props || {};

  const {
    global: { optionsNormalization, globalOptions },
  } = useGlobalContext();
  const DURATION_ID = optionsNormalization?.DURATION_ID;
  const [durationDays, setDurationDays] = useState(DEFAULT_DURATION_DAYS);
  const [maxDurationDays, setMaxDurationDays] = useState(null as any);

  const [dates, setDates] = useState(formik?.values?.dates || []);
  const durationTypes = globalOptions?.duration_types || [];
  const durationTypeOptions = useMemo(
    () =>
      durationTypes
        ?.filter(({ is_enabled }: { is_enabled: boolean }) => is_enabled)
        ?.map(({ id: code, name, description }: { id: string; name: string; description: string }) => ({
          code,
          name,
          description,
        })),
    [durationTypes]
  );

  useEffect(() => {
    formik?.validateForm();
  }, []);

  const changeDurationTypes = (value: number, formik: FormikProps<FormikValues>) => {
    setDates([]);
    formik.setValues({
      ...formik.values,
      start_date: "",
      end_date: "",
      dates: [],
      duration_id: value,
      duration_days: DEFAULT_DURATION_DAYS,
    });
    setDurationDays(DEFAULT_DURATION_DAYS);
    setMaxDurationDays(null);
  };

  return (
    <div className="modal-container ClearanceForm">
      <div className="left-block">
        {preSelectedTalents.length === 1 && (
          <>
            <label className="talent-label">Talent</label>
            <TalentInfoBlock preSelectedTalents={preSelectedTalents} />
            <AvailabilityCalendarBlock
              formik={formik}
              dates={dates}
              setDates={setDates}
              durationDays={durationDays}
              setDurationDays={setDurationDays}
              maxDurationDays={maxDurationDays}
              setMaxDurationDays={setMaxDurationDays}
              talentId={preSelectedTalents[0]?.id}
              talentType={preSelectedTalents[0]?.type}
              capacity={formik.values.capacity}
            />
          </>
        )}
      </div>
      <div className="right-block">
        <div className="p-fluid field field-required mb-4">
          <label htmlFor="duration_id">Duration types</label>
          <ApRadioGroup
            name="duration_id"
            options={durationTypeOptions}
            onChange={({ value }) => changeDurationTypes(value, formik)}
            withTooltip
          />
        </div>
        <div className="p-fluid field field-required">
          <label htmlFor="duration_id">Capacity</label>

          {formik.values.duration_id === DURATION_ID?.EXACT ? (
            <DatesCapacityForm setDates={setDates} dates={dates} formik={formik} dateFormat="MMM D" />
          ) : (
            <>
              <br />
              {formik.values.start_date && formik.values.end_date && (
                <div className="active-date">
                  {moment(formik.values.start_date).format("MMM D")} - {moment(formik.values.end_date).format("MMM D")}
                </div>
              )}

              <div className="p-fluid field field-required">
                <ApInputNumber
                  id="capacity"
                  formik={formik}
                  noField
                  max={100}
                  min={1}
                  maxLength={4}
                  suffix="%"
                  placeholder="100"
                  shorthandValues={shortCapacityValues}
                  disabled={!formik.values.start_date.length}
                />
              </div>
            </>
          )}
        </div>
        <div className="p-fluid field">
          <ApInputTextarea id="comment" label="Comment" formik={formik} maxChars={1200} noField />
        </div>
      </div>
    </div>
  );
}
