import React from "react";
import { Button } from "primereact/button";
import "./ViewToggler.scss";
import cn from "classnames";
import { SquaresFour, Table } from "phosphor-react";

export enum ViewModes {
  GRID = "GRID",
  TABLE = "TABLE",
}

export default function ViewToggler({
  viewMode,
  setViewMode,
}: {
  viewMode: ViewModes;
  setViewMode: (mode: ViewModes) => void;
}) {
  function onChange(mode: ViewModes) {
    localStorage.setItem("VIEW_MODE", mode);
    setViewMode(mode);
  }

  return (
    <div className="ViewToggler">
      <label>View:</label>
      <Button
        className={cn("with-icon", { active: viewMode === ViewModes.TABLE })}
        onClick={() => onChange(ViewModes.TABLE)}
        tooltip="Table view"
        tooltipOptions={{ position: "top" }}
      >
        <Table size={25} />
      </Button>
      <Button
        className={cn("with-icon", { active: viewMode === ViewModes.GRID })}
        onClick={() => onChange(ViewModes.GRID)}
        tooltip="Grid view"
        tooltipOptions={{ position: "top" }}
      >
        <SquaresFour size={25} />
      </Button>
    </div>
  );
}
