import * as Yup from "yup";
import { requiredFieldMessage } from "~/utils";
import { selectAtLeastOne } from "./helpers";

export const directMatchSchema = (stepCode: string) => {
  switch (stepCode) {
    case "STEP_1_CLEARANCE":
      return Yup.object().shape(ClearanceSchema());
    case "STEP_2_EXISTING_BRIEF":
      return Yup.object().shape(ExistingBriefSchema());
    case "STEP_2_NEW_BRIEF_EXISTING_PROJECT":
      return Yup.object().shape(NewBriefSchema());
    case "STEP_2_NEW_PROJECT":
      return Yup.object().shape(NewProjectSchema());
    case "STEP_2_NEW_BRIEF":
      return Yup.object().shape(NewBriefNewProjectSchema());
    default:
      return Yup.object().shape({});
  }
};

const ClearanceSchema = () => {
  return {
    capacity: Yup.number().typeError("Please, add the capacity").min(1).max(100),
    start_date: Yup.string().required(requiredFieldMessage),
    end_date: Yup.string().nullable(),
    include_weekends: Yup.boolean(),
    comment: Yup.string()
      .min(2, "Please, add at least 2 symbols")
      .max(1200, "Please, make it shorter than 1200 symbols"),
  };
};

const ExistingBriefSchema = () => {
  return {
    talent_ids: Yup.array().of(Yup.number().required()).min(1, "At least one talent must be selected"),
    organisation_id: Yup.number().required(requiredFieldMessage),
    project_id: Yup.number().nullable().required(requiredFieldMessage),
    brief_id: Yup.number().nullable().required(requiredFieldMessage),
  };
};

const NewBriefSchema = () => {
  return {
    talent_ids: Yup.array().of(Yup.number().required()).min(1, "At least one talent must be selected"),
    organisation_id: Yup.number().required(requiredFieldMessage),
    author_id: Yup.number().required(requiredFieldMessage),
    project_id: Yup.number().nullable().required(requiredFieldMessage),
    brief_name: Yup.string().required(requiredFieldMessage),
    description: Yup.string()
      .min(2, "Please, add at least 2 symbols")
      .max(1000, "Please, make it shorter than 1000 symbols")
      .test(function (this: any, value: string | undefined) {
        const { description_url } = this.parent;
        if (!!description_url || !!value) {
          return true;
        }
        return this.createError({ message: "Please type a brief summary description or upload a brief file" });
      }),
    description_url: Yup.mixed().test(function (this: any, value: File | string | undefined) {
      const { description } = this.parent;
      if (!!description || !!value) {
        return true;
      }
      return this.createError({ message: "Please type a brief summary description or upload a brief file" });
    }),
    assignees: Yup.array().of(Yup.number()).label("brief assignee").min(1, selectAtLeastOne),
    ...ClearanceSchema(),
  };
};

const NewBriefNewProjectSchema = () => {
  return {
    ...NewBriefSchema(),
    project_id: Yup.number().nullable(),
  };
};

const NewProjectSchema = () => {
  return {
    talent_ids: Yup.array().of(Yup.number().required()).min(1, "At least one talent must be selected"),
    organisation_id: Yup.number().required(requiredFieldMessage),
    project_name: Yup.string()
      .min(2, "Please, add at least 2 symbols")
      .max(255, "255 characters max")
      .required(requiredFieldMessage),
    project_custom_id: Yup.string().nullable().max(100),
    project_description: Yup.string()
      .min(2, "Please, add at least 2 symbols")
      .max(1000, "Please, make it shorter than 1000 symbols")
      .test(function (this: any, value: string | undefined) {
        const { proposal_url } = this.parent;
        if (!!proposal_url || !!value) {
          return true;
        }
        return this.createError({ message: "Please type a project proposal description or upload a proposal file" });
      }),
    proposal_url: Yup.mixed().test(function (this: any, value: File | string | undefined) {
      const { project_description } = this.parent;
      if ((!!project_description && project_description.length > 2) || !!value) {
        return true;
      }
      return this.createError({ message: "Please type a project proposal description or upload a proposal file" });
    }),
    program_lead: Yup.string().nullable(),
    client_lead: Yup.string().nullable(),
    client_pod: Yup.string().nullable(),
    client: Yup.string().required(requiredFieldMessage),
    author_id: Yup.number().required(requiredFieldMessage),
    project_dates: Yup.array().of(Yup.mixed()).min(2, requiredFieldMessage).required(requiredFieldMessage),
  };
};
