import Axios, { GenericAbortSignal } from "axios";
import { API_URL } from "~/config";
import { AxiosError, AxiosResponse } from "axios";
import { IAIBioRequestParams, IQueryParams } from "~/interfaces/common";
import { ITalent, ITalentUser } from "~/store/constants/talent";

export class TalentService {
  static createPortfolioViewEvent = async (talentId: number) => {
    try {
      await Axios.post(`${API_URL}/client_admin/talent/${talentId}/event/portfolio_opened`);
    } catch (err) {
      console.log(err);
    }
  };
  static getMatches = async (
    isClientAdmin: boolean,
    talentId: number,
    status: string = "PENDING",
    controller: { signal: GenericAbortSignal }
  ): Promise<AxiosResponse | AxiosError> => {
    try {
      return await Axios.get(`${API_URL}/${isClientAdmin ? "client_admin/" : ""}talent/${talentId}/matches`, {
        params: { status },
        signal: controller?.signal,
      });
    } catch (err) {
      throw err;
    }
  };
  static async editTalent(talentId: number, data: ITalent) {
    return await Axios.put(`${API_URL}/talent/${talentId}`, data);
  }
  static async removeTalent(talentId: number) {
    return await Axios.delete(`${API_URL}/talent/${talentId}`);
  }
  static async updateTalentCV(talentId: number, file: any) {
    const data = new FormData();
    data.append("cv_url", file);
    return await Axios.post(`${API_URL}/talent/${talentId}`, data);
  }
  static async deleteTalentCV(talentId: number) {
    return await Axios.post(`${API_URL}/talent/${talentId}`, { cv_url: null });
  }
  static postUserEvent = async (event: string) => await Axios.post(`${API_URL}/user/current/event/${event}`, {});
  static async getIndividualUsers(params: IQueryParams, controller: { signal: GenericAbortSignal }) {
    return await Axios.get(`${API_URL}/talents/individual`, { params, signal: controller?.signal });
  }
  static async getIntroFLow() {
    return await Axios.get(`${API_URL}/user/current/intro_flow`);
  }
  static postFavouriteTalent = async (talentId: number) => await Axios.post(`${API_URL}/favourite/talent/${talentId}`);
  static deleteFavouriteTalent = async (talentId: number) =>
    await Axios.delete(`${API_URL}/favourite/talent/${talentId}`);
  static updateUserById = async (userId: number, data: ITalentUser) =>
    await Axios.put(`${API_URL}/user/${userId}`, data);
  static getAIBio = async (params: IAIBioRequestParams) =>
    await Axios.get(`${API_URL}/talent/generate_bio`, { params });
  static getAvailabilityCalendar = async (talentId: number, date: string) =>
    await Axios.get(`${API_URL}/talent/${talentId}/calendar`, { params: { date } });
}
