.availability-bar {
  cursor: pointer;
  .p-progressbar {
    height: 10px;
    width: 75px;
    border-radius: 10px;
    &.hidden {
      display: none;
    }
  }
  .green {
    background-color: var(--green-color);
  }
  .yellow {
    background-color: var(--yellow-color);
  }
  .red {
    background-color: var(--brand-color);
  }
}
