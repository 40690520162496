import cn from "classnames";
import { Form, Formik, FormikProps } from "formik";
import { Button } from "primereact/button";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "~/components/Loader/Loader";
import ApCalendar from "~/components/common/ApCalendar/ApCalendar";
import ApDropdown from "~/components/common/ApDropdown/ApDropdown";
import ApFileUpload from "~/components/common/ApFileUpload/ApFileUpload";
import ApInputText from "~/components/common/ApInputText/ApInputText";
import ApInputTextarea from "~/components/common/ApInputTextarea/ApInputTextarea";
import { useEntityContext } from "~/contexts/EntityContext";
import { usePermissionContext } from "~/contexts/PermissionContext";
import { useProjectContext } from "~/contexts/ProjectContext";
import useOrganisations from "~/hooks/useOrganisations";
import { IProjectForm, ProjectStatuses } from "~/interfaces/project";
import { CreateDraftProjectSchema, CreateProjectSchema } from "~/schemas/CreateProject";
import { IState } from "~/store/reducers/index";
import { IUserState } from "~/store/reducers/user";
import { validateFile } from "~/utils";
import actions from "../../store/actions";
import "./CreateProjectForm.scss";
import { IOrganisation } from "~/store/constants/organisation";

interface IProjectFormProps {
  initialValues: IProjectForm;
  onSubmit: any;
  handlePropmt: (isDirty: boolean) => void;
}

export default function CreateProjectForm({ initialValues, onSubmit, handlePropmt }: IProjectFormProps) {
  const dispatch = useDispatch();
  const formikRef = useRef<FormikProps<IProjectForm> | null>(null);
  const { isAdmin, isRegionalAdmin, isLocalAdmin } = usePermissionContext();
  const {
    programLeads,
    clientLeads,
    clientPods,
    setClientPods,
    podClients,
    setPodClients,
    getLeads,
    getClientPods,
    getPodClients,
    isFetching,
  } = useEntityContext();
  const { isProposalFetching, updateProjectProposal } = useProjectContext();
  const currentUser = useSelector(({ user: { authenticatedUser } }: { user: IUserState }) => authenticatedUser);
  const { organisations: storeOrganisations, isOrganisationsLoading } = useOrganisations();
  const { organisation } = useSelector((state: IState) => state.organisation);

  const organisations = isAdmin || isRegionalAdmin ? storeOrganisations : organisation ? [organisation] : [];

  const [organisationId, setOrganisationId] = useState<number | null>(null);
  const [submittedButton, setSubmittedButton] = useState<IProjectForm["status"] | null>(null);
  const [selectedClientPod, setSelectedClientPod] = useState<string | undefined>(undefined);

  const isNewProject = !initialValues.id;
  const readOnlyMode =
    !isNewProject && !isAdmin && !isRegionalAdmin && !isLocalAdmin && initialValues.author_id !== currentUser?.id;

  useEffect(() => {
    isAdmin && !organisationId && dispatch(actions.organisation.listOrganisations({ published: 1 }));
  }, [isAdmin]);

  useEffect(() => {
    const orgId =
      isNewProject && !isAdmin && !isRegionalAdmin ? currentUser?.organisation_id : initialValues.organisation || null;

    if (!organisationId && !!orgId) {
      dispatch(actions.organisation.getOrganisation(orgId));
      setOrganisationId(orgId);
    }
  }, [currentUser?.organisation_id, initialValues.organisation]);

  useEffect(() => {
    !!organisationId && getLeads(organisationId);
    !!organisationId && getClientPods(organisationId);
    !!organisationId && setPodClients([]);

    if (organisationId && ((!isAdmin && !isRegionalAdmin && isNewProject) || !isNewProject)) {
      formikRef.current?.setFieldValue("organisation", organisationId);
      formikRef.current?.setFieldValue("author_id", initialValues.author_id || currentUser?.id);
    }
  }, [organisationId]);

  useEffect(() => {
    if (clientPods.length && !isNewProject && initialValues.client_pod) {
      setSelectedClientPod(initialValues.client_pod);
    }
  }, [clientPods, initialValues.client_pod]);

  useEffect(() => {
    if (selectedClientPod && clientPods.length) {
      const clientPodId = clientPods.find((pod) => pod.name === selectedClientPod)?.id;
      clientPodId && getPodClients(clientPodId);
    }
  }, [selectedClientPod]);

  useEffect(() => {
    return () => {
      setClientPods([]);
      setPodClients([]);
    };
  }, []);

  const { projectOwners, clients } = useMemo(() => {
    const organisation =
      organisationId && organisations?.length
        ? (organisations as IOrganisation[])?.find(({ id }) => id === organisationId)
        : { users: [], clients: [] };

    return {
      projectOwners: organisation?.users || [],
      clients: organisation?.clients?.filter((c) => !c.client_pod) || [],
    };
  }, [organisationId, organisations]);

  const clientsOptions = useMemo(
    () => (selectedClientPod ? podClients : clients),
    [selectedClientPod, podClients, clients]
  );

  const saveProject = async (type: IProjectForm["status"]) => {
    if (type === ProjectStatuses.DRAFT) {
      try {
        CreateDraftProjectSchema.validateSync(formikRef.current?.values, { abortEarly: false });
        setSubmittedButton(ProjectStatuses.DRAFT);
        await onSubmit({ ...formikRef.current?.values, status: "DRAFT" });
      } catch (err: any) {
        const errors = err.inner.reduce(
          (acc: any, { path, message }: any) => ({
            ...acc,
            [path]: message,
          }),
          {}
        );

        formikRef.current?.setErrors(errors);
        formikRef.current?.setTouched(errors);
      }
    } else {
      if (formikRef.current?.values.status === ProjectStatuses.DRAFT || !formikRef.current?.values.status) {
        formikRef.current?.setFieldValue("status", ProjectStatuses.OPEN);
      }
      formikRef.current?.isValid && setSubmittedButton(ProjectStatuses.OPEN);
      await formikRef.current?.submitForm();
    }

    setSubmittedButton(null);
  };

  async function onUploadProposal([file]: [File], setFieldValue: any, setFieldError: any) {
    const errors = validateFile(file, {
      size: 10000000,
      types: ["doc", "docx", "pdf", "xls", "xlsx"],
    });

    if (errors.length) {
      setFieldError("proposal_url", errors.join(", "));
      return;
    }

    handlePropmt(false);

    if (initialValues.id) {
      const proposal_url = await updateProjectProposal({ id: initialValues.id, proposal_url: file });
      setFieldValue("proposal_url", proposal_url);
    } else {
      setFieldValue("proposal_url", file);
    }
  }

  function deleteProposal(setFieldValue: any) {
    if (initialValues.id && formikRef.current?.values.proposal_url) {
      const proposal_url = updateProjectProposal({ id: initialValues.id, proposal_url: null });
      setFieldValue("proposal_url", proposal_url);
    }
    setFieldValue("proposal_url", null);
  }

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={CreateProjectSchema}
        onSubmit={onSubmit}
        innerRef={formikRef}
      >
        {(props) => {
          return (
            <Form className="CreateProjectForm" onChange={() => handlePropmt(true)}>
              <div className="p-fluid field field-required">
                <ApInputText id="name" label="Project Name" disabled={readOnlyMode} />
              </div>
              <div className="p-fluid field">
                <ApInputText id="project_id" label="Project ID" disabled={readOnlyMode} />
              </div>
              <div className={cn("p-fluid field mb-5", !props.values.proposal_url && "field-required")}>
                <ApInputTextarea id="description" label="Project Proposal" maxChars={1000} readOnly={readOnlyMode} />
              </div>
              <div className="p-fluid field mb-5">
                {isProposalFetching ? (
                  <Loader />
                ) : (
                  <ApFileUpload
                    id="proposal_url"
                    mode="basic"
                    auto
                    accept=".doc,.docx,.pdf,.xls,.xlsx"
                    onChange={(e) => onUploadProposal(e, props.setFieldValue, props.setFieldError)}
                    buttonLabel="Upload proposal"
                    onDelete={(e) => deleteProposal(props.setFieldValue)}
                    disabled={readOnlyMode}
                  />
                )}
              </div>
              {(isAdmin || isRegionalAdmin) && (
                <div className="formgrid grid">
                  <div className="col field field-required">
                    <ApDropdown
                      id="organisation"
                      label="Organisation"
                      options={organisations}
                      optionLabel="name"
                      optionValue="id"
                      disabled={!isNewProject}
                      loading={isOrganisationsLoading}
                      onChange={({ value }) => {
                        setOrganisationId(value);
                        setSelectedClientPod(undefined);
                        formikRef.current?.setValues({
                          ...formikRef.current?.values,
                          organisation: value,
                          author_id: undefined,
                          program_lead: undefined,
                          client_lead: undefined,
                          client_pod: undefined,
                          client: undefined,
                        });
                        handlePropmt(true);
                      }}
                    />
                  </div>
                  <div className="col field field-required">
                    <ApDropdown
                      id="author_id"
                      label="Project Owner"
                      options={projectOwners}
                      optionLabel="full_name"
                      optionValue="id"
                      disabled={!isNewProject}
                    />
                  </div>
                </div>
              )}
              <div className="formgrid grid">
                <div className="col field">
                  <ApDropdown
                    editable
                    id="program_lead"
                    label="Program Lead"
                    options={programLeads}
                    optionLabel="name"
                    optionValue="name"
                    disabled={readOnlyMode}
                    loading={isFetching.leads}
                  />
                </div>
                <div className="col field">
                  <ApDropdown
                    editable
                    id="client_lead"
                    label="Client Lead"
                    options={clientLeads}
                    optionLabel="name"
                    optionValue="name"
                    disabled={readOnlyMode}
                    loading={isFetching.leads}
                  />
                </div>
              </div>
              <div className="formgrid grid">
                <div className="col field">
                  <ApDropdown
                    editable
                    panelClassName="no-transform"
                    id="client_pod"
                    label="Client Group"
                    options={clientPods}
                    optionLabel="name"
                    optionValue="name"
                    onChange={({ value }) => {
                      setSelectedClientPod(value);
                      formikRef.current?.setFieldValue("client_pod", value);
                      formikRef.current?.setFieldValue("client", undefined);
                    }}
                    disabled={readOnlyMode}
                    loading={isFetching.clientPods}
                  />
                </div>
                <div className="col field field-required">
                  <ApDropdown
                    panelClassName="no-transform"
                    editable
                    id="client"
                    label="Client Name"
                    options={clientsOptions}
                    optionLabel="name"
                    optionValue="name"
                    disabled={readOnlyMode}
                    loading={isFetching.podClients}
                  />
                </div>
              </div>
              <div className="p-fluid field field-required">
                <ApCalendar
                  id="dates"
                  label="Project Dates"
                  selectionMode="range"
                  readOnlyInput
                  disabled={readOnlyMode}
                />
              </div>
              {!readOnlyMode && (
                <footer className="text-right">
                  {(isNewProject || (!isNewProject && initialValues.status === ProjectStatuses.DRAFT)) && (
                    <Button
                      loading={submittedButton === ProjectStatuses.DRAFT}
                      onClick={() => saveProject(ProjectStatuses.DRAFT)}
                      label="Save Draft"
                      className="mr-2"
                      type="button"
                    />
                  )}
                  <Button
                    loading={submittedButton === ProjectStatuses.OPEN}
                    onClick={() => saveProject(ProjectStatuses.OPEN)}
                    label={
                      isNewProject || initialValues.status === ProjectStatuses.DRAFT
                        ? "Create Project"
                        : "Update Project"
                    }
                    type="button"
                  />
                </footer>
              )}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
