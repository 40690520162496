import { IClient, IListItem } from "~/interfaces/common";
import capitalize from "lodash/capitalize";

export interface IColumn {
  name?: string;
  field: string;
  header?: string;
  showStatement?: boolean;
  sortable?: boolean;
  cellCallback?: (rowData: any, columnProps?: any) => JSX.Element | null;
  className?: string;
  props?: {
    [key: string]: any;
  };
  style?: {
    [key: string]: any;
  };
}

const getColumns = (briefsCategory: string | undefined = "All") => {
  const renderColumnClient = ({ clients }: { clients: IClient[] }) => {
    return !!clients.length ? clients[0]?.name : null;
  };

  const columns: IColumn[] = [
    {
      field: "discipline_name",
      header: "Discipline",
      props: {
        sortable: true,
      },
    },
    {
      field: "projects",
      header: "Project",
      showStatement: !window.location.pathname.includes("/resourcing"),
      props: {
        body: ({ projects }: { projects: IListItem[] }) => projects?.map(({ name }) => name).join(", "),
      },
    },
    {
      field: "wbs_code",
      header: "WBS code",
      props: {
        sortable: true,
      },
    },
    {
      field: "job_number",
      header: "Job number",
      props: {
        sortable: true,
      },
    },
    {
      field: "client_name",
      header: "Client",
      props: {
        sortable: true,
        body: renderColumnClient,
      },
    },
    {
      field: "start_date",
      header: "Start date",
      showStatement: briefsCategory !== "draft",
      props: { sortable: true },
    },
    {
      field: "end_date",
      header: "End date",
      showStatement: briefsCategory !== "opened" && briefsCategory !== "draft",
      props: { sortable: true },
    },
  ];

  return columns;
};

export default getColumns;
