import { AxiosError, AxiosResponse } from "axios";
import React, { createContext, useContext } from "react";
import useOptions, { IConfigType, IOptionsNormalization } from "~/hooks/useOptions";
import { IPaymentCompany, IUmbrella, IGotoType } from "~/interfaces/brief";
import { IClient } from "~/interfaces/common";

export interface ICountry {
  name: string;
  code: string;
}

export interface ITimeZone {
  name: string;
  code: string;
}
export interface ILanguage {
  id: number;
  name: string;
}
export interface IGlobalOptions {
  isFetching: boolean;
  globalOptions: any;
  publicOptions: { images: { [key: string]: string } };
  umbrellaCompanies: IUmbrella[];
  paymentCompanies: IPaymentCompany[];
  clients: IClient[];
  countries: ICountry[];
  timezones: ITimeZone[];
  languages: ILanguage[];
  getGlobalOptions: () => void;
  getPublicOptions: () => void;
  getGlobalOption: (configID: IConfigType, code: string) => any;
  getGlobalOptionValue: (configID: IConfigType, code: string, defaultValue?: string) => string;
  getCountries: () => void;
  getTimeZones: () => void;
  getCities: (search: string) => Promise<AxiosResponse | AxiosError>;
  getLanguages: () => void;
  getUmbrellaCompanies: () => void;
  getPaymentCompanies: () => void;
  getClients: () => void;
  setCountry: (value: string) => void;
  optionsNormalization: IOptionsNormalization;
  maxSkillsForDiscipline: { [key: string | number]: number };
  TALENT_GOTO_TYPES: IGotoType[];
}

export const GlobalContext = createContext(null as { global: IGlobalOptions } | null);

export const useGlobalContext = () => {
  const context = useContext(GlobalContext);
  if (!context) {
    throw new Error("useGlobalContext must be used within a GlobalContextProvider");
  }
  return context;
};

const globalContextProvider = ({ children }: any) => {
  return (
    <GlobalContext.Provider
      value={{
        global: { ...useOptions() },
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default globalContextProvider;
