import { useState } from "react";
import isNull from "lodash/isNull";
import { ALL_DISCIPLINES } from "~/components/common/DisciplinesDropdown/DisciplinesDropdown";
import { ALL_BRIEF_TYPES } from "~/components/common/BriefTypesDropdown/BriefTypesDropdown";
import { BriefStatuses, IBriefFilterSettings } from "~/interfaces/brief";
import { DataTableSortMeta } from "primereact/datatable";
import getColumns from "~/components/common/BriefsTabs/columns";
import { prepareCustomColumns } from "~/utils";

const briefsCategories = [
  { label: "Open", code: BriefStatuses.OPEN },
  { label: "Resourced", code: BriefStatuses.RESOURCED },
  { label: "Cancelled", code: BriefStatuses.CANCELLED },
  { label: "Draft", code: BriefStatuses.DRAFT },
  { label: "All", code: BriefStatuses.ALL },
];
const defaultSort: DataTableSortMeta = {
  field: "id",
  order: -1,
};

const useBriefFilterSettings = () => {
  const [briefsCategory, setBriefsCategory] = useState(null as null | { label: string; code: string });
  const [discipline, setDiscipline] = useState(ALL_DISCIPLINES);
  const [briefType, setBriefType] = useState(ALL_BRIEF_TYPES);
  const columns = getColumns(briefsCategory?.code);
  const columnsOptions = prepareCustomColumns(columns);

  const getDefaultColumns = () => {
    const local = localStorage.getItem("BRIEF_COLUMNS");

    if (isNull(local)) return columnsOptions;
    if (local.length === 0) return [];

    const localArr = local.split(",");
    const filteredColumns = columnsOptions.reduce(
      (acc: { field: string; header: string }[], key: { field: string; header: string }) => {
        if (localArr.includes(key.field)) acc.push(key);
        return acc;
      },
      []
    );

    if (filteredColumns.length > 0) return filteredColumns;

    return columnsOptions;
  };

  const [selectedColumns, setSelectedColumns] = useState<{ field: string; header: string }[]>(
    getDefaultColumns() as { field: string; header: string }[]
  );
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState<DataTableSortMeta>(defaultSort);
  const defaultCategoryIndex = briefsCategories.findIndex((item) => item.code === "ALL");

  const resetFilterBriefsSettings = (currentPath: string) => {
    const isExistBriefId = Number.isInteger(+currentPath?.split("/")?.filter(Boolean)[1]);
    const isItemBriefPath = currentPath?.includes("/briefs") && isExistBriefId;
    const isItemOrganisationPath = currentPath?.includes("/organisations") && isExistBriefId;
    const isItemProjectPath = currentPath?.includes("/projects") && isExistBriefId;

    if (currentPath && currentPath !== "/briefs" && !isItemBriefPath && !isItemProjectPath && !isItemOrganisationPath) {
      setBriefsCategory(null);
      setDiscipline(ALL_DISCIPLINES);
      setSearch("");
      setSort(defaultSort);
      setBriefType(ALL_BRIEF_TYPES);
    }
  };

  return {
    columns,
    columnsOptions,
    briefType,
    setBriefType,
    briefsCategories,
    briefsCategory,
    setBriefsCategory,
    defaultCategoryIndex,
    discipline,
    setDiscipline,
    resetFilterBriefsSettings,
    selectedColumns,
    setSelectedColumns,
    search,
    setSearch,
    sort,
    setSort,
  } as IBriefFilterSettings;
};
export default useBriefFilterSettings;
