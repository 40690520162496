import { Field, FieldInputProps, FieldMetaProps, FormikProps, FormikValues } from "formik";
import { MultiSelect, MultiSelectProps } from "primereact/multiselect";
import { InputSwitch } from "primereact/inputswitch";
import cn from "classnames";
import React from "react";
import "./ApMultiselect.scss";

interface IMultiselect extends MultiSelectProps {
  id: string;
  formik?: FormikProps<FormikValues>;
  noField?: boolean;
  withSwitch?: boolean;
  onToggleSwitch?: (e: any) => void;
  switchValue?: string;
  optionsDesign?: string;
  removeIcon?: string;
  disabled?: boolean;
  loading?: boolean;
}

export default function ApMultiselect(props: IMultiselect) {
  let meta, field;
  const { noField, withSwitch, switchValue, onToggleSwitch, optionsDesign, removeIcon, loading, ...rest } = props;

  if (props.noField && props.formik) {
    meta = props.formik.getFieldMeta(props.id);
    field = props.formik.getFieldProps(props.id);
  }

  const value = props?.value ?? field?.value;

  const headerTemplate = (options: any) => {
    return (
      <>
        <div className="p-multiselect-header">
          {options.checkboxElement}
          {options.filterElement}
          {options.closeElement}
        </div>
        {withSwitch && (
          <div className="ap-multiselect-switch">
            <InputSwitch onChange={onToggleSwitch} checked={switchValue} trueValue={"AND"} falseValue={"OR"} />
            <span className="ap-multiselect-switch-label">
              {props.switchValue === "OR" ? "Include at least 1 selected item" : "Include all selected item(s)"}
            </span>
          </div>
        )}
      </>
    );
  };

  const renderMultiSelect = (field?: FieldInputProps<any>, meta?: FieldMetaProps<any>) => (
    <>
      <MultiSelect
        {...field}
        {...rest}
        className={cn("ApMultiselect", optionsDesign === "vertical" && "ApMultiselect-vertical", props.className)}
        filterPlaceholder={props.filterPlaceholder || "Filter..."}
        inputId={props.id}
        panelHeaderTemplate={headerTemplate}
        removeIcon={removeIcon || undefined}
        disabled={props.disabled || loading}
        loading={loading ? "true" : undefined}
        value={props.options?.length ? value : []}
      />
      {meta?.touched && meta?.error && <div className="ap-error">{meta.error}</div>}
    </>
  );

  return (noField && !props.formik) || withSwitch ? (
    renderMultiSelect()
  ) : noField && meta ? (
    renderMultiSelect(field as FieldInputProps<any>, meta)
  ) : (
    <Field name={props.id}>{({ field, meta }: any) => renderMultiSelect(field, meta)}</Field>
  );
}
