import Axios, { GenericAbortSignal } from "axios";
import { API_URL } from "~/config";
import { IQueryParams } from "~/interfaces/common";
import { IProjectPayload, IProjectProposalFile } from "~/interfaces/project";

export class ProjectService {
  static async cancelProject(data: { projectId: number; reason_id: number; description?: string }) {
    return await Axios.delete(`${API_URL}/project/${data.projectId}/cancel`, { data });
  }
  static async deleteProject(projectId: number) {
    return await Axios.delete(`${API_URL}/project/${projectId}/delete`);
  }
  static async createProject(data: IProjectPayload) {
    return await Axios.post(`${API_URL}/project`, data);
  }
  static async getProject(projectId: number) {
    return await Axios.get(`${API_URL}/project/${projectId}`);
  }
  static async listProjects(params: IQueryParams, controller: { signal: GenericAbortSignal }) {
    return await Axios.get(`${API_URL}/projects`, { params, signal: controller.signal });
  }
  static async updateProject(data: Partial<IProjectPayload>) {
    return await Axios.put(`${API_URL}/project/${data.id}`, data);
  }
  static async updateProjectProposal(data: IProjectProposalFile) {
    const form = data.proposal_url
      ? (() => {
          const form = new FormData();
          form.append("proposal_url", data.proposal_url);
          return form;
        })()
      : data;

    return await Axios.post(`${API_URL}/project/${data.id}`, form);
  }
  static async listOrganisationProjects(organisationId: number, controller: { signal: GenericAbortSignal }) {
    return await Axios.get(`${API_URL}/organisation/${organisationId}/projects`, { signal: controller.signal });
  }
  static async listProjectBriefs(projectId: number, talents: number[], controller: { signal: GenericAbortSignal }) {
    return await Axios.get(`${API_URL}/project/${projectId}/briefs`, {
      signal: controller.signal,
      params: { talents },
    });
  }
}
