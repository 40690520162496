import { useState } from "react";
import { BookingService } from "~/API/BookingService";
import { DEFAULT_PAGINATED_STATE } from "~/constants";
import { IBooking } from "~/interfaces/booking";
import { IDefaultPaginatedState, IQueryParams } from "~/interfaces/common";

const useBooking = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [bookingsState, setBookingsState] = useState<IDefaultPaginatedState<IBooking>>(DEFAULT_PAGINATED_STATE);

  let controller: any;
  const createController = () => {
    controller = new AbortController();
  };

  const getOrganisationBookings = async (id: number, params: IQueryParams) => {
    createController();
    setIsLoading(true);

    try {
      setBookingsState(DEFAULT_PAGINATED_STATE);
      const { status, data: newBookings } = await BookingService.getOrganisationBookings(id, params, controller);

      status === 200 && setBookingsState(newBookings);
    } finally {
      setIsLoading(false);
    }
  };

  const getTalentBookings = async (id: number, params: IQueryParams) => {
    createController();
    setIsLoading(true);

    try {
      setBookingsState(DEFAULT_PAGINATED_STATE);
      const { status, data: newBookings } = await BookingService.getTalentBookings(id, params, controller);

      status === 200 && setBookingsState(newBookings);
    } finally {
      setIsLoading(false);
    }
  };

  const resetState = () => {
    !!controller && "abort" in controller && controller.abort();
    setBookingsState(DEFAULT_PAGINATED_STATE);
  };

  return {
    isLoading,
    bookingsState,
    getOrganisationBookings,
    getTalentBookings,
    resetState,
  };
};

export default useBooking;
