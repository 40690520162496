import { useState } from "react";
import { useDispatch } from "react-redux";
import Toasts, { TOAST_ERROR_MESSAGE, TOAST_SUCCESS_MESSAGE } from "~/store/constants/toasts";
import { IUserForm } from "~/store/constants/user";
import { OrganisationUsersService } from "~/API/OrganisationUsersService";
import { AxiosError } from "axios";
import { IOrganisationUser } from "~/store/constants/organisation";
import { IDefaultPaginatedState, IQueryParams } from "~/interfaces/common";
import { ITalent } from "~/store/constants/talent";

const useOrganisationUsers = () => {
  const [isUsersFetching, setIsUsersFetching] = useState(false);
  const [isRoleChangeFetching, setIsRoleChangeFetching] = useState(false);
  const [isUserDataFetching, setIsUserDataFetching] = useState(false);
  const [organisationUsers, setOrganisationUsers] = useState<IUserForm[] | null>(null);
  const [organisationArchivedTalents, setOrganisationArchivedTalents] =
    useState<IDefaultPaginatedState<ITalent> | null>(null);
  const [userData, setUserData] = useState<IOrganisationUser | null>(null);
  const dispatch = useDispatch();

  const getOrganisationUsers = async (organisationId: number) => {
    try {
      setIsUsersFetching(true);
      const { status, data } = await OrganisationUsersService.getOrganisationUsers(organisationId);
      status === 200 && setOrganisationUsers(data);
    } catch (err) {
      dispatch(Toasts.setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
    } finally {
      setIsUsersFetching(false);
    }
  };
  const getOrganisationArchivedTalents = async (organisationId: number, params: IQueryParams) => {
    try {
      setIsUsersFetching(true);
      const { status, data } = await OrganisationUsersService.getOrganisationArchivedTalents(organisationId, params);
      status === 200 && setOrganisationArchivedTalents(data);
    } catch (err) {
      dispatch(Toasts.setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
    } finally {
      setIsUsersFetching(false);
    }
  };
  const attachUserRole = async (userId: number, roleId: string) => {
    try {
      setIsRoleChangeFetching(true);
      await OrganisationUsersService.attachUserRole(userId, roleId);
      dispatch(Toasts.setToasts([{ severity: "success", summary: "", detail: TOAST_SUCCESS_MESSAGE }]));
    } catch (err) {
      dispatch(Toasts.setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
    } finally {
      setIsRoleChangeFetching(false);
    }
  };

  const detachUserRole = async (userId: number, roleId: string) => {
    try {
      setIsRoleChangeFetching(true);
      await OrganisationUsersService.detachUserRole(userId, roleId);
      dispatch(Toasts.setToasts([{ severity: "success", summary: "", detail: TOAST_SUCCESS_MESSAGE }]));
    } catch (err) {
      dispatch(Toasts.setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
    } finally {
      setIsRoleChangeFetching(false);
    }
  };

  const getUserById = async (userId: number) => {
    try {
      setIsUserDataFetching(true);
      const { status, data } = await OrganisationUsersService.getUserById(userId);
      status === 200 && setUserData(data);
    } catch (err) {
      dispatch(Toasts.setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
    } finally {
      setIsUserDataFetching(false);
    }
  };

  const updateUserById = async (userId: number, formData: IOrganisationUser, errorCallback?: (content) => void) => {
    try {
      setIsUserDataFetching(true);
      const { status, data } = await OrganisationUsersService.updateUserById(userId, formData);
      if (status === 200) {
        setUserData(data as IOrganisationUser);
        dispatch(Toasts.setToasts([{ severity: "success", summary: "", detail: TOAST_SUCCESS_MESSAGE }]));
      }
      return status;
    } catch (err) {
      const detail = err instanceof AxiosError ? err?.response?.data?.message : TOAST_ERROR_MESSAGE;
      !!errorCallback
        ? errorCallback(err?.response?.data)
        : dispatch(Toasts.setToasts([{ severity: "error", summary: "", detail }]));
    } finally {
      setIsUserDataFetching(false);
    }
  };

  const validateEmail = async (email: string): Promise<void | string> => {
    return new Promise(async (resolve) => {
      if (!email) {
        resolve(TOAST_ERROR_MESSAGE);
        return;
      }

      try {
        setIsUserDataFetching(true);
        await OrganisationUsersService.validateEmail(email);
        resolve();
      } catch (err) {
        resolve(err instanceof AxiosError ? err?.response?.data.email : TOAST_ERROR_MESSAGE);
      } finally {
        setIsUserDataFetching(false);
      }
    });
  };

  return {
    isUsersFetching,
    isUserDataFetching,
    organisationUsers,
    organisationArchivedTalents,
    userData,
    getOrganisationUsers,
    getOrganisationArchivedTalents,
    getUserById,
    updateUserById,
    validateEmail,
    setOrganisationUsers,
    attachUserRole,
    detachUserRole,
    isRoleChangeFetching,
  };
};
export default useOrganisationUsers;
