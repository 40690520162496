import { IDiscipline, ISpecialism } from "~/store/constants/global";
import { IAIBioRequestParams, IClient } from "~/interfaces/common";
import { ISkill } from "~/store/constants/skills";
import { TalentActivityStatus, TalentType } from "~/store/constants/talent";

export const generateBio = async (talentDiscipline: IDiscipline | undefined, formik: any, getAIBio: Function) => {
  const talentSpecialism = talentDiscipline?.specialisms?.find(
    (specialism: ISpecialism) => specialism.code === formik.values.specialism_code
  );
  const AIRequestParams: IAIBioRequestParams = {
    discipline: talentDiscipline?.name || "",
    specialism: talentSpecialism?.name || "",
    level:
      talentSpecialism?.levels?.find((level: { code: string }) => level.code === formik.values.level_code)?.name || "",
    skills: Object.values(formik.values.skills as ISkill[]).map((skill: ISkill) => skill.name),
    sectors: Object.values(formik.values.sectors as ISkill[]).map((sector: ISkill) => sector.name),
    tools: Object.values(formik.values.tools as ISkill[]).map((tool: ISkill) => tool.name),
    clients: Object.values(formik.values.clients as IClient[]).map((client: IClient) => client.name),
  };
  const AIBio: string | undefined = await getAIBio(AIRequestParams);
  !!AIBio?.length && (await formik.setFieldValue("description", AIBio));
};

export const getDirectBookButtonProps = (talentProps: any) => {
  const {
    matchable,
    status,
    type,
    tro,
    organisation_id,
    isAdmin,
    isRegionalAdmin,
    isLocalAdmin,
    isTro,
    isBriefingUser,
    userOrgId,
    userId,
    organisations,
  } = talentProps;

  if (isBriefingUser) {
    return { text: "ADD TO BRIEF", color: "green" };
  }
  if (status !== TalentActivityStatus.PUBLISHED) {
    return { text: "INACTIVE", color: "grey" };
  }
  if (matchable !== "100%") {
    return { text: "PROFILE INCOMPLETE" };
  }
  if (type === TalentType.FREELANCER) {
    return { text: "ENQUIRE", color: "yellow", newTalentStatus: "ENQUIRED_FREELANCER" };
  }
  if (
    isAdmin ||
    (isLocalAdmin && organisation_id === userOrgId) ||
    (isRegionalAdmin && organisations?.some(({ id }: { id: number }) => organisation_id === id)) ||
    (isTro && tro.some(({ id }: { id: number }) => userId === id))
  ) {
    return { text: "ALLOCATE", color: "green", newTalentStatus: "BOOKED" };
  }

  return { text: "REQUEST CLEARANCE", color: "yellow", newTalentStatus: "CLEARANCE_REQUESTED" };
};
