import React from "react";

interface ICardSkills {
  skills: string[];
  sectors: string[];
  tools: string[];
  clients: string[];
}

export default function CardSkills(props: ICardSkills) {
  const { skills, sectors, tools, clients } = props;

  const renderItems = (title: string, items: string[]) => (
    <div className="skill-list">
      <b>{title}: </b>
      {items.length > 0 ? (
        <ul>
          {items.map((skill: string, index: number) => (
            <li key={index}>{skill}</li>
          ))}
        </ul>
      ) : (
        "—"
      )}
    </div>
  );

  return (
    <>
      {renderItems("Skills", skills ?? [])}
      {renderItems("Sectors", sectors ?? [])}
      {renderItems("Tools", tools ?? [])}
      {clients?.length > 0 && renderItems("Client history", clients)}
    </>
  );
}
