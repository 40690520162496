import React, { createContext, useContext } from "react";
import useNotifications from "~/hooks/useNotifications";

export interface INotificationContext {
  isFetching: boolean;
  notificationsCount: number | null;
  getNotifications: () => void;
}

export const NotificationContext = createContext(null as INotificationContext | null);

export const useNotificationContext = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error(" useNotificationContext be used within a NotificationContextProvider");
  }
  return context;
};

const NotificationContextProvider = ({ children }: any) => {
  return (
    <NotificationContext.Provider
      value={{
        ...useNotifications(),
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationContextProvider;
