import React, { ReactNode } from "react";
import { useDispatch } from "react-redux";
import toasts from "../../store/actions/toasts";
import { Popup } from "../../store/reducers/toasts";
import "./Toaster.scss";

export default function PopupIcon({ content, popup }: { content?: string | ReactNode; popup?: Popup }) {
  const dispatch = useDispatch();
  const item: Popup | null = popup ?? (content ? { content } : null);

  return item ? (
    <span className="pi pi-info-circle popup-item" onClick={() => dispatch(toasts.setPopup(item))}></span>
  ) : null;
}
