import React from "react";
import { Route, Redirect } from "react-router-dom";

/**
 * Component that protects route from unauthorized users.
 * @type {Function}
 */
export default ({ component: Component, ...rest }: { component: typeof React.Component }) => {
  if (!localStorage.getItem("user")) {
    return (
      <Redirect
        to={{
          pathname: "/login",
          state: { referrer: location.pathname + location.search },
        }}
      />
    );
  }
  return <Route {...rest} render={(props) => <Component {...props} />} />;
};
