import { useEffect, useMemo, useState } from "react";
import moment from "moment";
import { TALENT_BRIEF_PREFERENCES, TALENT_TYPES } from "~/interfaces/brief";
import { DataTableSortMeta } from "primereact/datatable";
import { ITalentFilterSettings } from "~/store/reducers/talent";
import { PaginatorPageChangeEvent } from "primereact/paginator";
import { useGlobalContext } from "~/contexts/GlobalContext";
import sum from "lodash/sum";
import { getTalentTypeText } from "~/utils";

const talentMatchableOptions = [
  { label: "Matchable", code: "matchable", param: true },
  { label: "Incomplete", code: "incomplete", param: false },
  { label: "Inactive", code: "inactive", param: true },
  { label: "All", code: "all", param: null },
];

const defaultSort: DataTableSortMeta = {
  field: "name",
  order: 1,
};

export const ALL_DISCIPLINES_FILTERING_LABEL = {
  label: "All",
  code: "",
};

const defaultTalentBriefPreference = TALENT_BRIEF_PREFERENCES?.find(({ code }: { code: string }) => code === ""); // empty means All
export const defaultTalentType = TALENT_TYPES?.find(({ code }: { code: string }) => code === "ALL") || {
  label: "",
  code: "",
};

export const ALL_TALENT_GOTO_LABEL = {
  name: "All",
  code: "",
};

export const defaultMatchableFilter = talentMatchableOptions.find((item) => item.code === "all");
export const FAV_TAG = { id: -1, name: "Favourites" };

const useTalentFilterSettings = () => {
  const {
    global: { TALENT_GOTO_TYPES },
  } = useGlobalContext();
  const [talentAppliedFiltersCount, setTalentAppliedFiltersCount] = useState(0);
  const [perPage, setPerPage] = useState<number>(20);
  const [page, setPage] = useState<number>(0);
  const [discipline, setDiscipline] = useState(ALL_DISCIPLINES_FILTERING_LABEL);
  const [talentBriefPreference, setTalentBriefPreference] = useState(defaultTalentBriefPreference);
  const [talentType, setTalentType] = useState(defaultTalentType);
  const [talentGotoType, setTalentGotoType] = useState(ALL_TALENT_GOTO_LABEL);
  const [specialisms, setSpecialisms] = useState([]);
  const [levels, setLevels] = useState([]);
  const [skills, setSkills] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [tagsFilter, setTagsFilter] = useState<number[]>([]);
  const [selectedColumns, setSelectedColumns] = useState<{ field: string; header: string }[]>([]);
  const [search, setSearch] = useState<string>("");
  const [sort, setSort] = useState<DataTableSortMeta>(defaultSort);
  const [matchableFilter, setMatchableFilter] = useState(defaultMatchableFilter);
  const [organisationFilter, setOrganisationFilter] = useState<number[]>([]);
  const [skillsOperator, setSkillsOperator] = useState<string>("OR");
  const [sectorsOperator, setSectorsOperator] = useState<string>("OR");
  const [tagsOperator, setTagsOperator] = useState<string>("OR");
  const [scrollToId, setScrollToId] = useState<string | null>(null);
  const talentGotoTypeOptions = useMemo(
    () => [
      ...TALENT_GOTO_TYPES?.map((type) => ({ name: getTalentTypeText(type.code), code: type.code })),
      ALL_TALENT_GOTO_LABEL,
    ],
    [TALENT_GOTO_TYPES]
  );
  const [availabilityRange, setAvailabilityRange] = useState<Date[]>([
    moment().toDate(),
    moment().clone().add(1, "month").toDate(),
  ]);

  const onPaginatorChange = (e: PaginatorPageChangeEvent) => {
    setPerPage(e.rows);
    setPage(e.page);
  };

  const resetFilterTalentSettings = (currentPath: string) => {
    const isExistTalentId = Number.isInteger(+currentPath?.split("/")?.filter(Boolean)[1]);
    const isItemTalentPath = currentPath?.includes("/talents") && isExistTalentId;
    if (currentPath && currentPath !== "/talents" && !isItemTalentPath) {
      setDiscipline(ALL_DISCIPLINES_FILTERING_LABEL);
      setTalentBriefPreference(defaultTalentBriefPreference);
      setTalentType(defaultTalentType);
      setSelectedColumns([]);
      setSort(defaultSort);
      setSearch("");
      setMatchableFilter(defaultMatchableFilter);
      setSpecialisms([]);
      setLevels([]);
      setSkills([]);
      setSectors([]);
      setTagsFilter([]);
      setOrganisationFilter([]);
      setSkillsOperator("OR");
      setSectorsOperator("OR");
      setTagsOperator("OR");
      setTalentGotoType(ALL_TALENT_GOTO_LABEL);
    }
  };

  useEffect(() => {
    const filters = [
      tagsFilter.length,
      organisationFilter.length,
      specialisms.length,
      levels.length,
      skills.length,
      sectors.length,
      matchableFilter?.label !== defaultMatchableFilter?.label ? 1 : 0,
      discipline.code !== ALL_DISCIPLINES_FILTERING_LABEL.code ? 1 : 0,
      talentGotoType.code !== ALL_TALENT_GOTO_LABEL.code ? 1 : 0,
    ];

    setTalentAppliedFiltersCount(sum(filters));
  }, [
    discipline,
    matchableFilter,
    organisationFilter,
    specialisms,
    levels,
    skills,
    sectors,
    tagsFilter,
    talentGotoType,
  ]);

  return {
    discipline,
    setDiscipline,
    specialisms,
    setSpecialisms,
    levels,
    setLevels,
    skills,
    setSkills,
    sectors,
    setSectors,
    tagsFilter,
    setTagsFilter,
    resetFilterTalentSettings,
    talentBriefPreference,
    setTalentBriefPreference,
    selectedColumns,
    setSelectedColumns,
    talentType,
    setTalentType,
    search,
    setSearch,
    sort,
    setSort,
    talentMatchableOptions,
    matchableFilter,
    setMatchableFilter,
    organisationFilter,
    setOrganisationFilter,
    skillsOperator,
    setSkillsOperator,
    sectorsOperator,
    setSectorsOperator,
    tagsOperator,
    setTagsOperator,
    scrollToId,
    setScrollToId,
    onPaginatorChange,
    page,
    perPage,
    talentGotoType,
    setTalentGotoType,
    talentGotoTypeOptions,
    talentAppliedFiltersCount,
    setTalentAppliedFiltersCount,
    availabilityRange,
    setAvailabilityRange,
  } as ITalentFilterSettings;
};
export default useTalentFilterSettings;
