import isString from "lodash/isString";
import React, { MouseEventHandler } from "react";
import { useDispatch } from "react-redux";
import FilterNameForm from "~/forms/FilterNameForm/FilterNameForm";
import toasts from "~/store/actions/toasts";
import "./AppliedFilters.scss";

export interface IAppliedFilter {
  label: string;
  value: string | { id: string | number; name: string }[];
  removeCallback: (id?: number) => void;
}

interface IProps {
  onReset: () => void;
  onSave: ({ filterName }: { filterName: string }) => void;
  filters: any;
  appliedFilters: IAppliedFilter[];
  appliedFiltersCount: number;
}

export default function AppliedFilters(props: IProps) {
  const { appliedFiltersCount } = props;
  const dispatch = useDispatch();

  function setFilterNameModal() {
    dispatch(
      toasts.setPopup({
        content: <strong>Save filter</strong>,
        form: (formProps: any) => <FilterNameForm {...formProps} filters={props.filters} />,
        buttons: [
          { text: "Go back" },
          {
            text: "Save",
            callback: props.onSave,
          },
        ],
      })
    );
  }

  const renderFilterItem = (name: string, callback: MouseEventHandler<HTMLButtonElement>) => (
    <div className="talent-filters-applied-item-filter" key={name}>
      <button className="delete-button" onClick={callback} type="button">
        <span className="pi pi-times" />
      </button>
      <span>{name}</span>
    </div>
  );

  return (
    <div className="talent-filters-item talent-filters-item_buttons">
      <div className="talent-filters-body">
        <label>Applied filters {!!appliedFiltersCount && `(${appliedFiltersCount})`}</label>

        {appliedFiltersCount === 0 && <div className="talent-filters-applied-empty">No filters applied</div>}

        <div className="talent-filters-applied">
          {props.appliedFilters.map((filter: any, index: number) => (
            <div key={index} className="talent-filters-applied-item">
              <span className="talent-filters-applied-item-label">{filter.label}:</span>

              <div>
                {isString(filter.value)
                  ? renderFilterItem(filter.value, () => filter.removeCallback())
                  : filter.value?.map((item: any) => {
                      return renderFilterItem(item.name, () => filter.removeCallback(item.id));
                    })}
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="talent-filters-footer">
        <button onClick={() => props.onReset()} type="button" disabled={!appliedFiltersCount}>
          Clear all
        </button>
        <button onClick={setFilterNameModal} type="button" disabled={!appliedFiltersCount}>
          Save filter
        </button>
      </div>
    </div>
  );
}
