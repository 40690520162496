import * as Yup from "yup";
import { IOptionsNormalization } from "~/hooks/useOptions";
import { requiredFieldMessage } from "../utils";
import { dropdownMessage, errorMsgEntries, selectAtLeastOne } from "./helpers";

const checkForOwnEntries: any = {
  name: "entries",
  message: errorMsgEntries,
  test: (value: { id: number }[]) => value.some((el: any) => el.id),
};

export enum YNAOptions {
  YES = "YES",
  NO = "NO",
  AGREE = "AGREE",
}

export enum FlexibilityCode {
  FLEXHOURS = "FREELANCER_FLEXHOURS",
  FREEHOURS = "FREELANCER_FREEHOURS",
  FIXEDHOURS = "FREELANCER_FIXEDHOURS",
}

export const BriefSchema = (
  step: number,
  isSuperAdmin: boolean,
  optionsNormalization: IOptionsNormalization,
  skillsCount: { [key: number]: number }
) => {
  switch (step) {
    case 0:
      return Yup.object().shape(BriefNewOverview());
    case 1:
      return Yup.object().shape(BriefNewDetails(optionsNormalization, isSuperAdmin, skillsCount));
  }
};

export const BriefRebookSchema = (step: number) => {
  switch (step) {
    case 0:
      return Yup.object().shape(RebookNewOverview());
    case 1:
      return Yup.object().shape(RebookNewDetails());
  }
};

export const BriefNewOverview = () => {
  return {
    ...CommonBriefNewSchema(),
    organisations: Yup.array().of(Yup.number()).label("organisations").min(1, dropdownMessage),
    project_id: Yup.number().label("project").required(dropdownMessage),
  };
};

const RebookNewOverview = () => ({
  ...CommonBriefNewSchema(),
  talent_ids: Yup.array().label("talent").min(1, dropdownMessage).required(dropdownMessage),
});

const BriefNewDetails = (
  optionsNormalization: IOptionsNormalization,
  isSuperAdmin: boolean,
  skillsCount: { [key: number]: number }
) => {
  const DISCIPLINE_ID = optionsNormalization?.DISCIPLINE_ID;
  return {
    ...CommonBriefDetailsSchema(),
    specialism_flexible: Yup.boolean().nullable(),
    discipline_id: Yup.mixed().label("discipline").required(dropdownMessage),
    specialism_id: Yup.mixed().label("specialism").required(dropdownMessage),
    level_id: Yup.mixed().label("level").required(dropdownMessage),
    production_id: Yup.number().nullable(),
    skills: (isSuperAdmin
      ? Yup.array().nullable()
      : Yup.array().of(Yup.object()).required("Gotta’add those powers please!").test(checkForOwnEntries)
    ).when("discipline_id", (discipline_id, schema) => {
      return schema.max(skillsCount[discipline_id], `Sorry, only ${skillsCount[discipline_id]} skills can be selected`);
    }),
    sectors: Yup.array()
      .of(Yup.object())
      .when("include_sectors", {
        is: true,
        then: Yup.array().of(Yup.object()).required("Please, choose the sectors"),
        otherwise: Yup.array().nullable(),
      }),
    tools: Yup.array()
      .of(Yup.object())
      .when("include_tools", {
        is: true,
        then: Yup.array().of(Yup.object()).required("Please, choose the tools"),
        otherwise: Yup.array().nullable(),
      }),
    include_weekends: Yup.boolean(),
  };
};

const RebookNewDetails = () => ({
  ...CommonBriefDetailsSchema(),
});

const CommonBriefNewSchema = () => {
  return {
    author_id: Yup.number()
      .min(1, "Please, choose the organisation user")
      .required("Please, choose the organisation user"),
    organisation_id: Yup.number().label("organisation").required(dropdownMessage),
    name: Yup.string()
      .min(2, "Please, add at least 2 symbols")
      .max(50, "Please, make it shorter than 50 symbols")
      .required(requiredFieldMessage),
    wbs_code: Yup.string().max(100, "Please, make it shorter than 100 symbols").nullable(),
    job_number: Yup.string().max(255, "Please, make it shorter than 255 symbols").nullable(),
    goto_types: Yup.array().of(Yup.number()).min(1, requiredFieldMessage),
  };
};
export const CommonBriefDetailsSchema = () => {
  return {
    start_date: Yup.string().required("Please, choose the date"),
    end_date: Yup.string().required("Please, choose the date"),
    capacity: Yup.number().typeError("Please, add the capacity").min(1).max(100),
    description: Yup.string()
      .min(2, "Please, add at least 2 symbols")
      .max(1000, "Please, make it shorter than 1000 symbols")
      .test(function (this: any, value: string | undefined) {
        const { description_url } = this.parent;
        if (!!description_url || !!value) {
          return true;
        }
        return this.createError({ message: "Please type a brief summary description or upload a brief file" });
      }),
    description_url: Yup.mixed().test(function (this: any, value: File | string | undefined) {
      const { description } = this.parent;
      if (!!description || !!value) {
        return true;
      }
      return this.createError({ message: "Please type a brief summary description or upload a brief file" });
    }),
    assignees: Yup.array().of(Yup.number()).label("brief assignee").min(1, selectAtLeastOne),
  };
};
