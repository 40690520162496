import { createBrowserHistory, Location } from "history";
import { AnyAction } from "redux";
import { LOCATION_CHANGE } from "../constants/location";

// ------------------------------------
// Interface
// ------------------------------------
export interface ILocationState extends Location {}

// ------------------------------------
// Initial state
// ------------------------------------
const initialState: ILocationState = createBrowserHistory().location;

// ------------------------------------
// Reducer
// ------------------------------------
export default (state = initialState, action: AnyAction): ILocationState =>
  action.type === LOCATION_CHANGE ? action.payload : state;
