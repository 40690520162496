import React from "react";
import { Avatar } from "primereact/avatar";
import { ITalent } from "~/store/constants/talent";
import "./TalentInfoBlock.scss";

const TalentInfoBlock = ({ preSelectedTalents }: { preSelectedTalents: ITalent[] }) => {
  return (
    <div className="talent-info">
      <Avatar
        icon="pi pi-user"
        size="large"
        shape="circle"
        image={preSelectedTalents[0]?.work_profile?.profile_image_url}
      />
      <div>
        <h3>{preSelectedTalents[0]?.name}</h3>
        <div className="flex align-items-baseline">
          <i className="icon-company" />
          <p>{preSelectedTalents[0]?.organisation_name}</p>
        </div>
      </div>
    </div>
  );
};

export default TalentInfoBlock;
