import React, { useState } from "react";
import moment from "moment";
import "./CalendarFooterTemplate.scss";
import ApInputNumber from "~/components/common/ApInputNumber/ApInputNumber";
import { shortCapacityValues } from "~/config";
import "./DatesCapacityForm.scss";
import cn from "classnames";

interface IFooterTemplate {
  dates: { date: Date; capacity?: number | string }[];
  setDates: (dates: { date: Date; capacity?: number | string }[]) => void;
  formik?: any;
  handleChange?: (i: number, value: any) => void;
  setValues?: (values: any) => void;
  dateFormat?: string;
}

export default function DatesCapacityForm({
  dates,
  setDates,
  formik,
  setValues,
  dateFormat = "DD/MM/YYYY",
}: IFooterTemplate) {
  const [activeDate, setActiveDate] = useState<number | null>(0);

  return (
    <div className="DatesCapacityForm">
      <div className="DatesCapacityForm-datesList">
        {(dates || []).map(({ date, capacity }, i) => (
          <span
            key={i}
            className={cn("DatesCapacityForm-date", activeDate === i && "active")}
            onClick={() => {
              setActiveDate(i);
            }}
          >
            {moment(date).format(dateFormat)}
            {capacity && ` (${parseInt(capacity as string)}%)`}
          </span>
        ))}
      </div>

      {activeDate !== null && (
        <div className="fadeIn" key={String(dates[activeDate]?.date)}>
          <ApInputNumber
            id={`dates[${activeDate}].capacity`}
            noField
            formik={formik}
            max={100}
            min={1}
            maxLength={4}
            placeholder="100"
            suffix="%"
            shorthandValues={shortCapacityValues}
            value={parseInt(dates[activeDate]?.capacity ?? 100)}
            disabled={!dates[activeDate]?.capacity}
            handleChange={({ value }) => {
              const newDates = [...dates];
              newDates[activeDate]?.capacity && (newDates[activeDate].capacity = value);

              setDates && setDates(newDates);
              formik.setFieldValue(`dates[${activeDate}].capacity`, String(value));
              setValues && setValues({ dates: newDates });
            }}
          />
        </div>
      )}
    </div>
  );
}
