import cn from "classnames";
import React, { useEffect, useMemo, useState } from "react";
import Loader from "~/components/Loader/Loader";
import ApMultiCheckbox, { IOption } from "~/components/common/ApMultiCheckbox/ApMultiCheckbox";
import ApRadioGroup, { IRadioOption } from "~/components/common/ApRadioGroup/ApRadioGroup";
import { ROLES, usePermissionContext } from "~/contexts/PermissionContext";
import useOrganisationUsers from "~/hooks/useOrganisationUsers";
import { processingRoles } from "~/utils";
import ApInputText from "../../components/common/ApInputText/ApInputText";
import "./UserManagementForm.scss";
import { FormikProps, FormikValues, useFormikContext } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { IState } from "~/store/reducers/index";
import actions from "~/store/actions";
import ApMultiselect from "~/components/common/ApMultiselect/ApMultiselect";
import useOrganisations from "~/hooks/useOrganisations";
import ApDropdown from "~/components/common/ApDropdown/ApDropdown";

interface Props {
  edit?: boolean;
  type?: "organisation" | "talent";
  isOwner?: boolean;
  isNewOrganisation?: boolean;
}

function UserManagementForm(props: Props) {
  const { roles: allRoles, isFetchingRoles, isAdmin } = usePermissionContext();
  const [filteredRoles, setFilteredRoles] = useState<{ main: IRadioOption[]; additional: IOption[] }>({
    main: [],
    additional: [],
  });

  const { validateEmail } = useOrganisationUsers();
  const { organisations } = useOrganisations();

  const formik: FormikProps<FormikValues> = useFormikContext();
  const { organisation, isFetchingOrganisationsList } = useSelector((state: IState) => state.organisation);
  const dispatch = useDispatch();

  useEffect(() => {
    const { values, setFieldValue } = formik || {};
    const { organisations: userOrganisations, role } = values || {};
    const organisationId = organisation?.id;

    if ([ROLES.REGIONAL_ADMIN, props.isNewOrganisation ? undefined : ROLES.LOCAL_ADMIN].includes(role)) {
      if (!props.isNewOrganisation) {
        role === ROLES.REGIONAL_ADMIN && setFieldValue("organisations", userOrganisations || [organisationId]);
        role === ROLES.LOCAL_ADMIN && setFieldValue("organisation", organisationId);

        !organisations?.length &&
          !isFetchingOrganisationsList &&
          isAdmin &&
          dispatch(actions.organisation.listOrganisations());
      }
    }

    formik.validateField("organisations");
  }, [formik?.values?.role]);

  useEffect(() => {
    const { main, additional } = processingRoles(allRoles, isAdmin);

    setFilteredRoles({ main, additional });
  }, [allRoles]);

  return (
    <div
      className={cn("UserManagementForm", {
        UserManagementForm_organisation: props.type === "organisation" && !props.isOwner,
      })}
    >
      <div className="UserManagementForm__col">
        <div className="p-fluid field field-required">
          <label htmlFor="first_name">First name</label>
          <ApInputText id="first_name" type="text" />
        </div>
        <div className="p-fluid field field-required">
          <label htmlFor="last_name">Last name</label>
          <ApInputText id="last_name" type="text" />
        </div>
        <div className="p-fluid field field-required">
          <label htmlFor="email">E-mail</label>
          <ApInputText id="email" type="email" validate={props.edit ? undefined : validateEmail} />
        </div>
        {props.type === "organisation" &&
          [ROLES.REGIONAL_ADMIN, props.isNewOrganisation ? undefined : ROLES.LOCAL_ADMIN].includes(
            formik?.values?.role
          ) && (
            <div className="p-fluid field field-required">
              <label htmlFor="organisations">
                {formik?.values?.role === ROLES.REGIONAL_ADMIN ? "Organisations" : "Organisation"}
              </label>
              {(() => {
                const commonProps = {
                  formik: formik,
                  noField: true,
                  filter: true,
                  options: organisations,
                  optionLabel: "name",
                  optionValue: "id",
                  panelClassName: "organisations-multiselect-panel",
                  loading: isFetchingOrganisationsList,
                  disabled: props.isOwner,
                  itemTemplate: (item) => (
                    <>
                      {item.name} {!item.published && <strong>(inactive)</strong>}
                    </>
                  ),
                };

                return formik?.values?.role === ROLES.REGIONAL_ADMIN ? (
                  <ApMultiselect {...commonProps} id="organisations" placeholder="Select organisations" />
                ) : (
                  <ApDropdown {...commonProps} id="organisation" placeholder="Select organisation" />
                );
              })()}
            </div>
          )}
      </div>
      {props.type === "organisation" && !props.isOwner && (
        <div className="UserManagementForm__col">
          <div className="p-fluid field mb-0">
            <label htmlFor="role">Role</label> <br />
            {isFetchingRoles ? (
              <Loader size={2} />
            ) : (
              <>
                <ApRadioGroup name="role" options={filteredRoles.main} discriptionType="list" />
                {!!filteredRoles.additional.length && (
                  <>
                    <label htmlFor="additional_roles">Additional role</label>
                    <ApMultiCheckbox id="additional_roles" options={filteredRoles.additional} selectedProp="code" />
                  </>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default UserManagementForm;
