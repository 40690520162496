import React from "react";
import "./GlobalError.scss";

export default function GlobalError({ error, resetErrorBoundary }: { error: Error; resetErrorBoundary: () => void }) {
  const [showErrorMessages, setShowErrorMessages] = React.useState(false);

  return (
    <div className="global-error">
      <div className="error-block">
        <p>Oops...</p>
        <p className="error-code" onDoubleClick={() => setShowErrorMessages((showErrorMessages) => !showErrorMessages)}>
          Something went wrong
        </p>
        <p className="user-operation">
          <a onClick={resetErrorBoundary} href="#">
            Try again
          </a>{" "}
          or <a href="/">Go to Home page</a>
        </p>
        {showErrorMessages && <pre className="error-message">{error.message}</pre>}
      </div>
    </div>
  );
}
