import { ITalent } from "../store/constants/talent";
import { DataTableSortMeta } from "primereact/datatable/datatable";
import { ITalentsPaginated } from "~/store/reducers/talent";
import { SetStateAction } from "react";
import { IBooking } from "~/store/constants/bookings";
import { AxiosResponse } from "axios";
import { ILanguage } from "~/contexts/GlobalContext";
import { ICommonFilterSettings, IClient } from "./common";
import { IClearance } from "./clearance";
import { IAssignee, IOrganisation } from "~/store/constants/organisation";

// ------------------------------------
// Constants
// ------------------------------------

export const TALENT_TYPES = [
  {
    code: "TYPE_GOTO",
    label: "Bookmarked talent",
  },
  {
    code: "TYPE_BOOKED",
    label: "Previously booked talent",
  },
  {
    code: "TYPE_INVITED",
    label: "Invited talent",
  },
  {
    code: "ALL",
    label: "All",
  },
];

export const TALENT_BRIEF_PREFERENCES = [
  {
    code: "DAY_RATE",
    label: "Day Rate",
  },
  {
    code: "FIXED_TERM",
    label: "Fixed Term",
  },
  {
    code: "PERMANENT",
    label: "Permanent Hiring",
  },
  {
    code: "",
    label: "All Brief Types",
  },
];

// ------------------------------------
// Interfaces
// ------------------------------------
export interface IBriefState {
  isFetching: boolean;
  isFetchingTalents: boolean;
  isFetchingBookings: boolean;
  isFetchingMatches: boolean;
  isMatchAdding: boolean;
  isMatchUpdating: boolean;
  isClearanceUpdating: boolean;
  isValidatingDates: boolean;
  isFetchingBriefDescriptionUrl: boolean;
  SDSBeingLoaded: number | undefined;
  brief?: IBrief;
  briefs?: IBriefsList;
  matches: IMatching[];
  validatedDates?: {
    duration_days: number;
    duration_weekends: number;
    talent_full_availability?: boolean;
  };
  validatedDatesErrors: any;
  cancelBrief: (data: { briefId: number | undefined; reason_id: number; description?: string }) => Promise<void>;
  setBrief: (data: IBrief) => void;
  deleteBrief: (briefId: number) => Promise<void>;
  createBrief: (data: IBrief) => Promise<void>;
  createClientAdminBrief: (data: IBrief) => Promise<void>;
  getBrief: (briefId: number) => Promise<IBrief>;
  getClientBrief: (briefId: number) => Promise<void>;
  setMatches: (matches: IMatching[]) => void;
  getBriefMatches: (briefId: number) => Promise<void>;
  getBriefMatchesByLimit: (briefId: number, maxMatches: number) => Promise<void>;
  listBriefs: (
    statuses: string,
    page: number,
    per_page: number,
    search?: string,
    sort?: DataTableSortMeta,
    other?: { [key: string]: any }
  ) => Promise<void>;
  createMatch: (talents: number | number[], brief_id?: number, matchData?: Partial<IMatching>) => Promise<void>;
  createClientAdminMatch: (talents: number | number[]) => Promise<void>;
  updateBrief: (data: any, shouldBriefBePublished?: boolean, isBriefDraft?: boolean) => Promise<void>;
  updateClientAdminBrief: (data: any, shouldBriefBePublished: boolean, isBriefDraft: boolean) => Promise<void>;
  updateDraftBrief: (data: any) => Promise<void>;
  updateClientAdminDraftBrief: (data: any) => Promise<void>;
  updateMatch: (matchToSave: any, showLoader?: boolean) => Promise<void>;
  getMatch: (matchId: number) => Promise<void>;
  validateBrief: (data: IBrief, type: number, IR35_TYPES: any) => Promise<unknown>;
  validateBriefDates: (data: IBriefValidate) => Promise<unknown>;
  validateTalentAvailability: (briefId: number, data: IBriefValidate) => Promise<unknown>;
  resetBrief: () => void;
  addTalentGotoSuccess: (data: AxiosResponse) => void;
  regenerateSDS: (data: { bookingId: number; matchId: number }) => Promise<unknown>;
  listAddableTalents: (data: IListAddableTalentsData) => Promise<unknown>;
  trackPortfolioClick: (talentId: number, matchId: number) => void;
  trackDownloadCvClick: (talentId: number, matchId: number) => void;
  addableTalents: undefined | ITalentsPaginated;
  setAddableTalents: SetStateAction<any>;
  customItems: (brief: IBrief) => { name: string; items: ICustomSkill[] | ICustomSector[] | ICustomPlatform[] }[];
  isSubmitDisabled: (form: { isValid?: boolean; dirty?: boolean; isSubmitting: boolean }, initial: boolean) => boolean;
  getBriefFormValues: (additionalValues: any) => any;
  additionalBriefValues: any;
  additionalRebookValues: any;
  jobUrlFormData: React.MutableRefObject<Object | null | undefined>;
  setJobUrlFormData: (data: Object | undefined | null) => void;
  updateClearance: (data: { clearanceId: number; matchId: number }, payload: IClearance) => Promise<unknown>;
  updateBooking: (bookingId: number, data: any) => Promise<unknown>;
  createBooking: (data: any, createBooking: boolean) => Promise<unknown>;
  saveOrConfirmCallback: (values: any) => void;
  redirectAfterBriefCreated: (brief: IBrief) => void;
  sendMessageFromBrief: (briefId: number, talents: { id: number }[], message: string) => Promise<unknown>;
  preMatchedList: IMatching[];
  getBriefPreMatchedList: (briefId: number) => Promise<unknown>;
  resetBriefPreMatchedList: () => void;
  removeFromPreMatchedList: (id: number) => void;
  updateBriefDescriptionUrl: (id: number, summary: File | null) => Promise<unknown>;
}

export type IBriefFilterSettings = {
  columns: { field: string; header: string }[];
  columnsOptions: { field: string; header: string }[];
  briefType: { label: string; code: string };
  setBriefType: () => void;
  briefsCategories: { label: string; code: string }[];
  briefsCategory: { label: string; code: string };
  setBriefsCategory: (briefsCategoryIndex: null | { label: string; code: string }) => void;
  discipline: { label: string; code: string };
  setDiscipline: (discipline: { code: string; label: string }) => void;
  resetFilterBriefsSettings: (currentPath: string | null) => void;
  selectedColumns: { field: string; header: string }[];
  setSelectedColumns: (columns: { field: string; header: string }[]) => void;
  defaultCategoryIndex: number;
} & ICommonFilterSettings;
export interface IBriefValidate {
  talent_id?: number;
  brief_id?: number;
  start_date: string | Date;
  end_date: string | Date;
  include_weekends: boolean;
  duration_id?: number;
  duration_days?: number;
  dates?: { date: string; capacity: number | string }[];
  contract_id?: number;
  job_title?: string;
  talent_rate?: number;
  organisation_id: string;
  status?: string;
  capacity?: string;
}

export enum BriefStatuses {
  OPEN = "OPEN",
  RESOURCED = "RESOURCED",
  CANCELLED = "CANCELLED",
  DRAFT = "DRAFT",
  ALL = "ALL",
}

export interface IBrief {
  author: {
    id: number;
    email: string;
    full_name: string;
  };
  discipline_id: number;
  specialism_id: number;
  level_id: number;
  team_id: number;
  contract_id: number;
  ir35_id: number;
  production_id?: number;
  budget?: number;
  budget_type?: string;
  notes: string;
  description: null | string;
  name: string;
  notifications: number;
  location_id: null;
  author_id: number;
  organisation_id: number;
  organisation_name: string;
  organisation: IOrganisation;
  project_id: number;
  created_at: Date;
  duration_days: number;
  duration_id: number;
  duration_weekends: number;
  first_response: null | string;
  fluent_in_all: boolean;
  freeze: boolean;
  freeze_reason: null | {
    code: string;
    created_at: string;
    updated_at: string;
    description: null | string;
    name: string;
  };
  frozen_by: {
    id: number;
    name: string;
  };
  id: number;
  include_weekends: boolean;
  last_response: Date;
  legal_name: string;
  live_url: string;
  match_count?: number;
  matches: IMatching[] | undefined;
  status: BriefStatuses;
  type?: string;
  brief_type?: string;
  contract_code?: string;
  discipline_code?: string;
  regions?: number[];
  goto_types?: IGotoType[];
  skills: ICustomSkill[];
  languages: ILanguage[];
  sectors: ICustomSector[];
  tools?: ICustomItem[];
  custom_skills: ICustomSkill[];
  custom_sectors: ICustomSector[];
  custom_platforms: ICustomPlatform[];
  platforms?: ICustomSkill[];
  start_date: string;
  end_date: string;
  updated_at: string;
  ir35: {
    freelancer_arrange_space: 1 | 0;
    freelancer_defines_location: 1 | 0;
    freelancer_flexibility: string;
    freelancer_type: string;
    payment_company_name: string;
  };
  umbrella_companies?: IUmbrella[];
  dates?: { date: string; capacity: string | number }[];
  job_title?: string;
  rate_min?: number;
  rate_max?: number;
  work_description?: string;
  work_setting?: string;
  right_requirements?: string;
  permanent_roles?: boolean;
  job_url?: string;
  job_url_name?: string;
  role_involves?: string;
  country_of_residence?: string;
  residence_city?: string;
  company_benefits?: string;
  excluded_talents: number[] | { id: number; name: string }[];
  organisations: { id: number; name: string }[];
  projects: { id: number; name: string; project_id: string }[];
  capacity: string;
  wbs_code: string;
  job_number: string;
  clients: IClient[];
  specialism_flexible: boolean;
  description_url: File | string | null;
  full_availability: boolean;
  assignees: IAssignee[];
}
export type IUmbrella = {
  id: number;
  company_name: string;
};
export type IPaymentCompany = {
  id?: number;
  name: string;
};
export type ICustomItem = {
  id: number;
  name: string;
  order_id?: number;
};
export type ICustomSkill = {
  id: number;
  name: string;
};
export type ICustomPlatform = {
  id: number;
  name: string;
};
export type ICustomSector = {
  id: number;
  name: string;
};
export interface IBriefsList {
  [k: string]: any;
  closed: IBrief[];
  fulfilled: IBrief[];
  opened: IBrief[];
  resourced: IBrief[];
}

export interface IMatching {
  id: number;
  bookings: IBooking[];
  brief_id: number;
  status: string;
  available_statuses: string[];
  brief_accepted: 0 | 1;
  brief_declined: 0 | 1;
  interview_requested: 0 | 1;
  interview_medium: string | null;
  interview_date: Date | string | null;
  booking_requested: 0 | 1;
  client_feedback: string | null;
  talent: ITalent;
  talent_feedback: string | null;
  talent_booked: 0 | 1;
  talent_rejected: 0 | 1;
  talent_rate: number | null;
  masked_talent_rate: string | null;
  talent_location: string | null;
  talent_substitution: string | null;
  wildcard: 0 | 1;
  display_order: number;
  system_matched: 0 | 1;
  created_at: string;
  updated_at: string;
  score_total: number;
  start_date?: string;
  end_date?: string;
  [key: string]: any;
  reason_id?: number;
  talent_type: string | null;
  notes?: string;
  talent_name: string;
  talent_discipline_code?: string;
  talent_discipline_name?: string;
  talent_level_code?: string;
  talent_level_name?: string;
  talent_skills: [{ id: number; name: string; order_id: number }];
  talent_sectors: [{ id: number; name: string; order_id: number }];
  talent_tools?: [{ id: number; name: string; order_id: number }];
  talent_organisation?: { id: number; name: string };
  talent_tro: any[];
  talent_availability: number;
  talent_full_availability: boolean;
  process_status_update?: boolean;
  enquiredFreelancerId?: number;
  talent_status: string;
  is_suggested: boolean;
}

export interface IBriefClientAdminData {
  id: number;
  description?: string | null;
  start_date?: string;
  end_date?: string;
  sds_ready?: boolean;
  include_weekends?: boolean;
  duration_days?: number;
  dates?: { date: string }[];
  job_url?: string;
}

export interface IListAddableTalentsData {
  briefId: number;
  page: number;
  search?: string;
  sort?: DataTableSortMeta;
}

export interface IGotoType {
  id?: number;
  code: string;
  name: string;
}
