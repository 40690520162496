import { User, Users } from "phosphor-react";
import { Avatar } from "primereact/avatar";
import { Menu } from "primereact/menu";
import React, { SyntheticEvent, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, RouteComponentProps, withRouter } from "react-router-dom";
import * as permissionType from "~/constants/permissions";
import { usePermissionContext } from "~/contexts/PermissionContext";
import { generateColor, getInitials, makeUniqueUrl } from "~/utils";
import actions from "../../store/actions";
import { IState } from "../../store/reducers/index";
import "./LayoutHeader.scss";
import {
  LOGOUT,
  PERSONAL_DETAILS,
  POPUP_MANAGEMENT,
  SEPARATOR,
  SETTINGS,
  TALENT_MENU,
  USER_MANAGEMENT,
} from "./constants";
import { SKILLS } from "~/forms/BriefViewDetailsForm/constants";
import { useGlobalContext } from "~/contexts/GlobalContext";

interface IProps extends RouteComponentProps {
  otherTalent: { name?: string; id: number; profile_image_url?: string }[];
}

function UserMenu(props: IProps) {
  const dispatch = useDispatch();
  const authenticatedUser = useSelector((state: IState) => state.user.authenticatedUser);
  const talent = useSelector((state: IState) => state.talent.talent);
  const { resetPermissions, isAdmin, isTalent, userAccess } = usePermissionContext();
  const {
    global: { publicOptions },
  } = useGlobalContext();

  const isOrganisation = userAccess(permissionType.getOwnOrganisation, permissionType.getAnyOrganisation);
  const headerLogo =
    authenticatedUser?.image_url || authenticatedUser?.header_image_url || publicOptions?.images?.HEADER_LOGO;

  let menuRef = useRef<any>(null);

  const availableItems: string[] = [LOGOUT];
  // isAdmin && availableItems.unshift(SUPERPOWERS, USER_MANAGEMENT, SETTINGS);
  isOrganisation && availableItems.unshift(PERSONAL_DETAILS);
  isTalent && availableItems.unshift(TALENT_MENU, SEPARATOR, PERSONAL_DETAILS);

  const talentProfileImage = talent?.work_profile?.profile_image_url;

  const allItems: { [key: string]: any } = useMemo(
    () => ({
      [SEPARATOR]: { separator: true },
      [TALENT_MENU]: {
        style: { padding: "0px" },
        items: [
          ...props?.otherTalent.map(({ name, id, profile_image_url }) => ({
            template: () => (
              <div className="p-menuitem-content">
                <NavLink
                  onClick={toggleMenu}
                  to={`/talents/${id}`}
                  className={"p-menuitem-link"}
                  style={{ position: "relative" }}
                >
                  <Avatar
                    image={profile_image_url}
                    className="talent-menu-avatar"
                    shape="circle"
                    icon={<Users size={15} />}
                  />
                  <div className="flex align">
                    <span>{name}</span>
                  </div>
                </NavLink>
              </div>
            ),
          })),
        ],
      },
      [PERSONAL_DETAILS]: {
        icon: "pi pi-fw pi-user",
        label: "Personal details",
        command: () => props.history.push("/profile"),
      },
      [SKILLS]: {
        icon: "pi pi-fw pi-tags",
        label: "Skills",
        command: () => props.history.push("/skills"),
      },
      [USER_MANAGEMENT]: {
        icon: "pi pi-fw pi-lock",
        label: "User management",
        command: () => props.history.push("/permissions"),
      },
      [POPUP_MANAGEMENT]: {
        icon: "pi pi-fw pi-clone",
        label: "Pop-up management",
        command: () => props.history.push("/popups"),
      },
      [SETTINGS]: {
        icon: "pi pi-fw pi-sliders-v",
        label: "Settings",
        command: () => props.history.push("/settings"),
      },
      [LOGOUT]: { icon: "pi pi-fw pi-power-off", label: "Log out", command: () => logout() },
    }),
    [props?.otherTalent]
  );

  useEffect(() => {
    window.addEventListener("scroll", hideMenu);
    return () => window.removeEventListener("scroll", hideMenu);
  }, []);

  const getItems = (items: any) =>
    items.reduce((specificItems: string[], key: string) => {
      if (key in allItems) {
        specificItems.push(allItems[key]);
      }
      return specificItems;
    }, []);

  const hideMenu = (e: Event) => menuRef.current?.hide(e);

  const toggleMenu = (e: SyntheticEvent) => {
    if (menuRef.current) {
      menuRef.current.toggle(e);
    }
  };

  const logout = () => {
    dispatch(actions.user.logoutUser());
    resetPermissions();
    props.history.push("/login");
  };

  return (
    <div className="UserMenu">
      <Menu model={getItems(availableItems)} popup={true} ref={menuRef} style={{ margin: "5px 10px" }} />
      <div className="user-button" onClick={toggleMenu}>
        <div className="user-images">
          {isTalent ? (
            <Avatar
              image={talentProfileImage && makeUniqueUrl(talentProfileImage)}
              className="user-avatar"
              shape="circle"
              icon={<User size={20} />}
            />
          ) : (
            <Avatar
              className="default-avatar"
              label={getInitials(<User size={20} />, authenticatedUser?.full_name)}
              style={{ backgroundColor: `${generateColor(authenticatedUser?.email)}` }}
            />
          )}
          {!!headerLogo && <img src={makeUniqueUrl(headerLogo)} alt="Header Logo" />}
        </div>
        <div className="user-name">
          {isTalent ? <span>{talent?.name}</span> : <span>{authenticatedUser?.full_name}</span>}
          {!isAdmin && !isTalent && authenticatedUser?.organisation_name && (
            <span>{authenticatedUser.organisation_name}</span>
          )}
        </div>
      </div>
    </div>
  );
}

export default withRouter(UserMenu);
