import React, { useMemo } from "react";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { useGlobalContext } from "~/contexts/GlobalContext";
import cn from "classnames";

interface IDiscipline {
  label: string;
  code: string;
}

interface IDisciplinesDropdown {
  discipline: IDiscipline;
  setDiscipline: ({ code, label }: { code: string; label: string }) => void;
  setPage?: (page: number) => void;
  className?: string;
  allDisciplines?: IDiscipline;
}

export const ALL_DISCIPLINES = {
  label: "All disciplines",
  code: "",
};

export default function DisciplinesDropdown(props: IDisciplinesDropdown) {
  const { discipline, setDiscipline, allDisciplines, setPage } = props;
  const {
    global: { globalOptions },
  } = useGlobalContext();

  const disciplines = useMemo(
    () =>
      globalOptions?.disciplines
        .map(({ name: label, code }: { name: string; code: string }) => ({
          label,
          code,
        }))
        .concat(allDisciplines || ALL_DISCIPLINES),
    [globalOptions?.disciplines]
  );

  function changeDiscipline(e: DropdownChangeEvent) {
    e.stopPropagation();
    setDiscipline(e.value);
    setPage && setPage(0);
  }

  return (
    <Dropdown
      className={cn("filteringSelector", props.className)}
      value={discipline}
      options={disciplines}
      onChange={changeDiscipline}
      optionLabel="label"
      placeholder="Select discipline"
      scrollHeight="auto"
    />
  );
}
