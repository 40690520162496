import { useState } from "react";
import { DataTableSortMeta } from "primereact/datatable";
import { IOrganisationFilterSettings } from "~/store/reducers/organisation";

const defaultSort: DataTableSortMeta = {
  field: "name",
  order: 1,
};

const useOrganisationFilterSettings = () => {
  const [search, setSearch] = useState<string>("");
  const [sort, setSort] = useState<DataTableSortMeta>(defaultSort);

  const resetFilterOrganisationSettings = (currentPath: string) => {
    const isExistOrganisationId = Number.isInteger(+currentPath?.split("/")?.filter(Boolean)[1]);
    const isItemOrganisationPath = currentPath?.includes("/organisations") && isExistOrganisationId;

    if (currentPath && currentPath !== "/organisations" && !isItemOrganisationPath) {
      setSearch("");
      setSort(defaultSort);
    }
  };

  return {
    search,
    setSearch,
    sort,
    setSort,
    resetFilterOrganisationSettings,
  } as IOrganisationFilterSettings;
};
export default useOrganisationFilterSettings;
