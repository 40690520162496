import React from "react";
import { IMaxCount } from "./BriefViewDetailsForm";
import { isNull, isUndefined } from "lodash";
import { Link } from "react-router-dom";
import { Tooltip } from "primereact/tooltip";
import moment from "moment";
import { TalentActivityStatus } from "~/store/constants/talent";

export const getEntriesCounter = (item: string, maxCount: IMaxCount, selectedEntries: any, selectedDiscipline: any) => {
  const maxLimit = maxCount[item]?.limit;

  let entry: string | undefined = undefined;
  switch (item) {
    case "skills":
      entry = "skill";
      break;
    case "sectors":
      entry = "sector";
      break;
    case "tools":
      entry = "tool";
      break;
    case "platforms":
      entry = "platform";
      break;
  }

  if (!maxLimit) return null;
  if (maxLimit) {
    const count = maxLimit - selectedEntries.length;
    const isPlural = count !== 1;

    return (
      <p className="left-choose-entries">
        You can select up to <b>{count}</b> {entry}
        {isPlural ? "s" : ""}
      </p>
    );
  }
};

export const jobUrlData = (jobUrl: string | undefined, jobUrlFormData: any) => {
  if (isUndefined(jobUrlFormData.current && !jobUrl)) {
    return false;
  }
  if (isNull(jobUrlFormData.current) && jobUrl) {
    return { job_url: null };
  }
  return jobUrlFormData.current;
};

// Add separator after each of array element except the last
export const intersperse = (arr: any[], separator: any) =>
  arr.length === 0 ? [] : arr.slice(1).reduce((result, item, i) => result.concat([separator, item]), [arr[0]]);

export const renderTalentTeams = (items: any[], separator: any, icons: ("link" | "status")[] = [], showPhone = true) =>
  intersperse(
    items.map(
      ({
        id,
        name,
        specialism_name,
        status,
        telephone,
      }: {
        id: number;
        name: string;
        specialism_name: string;
        status: TalentActivityStatus;
        telephone: string;
      }) => (
        <Link key={id} to={`/talents/${id}`} target="_blank">
          {icons?.includes("status") && (
            <>
              <Tooltip position="top" target=".pi-circle-fill" />
              <i
                className="pi pi-circle-fill"
                data-pr-tooltip={status === TalentActivityStatus.PUBLISHED ? "Active" : "Inactive"}
                style={{
                  color: status === TalentActivityStatus.PUBLISHED ? "var(--button-green-color)" : "var(--brand-color)",
                }}
              />
            </>
          )}
          <span>
            {name}
            {!!specialism_name && ` (${specialism_name})`}
            {!!telephone && showPhone && (
              <>
                <br />
                <small>{telephone}</small>
              </>
            )}
          </span>
          {icons?.includes("link") && <i className="pi pi-external-link" />}
        </Link>
      )
    ),
    separator
  ).map((item: any, id: number) => <React.Fragment key={id}>{item}</React.Fragment>);

export const renderBriefDates = (dates: { date: string; capacity: number | string }[]) =>
  intersperse(
    dates.map(({ date, capacity }, i) => (
      <span key={i}>
        {moment(date).format("DD/MM/YYYY")}
        {capacity && ` (${parseInt(capacity as string)}%)`}
      </span>
    )),
    ", "
  );
