import React, { createContext, useContext } from "react";
import useOrganisationFilterSettings from "~/hooks/useOrganisationFilterSettings";
import { IOrganisationFilterSettings } from "~/store/reducers/organisation";

export const OrganisationContext = createContext(null as IOrganisationFilterSettings | null);

export const useOrganisationContext = () => {
  const context = useContext(OrganisationContext);
  if (!context) {
    throw new Error("useOrganisationContext must be used within a OrganisationContextProvider");
  }
  return context;
};

const OrganisationContextProvider = ({ children }: any) => {
  return (
    <OrganisationContext.Provider
      value={{
        ...useOrganisationFilterSettings(),
      }}
    >
      {children}
    </OrganisationContext.Provider>
  );
};

export default OrganisationContextProvider;
