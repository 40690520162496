import React, { useEffect, useState } from "react";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import DisciplinesDropdown from "~/components/common/DisciplinesDropdown/DisciplinesDropdown";
import ApMultiselect from "~/components/common/ApMultiselect/ApMultiselect";
import {
  ALL_DISCIPLINES_FILTERING_LABEL,
  ALL_TALENT_GOTO_LABEL,
  FAV_TAG,
  defaultMatchableFilter,
} from "~/hooks/useTalentFilterSettings";
import { useDispatch, useSelector } from "react-redux";
import * as permissionType from "~/constants/permissions";
import { usePermissionContext } from "~/contexts/PermissionContext";
import { useFilterContext } from "~/contexts/FilterContext";
import { useGlobalContext } from "~/contexts/GlobalContext";
import { useTalentContext } from "~/contexts/TalentContext";
import { IState } from "~/store/reducers/index";
import trim from "lodash/trim";
import TagsFilter from "../TagsFilter/TagsFilter";
import AppliedFilters, { IAppliedFilter } from "../AppliedFilters/AppliedFilters";
import { createFilterObject, mapIdToLabel, toggleValue } from "~/utils";
import actions from "~/store/actions";
import { Accordion, AccordionTab, AccordionTabOpenEvent } from "primereact/accordion";

const FILTERS_COUNT = 9;

function createChangeHandler(setter: (value: any) => void) {
  return function (e: DropdownChangeEvent) {
    e.stopPropagation();
    setter(e.value);
  };
}

const createSwitchToggleHandler = (setter: (value: any) => void) => () =>
  setter((value: string) => toggleValue(value, "OR", "AND"));

interface IProps {
  collectFilters: () => any;
  activeFilter: number | null;
  setActiveFilter: any;
}

function SidebarFilters(props: IProps) {
  const { collectFilters, setActiveFilter } = props;
  const dispatch = useDispatch();
  const {
    discipline,
    setDiscipline,
    specialisms,
    setSpecialisms,
    levels,
    setLevels,
    skills,
    setSkills,
    sectors,
    setSectors,
    tagsFilter,
    setTagsFilter,
    talentMatchableOptions,
    matchableFilter,
    setMatchableFilter,
    organisationFilter,
    setOrganisationFilter,
    skillsOperator,
    setSkillsOperator,
    sectorsOperator,
    setSectorsOperator,
    resetFilterTalentSettings,
    tagsOperator,
    setTagsOperator,
    talentGotoType,
    setTalentGotoType,
    talentAppliedFiltersCount,
    talentGotoTypeOptions,
  } = useTalentContext();
  const {
    global: {
      optionsNormalization: { SPECIALISMS, LEVELS, SKILLS, SECTORS },
    },
  } = useGlobalContext();
  const { userAccess } = usePermissionContext();
  const { organisations: ORGANISATIONS, isFetchingOrganisationsList } = useSelector(
    (state: IState) => state.organisation
  );
  const TAGS = useSelector((state: IState) => state.user.authenticatedUser?.tags);
  const { filters, addFilter } = useFilterContext();
  const [appliedFilters, setAppliedFilters] = useState<IAppliedFilter[]>([]);
  const [activeIndex, setActiveIndex] = useState<number[]>(getSavedActiveIndex());

  useEffect(() => {
    !TAGS && dispatch(actions.user.getTags());
    !ORGANISATIONS && dispatch(actions.organisation.listOrganisations({ published: 1 }));
  }, []);

  const changeOrganisations = createChangeHandler(setOrganisationFilter);
  const changeSpecialisms = createChangeHandler(setSpecialisms);
  const changeLevels = createChangeHandler(setLevels);
  const changeSkills = createChangeHandler(setSkills);
  const changeSectors = createChangeHandler(setSectors);

  const toggleSkillsSwitch = createSwitchToggleHandler(setSkillsOperator);
  const toggleSectorsSwitch = createSwitchToggleHandler(setSectorsOperator);
  const toggleTagsSwitch = createSwitchToggleHandler(setTagsOperator);

  const isStatusFilterVisible = userAccess(
    permissionType.getGlobalTalentList,
    permissionType.getOwnTalentList,
    permissionType.getResourcedTalentList
  );

  // tags
  function changeTags(e: DropdownChangeEvent) {
    e.stopPropagation();

    setTagsFilter((tagsFilter) => (e.checked ? [...tagsFilter, e.value] : tagsFilter.filter((tag) => tag !== e.value)));
  }
  const tagsList = [FAV_TAG, ...(TAGS?.sort((a, b) => a.name.localeCompare(b.name)) || [])];

  // applied filters
  const handleSaveFilter = ({ filterName }: { filterName: string }) =>
    addFilter({
      name: trim(filterName),
      params: {
        ...collectFilters(),
        discipline,
        matchableFilter,
      },
    }).then(({ id }) => setActiveFilter(id));

  const handleResetFilter = () => {
    setActiveFilter(null);
    resetFilterTalentSettings("/");
  };

  useEffect(() => {
    const applyFiltersArray = [];

    tagsFilter.length &&
      applyFiltersArray.push(
        createFilterObject(
          "Tag",
          tagsFilter.map((id) => mapIdToLabel(tagsList || [], id, "name")),
          (removeId?: number) => setTagsFilter((tags) => tags.filter((id) => id !== removeId))
        )
      );

    matchableFilter.label !== defaultMatchableFilter?.label &&
      applyFiltersArray.push(
        createFilterObject("Status", matchableFilter.label, () => setMatchableFilter(defaultMatchableFilter))
      );

    organisationFilter.length &&
      applyFiltersArray.push(
        createFilterObject(
          "Organisation",
          organisationFilter.map((id) => mapIdToLabel(ORGANISATIONS || [], id, "name")),
          (removeId?: number) => setOrganisationFilter((organisations) => organisations.filter((id) => id !== removeId))
        )
      );

    talentGotoType.code !== ALL_TALENT_GOTO_LABEL.code &&
      applyFiltersArray.push(
        createFilterObject("Profile type", talentGotoType.name, () => setTalentGotoType(ALL_TALENT_GOTO_LABEL))
      );

    discipline.code !== ALL_DISCIPLINES_FILTERING_LABEL.code &&
      applyFiltersArray.push(
        createFilterObject("Discipline", discipline.label, () => setDiscipline(ALL_DISCIPLINES_FILTERING_LABEL))
      );

    specialisms.length &&
      applyFiltersArray.push(
        createFilterObject(
          "Specialism",
          specialisms.map((id) => mapIdToLabel(SPECIALISMS, id)),
          (removeId?: number) => setSpecialisms((specialisms) => specialisms.filter((id) => id !== removeId))
        )
      );

    levels.length &&
      applyFiltersArray.push(
        createFilterObject(
          "Level",
          levels.map((id) => mapIdToLabel(LEVELS, id)),
          (removeId?: number) => setLevels((levels) => levels.filter((id) => id !== removeId))
        )
      );

    skills.length &&
      applyFiltersArray.push(
        createFilterObject(
          "Skill",
          skills.map((id) => mapIdToLabel(SKILLS, id)),
          (removeId?: number) => setSkills((skills) => skills.filter((id) => id !== removeId))
        )
      );

    sectors.length &&
      applyFiltersArray.push(
        createFilterObject(
          "Sector",
          sectors.map((id) => mapIdToLabel(SECTORS, id)),
          (removeId?: number) => setSectors((sectors) => sectors.filter((id) => id !== removeId))
        )
      );

    setAppliedFilters(applyFiltersArray);
  }, [
    discipline,
    matchableFilter,
    organisationFilter,
    specialisms,
    levels,
    skills,
    sectors,
    tagsFilter,
    TAGS,
    talentGotoType,
  ]);

  function getSavedActiveIndex() {
    const storedActiveIndex = localStorage.getItem("TALENTS_FILTERS_ACTIVE_INDEX");
    return storedActiveIndex ? JSON.parse(storedActiveIndex) : Array.from(Array(FILTERS_COUNT).keys());
  }

  function onTabOpen(e: AccordionTabOpenEvent) {
    saveActiveIndex(activeIndex.concat(e.index));
  }

  function onTabClose(e: AccordionTabOpenEvent) {
    saveActiveIndex(activeIndex.filter((index) => index !== e.index));
  }

  function saveActiveIndex(newActiveIndex: number[]) {
    localStorage.setItem("TALENTS_FILTERS_ACTIVE_INDEX", JSON.stringify(newActiveIndex));
    setActiveIndex(newActiveIndex);
  }

  return (
    <div className="Talents__filters talent-filters">
      <AppliedFilters
        onSave={handleSaveFilter}
        onReset={handleResetFilter}
        filters={filters}
        appliedFilters={appliedFilters}
        appliedFiltersCount={talentAppliedFiltersCount}
      />

      <Accordion
        multiple
        className="border-design"
        expandIcon="pi pi-chevron-down"
        collapseIcon="pi pi-chevron-up"
        onTabOpen={onTabOpen}
        onTabClose={onTabClose}
        activeIndex={activeIndex}
      >
        <AccordionTab header="Tag">
          <TagsFilter
            tagsList={tagsList}
            onSwitchChange={toggleTagsSwitch}
            tagsOperator={tagsOperator}
            onTagChange={changeTags}
            tagsFilter={tagsFilter}
          />
        </AccordionTab>

        {isStatusFilterVisible && (
          <AccordionTab header="Status">
            <Dropdown
              className="filteringSelector"
              value={matchableFilter}
              options={talentMatchableOptions}
              onChange={(e: DropdownChangeEvent) => setMatchableFilter(e.value)}
              optionLabel="label"
              scrollHeight="300"
              placeholder="All"
            />
          </AccordionTab>
        )}

        <AccordionTab header="Profile type">
          <Dropdown
            className="filteringSelector"
            value={talentGotoType}
            options={talentGotoTypeOptions}
            onChange={(e: DropdownChangeEvent) => setTalentGotoType(e.value)}
            optionLabel="name"
            scrollHeight="300"
            placeholder="All"
          />
        </AccordionTab>

        <AccordionTab header="Organisation">
          <ApMultiselect
            id="organisations"
            className="filteringSelector"
            options={ORGANISATIONS}
            optionLabel="name"
            optionValue="id"
            placeholder="All"
            display="chip"
            value={organisationFilter}
            filter
            resetFilterOnHide
            onChange={changeOrganisations}
            noField
            optionsDesign="vertical"
            removeIcon="pi pi-times"
            loading={isFetchingOrganisationsList}
          />
        </AccordionTab>

        <AccordionTab header="Discipline">
          <DisciplinesDropdown
            className="disciplines-filter"
            discipline={discipline}
            setDiscipline={setDiscipline}
            allDisciplines={ALL_DISCIPLINES_FILTERING_LABEL}
          />
        </AccordionTab>

        <AccordionTab header="Specialism">
          <ApMultiselect
            className="filteringSelector"
            options={SPECIALISMS}
            optionLabel="label"
            optionValue="id"
            placeholder="All"
            display="chip"
            value={specialisms}
            filter
            resetFilterOnHide
            onChange={changeSpecialisms}
            id="specialisms"
            noField
            optionsDesign="vertical"
            removeIcon="pi pi-times"
          />
        </AccordionTab>

        <AccordionTab header="Level">
          <ApMultiselect
            className="filteringSelector"
            display="chip"
            filter
            id="levels"
            noField
            onChange={changeLevels}
            optionLabel="label"
            options={LEVELS}
            optionValue="id"
            placeholder="All"
            resetFilterOnHide
            value={levels}
            optionsDesign="vertical"
            removeIcon="pi pi-times"
          />
        </AccordionTab>

        <AccordionTab header="Skill">
          <label></label>
          <ApMultiselect
            id="skills"
            className="filteringSelector"
            options={SKILLS}
            optionLabel="label"
            optionValue="id"
            placeholder="All"
            display="chip"
            value={skills}
            filter
            resetFilterOnHide
            onChange={changeSkills}
            noField
            withSwitch
            switchValue={skillsOperator}
            onToggleSwitch={toggleSkillsSwitch}
            optionsDesign="vertical"
            removeIcon="pi pi-times"
          />
        </AccordionTab>

        <AccordionTab header="Sector">
          <ApMultiselect
            id="sectors"
            className="filteringSelector"
            options={SECTORS}
            optionLabel="label"
            optionValue="id"
            placeholder="All"
            display="chip"
            value={sectors}
            filter
            resetFilterOnHide
            onChange={changeSectors}
            noField
            withSwitch
            switchValue={sectorsOperator}
            onToggleSwitch={toggleSectorsSwitch}
            optionsDesign="vertical"
            removeIcon="pi pi-times"
          />
        </AccordionTab>
      </Accordion>
    </div>
  );
}

export default SidebarFilters;
