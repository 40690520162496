import isUndefined from "lodash/isUndefined";
import { MessagesMessage } from "primereact/messages";
import { ToastMessage } from "primereact/toast";
import { ReactNode } from "react";
import { AnyAction } from "redux";
import * as Toasts from "../constants/toasts";
import { AxiosError } from "axios";

export type Popup = {
  content: string | ReactNode;
  form?: Function;
  className?: string;
  buttons?: {
    text: string;
    callback?: Function;
    active?: boolean;
  }[];
};

// ------------------------------------
// Interface
// ------------------------------------
export interface IToastsState {
  alerts: MessagesMessage[];
  toasts: ToastMessage[];
  popup: Popup | undefined;
  globalError: AxiosError | undefined;
}

// ------------------------------------
// Initial state
// ------------------------------------
const initialState: IToastsState = {
  alerts: [],
  toasts: [],
  popup: undefined,
  globalError: undefined,
};

// ------------------------------------
// Reducer
// ------------------------------------
export default (state = initialState, action: AnyAction): IToastsState => {
  switch (action.type) {
    case Toasts.SET_ALERTS:
      return {
        ...state,
        alerts: !isUndefined(action.payload.length) ? action.payload : [...state.alerts, action.payload],
      };
    case Toasts.SET_TOASTS:
      return {
        ...state,
        toasts: !isUndefined(action.payload.length) ? action.payload : [...state.toasts, action.payload],
      };
    case Toasts.SET_POPUP:
      return {
        ...state,
        popup: action.payload,
      };
    case Toasts.SET_GLOBAL_ERROR:
      return {
        ...state,
        globalError: action.payload,
      };
    default:
      return state;
  }
};
