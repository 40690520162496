import React, { useEffect, useRef, useState, useCallback } from "react";
import { DropdownChangeEvent } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { ALL_TALENT_GOTO_LABEL, FAV_TAG } from "~/hooks/useTalentFilterSettings";
import { useFilterContext } from "~/contexts/FilterContext";
import { useTalentContext } from "~/contexts/TalentContext";
import CustomFilters from "./CustomFilters/CustomFilters";
import ViewToggler, { ViewModes } from "~/components/common/ViewToggler/ViewToggler";
import SidebarFilters from "./SidebarFilters/SidebarFilters";
import cn from "classnames";
import "./FiltersWrapper.scss";
import { getDefaultFilterVisibility, pluralize, toggleValue } from "~/utils";

export enum FiltersViewModes {
  VISIBLE = "VISIBLE",
  HIDDEN = "HIDDEN",
}

interface IProps {
  children: React.ReactNode;
  viewMode: ViewModes;
  totalTalents: number;
  setViewMode: (mode: ViewModes) => void;
  collectFilters: () => void;
}

function FiltersWrapper(props: IProps) {
  const { collectFilters, viewMode, setViewMode, totalTalents } = props;
  const {
    talentGotoTypeOptions,
    talentAppliedFiltersCount,
    resetFilterTalentSettings,
    setDiscipline,
    setSpecialisms,
    setLevels,
    setSkills,
    setSectors,
    setTagsFilter,
    setMatchableFilter,
    setOrganisationFilter,
    setSkillsOperator,
    setSectorsOperator,
    setTagsOperator,
    setTalentGotoType,
    availabilityRange,
    setAvailabilityRange,
  } = useTalentContext();

  const [showFiltersMode, setShowFiltersMode] = useState<FiltersViewModes>(getDefaultFilterVisibility());
  const [availabilityDates, setAvailabilityDates] = useState<(Date | null)[]>(availabilityRange);
  const { filters, deleteFilter, activeFilter, setActiveFilter, getFilters } = useFilterContext();

  const calendarRef = useRef<any>(null);

  const handleDeleteFilter = (filterId: number) => {
    deleteFilter(filterId);
    if (activeFilter === filterId) {
      resetFilterTalentSettings("/");
    }
  };

  useEffect(() => {
    getFilters();
  }, []);

  const handleChangeFilter = (e: DropdownChangeEvent) => {
    const { params } = (!!filters && filters.find(({ id }) => id === e.value)) || {};

    if (params) {
      resetFilterTalentSettings("/");

      "discipline" in params && setDiscipline(params.discipline);
      "matchableFilter" in params && setMatchableFilter(params.matchableFilter);
      "type" in params &&
        setTalentGotoType(talentGotoTypeOptions.find(({ code }) => code === params.type) || ALL_TALENT_GOTO_LABEL);
      "organisations" in params && setOrganisationFilter(params.organisations);
      "specialisms" in params && setSpecialisms(params.specialisms);
      "levels" in params && setLevels(params.levels);
      "skills" in params && setSkills(params.skills);
      "skills_operator" in params && setSkillsOperator(params.skills_operator);
      "sectors" in params && setSectors(params.sectors);
      "sectors_operator" in params && setSectorsOperator(params.sectors_operator);
      "tags_operator" in params && setTagsOperator(params.tags_operator);
      "tags" in params && setTagsFilter(params.tags);
      "favourite" in params && setTagsFilter((tagsFilter) => [FAV_TAG.id, ...tagsFilter]);

      setTimeout(() => setActiveFilter(e.value), 0);
    }
  };

  const toggleFilterVisibility = () => {
    const newValue = toggleValue(showFiltersMode, FiltersViewModes.HIDDEN, FiltersViewModes.VISIBLE);

    localStorage.setItem("TALENTS_FILTER_VISIBILITY", newValue as FiltersViewModes);
    setShowFiltersMode(newValue as FiltersViewModes);
  };

  const handleApplyRange = () => {
    let dates = availabilityDates;
    if (!dates[1]) {
      dates = [dates[0], dates[0]];
      setAvailabilityDates(dates);
    }
    setAvailabilityRange(dates as Date[]);
    calendarRef.current && calendarRef.current.hide();
  };

  const renderCalendarFooter = useCallback(
    () => (
      <div className="availability-picker-footer">
        <Button label="Apply" className="p-button-rounded apply-button" onClick={handleApplyRange} />
      </div>
    ),
    [calendarRef, availabilityDates]
  );

  return (
    <>
      <div className="Talents__head">
        <div className="Talents__head-left">
          <p>
            Found:{" "}
            <strong>
              {totalTalents || 0} {pluralize(totalTalents, "profile")}
            </strong>
          </p>
          <CustomFilters
            activeFilter={activeFilter}
            filters={filters}
            onChangeCustomFilter={handleChangeFilter}
            onDeleteFilter={handleDeleteFilter}
          />
          <button
            type="button"
            className={cn("button-grey with-icon", showFiltersMode === FiltersViewModes.VISIBLE && "active")}
            onClick={toggleFilterVisibility}
          >
            <span className="pi pi-sliders-h" />
            {showFiltersMode === FiltersViewModes.HIDDEN ? "Show" : "Hide"} filters
            {talentAppliedFiltersCount > 0 && <span className="badge">{talentAppliedFiltersCount}</span>}
          </button>
        </div>
        <div className="Talents__head-right">
          <div className="range-picker">
            <label>Availability:</label>
            <Calendar
              ref={calendarRef}
              value={availabilityDates}
              onChange={(e) => e.value && setAvailabilityDates(e.value)}
              dateFormat="dd/mm/yy"
              minDate={new Date()}
              selectionMode="range"
              readOnlyInput
              showIcon
              className="range-picker__calendar"
              footerTemplate={renderCalendarFooter}
            />
          </div>
          <ViewToggler viewMode={viewMode} setViewMode={setViewMode} />
        </div>
      </div>
      <div className="Talents__content">
        {showFiltersMode === FiltersViewModes.VISIBLE && (
          <SidebarFilters
            collectFilters={collectFilters}
            activeFilter={activeFilter}
            setActiveFilter={setActiveFilter}
          />
        )}
        {/* <TalentsList ... /> here */}
        {props.children}
      </div>
    </>
  );
}

export default FiltersWrapper;
