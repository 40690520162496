import React from "react";
import { Field, FieldInputProps, FormikProps, FormikValues } from "formik";
import { RadioButton, RadioButtonChangeParams } from "primereact/radiobutton";
import TooltipIcon from "../TooltipIcon/TooltipIcon";
import "./ApRadioGroup.scss";

export interface IRadioOption {
  id?: number;
  code?: string;
  name: string;
  description?: string;
}
interface IRadio {
  disabled?: boolean;
  formik?: FormikProps<FormikValues>;
  name: string;
  noField?: boolean;
  onChange?: (e: RadioButtonChangeParams) => void;
  options: IRadioOption[];
  selectedProp?: "id" | "code" | "name";
  discriptionType?: "list" | "text";
  withTooltip?: boolean;
}

export default function ApRadioGroup(props: IRadio) {
  let meta, field: FieldInputProps<any>;
  if (props.noField && props.formik) {
    meta = props.formik.getFieldMeta(props.name);
    field = props.formik.getFieldProps(props.name);
  }
  const selectedProp = props.selectedProp ?? "code";

  const renderRadioDescription = (description: string) => {
    if (props.discriptionType === "list") {
      return (
        <ul>
          {description.split("|").map((item: string, k: number) => (
            <li key={k}>{item}</li>
          ))}
        </ul>
      );
    }
    return description;
  };

  return props.noField && meta && props.formik ? (
    <>
      {props.options.map((radio, i: number) => {
        const inputId = radio.name.replace(/\s/g, "");
        return (
          <div key={i} className="col-12 p-radiobutton-wrapper">
            <RadioButton
              checked={field.value === radio[selectedProp]}
              disabled={props.disabled}
              inputId={inputId}
              name={radio.name}
              onChange={props.onChange || ((e) => props.formik?.setFieldValue(props.name, e.value))}
              value={radio[selectedProp]}
            />
            <label htmlFor={inputId} className="p-radiobutton-label">
              {radio.name} {props?.withTooltip && <TooltipIcon id={inputId} content={radio?.description} />}
              <br />
              {radio?.description && !props?.withTooltip && (
                <span className="p-radiobutton-label-description">{renderRadioDescription(radio?.description)}</span>
              )}
            </label>
          </div>
        );
      })}
      {meta.touched && meta.error && <div className="ap-error">{meta.error}</div>}
    </>
  ) : (
    <Field name={props.name}>
      {({ field, form, meta }: any) => (
        <>
          {props.options.map((radio, i: number) => {
            const inputId = radio.name.replace(/\s/g, "");
            return (
              <div key={i} className="col-12 p-radiobutton-wrapper">
                <RadioButton
                  checked={field.value === radio[selectedProp]}
                  disabled={props.disabled}
                  inputId={inputId}
                  name={radio.name}
                  onChange={props.onChange || ((e) => form.setFieldValue(props.name, e.value))}
                  value={radio[selectedProp]}
                />
                <label htmlFor={inputId} className="p-radiobutton-label">
                  {radio.name} {props?.withTooltip && <TooltipIcon id={inputId} content={radio?.description} />}
                  <br />
                  {radio?.description && !props?.withTooltip && (
                    <span className="p-radiobutton-label-description">
                      {renderRadioDescription(radio?.description)}
                    </span>
                  )}
                </label>
              </div>
            );
          })}
          {meta.touched && meta.error && <div className="ap-error">{meta.error}</div>}
        </>
      )}
    </Field>
  );
}
