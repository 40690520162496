import { Menubar } from "primereact/menubar";
import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, RouteComponentProps, withRouter } from "react-router-dom";
import * as permissionType from "~/constants/permissions";
import { usePermissionContext } from "~/contexts/PermissionContext";
import GenieLogo from "../../assets/genie-logo.svg";
import { IState } from "../../store/reducers/index";
import "./LayoutHeader.scss";
import UserMenu from "./UserMenu";
import { useClearanceContext } from "~/contexts/ClearanceContext";
import { Badge } from "primereact/badge";
import { Button } from "primereact/button";
import toasts from "~/store/actions/toasts";
import DirectBookSecondStep from "~/routes/Talents/DirectBookOptions/DirectBookSecondStep";
import { Sparkle } from "phosphor-react";
import { useNotificationContext } from "~/contexts/NotificationContext";

function LayoutHeader(props: RouteComponentProps) {
  const dispatch = useDispatch();
  const authenticatedUser = useSelector((state: IState) => state.user.authenticatedUser);
  const isAuthenticated = useSelector((state: IState) => state.user.isAuthenticated);
  const talent = useSelector((state: IState) => state.talent.talent);
  const { isAdmin, isRegionalAdmin, isOrganisationAdmin, isTalent, userAccess, isMatchButtonVisible } =
    usePermissionContext();
  const { allRequestsCount } = useClearanceContext();
  const { notificationsCount } = useNotificationContext();

  const otherTalent = useMemo(() => {
    if (!authenticatedUser?.talent_id || !isTalent || !authenticatedUser) return [];

    const talentId = +(window.location.pathname.split("/").pop() || "0");

    const talents = [
      {
        id: authenticatedUser.talent_id,
        name: authenticatedUser.talent_name,
        profile_image_url: authenticatedUser.profile_image_url,
      },
      ...(authenticatedUser.teams || []),
    ];
    return talents.filter(({ id }) => !!id && id !== talentId);
  }, [authenticatedUser?.teams, window.location.pathname]);

  const badgeItem = (label: string | null, value: number | undefined | null, classNames: string) => {
    const badge = value && value > 0 ? <Badge className={classNames} value={value} severity="danger" /> : null;
    return (
      <>
        <span>{label}</span>
        {badge}
      </>
    );
  };

  const items = [
    ...(isMatchButtonVisible
      ? [
          {
            template: () => (
              <Button
                className="p-button-rounded match-button"
                onClick={() => {
                  dispatch(
                    toasts.setPopup({
                      content: <DirectBookSecondStep callback={() => dispatch(toasts.setPopup())} />,
                      buttons: [],
                    })
                  );
                }}
              >
                <Sparkle size={18} />
                <span className="px-2">AI Match</span>
              </Button>
            ),
          },
        ]
      : []),
    ...(isAdmin || isRegionalAdmin
      ? [
          {
            template: () => (
              <NavLink to={"/talents"} className={"p-menuitem-link"}>
                Network
              </NavLink>
            ),
          },
          {
            template: () => (
              <NavLink to={"/resourcing"} className={"p-menuitem-link"}>
                {badgeItem("Resourcing", notificationsCount, "badge-menu-clearances")}
              </NavLink>
            ),
          },
          {
            template: () => (
              <NavLink to={"/requests"} className={"p-menuitem-link"}>
                {isRegionalAdmin ? badgeItem("Requests", allRequestsCount, "badge-menu-clearances") : "Requests"}
              </NavLink>
            ),
          },
          {
            template: () => (
              <NavLink to={"/organisations"} className={"p-menuitem-link"}>
                Organisations
              </NavLink>
            ),
          },
        ]
      : []),

    ...(isOrganisationAdmin
      ? [
          {
            template: () => (
              <NavLink to={"/talents"} className={"p-menuitem-link"}>
                Network
              </NavLink>
            ),
          },
          {
            template: () => (
              <NavLink to={"/resourcing"} className={"p-menuitem-link"}>
                {badgeItem("Resourcing", notificationsCount, "badge-menu-clearances")}
              </NavLink>
            ),
          },
          ...(userAccess(permissionType.getOwnUserBooking)
            ? [
                {
                  template: () => (
                    <NavLink to={"/bookings"} className={"p-menuitem-link"}>
                      Allocations
                    </NavLink>
                  ),
                },
              ]
            : []),
          ...(userAccess(permissionType.approveOwnTalentClearance)
            ? [
                {
                  template: () => (
                    <NavLink to={"/requests"} className={"p-menuitem-link"}>
                      {badgeItem("Requests", allRequestsCount, "badge-menu-clearances")}
                    </NavLink>
                  ),
                },
              ]
            : []),
          {
            template: () => (
              <NavLink to={`/organisations/${authenticatedUser?.organisation_id}`} className={"p-menuitem-link"}>
                Organisation Profile
              </NavLink>
            ),
          },
        ]
      : []),

    ...(isTalent
      ? [
          {
            label: talent?.name,

            ...(otherTalent.length
              ? {
                  items: otherTalent.map(({ name, id }) => ({
                    template: () => (
                      <NavLink to={`/talents/${id}`} className={"p-menuitem-link"}>
                        {name}
                      </NavLink>
                    ),
                  })),
                }
              : []),
          },
        ]
      : []),
    ...[
      {
        label: "Help",
        url: "https://help.meetgenie.co/en/",
        target: "_blank",
      },
    ],
  ];

  return (
    <header className="LayoutHeader">
      <Link to="/" style={{ textDecoration: "none" }}>
        <div className="logo-wrapper">
          <img src={GenieLogo} id="LayoutHeader__Logo" alt="Genie Logo" />
          <h4 className="logo-text">Inside</h4>
        </div>
      </Link>
      <div className="right-block">
        <div className="card">
          <Menubar model={items} />
        </div>
        {isAuthenticated && authenticatedUser && <UserMenu otherTalent={otherTalent} />}
      </div>
    </header>
  );
}

export default withRouter(LayoutHeader);
