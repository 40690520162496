import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { FormikProps, useFormikContext } from "formik";
import ApInputText from "../../components/common/ApInputText/ApInputText";
import ApInputTextarea from "../../components/common/ApInputTextarea/ApInputTextarea";
import ApDropdown from "../../components/common/ApDropdown/ApDropdown";
import { Chips } from "primereact/chips";
import { useGlobalContext } from "~/contexts/GlobalContext";
import { genieDetailsMaxInputLength } from "~/schemas/common";
import Toasts from "~/store/constants/toasts";
import { IClient } from "~/interfaces/common";
import { usePermissionContext } from "~/contexts/PermissionContext";
import * as permissionType from "~/constants/permissions";
import cn from "classnames";

export default function OrganisationDetailsForm(props: { handlePrompt: (isDirty: boolean) => void }) {
  const dispatch = useDispatch();
  const formik: FormikProps<any> = useFormikContext();
  const {
    global: { getCountries, countries: countryOptions },
  } = useGlobalContext();
  const { userAccess } = usePermissionContext();

  const clients = formik?.values.clients || [];
  const canEditFields = userAccess(permissionType.editOwnOrganisation, permissionType.accessAdmin);

  useEffect(() => {
    !countryOptions.length && getCountries();
  }, []);

  useEffect(() => {
    props.handlePrompt && props.handlePrompt(formik.dirty);
  }, [formik.dirty]);

  return (
    <div className="OrganisationDetailsForm">
      <div className={cn("p-fluid field", canEditFields && "field-required")}>
        <label htmlFor="name">Organisation name</label>
        {canEditFields ? <ApInputText id="name" /> : <div className="readonly-text">{formik.values.name}</div>}
      </div>
      <div className="p-fluid field">
        <label htmlFor="description">Organisation description</label>
        {canEditFields ? (
          <ApInputTextarea id="description" maxChars={genieDetailsMaxInputLength.description} />
        ) : (
          <div className="readonly-text" style={{ whiteSpace: "pre-line" }}>
            {formik.values.description}
          </div>
        )}
      </div>
      <div className="p-fluid field">
        <label htmlFor="address_1">Address line 1</label>
        {canEditFields ? (
          <ApInputText id="address_1" />
        ) : (
          <div className="readonly-text">{formik.values.address_1 ? formik.values.address_1 : "—"}</div>
        )}
      </div>
      <div className="p-fluid field">
        <label htmlFor="address_2">Address line 2</label>
        {canEditFields ? (
          <ApInputText id="address_2" />
        ) : (
          <div className="readonly-text">{formik.values.address_2 ? formik.values.address_2 : "—"}</div>
        )}
      </div>
      <div className="p-fluid field">
        <label htmlFor="city">Town / City</label>
        {canEditFields ? (
          <ApInputText id="city" />
        ) : (
          <div className="readonly-text">{formik.values.city ? formik.values.city : "—"}</div>
        )}
      </div>
      <div className={cn("p-fluid field", canEditFields && "field-required")}>
        <label htmlFor="country">Country</label>
        {canEditFields ? (
          countryOptions.length > 0 && (
            <ApDropdown
              id="country"
              optionLabel="name"
              optionValue="code"
              options={countryOptions}
              placeholder="Select a country"
              filter
            />
          )
        ) : (
          <div className="readonly-text">
            {countryOptions.length > 0 && (countryOptions.find((i) => i.code === formik.values.country)?.name || "—")}
          </div>
        )}
      </div>
      <div className="p-fluid field">
        <label htmlFor="postcode">Postcode</label>
        {canEditFields ? (
          <ApInputText id="postcode" />
        ) : (
          <div className="readonly-text">{formik.values.postcode ? formik.values.postcode : "—"}</div>
        )}
      </div>
      <div className="p-fluid field">
        <label htmlFor="clients">Client list</label>
        {canEditFields ? (
          <Chips
            id="clients"
            name="clients"
            placeholder={"Type client name and press enter"}
            value={clients.map((item: IClient) => item.name)}
            separator=","
            onChange={(e) => {
              if (clients.length > e.value.length) {
                // if removing occurs
                formik.setFieldValue(
                  "clients",
                  e.value.map((item) => {
                    return { name: item };
                  })
                );
              }
            }}
            onAdd={(e) => {
              const isSameClient = clients.filter((item: any) => item.name === e.value).length > 0;

              if (isSameClient) {
                dispatch(Toasts.setToasts([{ severity: "error", summary: "", detail: "This client already exists" }]));
              } else {
                formik.setFieldValue("clients", [...clients, { name: e.value }], true);
              }
            }}
          />
        ) : (
          <div className="readonly-text">{clients.map((item: IClient) => item.name).join(", ")}</div>
        )}
      </div>
    </div>
  );
}
