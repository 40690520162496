import * as Yup from "yup";
import { maxSymbolsMessage, minSymbolsMessage, requiredFieldMessage } from "../utils";

export const TalentPersonalYouSchema = Yup.object().shape({
  first_name: Yup.string().min(2, minSymbolsMessage).max(100, maxSymbolsMessage).required(requiredFieldMessage),
  last_name: Yup.string().min(2, minSymbolsMessage).max(100, maxSymbolsMessage).required(requiredFieldMessage),
  email: Yup.string().email("Please, enter the correct email").max(100).required("Please, enter the correct email"),
});

export const OrganisationPersonalYouSchema = Yup.object().shape({
  first_name: Yup.string().min(2, minSymbolsMessage).max(100, maxSymbolsMessage).required(requiredFieldMessage),
  last_name: Yup.string().min(2, minSymbolsMessage).max(100, maxSymbolsMessage).required(requiredFieldMessage),
  email: Yup.string().email("Please, enter the correct email").max(100).required("Please, enter the correct email"),
});
