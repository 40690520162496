import React, { useMemo, useEffect } from "react";
import moment from "moment";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { WarningCircle } from "phosphor-react";
import TalentInfoBlock from "../common/TalentInfoBlock/TalentInfoBlock";
import Loader from "../Loader/Loader";
import { FormikProps, FormikValues } from "formik";
import { useGlobalContext } from "~/contexts/GlobalContext";
import { useBriefContext } from "~/contexts/BriefContext";
import { IBriefValidate } from "~/interfaces/brief";
import { ITalent } from "~/store/constants/talent";
import { pluralize } from "~/utils";
import "./DirectMatchConfirmation.scss";

interface IProps {
  formik: FormikProps<FormikValues>;
  preSelectedTalents: ITalent[];
  newTalentStatus?: string;
}

interface IDate {
  date: string;
  capacity: number;
}

export default function DirectMatchConfirmation(props: IProps) {
  const { formik, preSelectedTalents, newTalentStatus } = props || {};
  const { validateBriefDates, validatedDates, isValidatingDates } = useBriefContext();

  const {
    global: { globalOptions, optionsNormalization },
  } = useGlobalContext();
  const DURATION_ID = optionsNormalization?.DURATION_ID;

  const durationTypes = globalOptions?.duration_types;
  const isExactWorkingDates = formik?.values?.duration_id === DURATION_ID?.EXACT;

  let payload: IBriefValidate = {
    organisation_id: formik?.values?.organisation_id,
    start_date: moment(formik?.values?.start_date).format("YYYY-MM-DD"),
    end_date: moment(formik?.values?.end_date ?? formik?.values?.start_date).format("YYYY-MM-DD"),
    include_weekends: formik?.values?.include_weekends,
    duration_id: formik?.values?.duration_id,
    ...(!isExactWorkingDates && { capacity: formik?.values?.capacity }),
  };
  if (isExactWorkingDates) {
    payload.dates = formik?.values?.dates;
  } else {
    payload.dates = [];
  }

  useEffect(() => {
    validateBriefDates(payload);
  }, []);

  const dates = useMemo(() => {
    return isExactWorkingDates
      ? formik?.values?.dates.map((item: IDate) => ({ ...item, date: moment(item.date).format("DD MMM, YYYY") }))
      : [
          {
            date: `${moment(formik?.values?.start_date).format("DD MMM, YYYY")} — ${moment(
              formik?.values?.end_date
            ).format("DD MMM, YYYY")}`,
            capacity: formik?.values?.capacity,
          },
        ];
  }, [isExactWorkingDates, formik?.values]);

  const totalDaysNumber = useMemo(() => {
    if (validatedDates) {
      const { duration_days, duration_weekends } = validatedDates;
      const totalDays = (duration_days || 0) + (duration_weekends || 0);
      return totalDays;
    }
    return 0;
  }, [validatedDates]);

  const getWarningMessage = () => {
    let actionString;
    switch (newTalentStatus) {
      case "BOOKED":
        actionString = "allocate the talent";
        break;
      case "CLEARANCE_REQUESTED":
        actionString = "request clearance for the talent";
        break;
      default:
        actionString = "add the talent to the brief";
        break;
    }
    return `Please review the information below and click confirm when ready to ${actionString}.`;
  };

  return (
    <div className="DirectMatchConfirmation">
      <p className="warning-message">
        <p className="warning-icon">
          <WarningCircle size={32} weight="light" />
        </p>
        {getWarningMessage()}
      </p>
      {preSelectedTalents?.length === 1 && <TalentInfoBlock preSelectedTalents={preSelectedTalents} />}
      <div className="formgrid grid">
        <div className="col">
          <label htmlFor="project_name">Project</label>
          <p>{formik.values.project_name}</p>
        </div>
        <div className="col">
          <label htmlFor="brief_name">Brief</label>
          <p>{formik.values.brief_name}</p>
        </div>
        <div className="col">
          <label htmlFor="duration_type">Duration type</label>
          <p>
            {durationTypes?.find(({ id }: { id: number }) => id === formik.values.duration_id)?.name || ""}
            {formik.values.include_weekends && !isExactWorkingDates && (
              <>
                <br />
                (weekends included)
              </>
            )}
          </p>
        </div>
        <div className="col">
          <label htmlFor="duration_days">Duration</label>
          <p>{isValidatingDates ? <Loader /> : `${totalDaysNumber} ${pluralize(totalDaysNumber, "day")}`}</p>
        </div>
      </div>
      <DataTable value={dates || []}>
        <Column field="date" header="Brief dates" style={{ width: "50%" }} />
        <Column field="capacity" header="Capacity" body={(rowData) => `${rowData.capacity}%`} />
      </DataTable>
    </div>
  );
}
