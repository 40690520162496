import { AxiosError, AxiosResponse } from "axios";
import { orderBy } from "lodash";
import { DataTableSortMeta } from "primereact/datatable";
import { MutableRefObject, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { BriefService } from "~/API/BriefService";
import { TalentBookingsService } from "~/API/TalentBookingsService";
import { PLATFORMS, SECTORS, SKILLS } from "~/forms/BriefViewDetailsForm/constants";
import { jobUrlData } from "~/forms/BriefViewDetailsForm/helper";
import { IBrief, IBriefClientAdminData, IBriefState, IListAddableTalentsData } from "~/interfaces/brief";
import { IClearance } from "~/interfaces/clearance";
import { IDefaultPaginatedState } from "~/interfaces/common";
import { checkBriefForDraft, draftBriefValues, getSuccessMessageForBriefMatching } from "~/routes/Brief/helper";
import Toasts, { TOAST_ERROR_MESSAGE, TOAST_SUCCESS_MESSAGE } from "~/store/constants/toasts";
import { IState } from "~/store/reducers/index";
import { ITalentsPaginated } from "~/store/reducers/talent";
import * as Brief from "../interfaces/brief";
import { useLoadingAnimationContext } from "./../contexts/LoadingAnimationContext";
import { usePermissionContext } from "~/contexts/PermissionContext";
import { useNotificationContext } from "~/contexts/NotificationContext";

const useBrief = () => {
  const { withLoadingAnimation } = useLoadingAnimationContext();
  const { isAdmin } = usePermissionContext();
  const { getNotifications } = useNotificationContext();
  const dispatch = useDispatch();
  const history = useHistory();
  const [isFetching, setIsFetching] = useState(false);
  const [isFetchingTalents, setIsFetchingTalents] = useState(false);
  const [isFetchingBookings, setIsFetchingBookings] = useState(false);
  const [isFetchingMatches, setIsFetchingMatches] = useState(false);
  const [isMatchAdding, setIsMatchAdding] = useState(false);
  const [isMatchUpdating, setIsMatchUpdating] = useState(false);
  const [isClearanceUpdating, setIsClearanceUpdating] = useState(false);
  const [isValidatingDates, setIsValidatingDates] = useState(false);
  const [isFetchingBriefDescriptionUrl, setIsFetchingBriefDescriptionUrl] = useState(false);
  const [brief, setBrief] = useState(undefined as Brief.IBrief | undefined);
  const [matches, setMatches] = useState([] as Brief.IMatching[]);
  const jobUrlFormData: MutableRefObject<Object | null | undefined> = useRef<Object | number | undefined>(undefined);
  const setJobUrlFormData = (data: Object | undefined | null) => (jobUrlFormData.current = data);
  const [briefs, setBriefs] = useState<undefined | IDefaultPaginatedState<IBrief>>(undefined);
  const [validatedDates, setValidatedDates] = useState(undefined);
  const [validatedDatesErrors, setValidatedDatesErrors] = useState(undefined);
  const [SDSBeingLoaded, setSDSBeingLoaded] = useState<number | undefined>(undefined);
  const token = useSelector((state: IState) => state.user.authenticatedUser?.token);
  const [addableTalents, setAddableTalents] = useState<undefined | ITalentsPaginated>(undefined);
  const [preMatchedList, setPreMatchedList] = useState([] as Brief.IMatching[]);
  const customItems = (brief: IBrief) => [
    { name: SKILLS, items: brief?.custom_skills },
    { name: SECTORS, items: brief?.custom_sectors },
    { name: PLATFORMS, items: brief?.custom_platforms },
  ];
  const initialValues = {
    organisation_id: 0,
    author_id: 0,
    name: "",
    start_date: "",
    end_date: "",
    include_weekends: false,
    duration_days: 1,
    duration_id: 1,
    clients: [],
    specialism_flexible: false,
    full_availability: false,
  };
  const additionalBriefValues = {
    discipline_id: null,
    specialism_id: null,
    level_id: null,
    description: "",
    organisations: [],
    production_id: null,
    // budget: "",
    budget: null,
    budget_type: "TYPE_TOTAL",
    skills: [],
    sectors: [],
    tools: [],
    platforms: [],
    dates: [],
    goto_types: [],

    job_title: null,
    rate_min: null,
    rate_max: null,
    work_description: null,
    work_setting: null,
    right_requirements: null,
    permanent_roles: false,
    job_url_name: "",
    job_url: null,
    role_involves: null,
    country_of_residence: null,
    residence_city: null,
    company_benefits: null,
    excluded_talents: [],
    capacity: 100,
    assignees: [],
  };
  const additionalRebookValues = {
    talent_ids: [],
    capacity: 100,
  };
  const getBriefFormValues = (additionalValues: any) => ({
    ...additionalValues,
    ...initialValues,
  });

  const createBrief = async (data: IBrief) => {
    if (token) {
      try {
        setIsFetching(true);
        const res = await BriefService.createBrief(data.project_id, data);
        if (jobUrlData(res.data.job_url, jobUrlFormData) && res.data.id) {
          await BriefService.updateJobDescriptionForBrief(res.data.id, jobUrlData(res.data.job_url, jobUrlFormData));
          setJobUrlFormData(undefined);
        }

        if (res.data.id && data.description_url instanceof File) {
          await BriefService.updateBriefDescriptionUrl(res.data.id, data.description_url);
        }

        return res.data;
      } catch (err) {
        dispatch(Toasts.setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
        throw err;
      } finally {
        setIsFetching(false);
      }
    }
  };

  const createClientAdminBrief = async (data: IBrief) => {
    if (token) {
      try {
        setIsFetching(true);
        const res = await BriefService.createClientAdminBrief(data);
        if (jobUrlData(res.data.job_url, jobUrlFormData) && res.data.id) {
          await BriefService.updateJobDescriptionClientAdminBrief(
            res.data.id,
            jobUrlData(res.data.job_url, jobUrlFormData)
          );
          setJobUrlFormData(undefined);
        }
        return res.data;
      } catch (err) {
        dispatch(Toasts.setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
        throw err;
      } finally {
        setIsFetching(false);
      }
    }
  };
  const redirectAfterBriefCreated = (brief: IBrief) => {
    if (checkBriefForDraft(brief)) {
      return history.push({
        pathname: "/briefs",
        state: { briefsCategoryIndex: 3 },
      });
    }
    return history.push(`/briefs/${brief.id}`);
  };

  const saveOrConfirmCallback = (values: any) => {
    withLoadingAnimation(createBrief, values);
  };

  const getBrief = async (briefId: number) => {
    if (token) {
      try {
        setIsFetching(true);
        setBrief(undefined);
        const res = await BriefService.getBrief(briefId);
        if (checkBriefForDraft(res.data)) {
          setBrief(draftBriefValues(res.data));
        } else {
          setBrief(res.data);
        }
        return res.data;
      } finally {
        setIsFetching(false);
      }
    }
  };
  const getBriefMatchesByLimit = async (briefId: number, requestedMatches: number) => {
    if (token) {
      try {
        setIsFetching(true);

        const res = await BriefService.getBriefMatchesByLimit(briefId, requestedMatches);

        if (res.status === 200) {
          const matchesFound = res.data?.new;
          setMatches((prevMatches) => {
            return res.data.matches.map((match: Brief.IMatching) => {
              const isNewMatch = !prevMatches.find(({ id }) => id === match.id);
              return isNewMatch ? { ...match, new_match: true } : match;
            });
          });

          const { msg, severity } = getSuccessMessageForBriefMatching(requestedMatches, matchesFound);
          dispatch(Toasts.setToasts([{ severity, summary: "", detail: msg }]));
        }
      } finally {
        setIsFetching(false);
      }
    }
  };
  const getBriefMatches = async (briefId: number) => {
    try {
      setIsFetchingMatches(true);
      const res = await BriefService.getBriefMatches(briefId);

      if (res.status === 200) {
        setMatches(res.data);
      }
    } finally {
      setIsFetchingMatches(false);
    }
  };
  const getClientBrief = async (briefId: number) => {
    if (token) {
      try {
        setIsFetching(true);
        setBrief(undefined);
        const res = await BriefService.getBrief(briefId);
        if (checkBriefForDraft(res.data)) {
          setBrief(draftBriefValues(res.data));
        } else {
          setBrief(res.data);
        }
      } finally {
        setIsFetching(false);
      }
    }
  };

  const listBriefs = async (
    statuses: string,
    page: number,
    per_page?: number,
    search?: string,
    sort?: DataTableSortMeta,
    other?: { [key: string]: any }
  ) => {
    if (token) {
      try {
        setIsFetching(true);
        setBriefs(undefined);
        const res = await BriefService.listBriefs(statuses, page, per_page, search, sort, { ...other });
        setBriefs(res.data);
      } finally {
        setIsFetching(false);
      }
    }
  };
  const createMatch = async (talents: number | number[], brief_id?: number, matchData?: Brief.IMatching) => {
    const briefId = brief_id || brief?.id;

    if (token && briefId) {
      try {
        setIsMatchAdding(true);
        await BriefService.createMatch(briefId, talents, { ...matchData, enquiredFreelancerId: undefined });

        if (!brief || matchData?.process_status_update) {
          history.push({
            pathname: `/briefs/${briefId}`,
            ...(matchData?.enquiredFreelancerId && { state: { enquiredFreelancerId: matchData.enquiredFreelancerId } }),
          });
        } else {
          const res = await BriefService.getBriefMatches(briefId);

          setMatches((prevMatches) => {
            return res.data.map((match: Brief.IMatching) => {
              const isNewMatch = !prevMatches.find(({ id }) => id === match.id);
              return isNewMatch ? { ...match, new_match: true } : match;
            });
          });
          dispatch(Toasts.setToasts([{ severity: "success", summary: "", detail: TOAST_SUCCESS_MESSAGE }]));
          return res?.status === 200;
        }
      } finally {
        setIsMatchAdding(false);
      }
    }
  };

  const getMatch = async (matchId: number) => {
    if (token && brief?.id) {
      try {
        setIsMatchAdding(true);
        const res = await BriefService.getBriefMatch(matchId);
        const updatedMatches = matches.map((match) => {
          if (match.id === matchId) {
            return res.data;
          }
          return match;
        });
        setMatches(updatedMatches);
        return res?.status === 200;
      } catch (err) {
        dispatch(Toasts.setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
        throw err;
      } finally {
        setIsMatchAdding(false);
      }
    }
  };

  const createClientAdminMatch = async (talents: number | number[]) => {
    const briefId = brief?.id;
    if (token && briefId) {
      try {
        setIsMatchAdding(true);
        await BriefService.createClientAdminMatch(briefId, talents);
        // const res = await BriefService.getClientBriefMatches(briefId); TODO: uncomment once BE part done
        const res = await BriefService.getClientBrief(briefId);

        setBrief(res.data);
      } finally {
        setIsMatchAdding(false);
      }
    }
  };

  const updateBrief = async (data: IBrief, shouldBriefBePublished: boolean = false, isBriefDraft: boolean = false) => {
    if (token) {
      try {
        setIsFetching(true);
        let res = await BriefService.updateBrief(data);
        if (jobUrlData(res.data.job_url, jobUrlFormData) && res.data.id) {
          res = await BriefService.updateJobDescriptionForBrief(
            res.data.id,
            jobUrlData(res.data.job_url, jobUrlFormData)
          );
          setJobUrlFormData(undefined);
        }

        if (res.data.id && (data.description_url instanceof File || data.description_url === null)) {
          await BriefService.updateBriefDescriptionUrl(res.data.id, data.description_url);
        }

        if (shouldBriefBePublished && !isBriefDraft) {
          setBrief(undefined);
          return res.data;
        } else if (shouldBriefBePublished && isBriefDraft) {
          history.push({
            pathname: `/projects/${data.project_id}`,
            state: { briefsCategoryIndex: data.status === Brief.BriefStatuses.DRAFT ? 3 : 0 },
          });
        } else {
          setBrief(res.data);
          dispatch(Toasts.setToasts([{ severity: "success", summary: "", detail: TOAST_SUCCESS_MESSAGE }]));
        }
      } catch (err) {
        dispatch(Toasts.setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
        throw err;
      } finally {
        setIsFetching(false);
      }
    }
  };

  const updateClientAdminBrief = async (
    data: IBriefClientAdminData,
    shouldBriefBePublished: boolean,
    isBriefDraft: boolean = false
  ) => {
    if (token) {
      try {
        setIsFetching(true);
        let res = await BriefService.updateClientAdminBrief(data);
        if (jobUrlData(res.data.job_url, jobUrlFormData) && res.data.id) {
          res = await BriefService.updateJobDescriptionClientAdminBrief(
            res.data.id,
            jobUrlData(res.data.job_url, jobUrlFormData)
          );
          setJobUrlFormData(undefined);
        }
        if (shouldBriefBePublished && !isBriefDraft) {
          setBrief(undefined);
          return res.data;
        } else if (shouldBriefBePublished && isBriefDraft) {
          history.push({
            pathname: "/briefs",
            state: { briefsCategoryIndex: 3 },
          });
        } else {
          setBrief(res.data);
        }
      } catch (err) {
        dispatch(Toasts.setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
        throw err;
      } finally {
        setIsFetching(false);
      }
    }
  };

  const updateDraftBrief = (data: IBrief) => {
    return updateBrief(data, true, true);
  };

  const updateClientAdminDraftBrief = (data: IBrief) => {
    return updateClientAdminBrief(data, true, true);
  };

  const getBriefPreMatchedList = async (briefId: number) => {
    if (token) {
      try {
        setIsFetchingTalents(true);
        const res = await BriefService.getBriefPreMatchedList(briefId);

        setPreMatchedList(res.data);
      } finally {
        setIsFetchingTalents(false);
      }
    }
  };

  const resetBriefPreMatchedList = () => setPreMatchedList([]);

  const removeFromPreMatchedList = (id: number) =>
    setPreMatchedList((preMatches) => preMatches.filter(({ id: matchId }) => matchId !== id));

  const updateMatch = async (data: Brief.IMatching, showLoader = true) => {
    if (token) {
      try {
        showLoader && setIsMatchUpdating(true);
        const res = await BriefService.updateMatch(data);
        setMatches((prevMatches) => {
          return prevMatches?.map((match) => (match.id === res.data.id ? res.data : match));
        });
        showLoader && dispatch(Toasts.setToasts([{ severity: "success", summary: "", detail: TOAST_SUCCESS_MESSAGE }]));
      } finally {
        setIsMatchUpdating(false);
      }
    }
  };

  const validateBrief = (data: IBrief, type: number, IR35_TYPES: any): Promise<number | AxiosError> => {
    return new Promise(async (resolve, reject) => {
      if (token) {
        try {
          const res = await BriefService.validateBrief(type, IR35_TYPES, data);
          resolve(res.data);
        } catch (err) {
          reject(err);
        }
      }
    });
  };
  const validateBriefDates = async (data: Brief.IBriefValidate) => {
    if (token) {
      try {
        setIsValidatingDates(true);
        const res = await BriefService.validateBriefDates(data);
        setValidatedDates(res.data);
      } catch (err: any) {
        setValidatedDatesErrors(err?.response?.data);
      } finally {
        setIsValidatingDates(false);
      }
    }
  };
  const validateTalentAvailability = async (briefId: number, data: Brief.IBriefValidate) => {
    try {
      setIsValidatingDates(true);
      const res = await BriefService.validateTalentAvailability(briefId, data);
      setValidatedDates(res.data);
    } catch (err: any) {
      dispatch(Toasts.setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
      setValidatedDates(undefined);
      setValidatedDatesErrors(err?.response?.data);
    } finally {
      setIsValidatingDates(false);
    }
  };
  const addTalentGotoSuccess = (res: AxiosResponse) => {
    setMatches((prevMatches) => {
      return prevMatches.map((item) =>
        item.talent.id === res.data.id ? { ...item, talent_type: res.data.type } : item
      );
    });
  };
  const cancelBrief = async (data: { briefId: number; reason_id: number; description?: string }) => {
    try {
      const res = await BriefService.cancelBrief(data);
      res?.status === 200 &&
        dispatch(Toasts.setToasts([{ severity: "success", summary: "", detail: TOAST_SUCCESS_MESSAGE }]));
      return res;
    } catch (err) {
      dispatch(Toasts.setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
    }
  };

  const deleteBrief = async (briefId: number) => {
    try {
      const res = await BriefService.deleteBrief(briefId);

      if (res?.status === 200 || res?.status === 204) {
        dispatch(Toasts.setToasts([{ severity: "success", summary: "", detail: TOAST_SUCCESS_MESSAGE }]));
        briefs &&
          setBriefs({
            ...briefs,
            data: briefs?.data?.filter(({ id }) => id !== briefId),
          });
      }
      return res;
    } catch (err) {
      dispatch(Toasts.setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
    }
  };

  const regenerateSDS = async (data: { bookingId: number; matchId: number }) => {
    try {
      setSDSBeingLoaded(data.bookingId);
      const res = await BriefService.regenerateSDS(data.bookingId);
      if (res?.status === 200) {
        setBrief((brief: IBrief) => {
          const matchIndex = matches?.findIndex((match) => match?.id === data?.matchId);
          const bookingIndex = matchIndex
            ? (matches || [])[matchIndex].bookings?.findIndex((booking) => booking?.id === data.bookingId)
            : undefined;
          !!matchIndex &&
            !!bookingIndex &&
            matchIndex >= 0 &&
            bookingIndex >= 0 &&
            ((matches || [])[matchIndex].bookings[bookingIndex] = res?.data);
          return { ...brief };
        });
        dispatch(Toasts.setToasts([{ severity: "success", summary: "", detail: TOAST_SUCCESS_MESSAGE }]));
      }
    } catch (err) {
      dispatch(Toasts.setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
    } finally {
      setSDSBeingLoaded(undefined);
    }
  };

  const updateClearance = async (data: { clearanceId: number; matchId: number }, payload: IClearance) => {
    if (token && brief?.id) {
      try {
        setIsClearanceUpdating(true);
        const res = await TalentBookingsService.UpdateClearanceById(data.clearanceId, payload);
        if (res?.status === 200) {
          setMatches((prevMatches) => {
            return prevMatches?.map((match) => {
              if (match.id === data.matchId) {
                return res.data;
              }
              return match;
            });
          });

          dispatch(Toasts.setToasts([{ severity: "success", summary: "", detail: TOAST_SUCCESS_MESSAGE }]));
          isAdmin && getNotifications();
        }
      } catch (err) {
        dispatch(Toasts.setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
      } finally {
        setIsClearanceUpdating(false);
      }
    }
  };

  const createBooking = async (data: any, isAdmin: boolean = false) => {
    try {
      setIsFetching(true);

      const res = await TalentBookingsService.CreateBooking(data.brief_id, data, isAdmin);
      if (res?.status === 200) {
        dispatch(Toasts.setToasts([{ severity: "success", summary: "", detail: TOAST_SUCCESS_MESSAGE }]));
        const matchToUpdate = (matches || []).find(({ id }) => id === data.match_id);
        if (!!matchToUpdate) {
          return [...matchToUpdate.bookings, res.data];
        }
        return null;
      }
    } catch (err) {
      dispatch(Toasts.setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
    } finally {
      setIsFetching(false);
    }
  };

  const updateBooking = async (bookingId: number, data: any) => {
    try {
      setIsFetchingBookings(true);

      const res = await BriefService.updateBooking(bookingId, data);
      if (res?.status === 201 || res?.status === 200) {
        dispatch(Toasts.setToasts([{ severity: "success", summary: "", detail: TOAST_SUCCESS_MESSAGE }]));
      }
    } catch (err) {
      dispatch(Toasts.setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
    } finally {
      setIsFetchingBookings(false);
    }
  };

  const trackPortfolioClick = (talentId: number, matchId: number) => {
    try {
      BriefService.trackPortfolioClick(talentId, matchId);
    } catch (err) {
      console.error(`Can't track portfolio click`, err);
    }
  };

  const trackDownloadCvClick = (talentId: number, matchId: number) => {
    try {
      BriefService.trackDownloadCvClick(talentId, matchId);
    } catch (err) {
      console.error(`Can't track download CV click`, err);
    }
  };

  const resetBrief = () => {
    setIsFetching(false);
    setIsMatchAdding(false);
    setIsMatchUpdating(false);
    setIsValidatingDates(false);
    setBrief(undefined);
    setBriefs(undefined);
    setValidatedDates(undefined);
    setValidatedDatesErrors(undefined);
    setAddableTalents(undefined);
    setMatches([]);
  };

  const listAddableTalents = async (data: IListAddableTalentsData) => {
    setIsFetchingTalents(true);
    try {
      const res = await BriefService.getAddableTalents(data);
      res?.status === 200 && setAddableTalents(res.data);
      return res;
    } catch (err) {
      dispatch(Toasts.setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
    } finally {
      setIsFetchingTalents(false);
    }
  };

  const sendMessageFromBrief = async (briefId: number, talents: { id: number }[], message: string) => {
    setIsFetching(true);
    try {
      const res = await BriefService.sendMessageFromBrief(briefId, talents, message);
      return res?.status === 200;
    } catch (err) {
      dispatch(Toasts.setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
    } finally {
      setIsFetching(false);
    }
  };

  const isSubmitDisabled = (
    form: { isValid?: boolean; dirty?: boolean; isSubmitting: boolean },
    initial = false
  ): boolean => {
    return initial ? !form.isValid || isFetching : !(form.dirty && form.isValid) || isFetching;
  };

  const updateBriefDescriptionUrl = async (briefId: number, description_url: File | null) => {
    try {
      setIsFetchingBriefDescriptionUrl(true);
      const res = await BriefService.updateBriefDescriptionUrl(briefId, description_url);

      setBrief((prevState) => (prevState ? { ...prevState, description_url: res.data.description_url } : undefined));
      return res?.status === 200;
    } catch (err) {
      dispatch(Toasts.setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
    } finally {
      setIsFetchingBriefDescriptionUrl(false);
    }
  };

  return {
    // brief state
    isFetching,
    isFetchingTalents,
    isFetchingBookings,
    isFetchingMatches,
    isFetchingBriefDescriptionUrl,
    isMatchAdding,
    isMatchUpdating,
    isClearanceUpdating,
    isValidatingDates,
    SDSBeingLoaded,
    brief,
    briefs,
    matches,
    validatedDates,
    validatedDatesErrors,
    addableTalents,
    customItems,
    additionalBriefValues,
    additionalRebookValues,
    preMatchedList,
    // actions
    cancelBrief,
    deleteBrief,
    createBrief,
    createClientAdminBrief,
    getBrief,
    setBrief,
    setMatches,
    getClientBrief,
    listBriefs,
    createMatch,
    updateBooking,
    createClientAdminMatch,
    updateBrief,
    updateClientAdminBrief,
    updateDraftBrief,
    updateClientAdminDraftBrief,
    updateMatch,
    getMatch,
    validateBrief,
    validateBriefDates,
    validateTalentAvailability,
    resetBrief,
    addTalentGotoSuccess,
    regenerateSDS,
    listAddableTalents,
    setAddableTalents,
    trackPortfolioClick,
    trackDownloadCvClick,
    isSubmitDisabled,
    getBriefFormValues,
    getBriefMatches,
    getBriefMatchesByLimit,
    jobUrlFormData,
    setJobUrlFormData,
    createBooking,
    updateClearance,
    saveOrConfirmCallback,
    redirectAfterBriefCreated,
    sendMessageFromBrief,
    getBriefPreMatchedList,
    resetBriefPreMatchedList,
    removeFromPreMatchedList,
    updateBriefDescriptionUrl,
  } as IBriefState;
};
export default useBrief;
