import { AnyAction } from "redux";
import * as Modal from "../constants/modal";

// ------------------------------------
// Interface
// ------------------------------------
export interface IModalState {
  initProps: object;
  modalProps: any;
  modalType: string;
}

// ------------------------------------
// Initial state
// ------------------------------------
const initialState = {
  initProps: {},
  modalProps: {},
  modalType: "",
};

// ------------------------------------
// Reducer
// ------------------------------------
export default (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case Modal.MODAL_SHOW:
      localStorage.removeItem("modalForm");
      return {
        modalType: action.payload.modalType,
        modalProps: action.payload.modalProps,
      };
    case Modal.MODAL_HIDE:
      return initialState;
    // case Modal.MODAL_SET_STATE:
    //   return { ...state, initProps: action.payload };
    default:
      return state;
  }
};
