import { DataTableSortMeta } from "primereact/datatable";

type IQueryParamType = string | number;

export interface IQueryParams {
  [key: string]: IQueryParamType | IQueryParamType[];
}

export interface IAIBioRequestParams {
  discipline: string;
  specialism: string;
  level: string;
  skills: string[];
  sectors: string[];
  tools: string[];
  clients: string[];
}

export interface ICommonFilterSettings {
  search: string;
  setSearch: (str: string) => void;
  sort: DataTableSortMeta;
  setSort: (opt: DataTableSortMeta) => void;
}

export interface IListItem {
  id: number;
  name: string;
}

export interface IClient extends IListItem {
  sector?: string;
  client_pod?: string;
}

export interface ICustomer extends IListItem {
  sector?: string;
}

export interface IDefaultPaginatedState<T> {
  data: T[];
  current_page: number;
  per_page: number;
  total: number;
}

export interface ITag extends IListItem {
  color: string;
}

export enum DisciplineCode {
  CREATIVE = "DISCIPLINE_CREATIVE",
}
