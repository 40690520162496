import { DataTableSortMeta } from "primereact/datatable";
import { useState } from "react";

const defaultSort: DataTableSortMeta = {
  field: "start_date",
  order: 1,
};

const useClearanceFilterSettings = () => {
  const [search, setSearch] = useState<string>("");
  const [sort, setSort] = useState<DataTableSortMeta>(defaultSort);

  const resetFilterClearanceSettings = (currentPath: string) => {
    setSearch("");
    setSort(defaultSort);
  };

  return {
    search,
    setSearch,
    sort,
    setSort,
    resetFilterClearanceSettings,
  };
};

export default useClearanceFilterSettings;
