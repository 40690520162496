import { useState } from "react";
import { IProjectFilterSettings } from "~/interfaces/project";
import { DataTableSortMeta } from "primereact/datatable";
import getColumns from "~/components/common/ProjectsTab/columns";
import { prepareCustomColumns } from "~/utils";
import { PaginatorPageChangeEvent } from "primereact/paginator";
import { DropdownChangeEvent } from "primereact/dropdown";
import isNull from "lodash/isNull";

const projectsCategories = [
  { label: "Open", code: "OPEN" },
  { label: "Resourced", code: "RESOURCED" },
  { label: "Cancelled", code: "CANCELLED" },
  { label: "Draft", code: "DRAFT" },
  { label: "All", code: "" },
];
const defaultSort: DataTableSortMeta = {
  field: "id",
  order: -1,
};

const useProjectFilterSettings = () => {
  const [projectsCategory, setProjectsCategory] = useState(null as null | { label: string; code: string });

  const columns = getColumns();
  const columnsOptions = prepareCustomColumns(columns);
  const [selectedColumns, setSelectedColumns] = useState<{ field: string; header: string | undefined }[]>([]);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState<DataTableSortMeta>(defaultSort);
  const [per_page, setPerPage] = useState(20);
  const [page, setPage] = useState(1);
  const defaultCategoryIndex = projectsCategories.findIndex((item) => item.code === "");

  const resetFilterProjectsSettings = (currentPath: string) => {
    const isExistProjectId = Number.isInteger(+currentPath?.split("/")?.filter(Boolean)[1]);
    const isItemProjectPath = currentPath?.includes("/projects") && isExistProjectId;
    const isItemOrganisationPath = currentPath?.includes("/organisations") && isExistProjectId;

    if (currentPath && currentPath !== "/projects" && !isItemProjectPath && !isItemOrganisationPath) {
      setProjectsCategory(null);
      setSelectedColumns(columnsOptions);
      setSearch("");
      setSort(defaultSort);
      setPage(1);
      setPerPage(20);
    }
  };

  function onPaginatorChange(e: PaginatorPageChangeEvent) {
    setPerPage(e.rows);
    setPage(e.page + 1);
  }

  function changeProjectsCategory(e: DropdownChangeEvent) {
    if (!isNull(e.value)) {
      setPage(1);
      setProjectsCategory(e.value);
    }
  }

  return {
    resetFilterProjectsSettings,
    columns,
    columnsOptions,
    projectsCategories,
    projectsCategory,
    defaultCategoryIndex,
    setProjectsCategory,
    selectedColumns,
    setSelectedColumns,
    search,
    sort,
    page,
    per_page,
    setPage,
    setSearch,
    setPerPage,
    setSort,
    onPaginatorChange,
    changeProjectsCategory,
  } as IProjectFilterSettings;
};
export default useProjectFilterSettings;
