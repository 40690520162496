import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { CONFIRM_LEAVE_PAGE } from "~/config";
import useBeforeUnload from "./useBeforeUnload";

const useShowPrompt = () => {
  const [showPrompt, setShowPrompt] = useState(false);
  const [message, setMessage] = useState(CONFIRM_LEAVE_PAGE);
  const history = useHistory();
  let unblock: any;

  useBeforeUnload(showPrompt, message);

  useEffect(() => {
    if (showPrompt) {
      unblock = history.block(message);

      return () => {
        unblock();
      };
    }
  }, [history, showPrompt]);

  return {
    showPrompt,
    setShowPrompt,
    setMessage,
  };
};

export default useShowPrompt;
