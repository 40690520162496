@import "../../mixins.scss";

.TimetableForm {
  .day-field {
    display: flex;
    align-items: center;
    border-radius: 5px;
    min-height: 32px;
    position: relative;
    &:not(:last-child) {
      margin-bottom: 12px;
      @include for-size(phone-only) {
        margin-bottom: 15px;
      }
    }
    label {
      width: 150px;
      margin-bottom: 0;
    }
    .ApInputNumber {
      width: 50px;
      position: relative;
      @include for-size(phone-only) {
        position: static;
      }

      input {
        text-align: center;
        padding: 7px 5px !important;
      }
      .ap-error {
        position: absolute;
        left: calc(100% + 10px);
        top: 4px;
        white-space: nowrap;
        @include for-size(phone-only) {
          left: 0;
          top: calc(100% - 7px);
        }
      }
    }
    &-disabled {
      opacity: 0.4;
    }
  }
  .work-divider {
    width: 325px;
  }
}
