import { FormikProps } from "formik";
import React, { useMemo } from "react";
import ItemSelector from "~/components/ItemSelector/ItemSelector";
import Loader from "~/components/Loader/Loader";
import ApDropdown from "~/components/common/ApDropdown/ApDropdown";
import ApInputText from "~/components/common/ApInputText/ApInputText";
import ApInputTextarea from "~/components/common/ApInputTextarea/ApInputTextarea";
import ApMultiselectEditable from "~/components/common/ApMultiselectEditable/ApMultiselectEditable";
import ApPhoneInput from "~/components/common/ApPhoneInput/ApPhoneInput";
import { useGlobalContext } from "~/contexts/GlobalContext";
import ImageCropper from "~/forms/ImageCropper/ImageCropper";
import { IClient } from "~/interfaces/common";
import { Button } from "primereact/button";
import { Sparkle } from "phosphor-react";

type SelectItemProps = {
  items: any;
  maxItems?: number;
  selected?: any;
  onSave: any;
  selectorStep?: number;
  onStepChange?: (step: number) => void;
};

export const SlideInitial = function ({ name }: { name: string }) {
  return (
    <div className="slide slide-initial">
      <h1>Welcome {name}!</h1>
      <p>Let’s take a moment to create your individual profile.</p>
      <p>It will only take a couple of minutes.</p>
      <p>
        <i className="icon-user" />
      </p>
    </div>
  );
};

export const SlideSpecialism = function ({
  formik,
  specialisms,
}: {
  formik: FormikProps<any>;
  specialisms: { name: string; code: string }[];
}) {
  const {
    global: { getGlobalOptionValue },
  } = useGlobalContext();

  const organisationName = useMemo(
    () => getGlobalOptionValue("application_settings", "APPLICATION_NAME", "organisation"),
    []
  );

  return (
    <div className="slide slide-specialism">
      <h1>Specialism</h1>
      <p>What is your role within {organisationName}?</p>
      <ul className="specialism-select">
        <li>
          <ApDropdown
            formik={formik}
            id="specialism_code"
            options={specialisms}
            optionLabel="name"
            optionValue="code"
            placeholder="Select specialism"
            noField
          />
        </li>
      </ul>
    </div>
  );
};

export const SlideLevel = function ({
  formik,
  levels,
}: {
  formik: FormikProps<any>;
  levels: { string: number; code: string }[];
}) {
  return (
    <div className="slide slide-level">
      <h1>Experience Level</h1>
      <p>What is your experience level?</p>
      <ul className="specialism-select">
        <li>
          <ApDropdown
            formik={formik}
            id="level_code"
            options={levels}
            optionLabel="name"
            optionValue="code"
            placeholder="Select level"
            noField
          />
        </li>
      </ul>
    </div>
  );
};

export const SlideSkills = function (props: SelectItemProps) {
  return (
    <div className="slide slide-skills">
      <h1>Skills</h1>
      <ItemSelector
        {...props}
        itemName="Skill"
        prioritize={true}
        onStepChange={(step) => !!props.onStepChange && props.onStepChange(step)}
        stepOneText={
          <p>
            What skills do you have?
            <br />
            Choose all that apply to you from the list below.
          </p>
        }
        stepTwoText={
          <p>Drag and prioritise your skills making sure the best ones shine at the very top of the list!</p>
        }
      />
    </div>
  );
};

export const SlideSectors = function (props: SelectItemProps) {
  return (
    <div className="slide slide-sectors">
      <h1>Sector Experience</h1>
      <ItemSelector
        {...props}
        prioritize
        itemName="Sector"
        onStepChange={(step) => !!props.onStepChange && props.onStepChange(step)}
        stepOneText={
          <p>
            What areas do you have experience working in?
            <br />
            Choose all that apply to you from the list below.
          </p>
        }
        stepTwoText={<p>Now arrange the sectors you're most experienced in by dragging them to the top of the list.</p>}
      />
    </div>
  );
};

export const SlideTools = function (props: SelectItemProps) {
  return (
    <div className="slide slide-tools">
      <h1>Tools / Applications</h1>
      <ItemSelector
        {...props}
        prioritize
        itemName="Tool"
        onStepChange={(step) => !!props.onStepChange && props.onStepChange(step)}
        stepOneText={
          <p>
            What tools and applications have you experience with?
            <br />
            Choose all that apply to you from the list below.
          </p>
        }
        stepTwoText={<p>Now arrange the tools you're most experienced in by dragging them to the top of the list.</p>}
      />
    </div>
  );
};

export const SlideClients = function ({ formik, clients }: { formik: FormikProps<any>; clients: IClient[] }) {
  return (
    <div className="slide slide-clients">
      <h1>Client Experience</h1>
      <p>What clients from the list below have you worked with?</p>
      <ApMultiselectEditable
        id="clients"
        options={clients}
        optionLabel="name"
        keyName="name"
        formik={formik}
        display="chip"
        noField
        filter
        onChange={(e) => {
          formik.setFieldValue("clients", e.value);
        }}
        onAdd={(value) => formik.setFieldValue("clients", [...formik.values.clients, value])}
        resetFilterOnHide
        className="w-8"
      />
    </div>
  );
};

export const SlideCountry = function ({
  formik,
  countries,
}: {
  formik: FormikProps<any>;
  countries: { name: string; code: string }[];
}) {
  return (
    <div className="slide slide-country">
      <h1>Country of residence</h1>
      <p>What country are you currently located in?</p>
      <ul className="specialism-select">
        <li>
          <ApDropdown
            formik={formik}
            id="country"
            options={countries}
            optionLabel="name"
            optionValue="code"
            placeholder="Select country"
            noField
            filter
          />
        </li>
      </ul>
    </div>
  );
};

export const SlideTimeZone = function ({
  formik,
  timezones,
}: {
  formik: FormikProps<any>;
  timezones: { name: string; code: string }[];
}) {
  return (
    <div className="slide slide-time-zone">
      <h1>Time zone</h1>
      <p>Please select the current time zone you are working in</p>
      <ul className="specialism-select">
        <li>
          <ApDropdown
            formik={formik}
            id="timezone"
            options={timezones}
            optionLabel="name"
            optionValue="code"
            placeholder="Select time zone"
            noField
            filter
          />
        </li>
      </ul>
    </div>
  );
};

export const SlidePhoneNumber = function ({ formik }: { formik: FormikProps<any> }) {
  return (
    <div className="slide slide-phone-number">
      <h1>Contact number</h1>
      <p>Please enter your contact number</p>
      <div className="specialism-select">
        <ApPhoneInput
          defaultCountry={formik.values.country}
          id="telephone"
          formik={formik}
          noField
          onChange={(value: string, meta: any) => {
            formik.setFieldValue("telephone", value);
            formik.setFieldValue("phone_meta", { iso2: meta.country.iso2, inputValue: meta.inputValue });
          }}
        />
      </div>
    </div>
  );
};

export const SlideBio = function ({
  formik,
  getAIBio,
  isFetching,
}: {
  formik: FormikProps<any>;
  getAIBio: () => void;
  isFetching: boolean;
}) {
  return (
    <div className="slide slide-bio">
      <h1>Your Bio</h1>
      <p>
        Please provide a brief overview of your career and experience to date. This can be similar to your LinkedIn bio,
        or click on the AI generator to get started.
      </p>
      <br />
      <div className="bio-text">
        <ApInputTextarea formik={formik} id="description" noField maxChars={1500} />
      </div>
      <div className="my-2">
        <Button disabled={isFetching} onClick={getAIBio}>
          {isFetching ? <i className="pi pi-spin pi-cog" /> : <Sparkle size={18} />}
          <span className="px-3">Generate Bio</span>
        </Button>
      </div>
    </div>
  );
};

export const SlidePortfolio = function ({ formik }: { formik: FormikProps<any> }) {
  return (
    <div className="slide slide-portfolio">
      <h1>Your Portfolio / LinkedIn</h1>
      <p>Please provide a link to your portfolio or LinkedIn profile.</p>
      <div className="field">
        <ApInputText formik={formik} id="portfolio_url" noField />
      </div>
    </div>
  );
};

export const SlidePhoto = function ({
  isFetching,
  talentId,
  saveImageCallback,
}: {
  isFetching: boolean;
  talentId: number;
  saveImageCallback: () => void;
}) {
  return (
    <div className="slide slide-photo">
      <h1>Lastly, a profile photo</h1>
      <p>Share a photo for your profile card - strike a pose!</p>
      {isFetching ? (
        <Loader size={2} />
      ) : (
        <ImageCropper isOnboarding talentId={talentId} saveImageCallback={saveImageCallback} isAvatarImage aspect={1} />
      )}
    </div>
  );
};

export const SlideFinal = function ({ skipOnboarding }: { skipOnboarding: boolean }) {
  return (
    <div className="slide slide-final">
      <h1>Excellent, all done!</h1>
      {skipOnboarding && <p>Someone from your team has already started the onboarding.</p>}
      <p>Remember, you can click on your profile section at anytime to sharpen it further.</p>
    </div>
  );
};
