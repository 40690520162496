import { useState } from "react";
import { useDispatch } from "react-redux";
import Axios from "axios";
import Toasts, { TOAST_ERROR_MESSAGE, TOAST_SUCCESS_MESSAGE } from "~/store/constants/toasts";
import talent, { ITalent } from "~/store/constants/talent";
import { TalentService } from "~/API/TalentService";
import { useHistory } from "react-router-dom";
import { IAIBioRequestParams, IQueryParams } from "~/interfaces/common";
import { TalentOnboardingFlow } from "~/store/reducers/talent";

const useTalent = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [isFetchingRemove, setIsFetchingRemove] = useState(false);
  const [isFetchingAvailabilityCalendar, setIsFetchingAvailabilityCalendar] = useState(false);
  const [matches, setMatches] = useState([]);
  const [individualUsers, setIndividualUsers] = useState<{ data: any; meta: any }>({ data: [], meta: undefined });
  const [introFlow, setIntroFlow] = useState(TalentOnboardingFlow.NONE);
  const dispatch = useDispatch();
  const history = useHistory();
  let controller: any;
  const createController = () => {
    controller = new AbortController();
  };
  const getMatches = async (isClientAdmin: boolean, talentId: number, matchesCategory: string) => {
    try {
      createController();
      setIsFetching(true);
      const response = await TalentService.getMatches(isClientAdmin, talentId, matchesCategory, controller);
      response?.status === 200 && setMatches(response.data);
      setIsFetching(false);
    } catch (err) {
      if (Axios.isCancel(err)) {
        console.error(err?.message);
      } else {
        setIsFetching(false);
        dispatch(Toasts.setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
      }
    }
  };

  const resetMatches = () => {
    setMatches([]);
    controller.abort();
  };

  const removeTalent = async (talentId: number) => {
    try {
      setIsFetchingRemove(true);
      await TalentService.removeTalent(talentId);

      history.push("/talents");
      dispatch(Toasts.setToasts([{ severity: "success", summary: "", detail: TOAST_SUCCESS_MESSAGE }]));
    } catch (err) {
      dispatch(Toasts.setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
    }

    setIsFetchingRemove(false);
  };

  const updateTalentCV = async (talentId: number, file: any, cb?: any) => {
    try {
      setIsFetching(true);
      const { status, data } = await TalentService.updateTalentCV(talentId, file);
      dispatch(talent.updateTalentCVsuccess(data.work_profile));
      dispatch(Toasts.setToasts([{ severity: "success", summary: "", detail: TOAST_SUCCESS_MESSAGE }]));
      status === 200 && !!cb && cb();
    } catch (err) {
      if (err.response?.status === 400 && err.response?.data?.code === "FILE_UPLOAD_FAILED") {
        dispatch(
          Toasts.setToasts([
            { severity: "error", summary: "", detail: err.response?.data?.message || TOAST_ERROR_MESSAGE },
          ])
        );
      } else {
        dispatch(Toasts.setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
      }
    } finally {
      setIsFetching(false);
    }
  };

  const deleteTalentCV = async (talentId: number) => {
    try {
      setIsFetching(true);
      const { data } = await TalentService.deleteTalentCV(talentId);
      dispatch(talent.updateTalentCVsuccess(data));
    } catch (err) {
      dispatch(Toasts.setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
    } finally {
      setIsFetching(false);
    }
  };

  const onboardingStarted = async () => {
    try {
      await TalentService.postUserEvent("onboarding_started");
      return true;
    } catch (err) {
      console.error(err);
    }
    return false;
  };

  const getIndividualUsers = async (params: IQueryParams) => {
    try {
      createController();
      setIsFetching(true);
      const res = await TalentService.getIndividualUsers(params, controller);
      res?.status === 200 && setIndividualUsers(res.data);
    } catch (err) {
      if (Axios.isCancel(err)) {
        console.error(err?.message);
      } else {
        setIsFetching(false);
        dispatch(Toasts.setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
      }
    } finally {
      setIsFetching(false);
    }
  };

  const editTalent = async (talentId: number, data: ITalent) => {
    try {
      createController();
      setIsFetching(true);
      const { status } = await TalentService.editTalent(talentId, data);

      return status;
    } catch (err) {
      if (Axios.isCancel(err)) {
        console.error(err?.message);
      } else {
        setIsFetching(false);
        dispatch(Toasts.setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
      }
    } finally {
      setIsFetching(false);
    }
  };

  const getIntroFlow = async () => {
    try {
      setIsFetching(true);
      const res = await TalentService.getIntroFLow();
      res?.status === 200 && setIntroFlow(res.data?.intro_flow);
    } catch (err) {
      console.error(err);
    } finally {
      setIsFetching(false);
    }
  };

  const toggleFavouriteTalent = async (talentId: number, isFavourite: boolean) => {
    try {
      setIsFetching(true);
      const res = isFavourite
        ? await TalentService.deleteFavouriteTalent(talentId)
        : await TalentService.postFavouriteTalent(talentId);
      if (res?.status === 204) {
        dispatch(talent.toggleFavouriteTalent(talentId));
        dispatch(
          Toasts.setToasts([
            {
              severity: "success",
              summary: "",
              detail: isFavourite ? "Profile removed from favourites" : "Profile added to favourites",
            },
          ])
        );
      }
    } catch (err) {
      dispatch(Toasts.setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
    } finally {
      setIsFetching(false);
    }
  };

  const updateTalentUser = async (userId: number, data: any) => {
    try {
      const res = await TalentService.updateUserById(userId, data);
      res?.status === 200 &&
        dispatch(Toasts.setToasts([{ severity: "success", summary: "", detail: TOAST_SUCCESS_MESSAGE }]));
    } catch (err) {
      dispatch(Toasts.setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
    }
  };

  const getAIBio = async (params: IAIBioRequestParams) => {
    try {
      setIsFetching(true);
      const res = await TalentService.getAIBio(params);
      if (res?.status === 200) {
        return res?.data?.bio;
      }
    } catch (err) {
      dispatch(Toasts.setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
      console.error(err);
    } finally {
      setIsFetching(false);
    }
  };

  const getAvailabilityCalendar = async (talentId: number, date: string) => {
    try {
      setIsFetchingAvailabilityCalendar(true);
      const res = await TalentService.getAvailabilityCalendar(talentId, date);
      if (res?.status === 200) {
        return res?.data;
      }
    } catch (err) {
      dispatch(Toasts.setToasts([{ severity: "error", summary: "", detail: TOAST_ERROR_MESSAGE }]));
      console.error(err);
    } finally {
      setIsFetchingAvailabilityCalendar(false);
    }
  };

  return {
    isFetching,
    isFetchingRemove,
    isFetchingAvailabilityCalendar,
    matches,
    getMatches,
    resetMatches,
    removeTalent,
    updateTalentCV,
    deleteTalentCV,
    onboardingStarted,
    getIndividualUsers,
    individualUsers,
    editTalent,
    introFlow,
    getIntroFlow,
    setIntroFlow,
    toggleFavouriteTalent,
    updateTalentUser,
    getAIBio,
    getAvailabilityCalendar,
  };
};
export default useTalent;
