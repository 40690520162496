export const EMAIL_REGEXP =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const URL_REGEXP =
  /^((https?):\/\/)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&\/=]*)?$/;
export const BACKEND_URL = `https://${process.env.REACT_APP_BACKEND_API_URL}`;
export const API_URL = `https://${process.env.REACT_APP_BACKEND_API_URL}/api`;
export const ENV = process.env.REACT_APP_ENV;

export const HOTJAR_ID = Number(process.env.REACT_APP_HOTJAR_ID);
export const HOTJAR_VERSION = Number(process.env.REACT_APP_HOTJAR_VERSION || 6);

export enum SSOConnections {
  GOOGLE = "google-oauth2",
  OFFICE365 = "windowslive",
}

export const shortCapacityValues = [25, 50, 75, 100];

export const primeLocale = {
  firstDayOfWeek: 1,
  dayNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
  dayNamesShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
  dayNamesMin: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
  monthNames: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],
  monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
  today: "Today",
  clear: "Clear",
  weekHeader: "Wk",
};

export const CONFIRM_LEAVE_PAGE =
  "Hold on, you haven’t saved the changes you made yet, do you still want to leave this section?";
