import React, { createContext, useContext } from "react";
import useEntity from "~/hooks/useEntity";
import { IEntityState } from "~/interfaces/entity";

export const EntityContext = createContext(null as IEntityState | null);

export const useEntityContext = () => {
  const context = useContext(EntityContext);
  if (!context) {
    throw new Error("useEntityContext must be used within a EntityContextProvider");
  }
  return context;
};

const EntityContextProvider = ({ children }: any) => {
  return (
    <EntityContext.Provider
      value={{
        ...useEntity(),
      }}
    >
      {children}
    </EntityContext.Provider>
  );
};

export default EntityContextProvider;
