import { AnyAction } from "redux";
import * as Broadcast from "../constants/broadcast";

// ------------------------------------
// Interface
// ------------------------------------
export interface IBroadcastState {
  isFetching: boolean;
}

// ------------------------------------
// Initial state
// ------------------------------------
const initialState: IBroadcastState = {
  isFetching: false,
};

// ------------------------------------
// Reducer
// ------------------------------------
export default (state = initialState, action: AnyAction): IBroadcastState => {
  switch (action.type) {
    case Broadcast.BROADCAST_SEND_SUCCESS:
    case Broadcast.BROADCAST_SEND_FAILURE:
      return { ...state, isFetching: false };
    case Broadcast.BROADCAST_SEND_REQUEST:
      return { ...state, isFetching: true };
    default:
      return state;
  }
};
