import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Form, Formik, FormikProps } from "formik";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { CaretRight } from "phosphor-react";
import DirectMatchClearanceForm from "~/forms/DirectMatchClearanceForm/DirectMatchClearanceForm";
import DirectMatchProjectBriefForm from "~/forms/DirectMatchProjectBriefForm/DirectMatchProjectBriefForm";
import DirectMatchConfirmation from "~/components/DirectMatchConfirmation/DirectMatchConfirmation";
import { useBriefContext } from "~/contexts/BriefContext";
import { useProjectContext } from "~/contexts/ProjectContext";
import { useGlobalContext } from "~/contexts/GlobalContext";
import actions from "~/store/actions/index";
import { IState } from "~/store/reducers/index";
import { ITalent } from "~/store/constants/talent";
import { ProjectStatuses } from "~/interfaces/project";
import { IDirectMatchForm } from "~/interfaces/directMatch";
import { directMatchSchema } from "~/schemas/DirectMatchSchema";
import { FLOW_STATUS } from "~/forms/DirectMatchProjectBriefForm/DirectMatchProjectBriefForm";
import "./DirectMatchModal.scss";

const TitleElement = (props: { status?: string; stepContent: string }) => {
  const getModalBase = () => {
    switch (props?.status) {
      case "CLEARANCE_REQUESTED":
        return "Request clearance for talent";
      case "CLEARANCE_AMENDED":
        return "Amend clearance for talent";
      case "BOOKED":
        return "Allocate talent";
      default:
        return "Add talent to brief";
    }
  };
  return (
    <div className="flex align-items-center">
      <span>{getModalBase()}</span>
      <CaretRight size={16} style={{ margin: "0 20px" }} />
      <span>{props.stepContent}</span>
    </div>
  );
};

export default function DirectMatchModal({
  newTalentStatus,
  preSelectedTalents,
}: {
  newTalentStatus: string;
  preSelectedTalents: ITalent[];
}) {
  const dispatch = useDispatch();
  const formikRef = useRef<FormikProps<IDirectMatchForm> | null>(null);
  const { createMatch, isMatchAdding } = useBriefContext();
  const { createProject, updateProjectProposal, isFetching: isProjectsFetching } = useProjectContext();
  const {
    global: { optionsNormalization },
  } = useGlobalContext();
  const isTalentUserFetching = useSelector((state: IState) => state.talent?.isFetching);
  const [step, setStep] = useState(newTalentStatus ? 1 : 2);

  const DURATION_ID = optionsNormalization?.DURATION_ID;
  const isEnquiredFreelancer = newTalentStatus === "ENQUIRED_FREELANCER";

  const initialValues: IDirectMatchForm = {
    talent_ids: preSelectedTalents.map((talent) => talent.id),
    brief_flow: FLOW_STATUS.EXISTING,
    project_flow: FLOW_STATUS.EXISTING,
    project_brief_flow: null,
    author_id: undefined,
    organisation_id: undefined,

    //clearance
    capacity: 100,
    start_date: "",
    end_date: "",
    dates: [],
    duration_id: DURATION_ID.CONSEC,
    duration_days: 1,
    include_weekends: true,
    comment: "",

    //brief
    project_id: undefined,
    brief_id: undefined,
    brief_name: "",
    wbs_code: "",
    job_number: "",
    description: "",
    description_url: "",
    assignees: [],

    //project
    project_name: "",
    project_custom_id: "",
    project_description: "",
    program_lead: "",
    client_lead: "",
    client_pod: "",
    client: "",
    organisations: [],
    project_dates: [],
    project_status: ProjectStatuses.OPEN,
    proposal_url: "",
  };

  const renderForm = (formikProps: any) => {
    switch (step) {
      case 1:
        return <DirectMatchClearanceForm formik={formikProps} preSelectedTalents={preSelectedTalents} />;
      case 2:
        return (
          <DirectMatchProjectBriefForm
            formik={formikProps}
            preSelectedTalents={preSelectedTalents}
            newTalentStatus={newTalentStatus}
          />
        );
      case 3:
        return (
          <DirectMatchConfirmation
            formik={formikProps}
            preSelectedTalents={preSelectedTalents}
            newTalentStatus={newTalentStatus}
          />
        );
      default:
        return null;
    }
  };

  const STEP_ACTIONS = {
    STEP_1_CLEARANCE: {
      title: "Select dates and capacity",
      onClickNext: () => setStep(step + 1),
      onClickBack: () => dispatch(actions.modal.closeModal()),
    },
    STEP_2_EXISTING_BRIEF: {
      title: !!newTalentStatus ? "Add talent to brief" : "",
      onClickNext: () => {
        if (!newTalentStatus) {
          formikRef.current?.submitForm();
        } else {
          setStep(step + 1);
        }
      },
      onClickBack: () => setStep(step - 1),
    },
    STEP_2_NEW_BRIEF_EXISTING_PROJECT: {
      title: "Select project and create new brief",
      onClickNext: () => setStep(step + 1),
      onClickBack: () => setStep(step - 1),
    },
    STEP_2_NEW_BRIEF: {
      title: "Create new brief",
      onClickNext: () => setStep(step + 1),
      onClickBack: async () => {
        await formikRef.current?.setFieldValue("project_brief_flow", null);
        formikRef.current?.validateForm();
      },
    },
    STEP_2_NEW_PROJECT: {
      title: "Create new project",
      onClickNext: async () => {
        await formikRef.current?.setFieldValue("project_brief_flow", FLOW_STATUS.NEW_PROJECT_NEW_BRIEF);
        //to trigger current validationSchema but don't show errors
        formikRef.current?.validateForm();
        formikRef.current?.setTouched({});
      },
      onClickBack: () => setStep(step - 1),
    },
    STEP_3_CONFIRM: {
      title: "Review and confirm",
      onClickNext: () => formikRef.current?.submitForm(),
      onClickBack: () => setStep(step - 1),
    },
    DEFAULT: {
      title: "Add talent to brief",
      onClickNext: () => setStep(step + 1),
      onClickBack: () => setStep(step - 1),
    },
  };

  const getStepCode = () => {
    const isNewBrief = formikRef.current?.values?.brief_flow === FLOW_STATUS.NEW;
    const isNewProject = formikRef.current?.values?.project_flow === FLOW_STATUS.NEW;
    const isBriefAfterProject = formikRef.current?.values?.project_brief_flow === FLOW_STATUS.NEW_PROJECT_NEW_BRIEF;

    if (step === 1) return "STEP_1_CLEARANCE";
    if (step === 2 && isBriefAfterProject) return "STEP_2_NEW_BRIEF";
    if (step === 2 && isNewProject) return "STEP_2_NEW_PROJECT";
    if (step === 2 && !isNewProject && isNewBrief) return "STEP_2_NEW_BRIEF_EXISTING_PROJECT";
    if (step === 2 && !isNewBrief) return "STEP_2_EXISTING_BRIEF";
    if (step === 3) return "STEP_3_CONFIRM";
    return "DEFAULT";
  };

  const onClickNext = () => {
    const code = getStepCode();
    STEP_ACTIONS[code].onClickNext();
  };

  const onClickBack = () => {
    const code = getStepCode();
    STEP_ACTIONS[code].onClickBack();
  };

  const showBackButton = () => {
    const code = getStepCode();
    if (step > 1 && !!newTalentStatus) return true;
    if (step > 2 && !newTalentStatus) return true;
    if (step === 2 && code === "STEP_2_NEW_BRIEF") return true;
  };

  const getNextButton = () => {
    const code = getStepCode();
    switch (true) {
      case step === 3:
        return "Confirm";
      case code === "STEP_2_EXISTING_BRIEF" && !newTalentStatus:
        return "Add";
      default:
        return "Next";
    }
  };

  const onSubmit = async (values: IDirectMatchForm) => {
    const isNewBrief = values.brief_flow === FLOW_STATUS.NEW;
    const isNewProject = values.project_flow === FLOW_STATUS.NEW;

    const clearance = {
      capacity: values.capacity.toString(),
      dates: values.duration_id === DURATION_ID.EXACT ? values.dates : [],
      start_date: values.start_date,
      end_date: values.end_date,
      duration_id: values.duration_id,
      include_weekends: values.include_weekends,
      status: newTalentStatus,
      process_status_update: !!newTalentStatus && ["BOOKED", "CLEARANCE_REQUESTED"].includes(newTalentStatus),
      enquiredFreelancerId: isEnquiredFreelancer && values.talent_ids.length === 1 ? values.talent_ids[0] : undefined,
    };

    const newBrief = {
      ...clearance,
      talent_ids: values.talent_ids,
      author_id: values.author_id,
      organisation_id: values.organisation_id,
      project_id: values.project_id,
      name: values.brief_name,
      wbs_code: values.wbs_code,
      job_number: values.job_number,
      description: values.description,
      description_url: values.description_url,
      assignees: values.assignees,
      status: undefined,
    };

    const newProject = {
      talent_ids: values.talent_ids,
      author_id: values.author_id as number,
      name: values.project_name,
      custom_id: values.project_custom_id,
      description: values.project_description,
      program_lead: values.program_lead,
      client_lead: values.client_lead,
      client_pod: values.client_pod,
      organisations: values.organisation_id ? [values.organisation_id] : [],
      status: values.project_status,
      proposal_url: values.proposal_url,
      start_date: moment(values.project_dates?.[0]).format("YYYY-MM-DD"),
      end_date: moment(values.project_dates?.[1] || values.project_dates?.[0]).format("YYYY-MM-DD"),
      assignees: values.assignees,
      ...(values.client ? { clients: [{ name: values.client as string }] } : { clients: [] }),
    };

    if (!isNewBrief) {
      await createMatch(values.talent_ids, values.brief_id, clearance);
    } else if (isNewBrief && !isNewProject) {
      dispatch(actions.talent.saveTalentRebook(newBrief));
    } else if (isNewProject) {
      const project = await createProject(newProject);

      if (!!project?.id && values.proposal_url instanceof File) {
        await updateProjectProposal({ id: project.id, proposal_url: values.proposal_url });
      }
      if (!!project?.id) {
        await dispatch(actions.talent.saveTalentRebook({ ...newBrief, project_id: project.id }));
      }
    }

    dispatch(actions.modal.closeModal());
    formikRef.current?.resetForm();
  };

  const loading = step === 3 && (isMatchAdding || isTalentUserFetching || isProjectsFetching);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      innerRef={formikRef}
      validationSchema={() => directMatchSchema(getStepCode())}
    >
      {(formikProps) => (
        <Form>
          <Dialog
            className="DirectMatchModal"
            header={<TitleElement status={newTalentStatus} stepContent={STEP_ACTIONS[getStepCode()].title} />}
            footer={
              <footer>
                <div>
                  <Button
                    className="p-button p-button-secondary"
                    label="Cancel"
                    onClick={() => {
                      formikProps.resetForm();
                      dispatch(actions.modal.closeModal());
                    }}
                    type="button"
                  />
                </div>
                <div>
                  {showBackButton() && (
                    <Button className="p-button p-button-secondary" label="Back" onClick={onClickBack} />
                  )}
                  <Button
                    className="p-button"
                    label={getNextButton()}
                    onClick={onClickNext}
                    disabled={!formikProps.isValid || formikProps.isSubmitting}
                    loading={loading}
                  />
                </div>
              </footer>
            }
            visible={true}
            modal={true}
            onHide={() => dispatch(actions.modal.closeModal())}
          >
            {renderForm(formikProps)}
          </Dialog>
        </Form>
      )}
    </Formik>
  );
}
